import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Drawer, Form, Input, message, Row, Select, Tabs, Typography } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { fetchSchools } from 'api/services/courses/courses.services';
import { StatusCode } from "common/enums";
import StudentsTable from "components/invitationHistory";
import AppButton from "components/libs/button";
import AppSelectGroupInscription from "components/libs/selectGroupInscription";
import { Dispatch, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RootStateOrAny, useSelector } from "react-redux";
import { logout } from "store/auth/authSlice";
import * as TEACHER_SERVICES from "../../api/services/teacher/teacher.services";
import s from "./style.module.css";

type Props = {
    visible: boolean,
    onClose: () => void,
}

const { Panel } = Collapse;

const { TabPane } = Tabs;
const { Text } = Typography;

const InvitationDrawer: React.FC<Props> = ({ visible, onClose }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    //!HOOKS
    const { t } = useTranslation();
    const auth = useSelector((state: RootStateOrAny) => state.auth);
    const [formInfos] = Form.useForm();
    const [registerFormInfos] = Form.useForm();
    const { user } = useSelector((state) => state.auth);

    //!STATES
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [studentCount, setStudentCount] = useState<number>(1);
    const [studentRegistrationCount, setStudentRegistrationCount] = useState<number>(1);
    const [students, setStudents] = useState<any>([]);
    const [registredStudents, setRegistredStudents] = useState<any>([]);
    const [studentsInvited, setStudentsInvited] = useState<any>(user?.listInvite);

    const [dataList] = useState<any>([]);
    const [currentPanel, setCurrentPanel] = useState<any>(["1", "2"]);
    const [currentTab, setCurrentTab] = useState<any>("1");
    const [educationSystemList, setEducationSystem] = useState<any>([]);

    const [countriesList, setCountries] = useState<any>([]);
    const [schoolList, setSchools] = useState<string[]>([]);
    const [system, setSystem] = useState<string>();
    const [country, setCountry] = useState<string>();
    const [loadingSchools, setLoadingSchools] = useState<boolean>(false);

    //!CONSTANTS
    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    ///Student Div
    const [divs, setDivs] = useState([{ index: studentCount }]);
    const [registrationDivs, setRegistrationDivs] = useState([{ index: studentRegistrationCount }]);

    ///Add New Student
    const handleAddDiv = (index: number) => {
        const newDivs = [...divs, { index: index }];
        setDivs(newDivs);
    };

    const handleAddRegistrationDiv = (index: number) => {
        const newRegistrationDivs = [...registrationDivs, { index: index }];
        setRegistrationDivs(newRegistrationDivs);
    };

    ///Remove Student
    const handleRemoveStudentDiv = (index: number) => {
        const newDivs = [...divs];
        newDivs.splice(index, 1);
        setDivs(newDivs);
        setStudentCount((studentCount) => studentCount - 1);
    };

    const handleRemoveStudentRegistrationDiv = (index: number) => {
        const newRegistrationDivs = [...registrationDivs];
        newRegistrationDivs.splice(index, 1);
        setRegistrationDivs(newRegistrationDivs);
        setStudentRegistrationCount((studentRegistrationCount) => studentRegistrationCount - 1);
    };

    const closeDrawer = async () => {
        const newDivs = [...divs];
        for (let i = 0; i < studentCount - 1; i++) {
            newDivs.splice(i, 1);
            setDivs(newDivs);
        }
        setStudentCount(1);
        onClose();
    }

    const handleCountryChange = (value: string) => {
        setCountry(value);
    };

    const handleSystemChange = (value: string) => {
        setSystem(value);
    };

    const addNewStudent = (index: number) => {
        setStudentCount((studentCount: number) => studentCount + 1);
        handleAddDiv(index);
    }

    const registerNewStudent = (index: number) => {
        setStudentRegistrationCount((studentRegistrationCount: number) => studentRegistrationCount + 1);
        handleAddRegistrationDiv(index);
    }

    const inviteStudent = async (values: any) => {
        for (let i = 0; i < studentCount; i++) {

            setIsLoading(true);

            var student = {};

            for (let i = 0; i < studentCount; i++) {

                const firstname = `firstname${i}`;
                const lastname = `lastname${i}`;
                const email = `email${i}`;

                student = {
                    "firstname": values[firstname],
                    "lastname": values[lastname],
                    "email": values[email],
                }
                students.push(student);

            }
        }
        addStudent(students);
    }

    const registerStudent = async (values: any) => {

        setIsLoading(true);

        var student = {};

        for (let i = 0; i < studentRegistrationCount; i++) {

            const firstname = `registrationFirstname${i}`;
            const lastname = `registrationLastname${i}`;
            const educSystem = `educSystem${i}`;
            const levelId = `level${i}`;
            const pays = `pays${i}`;
            const etablissement = `etablissement${i}`;

            if (values[firstname] !== undefined && values[lastname] !== undefined && values[educSystem] !== undefined && values[levelId] !== undefined && values[pays] !== undefined && values[etablissement] !== undefined) {
                student = {
                    "firstname": values[firstname],
                    "lastname": values[lastname],
                    "email": `${(values[firstname] || "").trim().replace(/\s+/g, "")}.${(values[lastname] || "").trim().replace(/\s+/g, "")}.${(user?.lastname || "").trim().replace(/\s+/g, "")}@monamialbert.com`,
                    "pseudo": values[firstname] + "." + values[lastname] + "." + user?.lastname,
                    "password": "123456",
                    "pays": values[pays],
                    "educSystem": values[educSystem],
                    "levelId": values[levelId],
                    "accountManaged": user?._id,
                    "organisationName": user?.lastname + " " + user?.firstname,
                    "etablissement": values[etablissement],
                };
                registredStudents.push(student);
            }
        }
        addRegisteredStudent();
    }

    const addStudent = async (students: any) => {
        try {
            const response = await AUTH_SERVICES.inviteStudents(user?._id, students);
            if (response?.statusCode === StatusCode.CREATED) {
                message.success({
                    content: "Invitations envoyées ",
                });
                setStudentsInvited(response?.data['listInvite']);
                setIsLoading(false);
                onClose();
            }
            else {
                message.warning({
                    content: "Ooops, Utilisateur déjà inscrit",
                });
                setIsLoading(false);
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        }
    }

    const addRegisteredStudent = async () => {
        for (let i = 0; i < registredStudents.length; i++) {
            try {
                const response = await AUTH_SERVICES.registerSponsoredStudentWithEmailPassword(registredStudents[i]);
                if (response?.statusCode === StatusCode.CREATED) {
                    message.success({
                        content: "Inscription effectuée ",
                    });
                }
                else {
                    message.warning({
                        content: response?.data?.message,
                    });
                    setIsLoading(false);
                }
            } catch (error: any) {
                message.error({
                    content: "Erreur lors de l'inscription ",
                });
            }
        }
        const storedPasscode = localStorage.getItem('userPasscode');
        if (storedPasscode) {
            await AUTH_SERVICES.reauthenticateParentOrganisation(user?.email, storedPasscode);
            setIsLoading(false);
            onClose();
        } else {
            dispatch(logout());
            localStorage.clear();
            setIsLoading(false);
            onClose();
        }
    }

    const getStaticLists = useCallback(
        async (type: string, dataList: any, state: Dispatch<any>) => {
            const response = await TEACHER_SERVICES.getDataList(type);
            if (response?.statusCode === StatusCode.OK) {
                state(response?.data);
            }
        },
        [dataList]
    );

    const handleTabSelect = (index: any) => {
        if (index !== "2") {
            setCurrentTab(index);
        }
    }

    const getSchoolsLists = useCallback(
        async () => {
            try {
                setLoadingSchools(true);
                if (country !== undefined && system !== undefined) {
                    const response = await fetchSchools(country, system);
                    if (response?.statusCode === StatusCode.OK) {
                        const namesArray = response.data.map((item: { name: any; }) => item);
                        setSchools(namesArray);
                    }
                }
            }
            catch (error) { }
            finally {
                setLoadingSchools(false);
            }
        },
        [country, system]
    );

    //!EFFECTS
    useEffect(() => {
        getStaticLists('educSystem', educationSystemList, setEducationSystem);
    }, []);

    useEffect(() => {
        getStaticLists('pays', countriesList, setCountries);
    }, []);

    useEffect(() => {
        getSchoolsLists();
    }, [country, system]);

    return (
        <div>
            <Drawer
                title={<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                    <div style={{ textTransform: "uppercase", fontSize: "1.2em", margin: "0px auto" }}>{"Ajouter des élèves"}</div>
                </div>}
                placement="right"
                closable={true}
                onClose={closeDrawer}
                visible={visible}
                width={isMobile ? "100%" : "70%"}
                headerStyle={{ display: "flex", justifyContent: "space-between" }}
                bodyStyle={{ background: "#FFF", padding: "40px" }}
            >

                <div style={{ margin: '0 auto', padding: '20px', borderRadius: '42px', border: '1px solid #707070', backgroundColor: '#FAFAFA' }}>
                    <Tabs defaultActiveKey="1" centered activeKey={currentTab} onChange={(activeKey) => handleTabSelect(activeKey)}>
                        <TabPane tab={<span style={{ fontSize: '18px' }}>Inviter mes élèves</span>} key="1">
                            <div style={{ fontSize: "16px", color: "#000000", fontFamily: "Poppins", fontWeight: "500", opacity: "60%", lineHeight: "40px", margin: "0px auto", textAlign: "center" }}>
                                Vous pouvez ici envoyer des invitations à vos élèves pour les associer à votre compte.
                            </div>

                            <div style={{ margin: "10px auto", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Form
                                    validateTrigger={["onFinish"]}
                                    validateMessages={validateMessages}
                                    name="inviteStudent"
                                    autoComplete="off"
                                    form={formInfos}
                                    onFinish={inviteStudent}
                                >
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                        {divs.map((div, index) => (
                                            <div key={div.index} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                                <Form.Item
                                                    name={`lastname${index}`}
                                                    rules={[{ required: true }]}
                                                    style={{ marginRight: "20px" }}
                                                >
                                                    <Input
                                                        className={s.field}
                                                        placeholder={t("auth.lastname")}
                                                        maxLength={30}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name={`firstname${index}`}
                                                    rules={[{ required: true }]}
                                                    style={{ marginRight: "20px" }}
                                                >
                                                    <Input
                                                        className={s.field}
                                                        placeholder={t("auth.firstname")}
                                                        maxLength={30}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name={`email${index}`}
                                                    rules={[{ required: true }, { type: "email" }]}
                                                    style={{ marginRight: "20px" }}
                                                >
                                                    <Input
                                                        type="email"
                                                        className={s.field}
                                                        placeholder={t("auth.email")}
                                                    />
                                                </Form.Item>

                                                {studentCount !== 0 && studentCount !== 1 && <Button icon={<MinusOutlined />} style={{ position: "relative", bottom: "12.5px", borderRadius: "50%", color: "white", backgroundColor: "red", width: "40px", height: "40px" }} onClick={() => handleRemoveStudentDiv(index)} />}
                                                {index === studentCount - 1 && <Button icon={<PlusOutlined />} style={{ position: "relative", bottom: "12.5px", borderRadius: "50%", color: "white", backgroundColor: "#80B1B2", width: "40px", height: "40px", marginLeft: "5px" }} onClick={() => addNewStudent(studentCount)} />}
                                            </div>
                                        ))}
                                    </div>
                                    <Row justify="center" style={{ marginTop: "30px" }}>
                                        <Form.Item>
                                            <AppButton loading={isLoading} htmlTypeSubmit={true} style={{ textTransform: "none" }}><a style={{ fontSize: "14px" }}>Envoyer les invitations</a></AppButton>
                                        </Form.Item>
                                    </Row>
                                </Form>
                            </div>

                            <div style={{ textAlign: 'center', marginBottom: '20px', display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                <Text style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: "500", lineHeight: "10px", opacity: "60%" }}>
                                    Vous souhaitez plutôt inscrire vos élèves directement ?
                                </Text>
                                <div onClick={() => handleTabSelect("3")} style={{ fontSize: "15px", fontFamily: "Poppins", lineHeight: "10px", color: "#8AB0B1", fontWeight: "bold", marginLeft: "5px", cursor: "pointer" }}>{' '}C’est par ici</div>
                            </div>
                        </TabPane>
                        <TabPane tab={<span style={{ fontSize: '18px', color: "#707070" }}>|</span>} key="2"></TabPane>
                        <TabPane tab={<span style={{ fontSize: '18px' }}>Inscrire mes élèves</span>} key="3">
                            <div style={{ fontSize: "16px", color: "#000000", fontFamily: "Poppins", fontWeight: "500", opacity: "60%", lineHeight: "40px", margin: "0px auto", textAlign: "center" }}>
                                Effectuer l’inscription de vos élèves directement, les informations d’accès seront<br />visible sur votre Dashboard
                            </div>

                            <div style={{ margin: "10px auto", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Form
                                    validateTrigger={["onFinish"]}
                                    validateMessages={validateMessages}
                                    name="registerStudent"
                                    autoComplete="off"
                                    form={registerFormInfos}
                                    onFinish={registerStudent}
                                >
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                        {registrationDivs.map((div, index) => (
                                            <div key={div.index} style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                                <Form.Item
                                                    name={`registrationLastname${index}`}
                                                    rules={[{ required: true }]}
                                                    style={{ marginRight: isMobile ? "5px" : "10px" }}
                                                >
                                                    <Input
                                                        className={s.field}
                                                        placeholder={t("auth.lastname")}
                                                        maxLength={30}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name={`registrationFirstname${index}`}
                                                    rules={[{ required: true }]}
                                                    style={{ marginRight: isMobile ? "0px" : "10px" }}
                                                >
                                                    <Input
                                                        className={s.field}
                                                        placeholder={t("auth.firstname")}
                                                        maxLength={30}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name={`level${index}`}
                                                    rules={[{ required: true }]}
                                                    style={{ marginRight: isMobile ? "0px" : "10px", paddingTop: "5px" }}
                                                    className={s.field}
                                                >
                                                    <AppSelectGroupInscription
                                                        placeholder="Classe"
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name={`educSystem${index}`}
                                                    rules={[{ required: true }]}
                                                    style={{ marginRight: isMobile ? "0px" : "10px", width: "200px" }}
                                                >
                                                    <Select
                                                        style={{ padding: "5px 0px 5px 0px", fontSize: "16px" }}
                                                        className={s.field}
                                                        placeholder={"Système d'éducation"}
                                                        mode="multiple"
                                                        bordered={false}
                                                        allowClear
                                                        dropdownStyle={{ padding: "3px" }}
                                                        showArrow={true}
                                                        onSelect={handleSystemChange}
                                                    >
                                                        {educationSystemList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{item}</Select.Option>))}
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item
                                                    style={{ marginRight: isMobile ? "0px" : "10px", width: "200px" }}
                                                    name={`pays${index}`}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ padding: "0px", fontSize: "16px", color: "#333" }}
                                                        className={s.field}
                                                        placeholder={<div style={{ fontSize: "16px", color: "#C0C0C0" }}>Pays</div>}
                                                        bordered={false}
                                                        allowClear
                                                        dropdownStyle={{ padding: "3px" }}
                                                        showArrow={true}
                                                        onSelect={handleCountryChange}
                                                        optionFilterProp="children"
                                                        filterOption={(input: any, option: any) =>
                                                            option?.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {countriesList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{item}</Select.Option>))}
                                                    </Select>
                                                </Form.Item>

                                                <Form.Item
                                                    style={{ marginRight: isMobile ? "0px" : "10px", width: "200px" }}
                                                    name={`etablissement${index}`}
                                                    rules={[{ required: true }]}
                                                >
                                                    <Select
                                                        showSearch
                                                        style={{ padding: "0px", fontSize: "16px", color: "#333" }}
                                                        className={s.field}
                                                        placeholder={<div style={{ fontSize: "16px", color: "#C0C0C0" }}>Établissement</div>}
                                                        bordered={false}
                                                        allowClear
                                                        dropdownStyle={{ padding: "3px" }}
                                                        showArrow={true}
                                                        loading={loadingSchools}
                                                        optionFilterProp="children"
                                                        filterOption={(input: any, option: any) =>
                                                            option?.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                    >
                                                        {!loadingSchools && schoolList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{item}</Select.Option>))}
                                                    </Select>
                                                </Form.Item>

                                                <div>
                                                    {studentRegistrationCount !== 0 && studentRegistrationCount !== 1 && <Button icon={<MinusOutlined />} loading={isLoading} style={{ position: "relative", bottom: "12.5px", borderRadius: "50%", color: "white", backgroundColor: "red", width: "40px", height: "40px" }} onClick={() => handleRemoveStudentRegistrationDiv(index)} />}
                                                    {index === studentRegistrationCount - 1 && <Button icon={<PlusOutlined />} loading={isLoading} style={{ position: "relative", bottom: "12.5px", borderRadius: "50%", color: "white", backgroundColor: "#80B1B2", width: "40px", height: "40px", marginLeft: "5px" }} onClick={() => registerNewStudent(studentRegistrationCount)} />}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <Row justify="center" style={{ marginTop: "30px" }}>
                                        <Form.Item>
                                            <AppButton loading={isLoading} htmlTypeSubmit={true} style={{ textTransform: "none" }}><a style={{ fontSize: "14px" }}>Effectuer l’inscription</a></AppButton>
                                        </Form.Item>
                                    </Row>
                                </Form>
                            </div>

                            <div style={{ textAlign: 'center', marginBottom: '20px', display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                                <Text style={{ fontSize: "16px", fontFamily: "Poppins", fontWeight: "500", lineHeight: "10px", opacity: "60%" }}>
                                    Vous souhaitez plutôt inviter vos élèves ?
                                </Text>
                                <div onClick={() => handleTabSelect("1")} style={{ fontSize: "15px", fontFamily: "Poppins", lineHeight: "10px", color: "#8AB0B1", fontWeight: "bold", marginLeft: "5px", cursor: "pointer" }}>{' '}C’est par ici</div>
                            </div>
                        </TabPane>
                    </Tabs>
                </div>

                <div style={{ margin: "40px auto" }}>
                    <StudentsTable students={studentsInvited} />
                </div>
            </Drawer>
        </div>
    );

};

export default InvitationDrawer;

function dispatch(arg0: any) {
    throw new Error("Function not implemented.");
}
