import { CloseOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Affix,
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Popconfirm,
  Result,
  Row,
  Select,
  Skeleton
} from "antd";
import * as ADS_SERVICES from "api/services/advertissements/advertissements.services";
import {
  findAllTeacherInsta,
  killAllTeacherInsta
} from "api/services/advertissements/advertissements.services";
import { FLAGS, StatusCode, UserRoles } from "common/enums";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import DrawerProfileTeacher from "screens/teacher/components/drawerProfileTeacher";
import {
  setChatReceiverId,
  setSelectUserChat,
  toggleAppChat
} from "store/AppChat/appChat";
import { setIsDrawerInstaProf } from "store/chat/roomSlice";
import { useFindRoomMutation } from "store/services/chat";
import {
  useLastSessionInstaProfQuery,
  useStopRequestInstaProfMutation,
} from "store/services/teacher/notification.services";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";
import SuccesSessionInsta from "./components/succesSessionInst";

//!COMPONENT
import CountdownTimer from "components/appCountDown";
import AppDrawer from "components/appDrawer";
import AppButton from "components/libs/button";
import GridInstaProf from "components/libs/gridInstaProf";
import H1 from "components/libs/title";
import CardAdsTeacher from "./components";

//!ASSETS
import ArrowBack from "../../../assets/images/app/arrow_back.svg";
import InstaIcon from "../../../assets/images/app/insta_icon.svg";

//!STYLE
import axios from "axios";
import { AppNotification } from "components/appNotification";
import AppResult from "components/appResults";
import AppSelectGroupInscription from "components/libs/selectGroupInscription";
import ZoomMeeting from "components/zoom";
import { useCheckSaissonMutation } from "store/services/student/ads.services";
import s from "./assets/style.module.css";

type Props = {
  openDrawerInstaProf: boolean;
  showDrawerSearchInstaProf: () => void;
  isInstaProf?: boolean;
  isBtnInstaProf: boolean;
  dataOfTeacher: any;
  setIsBtnInstaProf: React.Dispatch<React.SetStateAction<boolean>>;
};

type ErrorResponse = {
  error: string;
};

const { Option } = Select;

const DrawerSearchInstaProf: React.FC<Props> = ({
  openDrawerInstaProf,
  showDrawerSearchInstaProf,
  isInstaProf,
  isBtnInstaProf,
  dataOfTeacher,
  setIsBtnInstaProf,
}) => {
  //!HOOKS
  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const { socket } = useSelector((state) => state.appChat);
  const [findRoom, { isSuccess }] = useFindRoomMutation();
  const {
    isSuccess: isSesssionSuccess,
    data: sessionData,
    isLoading,
    refetch,
  } = useLastSessionInstaProfQuery(user?._id);
  const [checkSaisson, { data: dataCheckSaisson }] = useCheckSaissonMutation();
  const [
    stopRequestInstaProf,
    {
      isSuccess: isSuccessStopSession,
      isLoading: isStopingRequestInstaProf,
      isError,
    },
  ] = useStopRequestInstaProfMutation();

  const popupRef = useRef<HTMLDivElement>(null);

  //!STATES
  const [top, setTop] = useState(10);
  const [tempTopic, setTempTopic] = useState<any>([]);
  const [instaProf, setInstaProf] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const [currentProfAccepted, setCurrentProfAccepted] = useState<any>(null);
  const [openD, setOpenD] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [isAcceptedProf, setIsAcceptedProf] = useState<boolean>(false);
  const [teacherHasSession, setTeacherHasSession] = useState<boolean>(false);
  const [isStopingInstaProf, setIsStopingInstaProf] = useState<boolean>(false);
  const [infoTeacherAcceptedReservation, setInfoTeacherAcceptedReservation] =
    useState<any>(null);
  const [openDrawerProfileTeacher, setOpenDrwerProfileTeacher] =
    useState<boolean>(false);
  const [uidTeacherInvite, setUidTeacherInvite] = useState<string>("");

  const THREE_DAYS_IN_MS = 5 * 60 * 1000;
  const NOW_IN_MS = new Date().getTime();
  const dateTimeAfterThreeDays = NOW_IN_MS + THREE_DAYS_IN_MS;

  const [instaTeacher, setInstaTeacher] = useState<any>(null);

  const [isZoomMeetingVisible, setIsZoomMeetingVisible] = useState(false);
  const [currentMeetingId, setCurrentMeetingId] = useState('');

  const isStudent = user?.roles?.includes(UserRoles.STUDENT);

  const filterSubjects = (allSubjects: any[], levelsWithSubjects: any[]) => {
    const validDescriptions = new Set(
      levelsWithSubjects.flatMap((level) => level.matieres.map((m: any) => m.matiere.description))
    );

    return allSubjects.filter((subject) => validDescriptions.has(subject.description));
  };

  //!FUNCTIONS
  const OnSelectLevel = async (levelId?: string) => {
    if (!levelId) return;

    const response = await TOPICS_SERVICES.findAll(levelId);
    if (response?.statusCode === StatusCode.OK) {
      if (dataOfTeacher?.teacher?.nivMat) {
        const filteredSubjects = filterSubjects(response?.data, dataOfTeacher.teacher.nivMat);
        setTempTopic(filteredSubjects);
      } else {
        setTempTopic(response?.data);
      }
    }
  };


  const onSearchTeacherInsta = async (values: any) => {
    setIsSearch(true);
    const topicId =
      dataOfTeacher?.teacher?.nivMat[0]?.matieres[0]?.matiere?._id;

    const bodyInviteProf = {
      ...values,
      userProf: dataOfTeacher?.teacher?._id,
      level: dataOfTeacher ? user?.levelId : values?.level,
      matiere: dataOfTeacher ? topicId : values?.matiere,
    };

    try {
      const response = await findAllTeacherInsta(
        isBtnInstaProf ? bodyInviteProf : values
      );
      if (response?.statusCode === StatusCode.CREATED) {
        setInstaProf(response?.data);

        if (
          response?.message.includes("Prof is in a current session InstaProf")
        ) {
          setTeacherHasSession(true);
        }

        localStorage.setItem(
          "studentTempCodeInstaProf",
          response?.data?.session?.code
        );
      }
    } catch (error: any) {
      if (axios.isAxiosError(error)) {
        if (
          error?.response?.data?.message?.includes(
            "Prof is in a current session InstaProf"
          )
        ) {
          setTeacherHasSession(true);
        }
      } else {
      }
    }
  };

  const onClear = (ad: any) => {
    const teacher = ad.prof;
    const final = Object.assign(ad, { teacher: teacher });
    // delete ad.prof;

    return final;
  };

  const resetDrawerInstaProf = () => {
    setInstaTeacher(null);
    setIsSearch(false);
    setData([]);
    setInstaProf([]);
    setIsBtnInstaProf(false);
    localStorage.removeItem("studentTempCodeInstaProf");
    showDrawerSearchInstaProf();
  };

  const handleStopRequestInstaProf = async () => {
    socket?.connect();
    await killAllTeacherInsta(user?._id, instaProf?.session?.code);
    await socket.emit("killRequestInstaProf", instaProf?.session?.code);
    setIsStopingInstaProf(false);
    setIsSearch(false);
    setData([]);
    setInstaProf([]);
    setIsBtnInstaProf(false);
    localStorage.removeItem("studentTempCodeInstaProf");
  };

  const handleCloseDrawer = () => {
    resetDrawerInstaProf();
  };

  const handleClear = (res: any[]) => {
    console.log(res);
    return res.filter(
      (arr: any, index: number, self: any[]) =>
        index ===
        self.findIndex(
          (t: any) => t.code === arr.code && t.prof?._id === arr.prof?._id
        )
    );
  };

  type AcceptTeacherProps = {
    teacher: { _id: string };
  };

  const handleStudentAcceptTeacher = useCallback(
    async (value: AcceptTeacherProps) => {
      try {
        const data = await ADS_SERVICES.StudentCheckSession(value.teacher._id);

        if (data?.data.inCurrentSession === false) {
          setIsAcceptedProf(true);

          const { session } = instaProf || {};

          const finalBody = {
            studentId: user?._id,
            profId: value.teacher._id,
            level: session?.level,
            topic: session?.topic,
            range: session?.range || ["12:00", "14:00"],
            startDate: session?.createdAt,
            codeInstaProf: session?.code,
          };

          const res = await ADS_SERVICES.AcceptAProfByStudent(finalBody);

          const dataRefuseProfByStudent = {
            codeInstaProf: currentProfAccepted?.codeInstaProf,
            selectedProfId: value.teacher._id,
          };

          await Promise.all([
            socket.emit("acceptRequestByStudent", {
              code: currentProfAccepted?.code,
              reserveObj: res?.data,
            }),
            ///socket.emit("refuseProfByStudent", dataRefuseProfByStudent),
          ]);

          setInfoTeacherAcceptedReservation(res?.data);
          setIsSearch(false);
          resetDrawerInstaProf();
          setOpenD(true);
          refetch();
        } else {
          setTeacherHasSession(true);
        }
      } catch (error) {
      } finally {
        setIsAcceptedProf(false);
        localStorage.removeItem("studentTempCodeInstaProf");
      }
    },
    [instaProf, user, dataCheckSaisson, currentProfAccepted, refetch]
  );

  const handleDisplayTeacher = (idTeacher: string) => {
    if (isInstaProf) {
      dispatch(setIsDrawerInstaProf(true));
    }

    const finalData = {
      actor1: user?._id,
      actor2: idTeacher,
      flag: FLAGS.SINGLE_FLAG,
      type: `${isInstaProf ? "instaprof" : ""}`,
      instaprof: `${isInstaProf ? "find" : ""}`,
    };
    findRoom(finalData);
    dispatch(setChatReceiverId(idTeacher));
  };

  const handleOpenDrwerProfileTeacher = () => {
    setOpenDrwerProfileTeacher(true);
  };

  const checkAmount = (value: any) => {
    if (instaTeacher) {
      const twentyPercent = (instaTeacher?.teacher?.instaProfCost * 20) / 100;
      const minAmount = instaTeacher?.teacher?.instaProfCost - twentyPercent;
      if (value <= user?.coins) {
        if (value < minAmount) {
          message.warning("Le montant proposé est trop bas !");
          setDisabled(true);
        }
        else {
          setDisabled(false);
        }
      }
    }
  }

  useEffect(() => {
    if (user?.levelId) {
      OnSelectLevel(user.levelId);
    }
  }, [openDrawerInstaProf]);


  useEffect(() => {
    if (isSearch) {
      socket?.on("receivedProfAcceptedToStudent", (resp: any) => {
        console.log(resp);
        setData((res: any) => {
          return [...res, resp];
        });
      });

      socket?.on("receivedProfRefusedToStudent", (resp: any) => { });
    }

    socket?.on("receivedProfRefusedToStudent", (resp: any) => { });
  }, [isSearch, loading, instaProf, data, socket]);

  useEffect(() => {
    socket?.on("receivedStopRequest", (resp: any) => {
      if (resp && isStudent) {
        setIsStopingInstaProf(true);
        setOpenD(false);
        showDrawerSearchInstaProf();
        refetch();
      }
    });
  }, [/* isStopingInstaProf,  */socket]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setSelectUserChat(true));
      dispatch(toggleAppChat(true));
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isSuccessStopSession) {
      AppNotification(<span>Session arrêtée avec succès</span>, "AlbertHappy");
      setIsSearch(false);
      setData([]);
      setInstaProf([]);
      localStorage.removeItem("studentTempCodeInstaProf");
      refetch();
    }
  }, [isSuccessStopSession]);

  useEffect(() => {
    if (dataOfTeacher !== null) {
      setInstaTeacher(dataOfTeacher);
    }
  }, [dataOfTeacher]);

  const handleMeetingClick = () => {
    if (!sessionData?.data[0]?.data?.studentLink) {
      message.error("Lien de réunion non trouvé pour ce cours");
      return;
    }

    // Extract meeting ID from Zoom link - handle multiple URL formats
    let meetingId;
    try {
      const url = new URL(sessionData?.data[0]?.data?.studentLink);
      // Handle different Zoom URL formats
      if (url.pathname.includes('/j/')) {
        meetingId = url.pathname.split('/j/')[1]?.split('?')[0];
      } else if (url.pathname.includes('/s/')) {
        meetingId = url.pathname.split('/s/')[1]?.split('?')[0];
      } else {
        // Try to find any number sequence that could be a meeting ID
        meetingId = url.pathname.match(/\d{10,11}/)?.[0];
      }

      // Remove any non-numeric characters
      meetingId = meetingId?.replace(/\D/g, '');

      if (!meetingId || meetingId.length < 10) { // Zoom meeting IDs are typically 10-11 digits
        throw new Error('Invalid meeting ID format');
      }

      setCurrentMeetingId(meetingId);
      setIsZoomMeetingVisible(true);
    } catch (error) {
      message.error("ID de réunion invalide");
      return;
    }
  };

  const content = (
    <div>
      <Row justify="center" align="middle" style={{ marginBottom: "0.5em" }}>
        <Col>
          <CloseOutlined style={{ color: "red", fontSize: "23px" }} />
        </Col>
      </Row>

      <Row justify="center" align="middle" style={{ marginBottom: "0.5em" }}>
        <Col>
          <h3 style={{ color: "#333" }}>Votre solde est insuffisant</h3>
        </Col>
      </Row>

      <Row justify="center" align="middle" style={{ marginBottom: "0.5em" }}>
        <Col>
          <h3>
            <span style={{ color: "red" }}>{user?.coins}</span> €
          </h3>
        </Col>
      </Row>
    </div>
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1199);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1199 && window.innerWidth < 1606);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
    setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
    setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1199);
    setIsMediumScreen(window.innerWidth > 1199 && window.innerWidth < 1606);
  };
  window.addEventListener('resize', handleResize);

  return (
    <>
      <Drawer
        className="drawer"
        placement="right"
        width={isMobile ? "100%" : isXsScreen ? "100%" : isSmallScreen ? "100%" : isMediumScreen ? "95%" : "70%"}
        onClose={isSearch ? () => null : handleCloseDrawer}
        visible={openDrawerInstaProf}
        closable={isSearch ? false : true}
        headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: isMobile ? "20px" : "40px", backgroundColor: "transparent" }}
        closeIcon={
          <img
            src={ArrowBack}
            height={isMobile ? 10 : 18}
            style={{}}
          ></img>
        }
        drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: isMobile ? "0px" : "50px", borderBottomLeftRadius: isMobile ? "0px" : "50px", boxShadow: "-3px 3px 34px #39393929" }}
        title={
          <div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start", fontSize: isMobile ? "0.8em" : "20px", fontFamily: "Poppins", fontWeight: "600", color: "#393E3E", backgroundColor: "transparent" }}>
              <img src={InstaIcon} alt="" height={25} style={{ marginRight: "10px" }} />
              {!isStopingInstaProf && isSearch ? (
                <Fragment>
                  {" "}
                  {data.length > 0 ? (
                    <div className={s.drawer__title}>
                      <H1>Ta demande instaprof expire dans</H1>
                    </div>
                  ) : (
                    instaTeacher ? "Demande envoyée" : "Alerte lancée !"
                  )}
                </Fragment>
              ) : (
                "Instaprofs"
              )}
            </div>

            {isSearch && (
              <Affix offsetTop={top}>
                <div
                  style={{
                    width: "100%",
                    textAlign: isMobile ? "center" : "end",
                    paddingRight: isMobile ? "0%" : "10%",
                    paddingTop: isMobile ? "20px" : "0px",
                  }}
                >
                  <Popconfirm
                    title={instaTeacher ? "Arrête l'alerte Insta" : "Attention, tu es sur le point de suspendre la recherche de profs pour ta session InstaProf."}
                    onConfirm={handleStopRequestInstaProf}
                    onCancel={() => { }}
                    okText="Oui"
                    cancelText="Non"
                  >
                    <Button danger onClick={() => setTop(top - 10)} style={{ borderRadius: "18px" }}>
                      Arrêter l'alerte
                    </Button>
                  </Popconfirm>
                </div>
              </Affix>
            )}
          </div>
        }
      >
        {isLoading ? (
          <Fragment>
            <Skeleton active style={{ margin: "1em" }} />
            <Skeleton active style={{ marginBottom: "1em" }} />
            <Skeleton active style={{ marginBottom: "1em" }} />
          </Fragment>
        ) : (
          <Fragment>
            {isStopingInstaProf ? (
              <Result
                status="warning"
                title={isStudent ? "Le prof a arrêté la session" : "L'élève a arrêté la session"}
                extra={
                  <AppButton onClick={handleCloseDrawer}>
                    {" "}
                    Quitter la Session
                  </AppButton>
                }
              />
            ) : (
              <Fragment>
                {sessionData?.data[0]?.data?.etat === "booked" ? (
                  <Result
                    status="success"
                    title={
                      <h4 style={{ color: "#333" }}>
                        Hi{" "}
                        <span style={{ textTransform: "capitalize" }}>
                          {user?.firstname}
                        </span>{", "}
                        tu as une réservation en cours !
                      </h4>
                    }
                    extra={[
                      <AppButton onClick={handleMeetingClick}>
                        Rejoindre la visioconférence
                      </AppButton>,
                      <Popconfirm
                        title="Tu veux bien stopper la session instaProf ?"
                        onConfirm={async () => {
                          stopRequestInstaProf({
                            id: sessionData?.data[0]?._id,
                            type: "teacher",
                          });
                        }}
                        onCancel={() => { }}
                        okText="Oui"
                        cancelText="Non"
                      >
                        <AppButton loading={isStopingRequestInstaProf} style={{ marginTop: isMobile ? "10px" : "0px" }}>
                          Arrêter la session
                        </AppButton>
                      </Popconfirm>,
                    ]}
                  />
                ) : (
                  <Fragment>
                    {isSearch ? (
                      <Fragment>
                        <Row
                          justify="center"
                          style={{
                            marginBottom: "8px",
                          }}
                        >
                          <Col>
                            {data.length > 0 && (
                              <LoadingOutlined
                                style={{
                                  fontSize: "30px",
                                  color: "#0ED290",
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Row
                          justify="center"
                          style={{
                            marginBottom: "8px",
                          }}
                        >
                          <Col>
                            {data.length > 0 && (
                              <CountdownTimer
                                targetDate={dateTimeAfterThreeDays}
                                handleCloseDrawer={handleCloseDrawer}
                              />
                            )}
                          </Col>
                        </Row>
                      </Fragment>
                    ) : (
                      <Form onFinish={onSearchTeacherInsta}>
                        <div className={s.drawer__search}>
                          <div className={s.titleBox}>
                            <span className={s.detailsBoxTitle}>{instaTeacher?.teacher?.firstname ? `Envoyer une demande Instaprof à ${instaTeacher?.teacher?.firstname}` : "Lance une alerte aux profs"}</span>
                            <span className={s.detailsBoxMessage}>Nouveau sur Mon Ami Albert, trouves ton prof sauveteur<br />pour un cours particulier MAINTENANT !</span>
                          </div>

                          <div className={s.searchBox}>
                            <div className={s.selectBox}>
                              <span style={{ color: "#393E3E", opacity: "0.3", fontSize: isMobile ? "0.75em" : "14px" }}>Choisir le niveau</span>
                              <Form.Item
                                style={{ margin: 0, padding: 0 }}
                                name={"level"}
                                initialValue={user?.levelId}
                                rules={[
                                  {
                                    required: true,
                                    message: "Ce champ est requis",
                                  },
                                ]}
                              >
                                <AppSelectGroupInscription
                                  placeholder="Niveau"
                                  style={{ width: isMobile ? "70vw" : "205px", position: "relative", left: "-12px", top: isMobile ? "-10px" : "0px", fontSize: isMobile ? "0.8em" : "12px" }}
                                  onChange={(value: string) =>
                                    OnSelectLevel(value)
                                  }
                                />
                              </Form.Item>
                            </div>

                            <div className={s.separation}></div>

                            <div className={s.selectBox}>
                              <span style={{ color: "#393E3E", opacity: "0.3", fontSize: isMobile ? "0.75em" : "14px" }}>Choisir la matière</span>
                              <Form.Item
                                style={{ margin: 0, padding: 0 }}
                                name={"matiere"}
                                rules={[
                                  {
                                    required: true,
                                    message: "Ce champ est requis",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder={<span style={{ color: "#000", opacity: "0.8" }}>Matière</span>}
                                  style={{ width: isMobile ? "70vw" : "205px", position: "relative", left: "-12px", top: isMobile ? "-10px" : "0px", fontSize: isMobile ? "0.8em" : "12px" }}
                                  bordered={false}
                                >
                                  {tempTopic?.map((topic: any) => (
                                    <Option
                                      key={topic._id}
                                      value={topic._id}
                                    >
                                      {topic.title}
                                    </Option>
                                  ))}
                                </Select>
                              </Form.Item>
                            </div>

                            <div className={s.separation}></div>

                            <div className={s.amountBox}>
                              <span style={{ color: "#393E3E", opacity: "0.3", fontSize: isMobile ? "0.75em" : "14px" }}>Ton budget max pour cette session</span>
                              <Form.Item
                                style={{ margin: 0, padding: 0 }}
                                name={"budget"}
                                rules={[
                                  {
                                    required: true,
                                    message: "Un budget est requis",
                                  },
                                ]}
                                hasFeedback
                                validateStatus={!disabled ? "success" : "warning"}
                              >
                                <Input
                                  className={s.amountField}
                                  placeholder="Budget (€)"
                                  type="number"
                                  bordered={false}
                                  style={{ width: isMobile ? "70vw" : "205px", position: "relative", left: "-12px", top: isMobile ? "-12px" : "0px", fontSize: isMobile ? "0.8em" : "12px" }}
                                  onChange={(e) => {
                                    if (e.target.value > user?.coins) {
                                      setDisabled(true);
                                      popupRef.current?.click();
                                      message.warning(<span>Ton solde est insuffisant pour cette action.<br />Merci de bien vouloir recharger ton portefeuille.</span>);
                                    } else {
                                      setDisabled(false);
                                    }
                                  }}
                                  onBlur={(e) => {
                                    checkAmount(e.target.value);
                                  }}
                                />
                              </Form.Item>
                            </div>
                          </div>

                          <div className={s.fullField}>
                            <Form.Item
                              name={"probleme"}
                              rules={[
                                {
                                  required: true,
                                  message: "Ce champ est requis",
                                },
                              ]}
                              style={{ margin: 0, padding: 2 }}
                            >
                              <Input.TextArea
                                className={s.textareaField}
                                showCount={false}
                                maxLength={150}
                                bordered={false}
                                rows={6}
                                style={{ marginBottom: "1em" }}
                                placeholder="Décris brièvement ton problème ici …"
                              />
                            </Form.Item>
                            <span style={{ marginLeft: "20px", marginBottom: "10px" }}>Limite de 150 caractères</span>
                          </div>

                          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", margin: isMobile ? "0px auto" : "10px auto" }}>
                            {disabled ? (
                              <AppButton
                                className={s.instaButton}
                                onClick={() =>
                                  disabled && popupRef.current?.click()
                                }
                              >
                                <img src={InstaIcon} height={isMobile ? 20 : 30} style={{ marginRight: "15px" }}></img>
                                Lancer une alerte
                              </AppButton>
                            ) : (
                              <AppButton
                                className={instaTeacher ? s.instaTeacherButton : s.instaButton}
                                htmlTypeSubmit={true}
                              >
                                <img src={InstaIcon} height={isMobile ? 20 : 30} style={{ marginRight: instaTeacher ? "40px" : "15px" }}></img>
                                {instaTeacher ? "Envoyer" : "Lancer une alerte"}
                              </AppButton>
                            )}
                          </div>

                        </div>
                        <Form.Item
                          name="uid"
                          initialValue={user?._id}
                        ></Form.Item>
                      </Form>
                    )}

                    {isSearch && (
                      <div className={s.main}>
                        {data.length <= 0 && (
                          <Row
                            justify="center"
                            style={{
                              paddingTop: "0.5em",
                            }}
                          >
                            <div className={s.wait}>
                              <LoadingOutlined
                                style={{
                                  fontSize: "40px",
                                }}
                              />

                              <h3>{instaTeacher ? "En attente de la réponse du prof" : "En attente des profs disponibles!"}</h3>
                            </div>
                          </Row>
                        )}

                        <GridInstaProf className={s.grid}>
                          {handleClear(data)?.map((ad: any, index: number) => {
                            console.log(instaTeacher);
                            console.log(ad);
                            return (<div
                              key={index}
                              onClick={() => setCurrentProfAccepted(ad)}
                            >
                              <CardAdsTeacher
                                onShowDrawerCalendarProf={() => null}
                                setIsBtnInstaProf={() => null}
                                isBtnInstaProf={isBtnInstaProf}
                                showDrawerSearchInstaProf={() => null}
                                resetDrawerInstaProf={resetDrawerInstaProf}
                                handleStudentAcceptTeacher={() =>
                                  handleStudentAcceptTeacher(ad?.prof)
                                }
                                PlanningIsVisble={false}
                                isInstaProf={true}
                                ad={/* dataOfTeacher !== null ? instaTeacher :  */ad?.prof}
                                instaProfData={instaProf}
                                infoTeacherAcceptedReservation={
                                  infoTeacherAcceptedReservation
                                }
                                setCurrentProfAccepted={setCurrentProfAccepted}
                                currentProfAccepted={currentProfAccepted}
                                handleDisplayTeacher={handleDisplayTeacher}
                                handleOpenDrwerProfileTeacher={
                                  handleOpenDrwerProfileTeacher
                                }
                              />
                            </div>)
                          })}
                        </GridInstaProf>
                      </div>
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
            <AppDrawer
              title={<H1>Session déjà réservée</H1>}
              visible={teacherHasSession}
              onClose={() => {
                setTeacherHasSession(false);
                handleCloseDrawer();
              }}
            >
              <AppResult
                status={"instaProf"}
                title={`Le prof ${currentProfAccepted?.prof?.teacher?.firstname} a déjà une réservation en cours.`}
                isMobile={false}
              />
            </AppDrawer>
          </Fragment>
        )}
      </Drawer>

      <SuccesSessionInsta
        openD={openD}
        setOpenD={setOpenD}
        currentProfAccepted={currentProfAccepted}
        infoTeacherAcceptedReservation={infoTeacherAcceptedReservation}
        handleDisplayTeacher={() =>
          handleDisplayTeacher(currentProfAccepted?.prof?.teacher?._id)
        }
      />

      <DrawerProfileTeacher
        profId={currentProfAccepted?.prof?.teacher?._id}
        setOpenDrwerProfileTeacher={setOpenDrwerProfileTeacher}
        openDrawerProfileTeacher={openDrawerProfileTeacher}
      />

      {isZoomMeetingVisible && (
        <ZoomMeeting
          meetingNumber={currentMeetingId}
          userName={user?.firstname + " " + user?.lastname}
          sdkKey={process.env.REACT_APP_ZOOM_SDK_KEY ?? ""}
          sdkSecret={process.env.REACT_APP_ZOOM_SDK_SECRET ?? ""}
          onClose={() => {
            setIsZoomMeetingVisible(false);
          }}
        />
      )}
    </>
  );
};

export default DrawerSearchInstaProf;
