import { Button, Drawer, Form, message, Select, Switch } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";

//!STYLE
import { AppNotification } from "components/appNotification";
import AppButton from "components/libs/button";
import { useSelector } from "react-redux";
import {
  filterHoursByCurrentTime,
  hours
} from "utils/formats";
import s from "./style.module.css";

//!Images
import { removeDuplicates } from "common/enums";
import AlbertIcon from "../../../../assets/images/app/albert_icon.svg";
import ArrowBack from "../../../../assets/images/app/arrow_back.svg";

type Props = {
  setAllData: React.Dispatch<React.SetStateAction<any[]>>;
  setOpenDrawerAdsCreneau: React.Dispatch<React.SetStateAction<boolean>>;
  showDrawerDrawerAdsCreneau: (key?: string) => void;
  openDrawerAdsCreneau: boolean;
  keyCreneau: string | undefined;
  allData: any;
};

const format = "HH:mm";
const { Option } = Select;

const DrawerAdsCreneau: React.FC<Props> = ({
  showDrawerDrawerAdsCreneau,
  openDrawerAdsCreneau,
  setAllData,
  keyCreneau,
  allData,
  setOpenDrawerAdsCreneau,
}) => {
  //!HOOKS
  const [form] = Form.useForm();
  const { user } = useSelector((state) => state.auth);

  //!STATES
  const [tempTopic, setTempTopic] = useState<any>([]);
  const [topicSelected, setTopicSelected] = useState<any>([]);
  const [tempLevel, setTempLevel] = useState<any>([]);
  const closeDrawerRef = useRef<any>();

  const [selectedHour, setSelectedHour] = useState<any>("");
  const [startingHour, setStartingHour] = useState<any>("08:00");
  const [endingHour, setEndingHour] = useState<any>("09:00");
  const [isThirty, setIsThirty] = useState<boolean>(false);

  const [checkedLevels, setCheckedLevels] = useState(true);
  const [selectedGourp, setSelectedGourp] = useState<any>([]);
  const [userLevels, setUserLevels] = useState<any>();

  const handleChange = (checked: boolean) => {
    setCheckedLevels(checked);
    if (checked) {
      setSelectedGourp((prev: any) => {
        return [];
      });
    }
  };

  const handleGroupClick = (group: any) => {
    setSelectedGourp((prev: any[]) => {
      const isGroupSelected = prev.some(selected => selected.levelGroup === group.levelGroup);
      if (isGroupSelected) {
        return prev.filter(selected => selected.levelGroup !== group.levelGroup);
      } else {
        return [...prev, group];
      }
    });
  };

  const handleStartingChange = (value: any) => {
    setSelectedHour(value);
    setStartingHour(value);
  };

  const handleEndingChange = (value: any) => {
    setEndingHour(value);
  };

  const handleCloseDrawer = () => {
    setOpenDrawerAdsCreneau(false);
    setSelectedGourp((prev: any) => {
      return [];
    });
    setCheckedLevels(true);
  }

  const onSelectLevel = useCallback(async (levelId: string) => {
    return user?.nivMat
      .filter((nivMat: any) => {
        return nivMat?.niveau?._id === levelId;
      })
      .forEach((matiere: any) => {
        return setTempTopic(matiere?.matieres);
      });
  }, []);

  const onFinish = (values: any) => {
    if (
      (values.one === "00:00" && values.two === "23:00") ||
      (values.one === "23:00" && values.two === "00:00")
    ) {
      // Créneau valide
    } else if (
      Number(values.one.split(":")[0]) >= Number(values.two.split(":")[0])
    ) {
      AppNotification("Les créneaux de 30 minutes ne sont pas valides! Merci de sélectionner une durée minimale de 1 heure pour tes disponibilités.", "AlbertSad");
      throw new Error("Invalid creneau");
    } else {
      // Créneau valide
    }

    // if (Number(values.one.split(":")[0]) >= Number(values.two.split(":")[0])) {
    //   AppNotification("L'horaire du créneau n'est pas valide.", "AlbertSad");
    //   throw new Error("Invalid creneau");
    // }

    const concatenatedLevelGroups = selectedGourp.map((group: any) => group.levelGroup).join("$");

    const valuesData = {
      grouplevels: checkedLevels ? [""] : [concatenatedLevelGroups],
      date: keyCreneau,
      range: [{ hour: values.one }, { hour: values.two }],
      type: "individuel",
    };

    setAllData((prev: any) => {
      return [...prev, valuesData];
    });

    message.success("Créneau ajouté avec succès");
    form.resetFields();

    const startingKey = `one`;
    const endingKey = `two`;
    form.setFieldsValue({
      [startingKey]: "08:00",
      [endingKey]: "09:00",
    });

    setStartingHour("08:00");
    setEndingHour("09:00");

    setSelectedGourp((prev: any) => {
      return [];
    });
    setCheckedLevels(true);
  };

  useEffect(() => {
    if (selectedHour.toString().includes("30")) {
      setIsThirty(true);
    }
    else {
      setIsThirty(false);
    }
  }, [selectedHour]);

  useEffect(() => {
    const startingKey = `one`;
    const endingKey = `two`;
    form.setFieldsValue({
      [startingKey]: "08:00",
      [endingKey]: "09:00",
    });
  }, []);

  useEffect(() => {
    const uniqueLevels = removeDuplicates(user?.groupLevels);
    setUserLevels(uniqueLevels);
  }, [user]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
  const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
    setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
    setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
    setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
    setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
  };
  window.addEventListener('resize', handleResize);

  return (
    <Drawer
      className="drawer"
      onClose={handleCloseDrawer}
      visible={openDrawerAdsCreneau}
      width={isMobile ? "100%" : isXsScreen ? "100%" : isSmallScreen ? "60%" : isMediumScreen ? "60%" : isNormalScreen ? "40%" : "40%"}
      title={
        <div style={{ fontSize: isMobile ? "0.8em" : "20px", fontFamily: "Poppins", fontWeight: "600", color: "#393E3E", backgroundColor: "transparent", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
            <h3>Ajouter un créneau</h3>
            <Button style={{ marginLeft: "20px", background: "#EAEAEA", borderRadius: "8px" }}>{keyCreneau}</Button>
          </div>

          <span style={{ fontSize: isMobile ? "0.7em" : "16px" }}>{user?.fuseauHorraire.substring(6)}</span>
        </div>
      }
      headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: isMobile ? "20px" : "40px", backgroundColor: "transparent" }}
      closeIcon={
        <img
          src={ArrowBack}
          height={isMobile ? 10 : 18}
          style={{}}
        ></img>
      }
      drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: isMobile ? "0px" : "30px", borderBottomLeftRadius: isMobile ? "0px" : "30px", boxShadow: "-3px 3px 34px #39393929" }}
    >
      <Form onFinish={onFinish} form={form}>
        <div className={s.drawerBody}>
          <div className={s.timeBox}>
            <div className={s.timeTitle}>Créneau</div>
            <div className={s.selectBox}>
              <div className={s.timePicker}>
                <Form.Item
                  style={{ margin: 0, padding: 0 }}
                  rules={[
                    {
                      required: true,
                      message: "Ce champ est requis",
                    },
                  ]}
                  name={"one"}
                >
                  <Select
                    defaultValue="08:00"
                    value={startingHour}
                    placeholder="Heures"
                    style={{ width: "100%" }}
                    bordered={false}
                    onChange={handleStartingChange}
                    options={filterHoursByCurrentTime(hours).map((hour) => {
                      return {
                        value: hour.value,
                        label: hour.label,
                      };
                    })}
                  />
                </Form.Item>
              </div>
              <span>À</span>
              <div className={s.timePicker}>
                <Form.Item
                  style={{ margin: 0, padding: 0 }}
                  name={["two"]}
                  rules={[
                    {
                      required: true,
                      message: "Ce champ est requis",
                    },
                  ]}
                >
                  <Select
                    value={endingHour}
                    placeholder="Heures"
                    style={{ width: "100%" }}
                    bordered={false}
                    onChange={handleEndingChange}
                    options={filterHoursByCurrentTime(hours).map((hour) => {
                      return {
                        value: hour.value,
                        label: hour.label,
                      };
                    })}
                  />
                </Form.Item>
              </div>
            </div>
          </div>

          <div className={s.levelsBox}>
            Afficher ce créneau pour tous niveaux
            <Switch
              style={{ backgroundColor: checkedLevels ? "#7FB1B2" : "#868686", marginLeft: "30px" }}
              checked={checkedLevels}
              onChange={handleChange}
            />
          </div>

          {!checkedLevels && <div className={s.levelsButtons}>
            {userLevels.map((group: any) => (
              <div
                key={group?.levelGroup}
                onClick={() => handleGroupClick(group)}
                className={`${selectedGourp.some((selected: any) => selected.levelGroup === group.levelGroup) ? s.levelButtonActive : s.levelButton}`}
              >
                {group?.levelGroup}
              </div>
            ))}
          </div>}

          <div className={s.addButtonBox}>
            <Form.Item style={{ margin: 0, padding: 0 }}>
              <AppButton className={s.newMoveButton} htmlTypeSubmit={true}>
                Ajouter le créneau
              </AppButton>
            </Form.Item>
          </div>

          <div className={s.albertBox}>
            <img src={AlbertIcon} height={isMobile ? 150 : 300}></img>
          </div>

        </div>
      </Form>

      <button
        hidden
        onClick={() => showDrawerDrawerAdsCreneau()}
        ref={closeDrawerRef}
      ></button>
    </Drawer>
  );
};

export default DrawerAdsCreneau;
