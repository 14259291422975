import { Button, Typography } from "antd";
import Container from "components/container";
import * as appRoutes from "config/routes.config";
import React, { FC, useState } from "react";

import s from "./assets/calendar.module.css";

//!IMAGES

import Breadcrumbs from "components/breadcrumbs";
import HelpPopover from "components/help";
import AppButton from "components/libs/button";
import H1 from "components/libs/title";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CalendarPicture from "../../../assets/images/app/calendar_icon.svg";
import AlbertImage from "../../../assets/images/app/levieuxalbert.svg";
import AppScreen from "../ScreenWithSidebar";
import CalendarOfReservationTeacher from "./c";
import DrawerHistorique from "./components";

const { Title, Text } = Typography;

const AppCalendar: FC = () => {

  const auth = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth);
  const [openDrawerHistorique, setOpenDrawerHistorique] = useState<boolean>(false);
  const navigate = useNavigate();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1050);
  };
  window.addEventListener('resize', handleResize);

  //!RETURN
  return (
    <React.Fragment>
      <Breadcrumbs isCalendar={true} />
      {user?.isActive && user?.step === "completed" ? <div style={{ marginTop: "1em" }}>
        <Container>
          <div className={s.headerBox}>
            <div className={s.titleBox}>
              <div className={s.titlePictureBox}>
                <img src={CalendarPicture} height={isMobile ? 20 : 35}></img>
              </div>
              Planning des cours
            </div>
            <div className={s.buttonBox}>
              <Button className={s.newButton} onClick={() => setOpenDrawerHistorique(true)}>
                Mon historique
              </Button>
              <div style={{ marginLeft: "10px", marginTop: "5px" }}>
                <HelpPopover id={"planning_des_cours_mon_historique"} />
              </div>
            </div>
          </div>

          <div className={s.calendrier}>
            <div className={s.calendar__content}>
              <CalendarOfReservationTeacher />
            </div>
          </div>
        </Container>
      </div> : <AppScreen className={s.dashboard}>
        <H1 className={s.title}>Bienvenue {auth?.user?.firstname}</H1>
        <div className={s.dashboardUserBox}>
          <img src={AlbertImage} width={240} height={240} style={{ marginTop: "20px", marginBottom: "10px" }}></img>
          <p className={s.dashboardUserMessage} style={{ fontFamily: "CartoonFont" }}>Oups, tu n’as pas encore accès à cette fonctionnalité!<br /> Patience... ton compte est en cours de validation. </p>
          {user?.step !== "completed" && <AppButton onClick={() => navigate(appRoutes.COMPLETE_PROFILE)}>Completer mon profil</AppButton>}
        </div>
      </AppScreen>}

      <DrawerHistorique
        open={openDrawerHistorique}
        setOpen={setOpenDrawerHistorique}
      />
    </React.Fragment>
  );
};

export default AppCalendar;
