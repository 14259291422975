import {
  Avatar,
  Button,
  Drawer,
  Image,
  Skeleton
} from "antd";
import Container from "components/container";
import { FC, useCallback, useEffect, useState } from "react";

import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { findLevelsById } from "api/services/levels/levels.services";
import { FLAGS, StatusCode } from "common/enums";
import CertificationBadge from "components/certificationBadge";
import { useTranslation } from "react-i18next";
import Latex from "react-latex-next";
import "react-phone-input-2/lib/material.css";
import { useDispatch, useSelector } from "react-redux";
import TeacherCalendarForStudent from "screens/student/components/calendarTeacher";
import IconTopic from "screens/student/searchTeacher/components/iconTopic";
import DrawerSearchInstaProf from "screens/student/searchTeacher/drawerSearchInstaProf";
import { setChatReceiverId, setSelectUserChat, toggleAppChat } from "store/AppChat/appChat";
import { closeProfileDetailsDrawer, setUserConnect } from "store/auth/authSlice";
import { useFindRoomMutation } from "store/services/chat";
import { handleClearTopic } from "utils/formats";
import ArrowBack from "../../assets/images/app/arrow_back.png";
import InstaIcon from "../../assets/images/app/insta_icon.svg";
import MessageIcon from "../../assets/images/app/message_icon.svg";
import PlanningButtonIcon from "../../assets/images/app/planning_icon.svg";
import BadgeInsta from "../../assets/images/students/badgeInstaProf.svg";
import cardImage from '../../assets/images/teacher/card.svg';
import AvatarTeacher from "../../assets/images/teacher/teacher.svg";
import s from "./assets/style.module.css";

//!STYLES

export const ProfileDetailsDrawer: FC = () => {
  //!HOOKS
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isStudent, setIsSudent] = useState<boolean>(false);
  const [teacherData, setTeacherData] = useState<any>();
  const [studentData, setStudentData] = useState<any>();
  const [userLevel, setUserLevel] = useState<any>();

  const [open, setOpen] = useState<boolean>(false);
  const [isBtnInstaProf, setIsBtnInstaProf] = useState<boolean>(false);
  const [openDrawerInstaProf, setOpenDrawerInstaProf] = useState<boolean>(false);
  const [findRoom, { isSuccess: isSuccessRooms }] = useFindRoomMutation();

  const showDrawerSearchInstaProf = () => {
    if (isLoggedIn) {
      setOpenDrawerInstaProf(!openDrawerInstaProf);
    } else {
      dispatch(setUserConnect(true));
    }
  };

  const {
    profileDetailsDrawerOpen,
    profileDetailsID,
    profileDetailsType,
    user,
    isLoggedIn,
  } = useSelector((state) => state.auth);

  //STATES
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  //!FUNCTIONS
  const closeDrawer = () => {
    dispatch(closeProfileDetailsDrawer());
  };

  const handleDisplayTeacher = (idTeacher: string) => {
    const finalData = {
      actor1: user?._id,
      actor2: idTeacher,
      flag: FLAGS.SINGLE_FLAG,
      type: "",
      instaprof: "",
    };

    findRoom(finalData);
    dispatch(setChatReceiverId(idTeacher));
  };

  const getUserData = async (userId: string) => {
    try {
      setIsLoading(true);
      const response = await AUTH_SERVICES.getUserByUid(userId);
      if (response?.data) {
        if (response?.data?.roles[0] === "student") {
          setIsSudent(true);
          setStudentData(response?.data);
          getLevelById(response?.data?.levelId);
        }
        else {
          setIsSudent(false);
          getTeacherData(response?.data?._id);
        }
      } else {
        throw new Error("L'utilisateur n'existe pas");
      }
    } catch (error) { }
  }

  const getTeacherData = async (teacherId: string) => {
    try {
      const response = await AUTH_SERVICES.getTeacherData(user?._id, teacherId);
      setTeacherData(response?.data);
    } catch (error: any) { }
    finally {
      setIsLoading(false);
    }
  };

  const getLevelById = useCallback(
    async (idLevel: any) => {
      try {
        if (idLevel) {
          const response = await findLevelsById(idLevel);
          if (response.statusCode === StatusCode.OK) {
            setUserLevel(response?.data['title']);
            setIsLoading(false);
          }
        }
      } catch (error) { }
    },
    [userLevel]
  );

  const pathTeacherObj = {
    teacher: teacherData?.teacher,
  };

  // !USE_EFFECT
  useEffect(() => {
    getUserData(profileDetailsID);
    /* if (profileDetailsType === "teacher") {
      getTeacherData(profileDetailsID);
    } */
  }, [profileDetailsID, profileDetailsType]);

  useEffect(() => {
    if (isSuccessRooms) {
      dispatch(setSelectUserChat(true));
      dispatch(toggleAppChat(true));
    }
  }, [isSuccessRooms]);

  return (
    <div>
      <Container>
        <Drawer
          headerStyle={{ height: "70px", width: "100%", position: "relative", backgroundColor: "#8AB0B1" }}
          title={<div style={{ display: "flex", flexDirection: "row", background: "#8AB0B1", color: "#FFF", fontSize: isMobile ? "0.8em" : "18px", fontWeight: "600", fontFamily: "Poppins" }}><Image src={ArrowBack} width={isMobile ? 12 : 25} preview={false} onClick={closeDrawer} style={{ cursor: "pointer" }} />  <span style={{ marginLeft: "10px" }}>Détail du profil</span></div>}
          closable={false}
          width={isMobile ? "100%" : "35%"}
          onClose={closeDrawer}
          visible={profileDetailsDrawerOpen}
        >
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <div style={{ height: isMobile && isStudent ? "300px" : isMobile && !isStudent ? "340px" : "520px", width: isMobile ? "80%" : "450px", background: `url(${cardImage})`, backgroundSize: "cover", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", paddingTop: isMobile ? "15px" : "35px", paddingLeft: isMobile ? "30px" : "70px", paddingRight: "20px" }}>
              {isLoading ? <Skeleton></Skeleton> : !isStudent && teacherData?.teacher?.instaProf ? <img src={BadgeInsta} height={isMobile ? 20 : 40} style={{ position: "relative", right: isMobile ? "-30px" : "-40px", top: isMobile ? "20px" : "40px", zIndex: "2" }}></img> : <div style={{ position: "relative", right: "-40px", top: "40px", zIndex: "2", height: "40px", width: "40px" }}></div>}
              {isLoading ? <Skeleton></Skeleton> : <Avatar
                src={!isStudent ? (teacherData?.teacher?.profile || AvatarTeacher) : studentData?.profile}
                size={isMobile ? 50 : 100}
                style={{
                  background: "#fff",
                  border: "1px solid #707070"
                }}
              />}

              {/* Name and Rate */}
              {isLoading ? <Skeleton></Skeleton> : <div className={s.details}>
                {!isStudent ? <div className={s.name}>
                  {teacherData?.teacher?.firstname?.split(" ")[0] || "-"}{" "}
                  {teacherData?.teacher?.lastname?.split(" ")[0] || "-"}
                  {teacherData?.teacher?.certified && <CertificationBadge />}
                </div> : <div className={s.name}>
                  {studentData?.firstname?.split(" ")[0] || "-"}{" "}
                  {studentData?.lastname?.split(" ")[0] || "-"}
                </div>}
                {!isStudent && <p className={s.rate}>{teacherData?.minPrice} €/heure</p>}
                <div className={s.subjects} style={{ margin: "0px auto" }}>
                  <div style={{ fontWeight: "700" }} className={`${handleClearTopic(teacherData?.topic)?.length <= 5 ? s.singleCourse : s.cardDisplay}`}>
                    {!isStudent && handleClearTopic(teacherData?.topic)?.map((item: any) => (
                      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", textAlign: "center", marginRight: "5px" }}>
                        <IconTopic data={item} />
                        {item?.title?.substring(0, 4)}
                      </div>
                    ))}
                    {isStudent && <div style={{ background: "#D5D5D5", width: "auto", paddingLeft: "15px", paddingRight: "15px", paddingTop: "5px", paddingBottom: "5px", margin: "0px auto", borderRadius: "12px", fontSize: isMobile ? "0.8em" : "12px" }}>{userLevel}</div>}
                  </div>
                  <br />
                  {!isStudent && <span className={s.levels}>
                    {teacherData?.teacher?.groupLevels?.length > 0 ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                      {teacherData?.teacher?.groupLevels?.map((level: any, index: number) => {
                        return (
                          <>
                            <div style={{ marginRight: "5px" }} key={index}>{level?.levelGroup}</div>
                            <div style={{ marginRight: "5px" }}> - </div>
                          </>
                        );
                      })}
                    </div> : <span>Non renseigné</span>}
                  </span>}
                  {!isStudent && <span className={s.languages}>
                    {teacherData?.teacher?.langues?.includes("en") && (
                      "Anglais - "
                    )}
                    {teacherData?.teacher?.langues?.includes("fr") && (
                      "Français"
                    )}
                  </span>}
                </div>

                {/* Short Description */}
                {!isStudent && <p className={s.description}>
                  <Latex>
                    {teacherData?.teacher?.career
                      ? teacherData?.teacher?.career?.substring(0, 300)?.concat("...")
                      : "Aucune description disponible"}
                  </Latex>
                </p>}
              </div>}

              {/* Buttons */}
              {isLoading ? <Skeleton></Skeleton> : <div className={s.rowButtons}>
                {!isStudent && <Button className={s.newPlanningButton} onClick={() => setOpen(true)}>
                  <img
                    src={PlanningButtonIcon}
                    alt=""
                    style={{
                      position: "relative",
                      top: "-1px",
                      marginRight: "5px",
                    }}
                  />
                  Mon Planning
                </Button>}

                {!isStudent && <Button
                  onClick={() => {
                    setIsBtnInstaProf(true);
                    showDrawerSearchInstaProf();
                  }}
                  className={s.newInstaButton}
                >
                  <img
                    src={InstaIcon}
                    alt=""
                    style={{ height: "20px", marginRight: "5px" }}
                  />
                  InstaProf
                </Button>}

                {!isStudent && user?._id && (
                  <Button
                    onClick={() => handleDisplayTeacher(teacherData?.teacher?._id)}
                    className={s.newMessageButton}
                  >
                    <img src={MessageIcon} height={14} />
                  </Button>
                )}
                {isStudent && user?._id && (
                  <Button
                    onClick={() => handleDisplayTeacher(studentData?._id)}
                    className={s.newMessageButton}
                  >
                    <img src={MessageIcon} height={14} style={{ marginRight: "5px" }} />
                    Envoyer un message
                  </Button>
                )}
              </div>}
            </div>
          </div>
        </Drawer>

        <TeacherCalendarForStudent
          setOpen={setOpen}
          open={open}
          currentTeacher={teacherData}
        />

        <DrawerSearchInstaProf
          openDrawerInstaProf={openDrawerInstaProf}
          showDrawerSearchInstaProf={showDrawerSearchInstaProf}
          isBtnInstaProf={isBtnInstaProf}
          setIsBtnInstaProf={setIsBtnInstaProf}
          dataOfTeacher={teacherData}
        />
      </Container>
    </div>
  );
};
