import moment from "moment";

export const formatDates = (date: string) => {
  return moment(date).format("MM/DD/YYYY");
};

export const getMinDiff = (startDate: any, endDate: any) => {
  const msInMinute = 60 * 1000;

  return Math.round(Math.abs(endDate - startDate) / msInMinute);
};

export const translateDate = (dates: any) => {
  for (const date of dates) {
    switch (date) {
      case "Monday":
        return "MO";
      case "Tuesday":
        return "TU";
      case "Wednesday":
        return "WE";
      case "Thursday":
        return "TH";
      case "Friday":
        return "FR";
      case "Saturday":
        return "SA";
      case "Sunday":
        return "SU";
      default:
        return "";
    }
  }
};

export const translateDay = (date: any) => {
  switch (date) {
    case "Lundi":
      return "Monday";
    case "Mardi":
      return "Tuesday";
    case "Mercredi":
      return "Wednesday";
    case "Jeudi":
      return "Thursday";
    case "Vendredi":
      return "Friday";
    case "Samedi":
      return "Saturday";
    case "Dimanche":
      return "Sunday";
    default:
      return "";
  }
};

export function convertDaysToFrench(day: string): string {
  const daysMap: { [key: string]: string } = {
    Monday: "Lundi",
    Tuesday: "Mardi",
    Wednesday: "Mercredi",
    Thursday: "Jeudi",
    Friday: "Vendredi",
    Saturday: "Samedi",
    Sunday: "Dimanche",
  };

  return daysMap[day] || "";
}

export const handleClearNiveau = (res: any[]) => {
  return res?.filter(
    (arr: any, index: number, self: any[]) =>
      index ===
      self?.findIndex(
        (t: any) =>
          t?.niveau?.levelGroup === arr.niveau?.levelGroup &&
          t.niveau?.levelGroup?._id === arr.niveau?.levelGroup?._id
      )
  );
};

export const handleClearTopic = (res: any[]) => {
  return res?.filter(
    (arr: any, index: number, self: any[]) =>
      index === self?.findIndex((t: any) => t?.description === arr?.description)
  );
};

export const handleClearTopicStrings = (res: string[]) => {
  return res?.filter((item, index, self) => self?.indexOf(item) === index);
};

export const handleClearNiveauMatUser = (res: any[]) => {
  return res?.filter(
    (arr: any, index: number, self: any[]) =>
      index ===
      self?.findIndex(
        (t: any) =>
          t?.niveau?.levelGroup === arr.niveau?.levelGroup &&
          t?.niveau?._id === arr.niveau?._id
      )
  );
};

export const removeDuplicateRange = (myArray: any) => {
  return (
    myArray.filter((item: any, index: number, self: any) => {
      const rangeStr = JSON.stringify(item.range);
      const dateStr = item.date;

      return (
        index ===
        self.findIndex((obj: any) => {
          return JSON.stringify(obj.range) === rangeStr && obj.date === dateStr;
        })
      );
    }) || []
  );
};

export const filterHoursByCurrentTime = (
  hours: { value: string; label: string }[],
  currentDate?: string
): { value: string; label: string }[] => {
  const nowDate = new Date();
  const currentHour = new Date().getHours();

  if (currentDate === nowDate.toISOString().split("T")[0]) {
    return hours.filter((hour) => {
      const hourValue = parseInt(hour.value.split(":")[0], 10);
      return hourValue > currentHour;
    });
  } else {
    return hours;
  }
};

export const filterOnlyHoursByCurrentTime = (
  hours: { value: string; label: string }[],
  currentDate?: string
): { value: string; label: string }[] => {
  const nowDate = new Date();
  const currentHour = new Date().getHours();

  if (currentDate === nowDate.toISOString().split("T")[0]) {
    return hours.filter((hour) => {
      const hourValue = parseInt(hour.value.split(":")[0], 10);
      return hourValue > currentHour;
    });
  } else {
    return hours;
  }
};

export const filterHoursMinutesByCurrentTime = (
  hours: { value: string; label: string }[],
  currentDate?: string
): { value: string; label: string }[] => {
  const nowDate = new Date();
  const currentHour = new Date().getHours();

  if (currentDate === nowDate.toISOString().split("T")[0]) {
    return hours.filter((hour) => {
      const hourValue = parseInt(hour.value.split(":")[0], 10);
      return hourValue > currentHour;
    });
  } else {
    return hours;
  }
};

export const hours = [
  /* { value: "00:00", label: "00:00" },
  { value: "00:30", label: "00:30" },
  { value: "01:00", label: "01:00" },
  { value: "01:30", label: "01:30" },
  { value: "02:00", label: "02:00" },
  { value: "02:30", label: "02:30" },
  { value: "03:00", label: "03:00" },
  { value: "03:30", label: "03:30" },
  { value: "04:00", label: "04:00" },
  { value: "04:30", label: "04:30" },
  { value: "05:00", label: "05:00" },
  { value: "05:30", label: "05:30" }, */
  { value: "06:00", label: "06:00" },
  { value: "06:30", label: "06:30" },
  { value: "07:00", label: "07:00" },
  { value: "07:30", label: "07:30" },
  { value: "08:00", label: "08:00" },
  { value: "08:30", label: "08:30" },
  { value: "09:00", label: "09:00" },
  { value: "09:30", label: "09:30" },
  { value: "10:00", label: "10:00" },
  { value: "10:30", label: "10:30" },
  { value: "11:00", label: "11:00" },
  { value: "11:30", label: "11:30" },
  { value: "12:00", label: "12:00" },
  { value: "12:30", label: "12:30" },
  { value: "13:00", label: "13:00" },
  { value: "13:30", label: "13:30" },
  { value: "14:00", label: "14:00" },
  { value: "14:30", label: "14:30" },
  { value: "15:00", label: "15:00" },
  { value: "15:30", label: "15:30" },
  { value: "16:00", label: "16:00" },
  { value: "16:30", label: "16:30" },
  { value: "17:00", label: "17:00" },
  { value: "17:30", label: "17:30" },
  { value: "18:00", label: "18:00" },
  { value: "18:30", label: "18:30" },
  { value: "19:00", label: "19:00" },
  { value: "19:30", label: "19:30" },
  { value: "20:00", label: "20:00" },
  { value: "20:30", label: "20:30" },
  { value: "21:00", label: "21:00" },
  { value: "21:30", label: "21:30" },
  { value: "22:00", label: "22:00" },
  { value: "22:30", label: "22:30" },
  { value: "23:00", label: "23:00" },
  { value: "23:30", label: "23:30" },
  { value: "00:00", label: "00:00" }
];

export const hoursOnly = [
  { value: "00:00", label: "00:00" },
  { value: "01:00", label: "01:00" },
  { value: "02:00", label: "02:00" },
  { value: "03:00", label: "03:00" },
  { value: "04:00", label: "04:00" },
  { value: "05:00", label: "05:00" },
  { value: "06:00", label: "06:00" },
  { value: "07:00", label: "07:00" },
  { value: "08:00", label: "08:00" },
  { value: "09:00", label: "09:00" },
  { value: "10:00", label: "10:00" },
  { value: "11:00", label: "11:00" },
  { value: "12:00", label: "12:00" },
  { value: "13:00", label: "13:00" },
  { value: "14:00", label: "14:00" },
  { value: "15:00", label: "15:00" },
  { value: "16:00", label: "16:00" },
  { value: "17:00", label: "17:00" },
  { value: "18:00", label: "18:00" },
  { value: "19:00", label: "19:00" },
  { value: "20:00", label: "20:00" },
  { value: "21:00", label: "21:00" },
  { value: "22:00", label: "22:00" },
  { value: "23:00", label: "23:00" },
  { value: "00:00", label: "00:00" }
];

export const hoursMinutes = [
  { value: "00:30", label: "00:30" },
  { value: "01:30", label: "01:30" },
  { value: "02:30", label: "02:30" },
  { value: "03:30", label: "03:30" },
  { value: "04:30", label: "04:30" },
  { value: "05:30", label: "05:30" },
  { value: "06:30", label: "06:30" },
  { value: "07:30", label: "07:30" },
  { value: "08:30", label: "08:30" },
  { value: "09:30", label: "09:30" },
  { value: "10:30", label: "10:30" },
  { value: "11:30", label: "11:30" },
  { value: "12:30", label: "12:30" },
  { value: "13:30", label: "13:30" },
  { value: "14:30", label: "14:30" },
  { value: "15:30", label: "15:30" },
  { value: "16:30", label: "16:30" },
  { value: "17:30", label: "17:30" },
  { value: "18:30", label: "18:30" },
  { value: "19:30", label: "19:30" },
  { value: "20:30", label: "20:30" },
  { value: "21:30", label: "21:30" },
  { value: "22:30", label: "22:30" },
  { value: "23:30", label: "23:30" },
  { value: "00:30", label: "00:30" }
];
