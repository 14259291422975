import { FormOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Input, message, Popconfirm, Row, Skeleton, Space, Typography } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import * as appRoutes from "config/routes.config";
import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

//!STYLES
import Col from "antd/es/grid/col";
import { findReservationStudent } from "api/services/studentReservation/studentReservation.services";
import axios from "axios";
import { StatusCode } from "common/enums";
import { AppNotification } from "components/appNotification";
import AppResult from "components/appResults";
import AppButton from "components/libs/button";
import useWebhook from "hooks/useWebHook";
import { badRequest } from "messages";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { closeProfileDetailsDrawer, setIdTeacher, setSeeContributions, setSeePublications, setUserConnect } from "store/auth/authSlice";
import * as TEACHER_SERVICES from "../../../../api/services/teacher/teacher.services";
import TeacherCalendarDetails from "./drawerDetails";
import s from "./style.module.css";

//!IMAGES
import CertificationBadge from "components/certificationBadge";
import { Link, useNavigate } from "react-router-dom";
import IconCourse from "screens/student/searchTeacher/components/iconCourse";
import { handleClearTopicStrings } from "utils/formats";
import ArrowBackButton from "../../../../assets/images/app/arrow_back.png";
import ArrowBack from "../../../../assets/images/app/arrow_back.svg";
import FileDocument from "../../../../assets/images/app/file_document.svg";
import LikeIcon from "../../../../assets/images/app/like_icon.svg";
/* import starCoins from "../../../../assets/images/app/star-coins.png"; */
import { API_RESSOURCES } from "api/services/teacher/teacher.routes";
import AlbertHappy from "../../../../assets/images/albert/status/Happy.svg";
import CheckIcon from "../../../../assets/images/app/checkAlert.svg";
import EnglishIcon from "../../../../assets/images/students/englishIcon.svg";
import FrenchIcon from "../../../../assets/images/students/frenchIcon.svg";
import StartIcon from "../../../../assets/images/students/teacherStart.svg";
import { default as Teacher } from "../../../../assets/images/teacher/teacher.svg";

type Props = {
  open: boolean;
  currentTeacher: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentReservation?: any;
};

interface Niche {
  date: string;
  level: {
    _id: string;
    title: string;
    levelGroup: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
  };
  topic: {
    _id: string;
    title: string;
    level: string;
    status: string;
    position: number;
    language: string;
    createdAt: string;
    updatedAt: string;
    __v: number;
    description: string;
    price: string;
  };
  timeRanges: string[];
  idCreneau: string;
  reservations: any[];
  status: any[];
  grouplevels: any[];
  id: number;
  day: string;
}

interface Obj {
  day: string;
  niches: Niche[];
}

const { Text } = Typography;

const morningHours = [
  "06:00",
  "06:30",
  "07:00",
  "07:30",
  "08:00",
  "08:30",
  "09:00",
  "09:30",
  "10:00",
  "10:30",
  "11:00",
  "11:30",
  "12:00",
  "12:30",
];

const afternoonHours = [
  "13:00",
  "13:30",
  "14:00",
  "14:30",
  "15:00",
  "15:30",
  "16:00",
  "16:30",
  "17:00",
  "17:30",
  "18:00",
  "18:30",
];

const nightHours = [
  "19:00",
  "19:30",
  "20:00",
  "20:30",
  "21:00",
  "21:30",
  "22:00",
  "22:30",
  "23:00",
  "23:30",
];

export const handleRemoveDoublonNiveau = (res: any[]) => {
  return res?.filter(
    (arr: any, index: number, self: any[]) =>
      index ===
      self?.findIndex(
        (t: any) =>
          t?.levelGroup === arr?.levelGroup &&
          t?.levelGroup?._id === arr?.levelGroup?._id
      )
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 4,
  className: s.slider,
  responsive: [
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 3,
      },
    },
  ],
};

const settingsMobile = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  className: s.slider,
  responsive: [
    {
      breakpoint: 500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const TeacherCalendarForStudent: FC<Props> = ({
  setOpen,
  open,
  currentTeacher,
  currentReservation,
}) => {
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [sendWebhook] = useWebhook();
  const [creneau, setCreneau] = useState<any>([]);
  const [index, setIndex] = useState<number>(0);
  const [allData, setAllData] = useState<any>([]);
  const [allSuccessRes, setAllSuccessRes] = useState<any>([]);
  const [allCreneaux, setAllCreneaux] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [isAllData, setIsAllData] = useState<boolean>(true);
  const [drawerRes, setDrawerRes] = useState<boolean>(false);
  const [creditAllowed, setCreditAllowed] = useState<boolean>(false);
  const [isReplanning, setIsReplanning] = useState<boolean>(false);
  const dateRef = useRef<(HTMLDivElement | null)[]>([]);
  const [confirmReservations, setConfirmRes] = useState<any[]>([]);
  const [selectedReservations, setSelectedReservations] = useState<any[]>([]);

  const [currentCreneau, setCurrentCreneau] = useState<any>();
  const [selectedDay, setSelectedDay] = useState<any>();
  const [currentCreneauLength, setCurrentCreneauLength] = useState<number>(0);
  const [selectedTime, setSelectedTime] = useState<{ [day: string]: string[] }>({});

  const [couponForm] = Form.useForm();
  const [loadingCoupon, setLoadingCoupons] = useState<boolean>(false);
  const [couponAdded, setCouponAdded] = useState<boolean>(false);
  const [errorCoupon, setErrorCoupon] = useState<boolean>(false);
  const [couponData, setCouponData] = useState<any>();
  const [teacherCoupons, setTeacherCoupons] = useState<any>([]);

  const navigate = useNavigate();

  const [openCouponDrawer, setOpenCouponDrawer] = useState<boolean>(false);
  const [couponApplayed, setCouponApplayed] = useState<boolean>(false);
  const [couponValidated, setCouponValidated] = useState<boolean>(false);
  const [hasFreeCoupon, setHasFreeCoupon] = useState<boolean>(false);
  const [hasOthersCoupon, setHasOthersCoupon] = useState<boolean>(false);
  const [freeCouponApplyed, setFreeCouponApplyed] = useState<boolean>(false);
  const [othersCouponApplyed, setOthersCouponApplyed] = useState<boolean>(false);
  const [couponCode, setCouponCode] = useState<any>("");

  const handleCloseCouponDrawer = () => {
    setOpenCouponDrawer(false);
  }

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('fr-FR', {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    }).format(date);
  };

  const isTimeInRange = (time: string, userData: any[]) => {
    // Calculate nextTime
    const [hour, minute] = time.split(":").map(Number);
    const nextTime = new Date(0, 0, 0, hour, minute + 30).toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" });

    // Check if either time or nextTime is in range and the status is not "deleted"
    return userData?.some(
      (data) => (data?.timeRanges.includes(time) && data?.timeRanges.includes(nextTime) && data?.status === "recurrent")
    );
  };

  const isAvailable = (time: string, userData: any[]) => {
    // Calculate nextTime
    const [hour, minute] = time.split(":").map(Number);
    const nextTime = new Date(0, 0, 0, hour, minute + 30)
      .toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" });

    // Check if either time or nextTime is in range and the status is not "deleted"
    return userData?.some(
      (data) =>
        (data?.timeRanges.includes(time)) && data?.status !== "deleted" && data?.status !== "reserved"
    );
  };

  const isTimeDisabled = (time: string, day: string) => {
    const [hour, minute] = time.split(":").map(Number);
    const nextTime = new Date(0, 0, 0, hour, minute + 30)
      .toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" });

    const selectedDays = Object.keys(selectedTime);
    const totalSelectedTimes = Object.values(selectedTime).flat().length; // Total selected times across all days
    const selectedTimesForDay = selectedTime[day] || []; // Get selected times for the specific day
    const isSelected = selectedTimesForDay.includes(time) && selectedTimesForDay.includes(nextTime);

    // Disable if:
    return (
      !isSelected && // Don't disable the currently selected time
      (totalSelectedTimes > 1 || (totalSelectedTimes === 1 && !selectedDays.includes(day)))
    );
  };

  const isTimeSelected = (time: string, day: string) => {
    const [hour, minute] = time.split(":").map(Number);
    const nextTime = new Date(0, 0, 0, hour, minute + 30)
      .toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" });

    const selectedDays = Object.keys(selectedTime);
    const selectedTimesForDay = selectedTime[day] || []; // Get selected times for the specific day
    const isSelected = selectedTimesForDay.includes(time) && selectedTimesForDay.includes(nextTime);

    return (
      isSelected && selectedDays.includes(day)
    );
  };

  const generateNiches = useCallback(
    (data: any) => {
      const niches = [];
      if (Array.isArray(data?.niches) && data?.niches?.length > 0) {
        const topics = Array.isArray(data?.niches[0]?.topic)
          ? data?.niches[0]?.topic
          : [];
        const levels = Array.isArray(data?.niches[0]?.level)
          ? data?.niches[0]?.level
          : [];
        const topicsLength = topics?.length;
        const levelsLength = levels?.length;

        for (let i = 0; i < topicsLength; i++) {
          for (let j = 0; j < levelsLength; j++) {
            const filteredTopics = data?.niches.map((n: any) =>
              Array.isArray(n.topic) && Array.isArray(n?.level)
                ? n?.topic.filter(
                  (t: any) =>
                    t?.level === n?.level[j]._id && t?.position === i + 1
                )
                : []
            );

            const foundTopic = filteredTopics?.find((n: any) => n?.length > 0);

            if (foundTopic) {
              const niche = {
                date: data.niches[0].date,
                level: data.niches[0].level[j],
                topic: foundTopic[j],
                timeRanges: data.niches[0].timeRanges[j],
                idCreneau: data.niches[0].idCreneau,
                reservations:
                  data.niches[0] && data.niches[0].reservations
                    ? data.niches[0].reservations[j] || []
                    : [],
                status: data.niches[0].status[j] || [],
                annonce: data.annonce,
                id: j + 1,
                creneauTypes: data.niches[0].creneauTypes[j] || [],
                grouplevels: data.niches[0].grouplevels[j] || "",
                day: data.day,
              };
              niches.push(niche);
            }
          }
        }
      }

      const output = { day: data.day, niches: niches };
      setCreneau(output);
      return output;
    },
    [creneau]
  );

  const calculateTimeDifference = (): number => {
    let totalSum = 0;

    Object.entries(selectedTime).forEach(([date, times]) => {
      // Convert times to Date objects for calculation
      const timeObjects = times.map((time: string) => {
        const [hours, minutes] = time.split(":").map(Number);
        return new Date(0, 0, 0, hours, minutes);
      });

      // Calculate the difference between the last and first time in minutes
      const timeDifference =
        (timeObjects[timeObjects.length - 1].getTime() - timeObjects[0].getTime()) /
        (1000 * 60);

      // Apply the formula
      const result = (timeDifference * 0.5) / 30;

      // Add to the total sum
      totalSum += result;
    });

    return totalSum;
  };

  const calculatePriceForReservationCredit = (): number => {
    let totalCredit = 0;

    selectedReservations.map((item) => {
      // Convert times to Date objects for calculation
      const timeObjects = item.timeRanges.map((time: string) => {
        const [hours, minutes] = time.split(":").map(Number);
        return new Date(0, 0, 0, hours, minutes);
      });

      // Calculate the difference between the last and first time in minutes
      const timeDifference =
        (timeObjects[timeObjects.length - 1].getTime() - timeObjects[0].getTime()) /
        (1000 * 60);

      // Apply the formula
      const result = (timeDifference * 0.5) / 30;

      // Add to the total sum
      totalCredit += result;
    });

    return totalCredit;
  }

  const calculateTimeDifferenceForDay = (specificDay: string): number => {
    let totalSum = 0;

    // Check if there are times for the specific day
    if (selectedTime[specificDay]) {
      const times = selectedTime[specificDay];

      // Convert times to Date objects for calculation
      const timeObjects = times.map((time: string) => {
        const [hours, minutes] = time.split(":").map(Number);
        return new Date(0, 0, 0, hours, minutes);
      });

      // Calculate the difference between the last and first time in minutes
      const timeDifference =
        (timeObjects[timeObjects.length - 1].getTime() - timeObjects[0].getTime()) /
        (1000 * 60);

      // Apply the formula
      const result = (timeDifference * 0.5) / 30;

      // Add to the total sum
      totalSum += result;
    }

    return totalSum;
  };

  const calculateForReplanification = (price: any): number => {
    let totalSum = 0;
    if (currentReservation?.byCredits !== "") {
      if (price > currentReservation?.creditedValue) {
        totalSum = price - currentReservation?.creditedValue;
      }
      else if (price < currentReservation?.creditedValue) {
        totalSum = currentReservation?.creditedValue - price;
      }
      else {
        totalSum = 0;
      }
      return totalSum;
    }
    else {
      if (price > currentReservation?.price) {
        totalSum = price - currentReservation?.price;
      }
      else if (price < currentReservation?.price) {
        totalSum = currentReservation?.price - price;
      }
      else {
        totalSum = 0;
      }
      return totalSum;
    }
  };

  const sumPrices = useCallback(
    (arr: any[]) => {
      let total = 0;

      const totalResult = calculateTimeDifference();

      if (creditAllowed && user?.credits > 0) {
        return totalResult;
      }
      else {
        for (let obj of arr) {

          let price = obj?.topic?.price;

          if (typeof price !== "number") {

            price = Number(price);

            if (isNaN(price)) {
              throw new Error(
                "Chaque objet doit avoir une propriété 'price' numérique"
              );
            }
          }

          total += price;
        }

        return total;
      }

    },
    [confirmReservations, creditAllowed]
  );

  const sumReservationsPrices = () => {
    console.log(selectedReservations);
    const totalResult = calculatePriceForReservationCredit();
    if (creditAllowed && user?.credits > 0) {
      return totalResult;
    }
    else {
      const totalPrice = selectedReservations.reduce((sum, item, index) => {
        if (item.topic && typeof item.topic.price === "number") {
          return sum + item.topic.price;
        } else {
          return sum;
        }
      }, 0);
      console.log(totalPrice);
      return totalPrice;
    }
  };

  const reservationAdded = (day: string) => {
    return selectedReservations.some((item) => item?.day === day);
  }

  const removeReservation = (day: string) => {
    setSelectedReservations((prevReservations) =>
      prevReservations.filter((item) => item?.day !== day)
    );
    if (couponApplayed && couponData?.couponType === "FREE_CLASS" && couponAdded) {
      setCouponValidated(false);
      setCouponAdded(false);
    }
  }

  const addNewReservation = (reservation: any) => {
    setSelectedReservations((prev: any) => {
      return [...prev, reservation];
    });
    if (couponApplayed && couponData?.couponType === "FREE_CLASS" && couponCode !== "") {
      couponForm.submit();
    }
  }

  const fetchCalender = useCallback(
    async (idTeacher: string) => {
      setLoading(true);
      if (currentTeacher?.teacher?._id) {
        try {
          const response = await axios.get(
            `${API_RESSOURCES}/advertisements/fetch/ReelCalender/${idTeacher}/${user?._id}`
          );
          setAllData(response.data?.data || []);

          if (allCreneaux?.length <= 0) {
            const result = response.data?.data
              .filter((data: any) => data?.niches.length > 0)
              .map((niche: any) => generateNiches(niche));

            setAllCreneaux(result);
          }
        } catch (error) {
        } finally {
          setLoading(false);
        }
      }
    },
    [currentTeacher?.teacher?._id]
  );

  const handleConfirmReservations = useCallback(async () => {
    if (!currentTeacher?._id || !creneau?.day || !selectedReservations?.length) {
      return;
    }

    let allSuccessful = true;

    for (const reservation of selectedReservations) {
      const finalData = {
        studentId: user?._id,
        profId: currentTeacher?.teacher?._id,
        advertisementId: reservation?.annonce?._id,
        level: user?.levelId,
        topic: reservation?.topic?._id,
        range: reservation?.timeRanges,
        idCreneau: reservation?.idCreneau,
        grouplevels: reservation?.grouplevels,
        startDate:
          reservation?.day?.split("T")[0] +
          "T" +
          `${reservation?.timeRanges[0]}:00.000Z`,
        type:
          typeof reservation?.creneauTypes === "string"
            ? reservation?.creneauTypes
            : reservation?.creneauTypes.creneauTypes[0],
        grouplevel: reservation?.grouplevel,
      };

      try {
        const response = await findReservationStudent(currentTeacher?._id, finalData);

        if (response?.status === "OK") {
          fetchCalender(currentTeacher?.teacher?._id);
          setAllSuccessRes((res: any) => [...res, response.data]);
          sendWebhook(response.data);
          localStorage.setItem("reservations", allSuccessRes);
          setConfirmRes([]);
          setSelectedTime({});
          setSelectedReservations([]);
        } else {
          allSuccessful = false; // Mark failure
          AppNotification(badRequest.error, "AlbertSad");
          break; // Stop further execution if any request fails
        }
      } catch (error) {
        allSuccessful = false; // Mark failure
        if (axios.isAxiosError(error)) {
          if (error?.response?.data?.status === "KO") {
            AppNotification(
              "Ce prof a déjà une réservation en cours.",
              "AlbertSad"
            );
            fetchCalender(currentTeacher?.teacher?._id);
          }
        } else {
          AppNotification(badRequest.error, "AlbertSad");
        }
        break; // Stop further execution if any request fails
      }
    }

    // Navigate only if all requests were successful
    if (allSuccessful) {
      setTimeout(() => {
        navigate(appRoutes.STUDENT_CALENDAR);
      }, 1000);
    }
  }, [currentTeacher, creneau, selectedReservations, allSuccessRes]);

  const handleConfirmReservation = useCallback(async () => {
    if (!currentTeacher?._id || !creneau?.day || !confirmReservations?.length) {
      return;
    }

    try {
      const requests = confirmReservations.map((reservation) => {
        const finalData = {
          studentId: user?._id,
          profId: currentTeacher?.teacher?._id,
          advertisementId: reservation?.annonce?._id,
          level: user?.levelId,
          topic: reservation?.topic?._id,
          range: reservation?.timeRanges,
          idCreneau: reservation?.idCreneau,
          grouplevels: reservation?.grouplevels,
          startDate:
            reservation?.day?.split("T")[0] +
            "T" +
            `${reservation?.timeRanges[0]}:00.000Z`,
          type:
            typeof reservation?.creneauTypes === "string"
              ? reservation?.creneauTypes
              : reservation?.creneauTypes.creneauTypes[0],
          grouplevel: reservation?.grouplevel,
        };

        return findReservationStudent(currentTeacher?._id, finalData);
      });

      const responses = await Promise.all(requests);

      responses.forEach((response, i) => {
        if (response?.status === "OK") {
          fetchCalender(currentTeacher?.teacher?._id);
          setAllSuccessRes((res: any) => [...res, response.data]);
          sendWebhook(response.data);
          localStorage.setItem("reservations", allSuccessRes);
          setConfirmRes([]);
          setSelectedTime({});
          setSelectedReservations([]);

          setTimeout(() => {
            navigate(appRoutes.STUDENT_CALENDAR);
          }, 1000);
        } else {
          AppNotification(badRequest.error, "AlbertSad");
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.data?.status === "KO") {
          AppNotification(
            "Ce prof a déjà une réservation en cours.",
            "AlbertSad"
          );
          fetchCalender(currentTeacher?.teacher?._id);
        }
      } else {
        AppNotification(badRequest.error, "AlbertSad");
      }
    }
  }, [currentTeacher, creneau, confirmReservations, allSuccessRes]);

  const handleConfirmReservationsFreeClass = useCallback(async () => {
    if (!currentTeacher?._id || !creneau?.day || !selectedReservations?.length) {
      return;
    }

    try {
      const requests = selectedReservations.map((reservation) => {
        const finalData = {
          studentId: user?._id,
          profId: currentTeacher?.teacher?._id,
          advertisementId: reservation?.annonce?._id,
          level: user?.levelId,
          topic: reservation?.topic?._id,
          range: reservation?.timeRanges,
          idCreneau: reservation?.idCreneau,
          grouplevels: reservation?.grouplevels,
          startDate:
            reservation?.day?.split("T")[0] +
            "T" +
            `${reservation?.timeRanges[0]}:00.000Z`,
          type:
            typeof reservation?.creneauTypes === "string"
              ? reservation?.creneauTypes
              : reservation?.creneauTypes.creneauTypes[0],
          grouplevel: reservation?.grouplevel,
          coupon: couponData?.code,
        };

        return findReservationStudent(currentTeacher?._id, finalData);
      });

      const responses = await Promise.all(requests);

      responses.forEach((response, i) => {
        if (response?.status === "OK") {
          /* AppNotification(
            `Réservation ${i + 1} effectuée avec succès`,
            "AlbertHappy"
          ); */
          fetchCalender(currentTeacher?.teacher?._id);
          setAllSuccessRes((res: any) => [...res, response.data]);
          sendWebhook(response.data);
          localStorage.setItem("reservations", allSuccessRes);
          setConfirmRes([]);
          setSelectedTime({});
          setSelectedReservations([]);

          setTimeout(() => {
            navigate(appRoutes.STUDENT_CALENDAR);
          }, 1000);
        } else {
          AppNotification(badRequest.error, "AlbertSad");
        }
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.data?.status === "KO") {
          AppNotification(
            "Ce prof a déjà une réservation en cours.",
            "AlbertSad"
          );
          fetchCalender(currentTeacher?.teacher?._id);
        }
      } else {
        AppNotification(badRequest.error, "AlbertSad");
      }
    }
  }, [currentTeacher, creneau, selectedReservations, allSuccessRes]);

  const handleConfirmReservationsDiscount = useCallback(async () => {
    if (!currentTeacher?._id || !creneau?.day || !selectedReservations?.length) {
      return;
    }

    let allSuccessful = true;

    for (const reservation of selectedReservations) {
      const finalData = {
        studentId: user?._id,
        profId: currentTeacher?.teacher?._id,
        advertisementId: reservation?.annonce?._id,
        level: user?.levelId,
        topic: reservation?.topic?._id,
        range: reservation?.timeRanges,
        idCreneau: reservation?.idCreneau,
        grouplevels: reservation?.grouplevels,
        startDate:
          reservation?.day?.split("T")[0] +
          "T" +
          `${reservation?.timeRanges[0]}:00.000Z`,
        type:
          typeof reservation?.creneauTypes === "string"
            ? reservation?.creneauTypes
            : reservation?.creneauTypes.creneauTypes[0],
        grouplevel: reservation?.grouplevel,
        coupon: couponData?.code,
      };

      try {
        const response = await findReservationStudent(currentTeacher?._id, finalData);

        if (response?.status === "OK") {
          fetchCalender(currentTeacher?.teacher?._id);
          setAllSuccessRes((res: any) => [...res, response.data]);
          sendWebhook(response.data);
          localStorage.setItem("reservations", allSuccessRes);
          setConfirmRes([]);
          setSelectedTime({});
          setSelectedReservations([]);
        } else {
          allSuccessful = false; // Mark failure
          AppNotification(badRequest.error, "AlbertSad");
          break; // Stop further execution if any request fails
        }
      } catch (error) {
        allSuccessful = false; // Mark failure
        if (axios.isAxiosError(error)) {
          if (error?.response?.data?.status === "KO") {
            AppNotification(
              "Ce prof a déjà une réservation en cours.",
              "AlbertSad"
            );
            fetchCalender(currentTeacher?.teacher?._id);
          }
        } else {
          AppNotification(badRequest.error, "AlbertSad");
        }
        break; // Stop further execution if any request fails
      }
    }

    // Navigate only if all requests were successful
    if (allSuccessful) {
      setTimeout(() => {
        navigate(appRoutes.STUDENT_CALENDAR);
      }, 1000);
    }
  }, [currentTeacher, creneau, selectedReservations, allSuccessRes]);

  const scheduleMeeting = async (price: any) => {
    setIsReplanning(true);
    const currentTeacherPrice = getLevelPrice(currentTeacher?.teacher?.nivMat, user?.levelId);
    try {
      let data = {};
      let currentPrice = 0;

      confirmReservations.map((reservation) => {
        const startTime = reservation?.timeRanges[0];
        const endTime = reservation?.timeRanges[reservation?.timeRanges.length - 1];
        if (startTime && endTime) {
          const timeDifference = getTimeDifferenceInMinutes(startTime, endTime);
          currentPrice = (Number(currentTeacherPrice) * timeDifference) / 60;
        }

        data = {
          "idReservation": currentReservation?._id,
          "newDate": reservation?.day,
          "newRange": reservation?.timeRanges,
          "newPrice": currentPrice || price,
          "newCredit": creditAllowed && currentTeacher?.credits !== 0 && currentReservation?.byCredits !== "" ? price : null,
        }
      });

      const response = await AUTH_SERVICES.scheduleMeeting(data);

      if (response?.statusCode === StatusCode.CREATED) {
        message.success({
          content: "Meet replanifié ",
        });
        setTimeout(() => {
          window.location.reload();
        }, 7000);
        setIsReplanning(false);
      }
      else {
        message.warning({
          content: "Ooops, erreur lors de la replanification",
        });
        setIsReplanning(false);
      }
    } catch (error: any) {
      let content = "";
      for (
        let index = 0;
        index < error?.response?.data?.message?.length;
        index++
      ) {
        content = error?.response?.data?.message[index] + ", " + content;
      }
      message.error({
        content: content,
      });
      setIsReplanning(false);
    }
  }

  const handleStudentAttribution = async (teacherId: string) => {
    try {
      const response = await TEACHER_SERVICES.getStudentsAttibutions(teacherId, user?._id);
      if (response?.statusCode === StatusCode.OK) {
        const credit = response?.data[0]?.creditsForStudent;
        if (typeof credit === 'number' && credit > 0) {
          setCreditAllowed(true);
        } else {
          setCreditAllowed(false);
        }
      } else {
        throw new Error("Error");
      }
    } catch (error) { }
  }

  const checkTeacherCoupons = async (teacherId: string) => {
    try {
      const response = await TEACHER_SERVICES.checkTeacherCoupons(teacherId);
      if (response?.statusCode === StatusCode.OK) {
        if (response?.message === "Found successfully !") {
          fetchCoupons(teacherId);
        }
      } else {
        throw new Error("Error");
      }
    } catch (error) { }
  }

  const fetchCoupons = async (teacherId: string) => {
    try {
      setLoadingCoupons(true);
      const response = await TEACHER_SERVICES.fetchStudentCoupons(teacherId, user?._id);
      if (response?.statusCode === StatusCode.OK) {
        if (response?.data?.items.length !== 0) {
          const otherCoupons = response?.data?.items.filter((coupon: any) => {
            return coupon.couponStatus === "ACTIVE" && coupon.couponType !== "FREE_CLASS";
          });
          if (otherCoupons && otherCoupons.length > 0) {
            const validCoupons = otherCoupons.filter((coupon: any) => {
              if (coupon?.applicableTo === "ALL") return true;
              if (coupon?.applicableTo === "MY_STUDENTS" && coupon?.isStudentInClass) return true;
              if (coupon?.applicableTo === "SPECIFIC_STUDENT" && coupon?.isSpecificStudent) return true;
              return false;
            });

            if (validCoupons.length > 0) {
              setTeacherCoupons(validCoupons);
              setHasOthersCoupon(true);
            } else {
              setTeacherCoupons([]);
              setHasOthersCoupon(false);
            }
          } else {
            setTeacherCoupons([]);
            setHasOthersCoupon(false);
          }

          const freeClassCoupon = response?.data?.items.find((coupon: any) =>
            coupon.couponStatus === "ACTIVE" && coupon.couponType === "FREE_CLASS"
          );
          if (freeClassCoupon) {
            const isValidCoupon =
              freeClassCoupon?.applicableTo === "ALL" ||
              (freeClassCoupon?.applicableTo === "MY_STUDENTS" && freeClassCoupon?.isStudentInClass) ||
              (freeClassCoupon?.applicableTo === "SPECIFIC_STUDENT" && freeClassCoupon?.isSpecificStudent);

            if (isValidCoupon) {
              setHasFreeCoupon(true);
              setCouponCode(freeClassCoupon.code);
              couponForm.setFieldsValue({ couponCode: freeClassCoupon.code });
              setCouponData(freeClassCoupon);
            } else {
              setHasFreeCoupon(false);
              setCouponCode("");
              setCouponData(null);
            }
          } else {
            setHasFreeCoupon(false);
            setCouponCode("");
            setCouponData(null);
          }
        }
        else {
          setHasOthersCoupon(false);
          setHasFreeCoupon(false);
          setCouponData(null);
          setCouponValidated(false);
          setCouponAdded(false);
          setCouponCode("");
          setTeacherCoupons([]);
        }
      }
    } catch (error) { }
    finally {
      setLoadingCoupons(false);
    }
  }

  const getNextTimeClassName = (time: string, day: string) => {
    // Calculate nextTime
    const [hour, minute] = time.split(":").map(Number);
    const nextTime = new Date(0, 0, 0, hour, minute + 30)
      .toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" });

    return `${selectedTime[day]?.includes(time) && selectedTime[day]?.includes(nextTime) ? s.timeButtonNextTimeActive : s.timeButtonNextTime}`;
  };

  const handleTimeClick = (time: string, creneau: any) => {
    setSelectedTime((prev) => {
      const dayTimes = prev[creneau?.day] || [];
      const allTimes = [...morningHours, ...afternoonHours, ...nightHours];

      // Calculate nextTime
      const [hour, minute] = time.split(":").map(Number);
      const nextTime = new Date(0, 0, 0, hour, minute + 30)
        .toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" });

      // Check if both time and nextTime are already selected
      const bothTimesSelected = dayTimes.includes(time) && dayTimes.includes(nextTime);

      if (bothTimesSelected) {
        // Remove time and nextTime properly
        console.log(dayTimes.length);
        let updatedDayTimes = [];
        if (dayTimes.length <= 2) {
          updatedDayTimes = dayTimes.filter((t) => t !== time && t !== nextTime);
        }
        else {
          updatedDayTimes = dayTimes.filter((t) => t !== nextTime);
        }

        // If no times are left for the day, remove the day entry
        if (updatedDayTimes.length === 0) {
          const { [creneau?.day]: _, ...rest } = prev; // Omit the day property
          return rest;
        }

        return {
          ...prev,
          [creneau?.day]: updatedDayTimes,
        };
      } else {
        // Select time and nextTime together
        const newSelection = [...dayTimes, time, nextTime];

        // Sort the selected times
        const sortedSelection = newSelection.sort((a, b) => allTimes.indexOf(a) - allTimes.indexOf(b));

        // Get the full range of times
        const selectedIndices = sortedSelection.map((t) => allTimes.indexOf(t));
        const rangeStart = selectedIndices[0];
        const rangeEnd = selectedIndices[selectedIndices.length - 1];
        const rangeTimes = allTimes.slice(rangeStart, rangeEnd + 1);

        // If rangeTimes is empty, remove the day from the object
        if (rangeTimes.length === 0) {
          const { [creneau?.day]: _, ...rest } = prev; // Omit the day property
          return rest;
        }

        // Ensure all times in rangeTimes are valid
        const areAllTimesValid = rangeTimes.every((t) => isAvailable(t, creneau?.niches));
        if (!areAllTimesValid) {
          return prev;
        }

        return {
          ...prev,
          [creneau?.day]: rangeTimes,
        };
      }
    });

    // Make sure state updates happen in the correct order
    setTimeout(() => {
      setCurrentCreneau(creneau?.niches[0]);
      setCurrentCreneauLength(creneau?.niches?.length);
      setSelectedDay(creneau?.day);
    }, 0);
  };

  const handleTimeClickCoupon = (time: string, creneau: any) => {
    setSelectedTime((prev) => {
      const dayTimes = prev[creneau?.day] || [];

      // Calculate nextTime
      const [hour, minute] = time.split(":").map(Number);
      const nextTime = new Date(0, 0, 0, hour, minute + 30)
        .toLocaleTimeString("fr-FR", { hour: "2-digit", minute: "2-digit" });

      // Check if both time and nextTime are already selected
      const bothTimesSelected = dayTimes.includes(time) && dayTimes.includes(nextTime);

      if (bothTimesSelected) {
        // Remove both time and nextTime
        setConfirmRes([]);
        // Otherwise, return updatedDayTimes with only `nextTime` removed
        return {
          ...prev,
          [creneau?.day]: [],
        };
      } else {
        // Add both time and nextTime
        const newDayTimes = [time, nextTime];

        return {
          ...prev,
          [creneau?.day]: newDayTimes,
        };
      }
    });

    setCurrentCreneau(creneau?.niches[0]);
    setCurrentCreneauLength(creneau?.niches?.length);
    setSelectedDay(creneau?.day);
  };

  const clearFreeCouponTime = (creneau: any) => {
    setSelectedTime((prev) => {
      // Remove both time and nextTime
      setConfirmRes([]);
      // Otherwise, return updatedDayTimes with only `nextTime` removed
      return {
        ...prev,
        [creneau?.day]: [],
      };
    });

    setCurrentCreneau(creneau?.niches[0]);
    setCurrentCreneauLength(creneau?.niches?.length);
    setSelectedDay(creneau?.day);
  }

  const getTimeDifferenceInMinutes = (time1: string, time2: string) => {
    const [hours1, minutes1] = time1.split(":").map(Number);
    const [hours2, minutes2] = time2.split(":").map(Number);

    const totalMinutes1 = hours1 * 60 + minutes1;
    const totalMinutes2 = hours2 * 60 + minutes2;

    return Math.abs(totalMinutes2 - totalMinutes1);
  };

  const [hideDiv, setHideDiv] = useState(false);
  const drawerContentRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = () => {
    if (drawerContentRef.current) {
      const scrollTop = drawerContentRef.current.scrollTop;
      if ((couponApplayed && scrollTop >= 80) || (!couponApplayed && scrollTop >= 150)) {
        setHideDiv(true);
      } else {
        setHideDiv(false);
      }
    }
  };

  useEffect(() => {
    const drawerContent = drawerContentRef.current;

    if (drawerContent) {
      drawerContent.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (drawerContent) {
        drawerContent.removeEventListener("scroll", handleScroll);
      }
    };
  }, [drawerContentRef.current]);

  const setDrawerContentRef = (node: HTMLDivElement | null) => {
    if (node) {
      drawerContentRef.current = node;
      node.addEventListener("scroll", handleScroll);
    } else if (drawerContentRef.current) {
      drawerContentRef.current.removeEventListener("scroll", handleScroll);
      drawerContentRef.current = null;
    }
  };

  useEffect(() => {
    (async () => {
      if (open) {
        await fetchCalender(currentTeacher?.teacher?._id);
      }
    })();
  }, [open, fetchCalender]);

  useEffect(() => {
    if (open === false) {
      setAllData([]);
      setAllCreneaux([]);
      setConfirmRes([]);
      setAllSuccessRes([]);
    }
  }, [open]);

  useEffect(() => {
    if (currentTeacher?.teacher?._id) {
      if (creneau?.day === confirmReservations[0]?.day) {
        // dateRef.current[index]?.click();
      }
    }
  }, [currentTeacher]);

  useEffect(() => {
    const filterReservations = (allCreneaux: any[]): any[] => {
      const reservations: any[] = allCreneaux.filter((creneau: any) => {
        return creneau.niches.some((niche: any) => {
          return niche.reservations && niche.reservations._id;
        });
      });

      const successReservations: any[] = reservations.reduce(
        (acc: any[], creneau: any) => {
          const niche = creneau.niches[0];
          if (niche.reservations) {
            acc.push({
              ...niche.reservations,
              topic: niche.topic,
            });
          }
          return acc;
        },
        []
      );

      return successReservations;
    };

    const myReservations: any[] = filterReservations(allCreneaux);

    setAllSuccessRes((res: any) => [...res, myReservations]);
  }, [open, allCreneaux]);

  useEffect(() => {
    if (open) {
      if (!isLoggedIn) {
        setOpen(false);
        dispatch(setUserConnect(true));
      }
    }
  }, [isLoggedIn, open]);

  useEffect(() => {
    (async () => {
      if (open) {
        await handleStudentAttribution(currentTeacher?.teacher?._id);
        await checkTeacherCoupons(currentTeacher?.teacher?._id);
      }
    })();
  }, [open]);

  useEffect(() => {
    // Logic to run whenever selectedTime is updated
    const times = selectedTime[selectedDay] || [];
    // Example: Check if any day has times with differences > 30 minutes
    if (times.length > 1) {
      // Sort the times to ensure the range is correct
      const sortedTimes = [...times].sort();

      const startTime = sortedTimes[0];
      const endTime = sortedTimes[sortedTimes.length - 1];

      // Calculate the difference in minutes
      const timeDifference = getTimeDifferenceInMinutes(startTime, endTime);

      // Example condition: Warn if the difference exceeds 30 minutes
      if (couponApplayed && couponData?.couponType === "FREE_CLASS") {
        const updatedCreneau = { ...currentCreneau }; // Clone object

        if (timeDifference === 30) {

          const level = updatedCreneau?.level?.levelGroup;
          const matchingLevel = currentTeacher?.teacher?.groupLevels.find((l: any) => l.levelGroup === level);
          const price = matchingLevel ? matchingLevel.price : 0;

          const coursePrice = getLevelPrice(currentTeacher?.teacher?.nivMat, user?.levelId);
          updatedCreneau.topic = { ...updatedCreneau.topic, price: (coursePrice * timeDifference) / 60 };
          updatedCreneau.timeRanges = [startTime, endTime];
          updatedCreneau.status = "reserved";

          // Update confirmReservations without duplicates
          setConfirmRes((prev) => {
            const exists = prev.some((creneau) => creneau === updatedCreneau);
            return exists ? prev : [...prev, updatedCreneau];
          });
        } else {
          // Remove from confirmReservations if it doesn't match the condition
          setConfirmRes((prev) => prev.filter((creneau) => creneau !== currentCreneau));
        }
      }
      else {
        if (timeDifference > 30) {
          const updatedCreneau = { ...currentCreneau }; // Clone object
          if (currentReservation) {
            // Check if creditAllowed is defined and modify the item price if necessary
            if (creditAllowed && currentTeacher?.credits !== 0 && currentReservation?.byCredits !== "") {
              updatedCreneau.topic = { ...updatedCreneau.topic, price: 1 };
              updatedCreneau.timeRanges = [startTime, endTime];
              updatedCreneau.status = "reserved";
            }
            else {
              const level = updatedCreneau?.level?.levelGroup;
              const matchingLevel = currentTeacher?.teacher?.groupLevels.find((l: any) => l.levelGroup === level);
              const price = matchingLevel ? matchingLevel.price : 0;

              const coursePrice = getLevelPrice(currentTeacher?.teacher?.nivMat, user?.levelId);
              updatedCreneau.topic = { ...updatedCreneau.topic, price: (coursePrice * timeDifference) / 60 };
              updatedCreneau.timeRanges = [startTime, endTime];
              updatedCreneau.status = "reserved";
            }

            // Add or update the currentCreneau in confirmReservations
            setConfirmRes((prev) => {
              const existingIndex = prev.findIndex((creneau) => creneau.idCreneau === updatedCreneau.idCreneau);

              if (existingIndex !== -1) {
                return prev.map((creneau, index) =>
                  index === existingIndex ? { ...creneau, ...updatedCreneau } : creneau
                );
              }

              return [...prev, updatedCreneau];
            });
          }
          else {
            // Check if creditAllowed is defined and modify the item price if necessary
            if (creditAllowed && currentTeacher?.credits !== 0) {
              updatedCreneau.topic = { ...updatedCreneau.topic, price: 1 };
              updatedCreneau.timeRanges = [startTime, endTime];
              updatedCreneau.status = "reserved";
            }
            else {
              const level = updatedCreneau?.level?.levelGroup;
              const matchingLevel = currentTeacher?.teacher?.groupLevels.find((l: any) => l.levelGroup === level);
              ///const price = matchingLevel ? matchingLevel.price : 0;

              const price = getLevelPrice(currentTeacher?.teacher?.nivMat, user?.levelId);

              if (couponApplayed && couponData?.couponType !== "FREE_CLASS") {
                const formerPrice = (price * timeDifference) / 60;
                const amounToPay = formerPrice - ((formerPrice * couponData?.discountRate) / 100);
                updatedCreneau.topic = { ...updatedCreneau.topic, price: amounToPay };
              }
              else {
                updatedCreneau.topic = { ...updatedCreneau.topic, price: (price * timeDifference) / 60 };
              }
              updatedCreneau.timeRanges = [startTime, endTime];
              updatedCreneau.status = "reserved";
            }

            // Add or update the currentCreneau in confirmReservations
            setConfirmRes((prev) => {
              const existingIndex = prev.findIndex((creneau) => creneau.idCreneau === updatedCreneau.idCreneau);

              if (existingIndex !== -1) {
                return prev.map((creneau, index) =>
                  index === existingIndex ? { ...creneau, ...updatedCreneau } : creneau
                );
              }

              return [...prev, updatedCreneau];
            });
          }
        }
        else {
          // Remove currentCreneau from confirmReservations
          setConfirmRes((prev) =>
            prev.filter((creneau) => creneau.idCreneau !== currentCreneau.idCreneau)
          );
        }
      }
    }
  }, [selectedTime, currentCreneau, currentCreneauLength, selectedDay]);

  const updateReservationPrice = () => {
    if (selectedReservations.length > 0) {
      const updatedReservations = selectedReservations.map((item) => ({
        ...item,
        topic: {
          ...item.topic,
          price: Math.max(0, item.topic.price - ((item.topic.price * (couponData?.discountRate || 0)) / 100)),
        },
      }));

      setSelectedReservations(updatedReservations);

      sumReservationsPrices();
    }

    if (confirmReservations.length > 0) {
      const updatedReservations = confirmReservations.map((item) => ({
        ...item,
        topic: {
          ...item.topic,
          price: Math.max(0, item.topic.price - ((item.topic.price * (couponData?.discountRate || 0)) / 100)),
        },
      }));

      setConfirmRes(updatedReservations);
    }
  }

  const handleCouponSubmit = async (values: any) => {
    const price = getLevelPrice(currentTeacher?.teacher?.nivMat, user?.levelId);
    if (values.couponCode) {
      try {
        setLoadingCoupons(true);
        const response = await TEACHER_SERVICES.applyCoupon(user?._id, values.couponCode, Number(price));
        if (response?.statusCode === StatusCode.CREATED) {
          setCouponData(response?.data);
          setCouponValidated(true);
          setCouponAdded(true);
          updateReservationPrice();
        }
        else {
          message.error("Coupon invalid");
          setCouponValidated(false);
          setCouponAdded(false);
        }
      } catch (error) {
        message.error("Coupon invalid");
      }
      finally {
        setLoadingCoupons(false);
      }
    }
    else {
      setErrorCoupon(true);
    }
  }

  const handleApplyCoupon = async (coupon: any) => {
    console.log(coupon?.code);
    setCouponCode(coupon?.code);
    couponForm.setFieldsValue({ couponCode: coupon?.code });
    setCouponData(coupon);
    setCouponApplayed(true);
    setOpenCouponDrawer(false);
    setOthersCouponApplyed(true);
    setTimeout(() => {
      couponForm.submit();
    }, 2000);
  }

  const clearCoupon = async () => {
    setCouponApplayed(false);
    setCouponData(null);
    setCouponAdded(false);
    setCouponValidated(false);
    setErrorCoupon(false);
    couponForm.resetFields();
    setSelectedTime({});
    setSelectedReservations([]);
    setConfirmRes([]);
    setFreeCouponApplyed(false);
    setOthersCouponApplyed(false);
    fetchCoupons(currentTeacher?.teacher?._id);
  }

  const getLevelPrice = (data: any, levelId: string) => {
    const levelData = data.find((item: any) => item?.niveau._id === levelId);
    return levelData?.matieres.length ? levelData.matieres[0].cost : 0;
  };

  const handleProfileClick = () => {
    if (isLoggedIn) {
      dispatch(setIdTeacher(currentTeacher?.teacher?._id));
      const profileUrl = `${appRoutes.PROFILETEACHER}/${currentTeacher?.teacher?._id}/${currentTeacher?.teacher?.firstname ? currentTeacher?.teacher?.firstname : "nom"}.${currentTeacher?.teacher?.lastname ? currentTeacher?.teacher?.lastname : "complet"}`;
      navigate(profileUrl);
      setOpen(false);
      dispatch(closeProfileDetailsDrawer());
    } else {
      dispatch(setUserConnect(true));
    }
  }

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
  const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
    setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
    setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
    setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
    setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
  };
  window.addEventListener('resize', handleResize);

  return (
    <Drawer
      className="drawer"
      onClose={() => {
        setOpen(false);
        setAllSuccessRes([]);
        setCreneau([]);
      }}
      visible={open}
      width={isMobile ? "100%" : isXsScreen ? "100%" : isSmallScreen ? "100%" : isMediumScreen ? "95%" : isNormalScreen ? "90%" : "70%"}
      title={
        <div className={s.drawerTitle}>
          Planning du prof
        </div>
      }
      headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: isMobile ? "20px" : "40px", backgroundColor: "transparent" }}
      closeIcon={
        <img
          src={ArrowBack}
          height={isMobile ? 10 : 18}
          style={{}}
        ></img>
      }
      drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: isMobile ? "0px" : "30px", borderBottomLeftRadius: isMobile ? "0px" : "30px", boxShadow: "-3px 3px 34px #39393929" }}
    >
      {loading ? (
        <>
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </>
      ) : (
        <Fragment>
          <div ref={setDrawerContentRef} className={s.mainContainer}>
            {<div className={hideDiv ? (teacherCoupons.length > 0 && !creditAllowed && currentTeacher?.credits === 0) ? s.headerBoxFullCouponExist : s.headerBoxFull : s.headerBox}>
              <div style={{ display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "flex-start", justifyContent: "space-between", width: "100%", height: "auto" }}>
                <div className={hideDiv ? s.detailsBoxFull : s.detailsBox}>
                  {isMobile && currentTeacher?.recommended && user?.accountManaged !== "" && <div className={s.ribbon} style={{ position: "absolute", top: "5px", right: currentTeacher?.credits > 0 ? "0%" : "0%" }}>
                    <img src={LikeIcon} height={isMobile ? 10 : 12} style={{ position: "relative", bottom: isMobile ? "0px" : "2px", marginRight: "5px" }}></img> Recommandé
                    {currentTeacher?.credits > 0 && <div> : {currentTeacher?.credits} <span className={s.credit}>CDT</span></div>}
                  </div>}
                  {!hideDiv && <div className={s.avatarBox}>
                    <img src={currentTeacher?.teacher?.profile || Teacher} alt="Teacher" className={s.avatarPicture} />
                  </div>}
                  {hideDiv && <div className={s.smallHeaderleft}>
                    <img src={currentTeacher?.teacher?.profile || Teacher} alt="Teacher" className={s.avatarImage} />
                    <div className={s.smallHeaderNameBox}>
                      <span className={s.name}>{currentTeacher?.teacher?.firstname + " " + currentTeacher?.teacher?.lastname} {currentTeacher?.teacher?.certified && <CertificationBadge />}</span>
                      <span className={s.rateLanguages}>
                        {currentTeacher?.teacher?.rate && currentTeacher?.teacher?.rate !== 0 && Number(currentTeacher?.teacher?.rate).toFixed(2)}
                        {currentTeacher?.teacher?.rate && currentTeacher?.teacher?.rate !== 0 && <img src={StartIcon} style={{ position: "relative", top: "-2px", marginRight: "10px", marginLeft: "5px" }} alt="Star" />}
                        Je parle {currentTeacher?.teacher?.langues?.includes("fr") && <img src={FrenchIcon} alt="French" />} {currentTeacher?.teacher?.langues?.includes("en") && <img src={EnglishIcon} alt="English" />}
                      </span>
                    </div>
                  </div>}
                  {hideDiv && <div className={s.smallHeaderRight}>
                    {!creditAllowed && currentTeacher?.credits === 0 && <div className={s.priceBox}>
                      <span className={s.priceTitle}>Mes cours à partir de</span>
                      <span className={s.price}>{getLevelPrice(currentTeacher?.teacher?.nivMat, user?.levelId)}€/séance</span>
                    </div>}
                    <div className={s.coursesBoxSticky}>
                      {handleClearTopicStrings(currentTeacher?.teacher?.matieres)?.map((item: any) => (
                        <div className={s.courses}>
                          <IconCourse course={item} />
                          <span>{item.includes("-") ? item.split("-")[0] : item.substring(0, 4)}</span>
                        </div>
                      ))}
                    </div>
                  </div>}
                  {!hideDiv && <div className={s.nameBox}>
                    {!isMobile && currentTeacher?.recommended && user?.accountManaged !== "" && <div className={s.ribbon} style={{ position: "relative", top: "10px", right: currentTeacher?.credits > 0 ? "-41%" : "-54%" }}>
                      <img src={LikeIcon} height={isMobile ? 10 : 12} style={{ position: "relative", bottom: isMobile ? "0px" : "2px", marginRight: "5px" }}></img> Recommandé
                      {currentTeacher?.credits > 0 && <div> : {currentTeacher?.credits} <span className={s.credit}>CDT</span></div>}
                    </div>}
                    <div className={s.nameDetails}>
                      <span className={s.name}>{currentTeacher?.teacher?.firstname + " " + currentTeacher?.teacher?.lastname} {currentTeacher?.teacher?.certified && <CertificationBadge />}</span>
                      <span className={s.rateLanguages}>
                        {currentTeacher?.teacher?.rate && currentTeacher?.teacher?.rate !== 0 && Number(currentTeacher?.teacher?.rate).toFixed(2)}
                        {currentTeacher?.teacher?.rate && currentTeacher?.teacher?.rate !== 0 && <img src={StartIcon} style={{ position: "relative", top: "-2px", marginRight: "10px", marginLeft: "5px" }} alt="Star" />}
                        Je parle {currentTeacher?.teacher?.langues?.includes("fr") && <img src={FrenchIcon} alt="French" />} {currentTeacher?.teacher?.langues?.includes("en") && <img src={EnglishIcon} alt="English" />}
                      </span>
                      {currentTeacher?.teacher?.career !== "" && <span className={s.title} dangerouslySetInnerHTML={{ __html: currentTeacher?.teacher?.career?.split("<p>")[1]?.split("</p>")[0]?.length > 96 ? currentTeacher?.teacher?.career?.split("<p>")[1]?.split("</p>")[0]?.substring(0, 96) + "..." : currentTeacher?.teacher?.career?.split("<p>")[1]?.split("</p>")[0] }}></span>}
                      <span className={s.levels}>
                        {currentTeacher?.teacher?.groupLevels?.length > 0 ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                          {currentTeacher?.teacher?.groupLevels?.map((level: any, index: number) => {
                            return (
                              <>
                                <div style={{ marginRight: "5px" }} key={index}>{level?.levelGroup}</div>
                                <div style={{ marginRight: "5px" }}> - </div>
                              </>
                            );
                          })}
                        </div> : <span>Non renseigné</span>}
                      </span>
                    </div>
                    <div className={s.buttonsRow}>
                      <Link to={`/prof/${currentTeacher?.teacher?._id}/${currentTeacher?.teacher?.firstname ? currentTeacher?.teacher?.firstname : "nom"}.${currentTeacher?.teacher?.lastname ? currentTeacher?.teacher?.lastname : "complet"}`}>
                        <Button className={s.newMoveButton}>
                          Voir mon profil
                        </Button>
                      </Link>

                      {/* <Button className={s.newProfileButton} style={{ marginLeft: "5px" }}>
                      <img src={starCoins} height={18} style={{ marginRight: "5px" }} />
                      {currentTeacher?.teacher?.credits} <span style={{ marginLeft: "5px" }}> Crédits</span>
                    </Button> */}
                    </div>
                  </div>}
                </div>

                {!hideDiv && <div className={s.descriptionPriceBox}>
                  <div className={s.descriptionBox}>
                    {!isMobile && currentTeacher?.teacher?.career?.split("<p>")[2]?.split("</p>")[0]?.length > 250 ? <div className={s.description}>
                      <div
                        style={{ whiteSpace: 'pre-wrap', textAlign: "left" }}
                        dangerouslySetInnerHTML={{ __html: currentTeacher?.teacher?.career?.split("<p>")[2]?.split("</p>")[0]?.substring(0, 250) + "..." }}
                      />
                    </div> : !isMobile && <div className={s.description}>
                      <div
                        style={{ whiteSpace: 'pre-wrap', textAlign: "left" }}
                        dangerouslySetInnerHTML={{ __html: currentTeacher?.teacher?.career?.split("<p>")[2]?.split("</p>")[0] }}
                      />
                    </div>}
                    {isMobile && currentTeacher?.teacher?.career?.split("<p>")[2]?.split("</p>")[0]?.length > 150 ? <div className={s.description}>
                      <div
                        style={{ whiteSpace: 'pre-wrap', textAlign: "left" }}
                        dangerouslySetInnerHTML={{ __html: currentTeacher?.teacher?.career?.split("<p>")[2]?.split("</p>")[0]?.substring(0, 150) + "..." }}
                      />
                    </div> : isMobile && <div className={s.description}>
                      <div
                        style={{ whiteSpace: 'pre-wrap', textAlign: "left" }}
                        dangerouslySetInnerHTML={{ __html: currentTeacher?.teacher?.career?.split("<p>")[2]?.split("</p>")[0] }}
                      />
                    </div>}
                    {isMobile && <div className={s.descriptionLineSeparator}></div>}
                    <div className={s.docsBox}>
                      <Space onClick={() => { dispatch(setSeePublications(true)); handleProfileClick() }}>
                        <FormOutlined style={{ color: "var(--first-color)" }} />{" "}
                        <div className={s.textStats} style={{ cursor: "pointer" }}>
                          {" "}
                          {currentTeacher?.teacher?.postsNum === 0 || currentTeacher?.teacher?.postsNum === 1 ? (
                            <span>{currentTeacher?.teacher?.postsNum} publication en ligne</span>
                          ) : (
                            <span>{currentTeacher?.teacher?.postsNum} publications en ligne</span>
                          )}
                        </div>
                      </Space>
                      <Space onClick={() => { dispatch(setSeeContributions(true)); handleProfileClick() }}>
                        <img src={FileDocument} height={15} ></img>{" "}
                        <div className={s.textStats}>
                          {" "}
                          {currentTeacher?.teacher?.docsNum === 0 || currentTeacher?.teacher?.docsNum === 1 ? (
                            <span>{currentTeacher?.teacher?.docsNum} Contenu publié</span>
                          ) : (
                            <span>{currentTeacher?.teacher?.docsNum} Contenus publiés</span>
                          )}
                        </div>
                      </Space>
                    </div>
                  </div>

                  <div className={s.coursesPicesBox}>
                    <div className={s.coursesBox}>
                      {handleClearTopicStrings(currentTeacher?.teacher?.matieres)?.map((item: any) => (
                        <div className={s.courses}>
                          <IconCourse course={item} />
                          <span>{item.includes("-") ? item.split("-")[0] : item.substring(0, 4)}</span>
                        </div>
                      ))}
                    </div>
                    {!creditAllowed && currentTeacher?.credits === 0 && <div className={s.priceBox}>
                      <span className={s.priceTitle}>Mes cours à partir de</span>
                      <span className={s.price}>{getLevelPrice(currentTeacher?.teacher?.nivMat, user?.levelId)}€/séance</span>
                    </div>}
                  </div>
                </div>}
              </div>

              {/* Coupon Section */}
              {!creditAllowed && currentTeacher?.credits === 0 && (hasFreeCoupon || hasOthersCoupon) && <div className={hideDiv ? s.couponSectionSticky : s.couponSection}>
                {hasFreeCoupon && couponApplayed && <div className={hideDiv ? s.BackCalendarBoxSticky : s.BackCalendarBox}>
                  <Button onClick={() => clearCoupon()} className={s.confirmButton}><img src={ArrowBackButton} height={15} style={{ marginRight: "5px" }}></img>{couponData?.couponType === "FREE_CLASS" ? "Calendrier normal" : "Réserver sans coupon"}</Button>
                </div>}
                {((hasFreeCoupon || hasOthersCoupon) && !couponApplayed || hasOthersCoupon && couponValidated) && <img src={AlbertHappy} height={isMobile ? 25 : 50} style={{ marginRight: "10px" }}></img>}
                {(hasFreeCoupon || hasOthersCoupon) && isMobile && !couponApplayed && <span style={{ marginRight: "0px" }}>Ce prof propose des coupons</span>}
                {(hasFreeCoupon || hasOthersCoupon) && !isMobile && !couponApplayed && <span style={{ marginRight: "20px" }}>Ce prof propose des coupons</span>}
                {hasOthersCoupon && othersCouponApplyed && couponValidated && <div className={s.reservationAddedBox}>
                  {`Remise de -${couponData?.discountRate}% est appliquée !`}
                  <div className={s.verticalSeparator}></div>
                  <Button
                    type="link"
                    style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "30px", width: "auto", paddingLeft: "5px", paddingRight: "5px", background: "#FFF", color: "#707070", fontSize: isMobile ? "1em" : "14px" }}
                    onClick={() => clearCoupon()}
                  >
                    Retirer le coupon
                  </Button>
                </div>}
                {hasFreeCoupon && !couponApplayed && <div className={s.couponSectionBox} style={{ marginRight: isMobile ? "0px" : "20px" }}>
                  <div className={s.couponSectionMessage}>Réserve ton cours d’essai</div>
                  <div style={{ width: "30%" }}>
                    <Button onClick={() => { setCouponApplayed(true); setFreeCouponApplyed(true); }} className={s.couponButton}>Réserver</Button>
                  </div>
                </div>}
                {hasOthersCoupon && !couponApplayed && <Button onClick={() => setOpenCouponDrawer(true)} className={s.newMoveButton}>Voir les Coupons disponibles !</Button>}
              </div>}

              {!isMobile && !hideDiv && !couponApplayed && <div className={s.lineSeparator}></div>}

              {/* Week Days */}
              {!isMobile && allData?.length > 0 && <div className={hideDiv ? (teacherCoupons.length > 0 && !creditAllowed && currentTeacher?.credits === 0) ? s.weekBoxStickyCouponExist : s.weekBoxSticky : s.weekBox} style={{ background: couponApplayed && couponData?.couponType === "FREE_CLASS" ? "#E7F8F8" : "#FFF" }}>
                <div style={{ width: "5%" }}>
                  <Button
                    onClick={() => {
                      setConfirmRes([]);
                      setIsAllData(true);
                    }}
                    style={{ height: isMobile ? "25px" : "35px", backgroundColor: isAllData ? "var(--first-color)" : "transparent", color: isAllData ? "#fff" : "#333", borderRadius: isMobile ? "12px" : "16px", fontSize: isMobile ? "0.8em" : "14px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}
                  >
                    Afficher tout
                  </Button>
                </div>
                <div style={{ width: isMobile ? "50%" : "83%", marginRight: isMobile ? "25px" : "20px" }}>
                  {!isMobile && <Slider {...settings} arrows={true}>
                    {allData?.map((item: any, index: number) => (
                      <div
                        key={index}
                        ref={(el) => (dateRef.current[index] = el)}
                        onClick={() => {
                          generateNiches(item);
                          setConfirmRes([]);
                          setIndex(index);
                          setIsAllData(false);
                        }}
                        className={`${s.date} ${creneau?.day === item?.day && s.active}`}
                      >
                        <span>{moment(item?.day).format('dddd D MMMM YYYY')}</span>
                      </div>
                    ))}
                  </Slider>}
                  {isMobile && <Slider {...settingsMobile} arrows={true}>
                    {allData?.map((item: any, index: number) => (
                      <div
                        key={index}
                        ref={(el) => (dateRef.current[index] = el)}
                        onClick={() => {
                          generateNiches(item);
                          setConfirmRes([]);
                          setIndex(index);
                          setIsAllData(false);
                        }}
                        className={`${s.date} ${creneau?.day === item?.day && s.active}`}
                      >
                        <span>{moment(item?.day).format('dddd D MMMM YYYY')}</span>
                      </div>
                    ))}
                  </Slider>}
                </div>
              </div>}

              {/*  Coupon Application */}
              {!isMobile && <div className={hideDiv ? s.confirmButtonBoxSticky : s.confirmButtonBox}>
                {!creditAllowed && couponApplayed && <div className={s.couponBox} style={{ marginRight: (hasFreeCoupon || hasOthersCoupon) ? "10px" : "0px" }}>
                  <Form
                    form={couponForm}
                    layout="inline"
                    onFinish={handleCouponSubmit}
                    initialValues={{ couponCode: couponCode }}
                  >
                    <div style={{ width: "63%" }}>
                      <Form.Item
                        name="couponCode"
                        rules={[{ required: false }]}
                        style={{ margin: 0 }}
                      >
                        <Input
                          bordered={false}
                          type="text"
                          placeholder="J’ai un coupon"
                          className={s.couponInput}
                          maxLength={8}
                          value={couponCode}
                          onChange={(e) => {
                            setErrorCoupon(false);
                            setCouponCode(e.target.value);
                            couponForm.setFieldsValue({ couponCode: e.target.value });
                          }}
                        />
                      </Form.Item>
                    </div>
                    <div style={{ width: "30%" }}>
                      {couponAdded ? hasFreeCoupon ? <img src={CheckIcon} height={15} style={{ position: "relative", top: "5px", right: "-50%" }}></img> : <Button htmlType="button" onClick={clearCoupon} className={s.cancelcouponButton}>Annuler</Button> : <Form.Item>
                        <Button htmlType="submit" className={s.couponButton} loading={loadingCoupon}>Appliquer</Button>
                      </Form.Item>}
                    </div>
                  </Form>
                </div>}

                {selectedReservations.length !== 0 && couponApplayed && couponValidated && couponData?.couponType === "FREE_CLASS" && <Popconfirm
                  placement="top"
                  title={
                    "Veux-tu vraiment faire la réservation ?"
                  }
                  onConfirm={handleConfirmReservationsFreeClass}
                  okText="Oui"
                  cancelText="Non"
                >
                  <Button className={s.confirmButton}>
                    Confirmer la réservation
                  </Button>
                </Popconfirm>}

                {selectedReservations.length !== 0 && couponApplayed && couponValidated && couponData?.couponType !== "FREE_CLASS" && <Popconfirm
                  placement="top"
                  title={
                    "Veux-tu vraiment faire la réservation ?"
                  }
                  onConfirm={handleConfirmReservationsDiscount}
                  okText="Oui"
                  cancelText="Non"
                >
                  <Button className={s.confirmButton}>
                    {`Confirmer la réservation pour ${sumReservationsPrices()} €`}
                  </Button>
                </Popconfirm>}

                {selectedReservations.length !== 0 && !couponApplayed && <Popconfirm
                  placement="top"
                  title={
                    "Veux-tu vraiment faire la réservation ?"
                  }
                  onConfirm={handleConfirmReservations}
                  okText="Oui"
                  cancelText="Non"
                >
                  <Button className={s.confirmButton}>
                    {creditAllowed ? `Confirmer la réservation pour ${sumReservationsPrices()} crédit` : `Confirmer la réservation pour ${sumReservationsPrices()} €`}
                  </Button>
                </Popconfirm>}
              </div>}

              {/* Coupon Error */}
              {!isMobile && errorCoupon && <div
                style={{
                  width: "100%",
                  color: "red",
                  fontSize: "12px",
                  lineHeight: "1.5",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-end"
                }}
              >
                Merci de saisir le code du coupon
              </div>}
            </div>}

            {isMobile && <div className={s.lineSeparator}></div>}

            {/* Week Days */}
            {isMobile && allData?.length > 0 && <div className={hideDiv ? s.weekBoxSticky : s.weekBox} style={{ background: couponApplayed && couponData?.couponType === "FREE_CLASS" ? "#E7F8F8" : "#FFF" }}>
              <div style={{ width: "5%" }}>
                <Button
                  onClick={() => {
                    setConfirmRes([]);
                    setIsAllData(true);
                  }}
                  style={{ height: isMobile ? "25px" : "35px", backgroundColor: isAllData ? "var(--first-color)" : "transparent", color: isAllData ? "#fff" : "#333", borderRadius: isMobile ? "12px" : "16px", fontSize: isMobile ? "0.75em" : "14px", textAlign: "center", display: "flex", alignItems: "center", justifyContent: "center" }}
                >
                  Afficher tout
                </Button>
              </div>
              <div style={{ width: isMobile ? "48%" : "83%", marginRight: isMobile ? "25px" : "20px" }}>
                {!isMobile && <Slider {...settings} arrows={true}>
                  {allData?.map((item: any, index: number) => (
                    <div
                      key={index}
                      ref={(el) => (dateRef.current[index] = el)}
                      onClick={() => {
                        generateNiches(item);
                        setConfirmRes([]);
                        setIndex(index);
                        setIsAllData(false);
                      }}
                      className={`${s.date} ${creneau?.day === item?.day && s.active}`}
                    >
                      <span>{moment(item?.day).format('dddd D MMMM YYYY')}</span>
                    </div>
                  ))}
                </Slider>}
                {isMobile && <Slider {...settingsMobile} arrows={true}>
                  {allData?.map((item: any, index: number) => (
                    <div
                      key={index}
                      ref={(el) => (dateRef.current[index] = el)}
                      onClick={() => {
                        generateNiches(item);
                        setConfirmRes([]);
                        setIndex(index);
                        setIsAllData(false);
                      }}
                      className={`${s.date} ${creneau?.day === item?.day && s.active}`}
                    >
                      <span>{moment(item?.day).format('dddd D MMMM YYYY')}</span>
                    </div>
                  ))}
                </Slider>}
              </div>
            </div>}

            {/*  Coupon Application */}
            {isMobile && <div className={hideDiv ? s.confirmButtonBoxSticky : s.confirmButtonBox}>
              {!creditAllowed && couponApplayed && <div className={s.couponBox} style={{ marginRight: (hasFreeCoupon || hasOthersCoupon) ? isMobile ? "0px" : "10px" : "0px" }}>
                <Form
                  form={couponForm}
                  layout="inline"
                  onFinish={handleCouponSubmit}
                  initialValues={{ couponCode: couponCode }}
                >
                  <div style={{ width: "70%" }}>
                    <Form.Item
                      name="couponCode"
                      rules={[{ required: false }]}
                      style={{ margin: 0 }}
                    >
                      <Input
                        bordered={false}
                        type="text"
                        placeholder="J’ai un coupon"
                        className={s.couponInput}
                        maxLength={8}
                        value={couponCode}
                        onChange={(e) => {
                          setErrorCoupon(false);
                          setCouponCode(e.target.value);
                          couponForm.setFieldsValue({ couponCode: e.target.value });
                        }}
                      />
                    </Form.Item>
                  </div>
                  <div style={{ width: "30%" }}>
                    {couponAdded ? hasFreeCoupon ? <img src={CheckIcon} height={15} style={{ position: "relative", top: "5px", right: isMobile ? "-150%" : "-50%" }}></img> : <Button htmlType="button" onClick={clearCoupon} className={s.cancelcouponButton}>Annuler</Button> : <Form.Item>
                      <Button htmlType="submit" className={s.couponButton} loading={loadingCoupon}>Appliquer</Button>
                    </Form.Item>}
                  </div>
                </Form>
              </div>}

              {selectedReservations.length !== 0 && couponApplayed && couponValidated && couponData?.couponType === "FREE_CLASS" && <Popconfirm
                placement="top"
                title={
                  "Veux-tu vraiment faire la réservation ?"
                }
                onConfirm={handleConfirmReservationsFreeClass}
                okText="Oui"
                cancelText="Non"
              >
                <Button className={s.confirmButton}>
                  Confirmer la réservation
                </Button>
              </Popconfirm>}

              {selectedReservations.length !== 0 && couponApplayed && couponValidated && couponData?.couponType !== "FREE_CLASS" && <Popconfirm
                placement="top"
                title={
                  "Veux-tu vraiment faire la réservation ?"
                }
                onConfirm={handleConfirmReservationsDiscount}
                okText="Oui"
                cancelText="Non"
              >
                <Button className={s.confirmButton}>
                  {creditAllowed ? `Confirmer la réservation pour ${sumReservationsPrices()} crédit` : `Confirmer la réservation pour ${sumReservationsPrices()} €`}
                </Button>
              </Popconfirm>}

              {selectedReservations.length !== 0 && !couponApplayed && <Popconfirm
                placement="top"
                title={
                  "Veux-tu vraiment faire la réservation ?"
                }
                onConfirm={handleConfirmReservations}
                okText="Oui"
                cancelText="Non"
              >
                <Button className={s.confirmButton}>
                  {creditAllowed ? `Confirmer la réservation pour ${sumReservationsPrices()} crédit` : `Confirmer la réservation pour ${sumReservationsPrices()} €`}
                </Button>
              </Popconfirm>}
            </div>}

            {/* Coupon Error */}
            {isMobile && errorCoupon && <div
              style={{
                width: "100%",
                color: "red",
                fontSize: "0.8em",
                lineHeight: "1.5",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              Merci de saisir le code du coupon
            </div>}

            {allData?.length > 0 ? <div className={couponApplayed ? s.daysBoxCouponExist : s.daysBox}>
              {isAllData ? (
                allCreneaux?.map((cren: any, index: number) => {
                  return (<div key={index} className={s.dayBox} style={{ background: couponApplayed && couponData?.couponType === "FREE_CLASS" ? "#E7F8F8" : "#FFF" }}>
                    {couponApplayed ? <Row
                      justify="space-between"
                      align="middle"
                      style={{ marginBottom: "2em" }}
                    >
                      <Col>
                        <div className={s.calendarDay}>{moment(cren?.day).format('dddd D MMMM YYYY')}</div>
                      </Col>
                      <div className={s.reservationBox}>
                        {reservationAdded(cren?.day) && couponData?.couponType !== "FREE_CLASS" ? <div className={s.reservationAddedBox}>
                          <img src={CheckIcon} height={15} style={{ marginRight: "5px" }}></img>
                          Créneau ajouté
                          <div className={s.verticalSeparator}></div>
                          <Button
                            type="link"
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "30px", width: "auto", paddingLeft: "5px", paddingRight: "5px", color: "#707070" }}
                            onClick={() => removeReservation(cren?.day)}
                          >
                            Retirer
                          </Button>
                        </div> : confirmReservations.length !== 0 && confirmReservations[0].day === cren.day && couponData?.couponType === "FREE_CLASS" ? <span onClick={() => clearFreeCouponTime(cren)} className={s.reservationMessageCoupon}>Enlever la sélection</span> : <></>}
                        {!reservationAdded(cren?.day) && confirmReservations.map((reservation) => {
                          if (reservation.day === cren.day) {
                            const totalForDay = reservation?.topic?.price;
                            return (
                              <Fragment>
                                <Popconfirm
                                  placement="top"
                                  title={
                                    "Veux-tu vraiment sélectionner ce créneau ?"
                                  }
                                  onConfirm={() => addNewReservation(reservation)}
                                  okText="Oui"
                                  cancelText="Non"
                                >
                                  <AppButton className={s.newProfileButton}>
                                    {couponData?.couponType === "FREE_CLASS" ? "Sélectionner ce créneau" : `Sélectionner ce créneau pour ${totalForDay} €`}
                                  </AppButton>
                                </Popconfirm>
                              </Fragment>
                            );
                          }
                        }, [])}
                      </div>
                    </Row> : <Row
                      justify="space-between"
                      align="middle"
                      style={{ marginBottom: "2em" }}
                    >
                      <Col>
                        <div className={s.calendarDay}>{moment(cren?.day).format('dddd D MMMM YYYY')}</div>
                      </Col>
                      <div className={s.reservationBox}>
                        {reservationAdded(cren?.day) ? <div className={s.reservationAddedBox}>
                          <img src={CheckIcon} height={15} style={{ marginRight: "5px" }}></img>
                          Créneau ajouté
                          <div className={s.verticalSeparator}></div>
                          <Button
                            type="link"
                            style={{ display: "flex", alignItems: "center", justifyContent: "center", height: "30px", width: "auto", paddingLeft: "5px", paddingRight: "5px", background: "#FFF", color: "#707070" }}
                            onClick={() => removeReservation(cren?.day)}
                          >
                            Retirer
                          </Button>
                        </div> : <span className={s.reservationMessage}>Réservation minimum 1 heure</span>}
                        {!reservationAdded(cren?.day) && confirmReservations.map((reservation) => {
                          if (reservation.day === cren.day) {
                            const totalForDay = reservation?.topic?.price;
                            return (
                              currentReservation ? creditAllowed && currentTeacher?.credits !== 0 && currentReservation?.byCredits !== "" ? <Fragment>
                                {confirmReservations.length > 0 && (
                                  <Fragment>
                                    {currentTeacher?.credits <
                                      calculateForReplanification(calculateTimeDifferenceForDay(reservation.day)) ? (
                                      <Text type="danger">
                                        Vous n'avez pas suffisamment de "crédit"
                                        pour effectuer cette réservation.
                                      </Text>
                                    ) : (
                                      <Fragment>
                                        <Popconfirm
                                          placement="top"
                                          title={
                                            "Veux-tu vraiment sélectionner ce créneau ?"
                                          }
                                          onConfirm={() => scheduleMeeting(calculateTimeDifferenceForDay(reservation.day))}
                                          okText="Oui"
                                          cancelText="Non"
                                        >
                                          <AppButton className={s.newProfileButton} loading={isReplanning}>
                                            {calculateTimeDifferenceForDay(reservation.day) > currentReservation?.creditedValue ? `Modifier ce créneau pour ${calculateForReplanification(calculateTimeDifferenceForDay(reservation.day))} crédit de plus` : "Réserver ce créneau"}
                                          </AppButton>
                                        </Popconfirm>
                                      </Fragment>
                                    )}
                                  </Fragment>
                                )}
                              </Fragment> : <Fragment>
                                {confirmReservations.length > 0 && (
                                  <Fragment>
                                    {user.coins <
                                      calculateForReplanification(totalForDay) ? (
                                      <Text type="danger">
                                        Vous n'avez pas suffisamment de "coins"
                                        pour effectuer cette réservation. Coût :{" "}
                                        {totalForDay} €
                                      </Text>
                                    ) : (
                                      <Fragment>
                                        <Popconfirm
                                          placement="top"
                                          title={
                                            "Veux-tu vraiment sélectionner ce créneau ?"
                                          }
                                          onConfirm={() => scheduleMeeting(totalForDay)}
                                          okText="Oui"
                                          cancelText="Non"
                                        >
                                          <AppButton className={s.newProfileButton} loading={isReplanning}>
                                            {totalForDay > currentReservation?.price ? `Modifier ce créneau pour ${calculateForReplanification(totalForDay)} € de plus` : "Réserver ce créneau"}
                                          </AppButton>
                                        </Popconfirm>
                                      </Fragment>
                                    )}
                                  </Fragment>
                                )}
                              </Fragment> : creditAllowed && currentTeacher?.credits !== 0 ? <Fragment>
                                {confirmReservations.length > 0 && (
                                  <Fragment>
                                    {currentTeacher?.credits <
                                      sumPrices(confirmReservations) ? (
                                      <Text type="danger">
                                        Vous n'avez pas suffisamment de "crédit"
                                        pour effectuer cette réservation.
                                      </Text>
                                    ) : (
                                      <Fragment>
                                        <Popconfirm
                                          placement="top"
                                          title={
                                            "Veux-tu vraiment sélectionner ce créneau ?"
                                          }
                                          onConfirm={() => addNewReservation(reservation)}
                                          okText="Oui"
                                          cancelText="Non"
                                        >
                                          <AppButton className={s.newProfileButton}>
                                            Sélectionner ce créneau pour {calculateTimeDifferenceForDay(reservation.day)} crédit
                                          </AppButton>
                                        </Popconfirm>
                                      </Fragment>
                                    )}
                                  </Fragment>
                                )}
                              </Fragment> : <Fragment>
                                {confirmReservations.length > 0 && (
                                  <Fragment>
                                    {user.coins <
                                      sumPrices(confirmReservations) ? (
                                      <Text type="danger">
                                        Vous n'avez pas suffisamment de "coins"
                                        pour effectuer cette réservation. Coût :{" "}
                                        {totalForDay} €
                                      </Text>
                                    ) : (
                                      <Fragment>
                                        <Popconfirm
                                          placement="top"
                                          title={
                                            "Veux-tu vraiment sélectionner ce créneau ?"
                                          }
                                          onConfirm={() => addNewReservation(reservation)}
                                          okText="Oui"
                                          cancelText="Non"
                                        >
                                          <AppButton className={s.newProfileButton}>
                                            Sélectionner ce créneau pour {totalForDay} €
                                          </AppButton>
                                        </Popconfirm>
                                      </Fragment>
                                    )}
                                  </Fragment>
                                )}
                              </Fragment>
                            );
                          }
                          ///return acc;
                        }, [])}
                      </div>
                    </Row>}

                    {couponApplayed && couponData?.couponType === "FREE_CLASS" ? <div className={s.dayTimeBox}>
                      <div className={s.momentBoxCoupon}>Matin</div>
                      <div className={s.timeBoxCoupon}>
                        {morningHours.filter((time) => isTimeInRange(time, cren?.niches)).length === 0 ?
                          <div style={{ height: "40px", width: isMobile ? "70vw" : "300px", fontSize: isMobile ? "0.8em" : "14px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: isMobile ? "center" : "flex-start", margin: isMobile ? "0px auto" : "0px" }}>Aucun horaire disponible</div>
                          : morningHours.filter((time) => isTimeInRange(time, cren?.niches)).map((time) => {
                            const [hour, minute] = time.split(":").map(Number);
                            const nextTime = new Date(0, 0, 0, hour, minute + 30).toLocaleTimeString("fr-FR", {
                              hour: "2-digit",
                              minute: "2-digit",
                            });

                            return (
                              <button
                                key={time}
                                onClick={() => {
                                  if (isTimeDisabled(time, cren?.day)) {
                                    message.warning(<span>Tu as déjà sélectionné un créneau.<br />Tu ne peux sélectionner que 30 minutes !</span>);
                                    return;
                                  }
                                  handleTimeClickCoupon(time, cren);
                                }}
                                className={isTimeSelected(time, cren?.day) ? s.timeButtonActive : s.timeButton}
                              /* disabled={isTimeDisabled(time, cren?.day)} */
                              >
                                {time}
                                <span className={isTimeSelected(time, cren?.day) ? s.timeButtonNextTimeActive : s.timeButtonNextTime}>{nextTime}</span>
                              </button>
                            );
                          })}
                      </div>
                    </div> : <div className={s.dayTimeBox}>
                      <div className={s.momentBox}>Matin</div>
                      <div className={s.timeBox}>
                        {morningHours.map((time: string) => {
                          const [hour, minute] = time.split(":").map(Number);
                          const nextTime = new Date(0, 0, 0, hour, minute + 30).toLocaleTimeString("fr-FR", {
                            hour: "2-digit",
                            minute: "2-digit",
                          });

                          return (
                            isTimeInRange(time, cren?.niches) ? <button
                              key={time}
                              onClick={() => handleTimeClick(time, cren)}
                              className={isTimeSelected(time, cren?.day) ? s.timeButtonActive : s.timeButton}
                            >
                              {time}
                              <span className={getNextTimeClassName(time, cren?.day)}>{nextTime}</span>
                            </button> : <button
                              key={time}
                              className={s.timeButtonDisabled}
                              disabled={true}
                            >
                              {time}
                              <span className={getNextTimeClassName(time, cren?.day)}>{nextTime}</span>
                            </button>);
                        })}
                      </div>
                    </div>}

                    {couponApplayed && couponData?.couponType === "FREE_CLASS" ? <div className={s.dayTimeBox}>
                      <div className={s.momentBoxCoupon}>Aprés-midi</div>
                      <div className={s.timeBoxCoupon}>
                        {afternoonHours.filter((time) => isTimeInRange(time, cren?.niches)).length === 0 ?
                          <div style={{ height: "40px", width: isMobile ? "70vw" : "300px", fontSize: isMobile ? "0.8em" : "14px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: isMobile ? "center" : "flex-start", margin: isMobile ? "0px auto" : "0px" }}>Aucun horaire disponible</div>
                          : afternoonHours.filter((time) => isTimeInRange(time, cren?.niches)).map((time) => {
                            const [hour, minute] = time.split(":").map(Number);
                            const nextTime = new Date(0, 0, 0, hour, minute + 30).toLocaleTimeString("fr-FR", {
                              hour: "2-digit",
                              minute: "2-digit",
                            });

                            return (
                              <button
                                key={time}
                                onClick={() => {
                                  if (isTimeDisabled(time, cren?.day)) {
                                    message.warning(<span>Tu as déjà sélectionné un créneau.<br />Tu ne peux sélectionner que 30 minutes !</span>);
                                    return;
                                  }
                                  handleTimeClickCoupon(time, cren);
                                }}
                                className={isTimeSelected(time, cren?.day) ? s.timeButtonActive : s.timeButton}
                              /* disabled={isTimeDisabled(time, cren?.day)} */
                              >
                                {time}
                                <span className={isTimeSelected(time, cren?.day) ? s.timeButtonNextTimeActive : s.timeButtonNextTime}>{nextTime}</span>
                              </button>
                            );
                          })}
                      </div>
                    </div> : <div className={s.dayTimeBox}>
                      <div className={s.momentBox}>Aprés-midi</div>
                      <div className={s.timeBox}>
                        {afternoonHours.map((time: string) => {
                          const [hour, minute] = time.split(":").map(Number);
                          const nextTime = new Date(0, 0, 0, hour, minute + 30).toLocaleTimeString("fr-FR", {
                            hour: "2-digit",
                            minute: "2-digit",
                          });

                          return (
                            isTimeInRange(time, cren?.niches) ? <button
                              key={time}
                              onClick={() => handleTimeClick(time, cren)}
                              className={isTimeSelected(time, cren?.day) ? s.timeButtonActive : s.timeButton}
                            >
                              {time}
                              <span className={getNextTimeClassName(time, cren?.day)}>{nextTime}</span>
                            </button> : <button
                              key={time}
                              className={s.timeButtonDisabled}
                              disabled={true}
                            >
                              {time}
                              <span className={getNextTimeClassName(time, cren?.day)}>{nextTime}</span>
                            </button>);
                        })}
                      </div>
                    </div>}

                    {couponApplayed && couponData?.couponType === "FREE_CLASS" ? <div className={s.dayTimeBox}>
                      <div className={s.momentBoxCoupon}>Soir</div>
                      <div className={s.timeBoxCoupon}>
                        {nightHours.filter((time) => isTimeInRange(time, cren?.niches)).length === 0 ?
                          <div style={{ height: "40px", width: isMobile ? "70vw" : "300px", fontSize: isMobile ? "0.8em" : "14px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: isMobile ? "center" : "flex-start", margin: isMobile ? "0px auto" : "0px" }}>Aucun horaire disponible</div>
                          : nightHours.filter((time) => isTimeInRange(time, cren?.niches)).map((time) => {
                            const [hour, minute] = time.split(":").map(Number);
                            const nextTime = new Date(0, 0, 0, hour, minute + 30).toLocaleTimeString("fr-FR", {
                              hour: "2-digit",
                              minute: "2-digit",
                            });

                            return (
                              <button
                                key={time}
                                onClick={() => {
                                  if (isTimeDisabled(time, cren?.day)) {
                                    message.warning(<span>Tu as déjà sélectionné un créneau.<br />Tu ne peux sélectionner que 30 minutes !</span>);
                                    return;
                                  }
                                  handleTimeClickCoupon(time, cren);
                                }}
                                className={isTimeSelected(time, cren?.day) ? s.timeButtonActive : s.timeButton}
                              /* disabled={isTimeDisabled(time, cren?.day)} */
                              >
                                {time}
                                <span className={isTimeSelected(time, cren?.day) ? s.timeButtonNextTimeActive : s.timeButtonNextTime}>{nextTime}</span>
                              </button>
                            );
                          })}
                      </div>
                    </div> : <div className={s.dayTimeBox}>
                      <div className={s.momentBox}>Soir</div>
                      <div className={s.timeBox}>
                        {nightHours.map((time: string) => {
                          const [hour, minute] = time.split(":").map(Number);
                          const nextTime = new Date(0, 0, 0, hour, minute + 30).toLocaleTimeString("fr-FR", {
                            hour: "2-digit",
                            minute: "2-digit",
                          });

                          return (
                            isTimeInRange(time, cren?.niches) ? <button
                              key={time}
                              onClick={() => handleTimeClick(time, cren)}
                              className={isTimeSelected(time, cren?.day) ? s.timeButtonActive : s.timeButton}
                            >
                              {time}
                              <span className={getNextTimeClassName(time, cren?.day)}>{nextTime}</span>
                            </button> : <button
                              key={time}
                              className={s.timeButtonDisabled}
                              disabled={true}
                            >
                              {time}
                              <span className={getNextTimeClassName(time, cren?.day)}>{nextTime}</span>
                            </button>);
                        })}
                      </div>
                    </div>}
                  </div>);
                })
              ) : (
                <div className={s.dayBox} style={{ background: couponApplayed ? "#E7F8F8" : "#FFF" }}>
                  {couponApplayed ? <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "2em" }}
                  >
                    <Col>
                      <div className={s.calendarDay}>{moment(creneau?.day).format('dddd D MMMM YYYY')}</div>
                    </Col>
                    <div className={s.reservationBox}>
                      {confirmReservations.length !== 0 && confirmReservations[0].day === creneau.day && couponData?.couponType === "FREE_CLASS" && <span onClick={() => clearFreeCouponTime(creneau)} className={s.reservationMessageCoupon}>Enlever la sélection</span>}
                      {confirmReservations.length > 0 &&
                        <Popconfirm
                          placement="top"
                          title={
                            "Veux-tu vraiment sélectionner ce créneau ?"
                          }
                          onConfirm={() => addNewReservation(confirmReservations[0])}
                          okText="Oui"
                          cancelText="Non"
                        >
                          <AppButton className={s.newProfileButton}>
                            Sélectionner ce créneau
                          </AppButton>
                        </Popconfirm>
                      }
                    </div>
                  </Row> : <Row
                    justify="space-between"
                    align="middle"
                    style={{ marginBottom: "2em" }}
                  >
                    <Col>
                      <div className={s.calendarDay}>{moment(creneau?.day).format('dddd D MMMM YYYY')}</div>
                    </Col>
                    <div className={s.reservationBox}>
                      <span className={s.reservationMessage}>Réservation minimum 1 heure</span>
                      {confirmReservations.length > 0 && (
                        currentReservation ? creditAllowed && currentTeacher?.credits !== 0 && currentReservation?.byCredits !== "" ? <Fragment>
                          {confirmReservations.length > 0 && (
                            <Fragment>
                              {currentTeacher?.credits <
                                calculateForReplanification(calculateTimeDifferenceForDay(confirmReservations[0]?.day)) ? (
                                <Text type="danger">
                                  Vous n'avez pas suffisamment de "crédit"
                                  pour effectuer cette réservation.
                                </Text>
                              ) : (
                                <Fragment>
                                  <Popconfirm
                                    placement="top"
                                    title={
                                      "Veux-tu vraiment sélectionner ce créneau ?"
                                    }
                                    onConfirm={() => scheduleMeeting(calculateTimeDifferenceForDay(confirmReservations[0]?.day))}
                                    okText="Oui"
                                    cancelText="Non"
                                  >
                                    <AppButton className={s.newProfileButton} loading={isReplanning}>
                                      {calculateTimeDifferenceForDay(confirmReservations[0]?.day) > currentReservation?.creditedValue ? `Modifier ce créneau pour ${calculateForReplanification(calculateTimeDifferenceForDay(confirmReservations[0]?.day))} crédit de plus` : "Réserver ce créneau"}
                                    </AppButton>
                                  </Popconfirm>
                                </Fragment>
                              )}
                            </Fragment>
                          )}
                        </Fragment> : <Fragment>
                          {confirmReservations.length > 0 && (
                            <Fragment>
                              {user.coins <
                                calculateForReplanification(confirmReservations[0]?.topic?.price) ? (
                                <Text type="danger">
                                  Vous n'avez pas suffisamment de "coins"
                                  pour effectuer cette réservation. Coût :{" "}
                                  {confirmReservations[0]?.topic?.price} €
                                </Text>
                              ) : (
                                <Fragment>
                                  <Popconfirm
                                    placement="top"
                                    title={
                                      "Veux-tu vraiment sélectionner ce créneau ?"
                                    }
                                    onConfirm={() => scheduleMeeting(confirmReservations[0]?.topic?.price)}
                                    okText="Oui"
                                    cancelText="Non"
                                  >
                                    <AppButton className={s.newProfileButton} loading={isReplanning}>
                                      {confirmReservations[0]?.topic?.price > currentReservation?.price ? `Modifier ce créneau pour ${calculateForReplanification(confirmReservations[0]?.topic?.price)} € de plus` : "Réserver ce créneau"}
                                    </AppButton>
                                  </Popconfirm>
                                </Fragment>
                              )}
                            </Fragment>
                          )}
                        </Fragment> : creditAllowed && currentTeacher?.credits !== 0 ? <Fragment>
                          {currentTeacher?.credits < sumPrices(confirmReservations) ? (
                            <Text type="danger">
                              Vous n'avez pas suffisamment de "crédit" pour
                              effectuer cette réservation.
                            </Text>
                          ) : (
                            <Fragment>
                              <Popconfirm
                                placement="top"
                                title={
                                  "Veux-tu vraiment faire la réservation ?"
                                }
                                onConfirm={handleConfirmReservation}
                                okText="Oui"
                                cancelText="Non"
                              >
                                <AppButton className={s.newProfileButton}>
                                  Confirmer la réservation pour {sumPrices(confirmReservations)} crédit
                                </AppButton>
                              </Popconfirm>
                            </Fragment>
                          )}
                        </Fragment> : <Fragment>
                          {user.coins < sumPrices(confirmReservations) ? (
                            <Text type="danger">
                              Vous n'avez pas suffisamment de "coins" pour
                              effectuer cette réservation. Coût :{" "}
                              {sumPrices(confirmReservations)} €
                            </Text>
                          ) : (
                            <Fragment>
                              <Popconfirm
                                placement="top"
                                title={
                                  "Veux-tu vraiment faire la réservation ?"
                                }
                                onConfirm={handleConfirmReservation}
                                okText="Oui"
                                cancelText="Non"
                              >
                                <AppButton className={s.newProfileButton}>
                                  Confirmer la réservation pour {sumPrices(confirmReservations)} €
                                </AppButton>
                              </Popconfirm>
                            </Fragment>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </Row>}

                  {creneau?.niches?.length <= 0 ? (
                    <AppResult
                      status="info"
                      title={`Ce professeur n'a aucune disponibilité pour le ${moment(
                        creneau?.day
                      ).format("LL")}`}
                    />
                  ) : (
                    <Fragment>
                      <>
                        {couponApplayed && couponData?.couponType === "FREE_CLASS" ? <div className={s.dayTimeBox}>
                          <div className={s.momentBoxCoupon}>Matin</div>
                          <div className={s.timeBoxCoupon}>
                            {morningHours.filter((time) => isTimeInRange(time, creneau?.niches)).length === 0 ?
                              <div style={{ height: "40px", width: isMobile ? "70vw" : "300px", fontSize: isMobile ? "0.8em" : "14px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: isMobile ? "center" : "flex-start", margin: isMobile ? "0px auto" : "0px" }}>Aucun horaire disponible</div>
                              : morningHours.filter((time) => isTimeInRange(time, creneau?.niches)).map((time) => {
                                const [hour, minute] = time.split(":").map(Number);
                                const nextTime = new Date(0, 0, 0, hour, minute + 30).toLocaleTimeString("fr-FR", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                });

                                return (
                                  <button
                                    key={time}
                                    onClick={() => {
                                      if (isTimeDisabled(time, creneau?.day)) {
                                        message.warning(<span>Tu as déjà sélectionné un créneau.<br />Tu ne peux sélectionner que 30 minutes !</span>);
                                        return;
                                      }
                                      handleTimeClickCoupon(time, creneau);
                                    }}
                                    className={isTimeSelected(time, creneau?.day) ? s.timeButtonActive : s.timeButton}
                                  /* disabled={isTimeDisabled(time, creneau?.day)} */
                                  >
                                    {time}
                                    <span className={isTimeSelected(time, creneau?.day) ? s.timeButtonNextTimeActive : s.timeButtonNextTime}>{nextTime}</span>
                                  </button>
                                );
                              })}
                          </div>
                        </div> : <div className={s.dayTimeBox}>
                          <div className={s.momentBox}>Matin</div>
                          <div className={s.timeBox}>
                            {morningHours.map((time: string) => {
                              const [hour, minute] = time.split(":").map(Number);
                              const nextTime = new Date(0, 0, 0, hour, minute + 30).toLocaleTimeString("fr-FR", {
                                hour: "2-digit",
                                minute: "2-digit",
                              });

                              return (
                                isTimeInRange(time, creneau?.niches) ? <button
                                  key={time}
                                  onClick={() => handleTimeClick(time, creneau)}
                                  className={isTimeSelected(time, creneau?.day) ? s.timeButtonActive : s.timeButton}
                                >
                                  {time}
                                  <span className={getNextTimeClassName(time, creneau?.day)}>{nextTime}</span>
                                </button> : <button
                                  key={time}
                                  className={s.timeButtonDisabled}
                                  disabled={true}
                                >
                                  {time}
                                  <span className={getNextTimeClassName(time, creneau?.day)}>{nextTime}</span>
                                </button>);
                            })}
                          </div>
                        </div>}

                        {couponApplayed && couponData?.couponType === "FREE_CLASS" ? <div className={s.dayTimeBox}>
                          <div className={s.momentBoxCoupon}>Aprés-midi</div>
                          <div className={s.timeBoxCoupon}>
                            {afternoonHours.filter((time) => isTimeInRange(time, creneau?.niches)).length === 0 ?
                              <div style={{ height: "40px", width: isMobile ? "70vw" : "300px", fontSize: isMobile ? "0.8em" : "14px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: isMobile ? "center" : "flex-start", margin: isMobile ? "0px auto" : "0px" }}>Aucun horaire disponible</div>
                              : afternoonHours.filter((time) => isTimeInRange(time, creneau?.niches)).map((time) => {
                                const [hour, minute] = time.split(":").map(Number);
                                const nextTime = new Date(0, 0, 0, hour, minute + 30).toLocaleTimeString("fr-FR", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                });

                                return (
                                  <button
                                    key={time}
                                    onClick={() => {
                                      if (isTimeDisabled(time, creneau?.day)) {
                                        message.warning(<span>Tu as déjà sélectionné un créneau.<br />Tu ne peux sélectionner que 30 minutes !</span>);
                                        return;
                                      }
                                      handleTimeClickCoupon(time, creneau);
                                    }}
                                    className={isTimeSelected(time, creneau?.day) ? s.timeButtonActive : s.timeButton}
                                  /* disabled={isTimeDisabled(time, creneau?.day)} */
                                  >
                                    {time}
                                    <span className={isTimeSelected(time, creneau?.day) ? s.timeButtonNextTimeActive : s.timeButtonNextTime}>{nextTime}</span>
                                  </button>
                                );
                              })}
                          </div>
                        </div> : <div className={s.dayTimeBox}>
                          <div className={s.momentBox}>Aprés-midi</div>
                          <div className={s.timeBox}>
                            {afternoonHours.map((time: string) => {
                              const [hour, minute] = time.split(":").map(Number);
                              const nextTime = new Date(0, 0, 0, hour, minute + 30).toLocaleTimeString("fr-FR", {
                                hour: "2-digit",
                                minute: "2-digit",
                              });

                              return (
                                isTimeInRange(time, creneau?.niches) ? <button
                                  key={time}
                                  onClick={() => handleTimeClick(time, creneau)}
                                  className={isTimeSelected(time, creneau?.day) ? s.timeButtonActive : s.timeButton}
                                >
                                  {time}
                                  <span className={getNextTimeClassName(time, creneau?.day)}>{nextTime}</span>
                                </button> : <button
                                  key={time}
                                  className={s.timeButtonDisabled}
                                  disabled={true}
                                >
                                  {time}
                                  <span className={getNextTimeClassName(time, creneau?.day)}>{nextTime}</span>
                                </button>);
                            })}
                          </div>
                        </div>}

                        {couponApplayed && couponData?.couponType === "FREE_CLASS" ? <div className={s.dayTimeBox}>
                          <div className={s.momentBoxCoupon}>Soir</div>
                          <div className={s.timeBoxCoupon}>
                            {nightHours.filter((time) => isTimeInRange(time, creneau?.niches)).length === 0 ?
                              <div style={{ height: "40px", width: isMobile ? "70vw" : "300px", fontSize: isMobile ? "0.8em" : "14px", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: isMobile ? "center" : "flex-start", margin: isMobile ? "0px auto" : "0px" }}>Aucun horaire disponible</div>
                              : nightHours.filter((time) => isTimeInRange(time, creneau?.niches)).map((time) => {
                                const [hour, minute] = time.split(":").map(Number);
                                const nextTime = new Date(0, 0, 0, hour, minute + 30).toLocaleTimeString("fr-FR", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                });

                                return (
                                  <button
                                    key={time}
                                    onClick={() => {
                                      if (isTimeDisabled(time, creneau?.day)) {
                                        message.warning(<span>Tu as déjà sélectionné un créneau.<br />Tu ne peux sélectionner que 30 minutes !</span>);
                                        return;
                                      }
                                      handleTimeClickCoupon(time, creneau);
                                    }}
                                    className={isTimeSelected(time, creneau?.day) ? s.timeButtonActive : s.timeButton}
                                  /* disabled={isTimeDisabled(time, creneau?.day)} */
                                  >
                                    {time}
                                    <span className={isTimeSelected(time, creneau?.day) ? s.timeButtonNextTimeActive : s.timeButtonNextTime}>{nextTime}</span>
                                  </button>
                                );
                              })}
                          </div>
                        </div> : <div className={s.dayTimeBox}>
                          <div className={s.momentBox}>Soir</div>
                          <div className={s.timeBox}>
                            {nightHours.map((time: string) => {
                              const [hour, minute] = time.split(":").map(Number);
                              const nextTime = new Date(0, 0, 0, hour, minute + 30).toLocaleTimeString("fr-FR", {
                                hour: "2-digit",
                                minute: "2-digit",
                              });

                              return (
                                isTimeInRange(time, creneau?.niches) ? <button
                                  key={time}
                                  onClick={() => handleTimeClick(time, creneau)}
                                  className={isTimeSelected(time, creneau?.day) ? s.timeButtonActive : s.timeButton}
                                >
                                  {time}
                                  <span className={getNextTimeClassName(time, creneau?.day)}>{nextTime}</span>
                                </button> : <button
                                  key={time}
                                  className={s.timeButtonDisabled}
                                  disabled={true}
                                >
                                  {time}
                                  <span className={getNextTimeClassName(time, creneau?.day)}>{nextTime}</span>
                                </button>);
                            })}
                          </div>
                        </div>}
                      </>
                    </Fragment>
                  )}
                </div>
              )}
            </div> :
              <AppResult
                status={"info"}
                title="Ce professeur n'a aucune disponibilité"
              />}
          </div>
        </Fragment>
      )}

      <TeacherCalendarDetails
        currentTeacher={currentTeacher}
        drawerRes={drawerRes}
        allSuccessRes={allSuccessRes}
        setDrawerRes={setDrawerRes}
      />

      <Drawer
        className="drawer"
        onClose={handleCloseCouponDrawer}
        visible={openCouponDrawer}
        width={isMobile ? "100%" : isXsScreen ? "100%" : isSmallScreen ? "65%" : isMediumScreen ? "65%" : isNormalScreen ? "45%" : "45%"}
        title={<div style={{ fontSize: isMobile ? "0.8em" : "20px", fontFamily: "Poppins", fontWeight: "600", color: "#393E3E", backgroundColor: "transparent" }}>{<h3>Coupons disponibles</h3>}</div>}
        headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: isMobile ? "20px" : "40px", backgroundColor: "transparent" }}
        closeIcon={
          <img
            src={ArrowBack}
            height={isMobile ? 10 : 18}
            style={{}}
          ></img>
        }
        drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: isMobile ? "0px" : "30px", borderBottomLeftRadius: isMobile ? "0px" : "30px", boxShadow: "-3px 3px 34px #39393929" }}
      >
        <div className={s.drawerBody}>
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: "10px auto", width: isMobile ? "100%" : "90%" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", margin: "10px auto" }}>
              <img src={AlbertHappy} height={50} style={{ marginRight: "10px" }}></img>
              <span style={{ marginRight: isMobile ? "0px" : "20px" }}>Profite des discounts</span>
            </div>
            {teacherCoupons.map((coupon: any, index: number) => {
              return (
                <div key={index} className={s.couponDetailsBox}>
                  <div className={s.couponDetailsBoxMessage}>Coupon {coupon.couponType === "SESSION_DISCOUNT" ? `${coupon.requiredSessions < 2 ? `${coupon.requiredSessions} séance` : coupon.requiredSessions < 1000 ? `${coupon.requiredSessions} séances` : "illimité"}` : `-${coupon.discountRate}%`} jusqu’au {formatDate(coupon.expirationDate)}</div>
                  <Button onClick={() => handleApplyCoupon(coupon)} className={s.couponButton}>Choisir ce coupon</Button>
                </div>
              );
            })}
          </div>
        </div>
      </Drawer>
    </Drawer>
  );
};

export default TeacherCalendarForStudent;
