import FaqCollapse from "components/collapse";
import FadeInUpOnScroll from "components/FadeInUp";
import { FC, useState } from "react";
import ellipse_mission from "../../assets/images/app/home/ellipse_mission.svg";
import ellipse_testimony from "../../assets/images/app/home/ellipse_testimony_one.svg";
import molecule_science from "../../assets/images/app/molecule_science.png";
import s from "./style.module.css";

type Props = {
    type: string;
}

const Faqs: FC<Props> = ({ type }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    return (
        <div className={s.mainContainer}>
            <div className={s.faqContainer}>
                <div className={s.mainBox}>
                    <FadeInUpOnScroll>
                        <h1 className={s.title}>FAQs</h1>
                    </FadeInUpOnScroll>
                    <img src={ellipse_mission} className={s.ellipseTitle}></img>
                    {!isMobile && <img src={ellipse_testimony} style={{ position: "relative", left: "-30%", width: "15px", bottom: "25px", zIndex: "0" }}></img>}
                    <div className={s.faqMainBox}>
                        {type === "teacher" ? <FaqCollapse type="half" title={"Que faire si je rencontre un problème technique?"} content={"Nous vous invitons à contacter notre équipe de support technique via le formulaire de contact disponible sur notre site ou par email à support@monamialbert.com."} background="#FFF8F2" /> :
                            type === "parent" ? <FaqCollapse type="half" title={"Comment puis-je créer un compte parent sur Mon Ami Albert?"} content={"Pour créer un compte Parent, visitez notre site web et cliquez sur l'option d'inscription en tant que Parent. Suivez les instructions pour remplir les informations nécessaires et activer votre compte."} background="#FFF8F2" /> :
                                <FaqCollapse type="half" title={"Comment notre organisation peut-elle devenir partenaire de Mon Ami Albert?"} content={"Pour explorer les possibilités de partenariat avec Mon Ami Albert, veuillez contacter notre équipe de développement des partenariats à l'adresse partenariat@monamialbert.com. Nous discuterons des options qui peuvent inclure des collaborations sur des programmes éducatifs, des projets de recherche, ou d'autres initiatives."} background="#FFF8F2" />
                        }
                        {type === "teacher" ? <FaqCollapse type="half" title={"A quoi me sert la possibilité de créer une classe? En quoi est-ce pertinent pour moi en tant que prof?"} content={"Créer une classe sur Mon Ami Albert permet aux professeurs d'organiser leurs élèves de manière structurée, de faciliter la gestion des cours et d'encourager l'interaction. Vous pouvez partager des ressources, suivre les progrès via des quiz, et motiver les élèves avec un système de points, de challenges et de classement, enrichissant ainsi l'expérience d'apprentissage pour tous."} background="#FFD8CD" /> :
                            type === "parent" ? <FaqCollapse type="half" title={"Comment puis-je suivre les progrès de mon enfant sur la plateforme?"} content={"Vous pouvez suivre les progrès de votre enfant en vous connectant à votre compte Parent et en accédant au tableau de bord dédié. Ce tableau affiche les cours suivis, les notes obtenues, et d'autres indicateurs de performance."} background="#FFD8CD" /> :
                                <FaqCollapse type="half" title={"Quelles sont les différentes manières pour notre organisme de collaborer avec Mon Ami Albert?"} content={"Nous sommes ouverts et ravis de discuter afin de co-créer une collaboration fructueuse et enrichissante ensemble. Nous vous invitons à contacter notre équipe de développement de partenariats à l'adresse partnerships@monamialbert.com."} background="#FFD8CD" />
                        }
                    </div>
                    <div className={s.faqMainBox}>
                        {type === "teacher" ? <FaqCollapse type="half" title={"Quels sont les critères pour fixer les tarifs de mes cours?"} content={<div dangerouslySetInnerHTML={{ __html: "<p>Les tarifs des cours sur Mon Ami Albert sont fix&eacute;s en tenant compte de plusieurs crit&egrave;res :</p><ol><li><strong>Qualification et exp&eacute;rience du professeur</strong> : les professeurs avec des qualifications sup&eacute;rieures ou une exp&eacute;rience plus &eacute;tendue dans l'enseignement peuvent fixer des tarifs plus &eacute;lev&eacute;s en raison de leur expertise accrue.</li><li>&nbsp;<strong>Niveau scolaire de l'&eacute;l&egrave;ve</strong> : les cours destin&eacute;s aux niveaux avanc&eacute;s ou sp&eacute;cialis&eacute;s comme les classes pr&eacute;paratoires sont souvent fix&eacute;s &agrave; des tarifs plus &eacute;lev&eacute;s en comparaison avec ceux qui sont destin&eacute;s aux niveaux collège et secondaire en raison de la complexit&eacute; des mati&egrave;res enseign&eacute;es.</li><li><strong>Demande et offre</strong> : les tarifs peuvent &eacute;galement varier en fonction de la demande pour des sujets sp&eacute;cifiques et de la disponibilit&eacute; des enseignants qualifi&eacute;s dans ces domaines.</li></ol>" }} />} background="#DAE6E7" /> :
                            type === "parent" ? <FaqCollapse type="half" title={"Comment puis-je inscrire mon enfant sur la plateforme?"} content={<div dangerouslySetInnerHTML={{ __html: "<p>Pour ajouter votre enfant &agrave; la plateforme, connectez-vous &agrave; votre compte Parent et cliquez sur l'option <strong>Ajouter des &eacute;l&egrave;ves</strong>. Suivez ensuite les instructions pour compl&eacute;ter l'inscription de votre enfant.</p>" }} />} background="#DAE6E7" /> :
                                <FaqCollapse type="half" title={"Fournissez-vous des rapports sur l'utilisation de la plateforme par notre organisme?"} content={<div dangerouslySetInnerHTML={{ __html: "Oui, nous fournissons des rapports détaillés qui peuvent aider votre organisme à suivre l'engagement et les progrès de vos membres." }} />} background="#DAE6E7" />
                        }
                        {type === "teacher" ? <FaqCollapse type="half" title={"Comment puis-je devenir professeur sur Mon Ami Albert?"} content={"Pour enseigner sur Mon Ami Albert, vous devez avoir un diplôme ou qualification reconnus dans la matière que vous souhaitez enseigner ainsi qu'une expérience pertinente. Un processus de vérification et un entretien en ligne sont également requis pour valider votre expertise."} background="#FAE7CD" /> :
                            type === "parent" ? <FaqCollapse type="half" title={"Comment puis-je payer pour les cours de mon enfant?"} content={"Les paiements pour les cours peuvent être effectués via votre compte Parent. Ajoutez une méthode de paiement et suivez les instructions pour recharger le portefeuille de votre enfant et régler les frais des cours sélectionnés."} background="#FAE7CD" /> :
                                <FaqCollapse type="half" title={"Comment notre organisme peut-il utiliser la plateforme pour ses membres?"} content={"Mon Ami Albert offre des solutions personnalisées pour les organismes ou fondations qui souhaitent fournir un accès éducatif à leurs membres. Vous pouvez créer un compte Organisme et nous pouvons configurer des accès groupés selon vos besoins spécifiques."} background="#FAE7CD" />
                        }
                    </div>
                    <div className={s.faqMainBox}>
                        {type === "teacher" ? <FaqCollapse type="full" title={"Quels équipements sont nécessaires pour enseigner des cours sur la plateforme?"} content={"Pour enseigner efficacement sur Mon Ami Albert, vous aurez besoin d'un ordinateur avec une connexion Internet stable. Il est également nécessaire d'utiliser une webcam et un microphone de bonne qualité pour une meilleure interaction avec les élèves. Assurez-vous de disposer d'un environnement calme pour minimiser les distractions pendant les cours."} background="#F5DDFF" /> :
                            type === "parent" ? <FaqCollapse type="full" title={"Est-il possible de communiquer directement avec les professeurs de mon enfant?"} content={"Il est tout à fait possible pour vous d'échanger avec les enseignants de votre enfant à travers la fonction Messagerie sur le plateforme."} background="#F5DDFF" /> :
                                <FaqCollapse type="full" title={"Mon Ami Albert, c'est quoi?"} content={<div dangerouslySetInnerHTML={{ __html: "<p>Mon Ami Albert est une plateforme &eacute;ducative en ligne d&eacute;di&eacute;e &agrave; l'am&eacute;lioration de l'apprentissage des &eacute;l&egrave;ves, du niveau collège &agrave; la classe pr&eacute;pa. Notre mission est d'offrir un acc&egrave;s facile, direct et flexible &agrave; des cours de soutien acad&eacute;mique de qualit&eacute;, &agrave; des ressources &eacute;ducatives innovantes et &agrave; une assistance personnalis&eacute;e.</p><p>Chez Mon ami Albert, nous mettons en contact des &eacute;l&egrave;ves motiv&eacute;s avec des professeurs exp&eacute;riment&eacute;s &agrave; travers des sessions en ligne interactives adapt&eacute;es aux besoins et aux rythmes de chacun. Que ce soit pour des r&eacute;visions, l&rsquo;apprentissage de nouvelles comp&eacute;tences ou la pr&eacute;paration &agrave; des examens, Mon Ami Albert s'engage &agrave; offrir une exp&eacute;rience &eacute;ducative b&eacute;n&eacute;fique, enrichissante et accessible &agrave; tous.</p>" }} />} background="#F5DDFF" />
                        }
                        {!isMobile && <img src={molecule_science} style={{ height: "128px" }}></img>}
                    </div>
                </div>
                {!isMobile && <a className={s.quote}>“</a>}
            </div>
        </div>
    );
};

export default Faqs;