import { Form } from 'antd';
import AppButton from 'components/libs/button';
import React, { useState } from 'react';

interface OTPFormProps {
    onSubmitOtp: (otp: string) => void;
}

const OTPForm: React.FC<OTPFormProps> = ({ onSubmitOtp }) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [otp, setOtp] = useState<string[]>(Array(4).fill(''));

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1050);
    };
    window.addEventListener('resize', handleResize);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const newOtp = [...otp];
        newOtp[index] = e.target.value;
        setOtp(newOtp);

        // Move to the next input field if needed
        if (e.target.value && index < 3) {
            const nextInput = document.getElementById(`otp-input-${index + 1}`);
            nextInput?.focus();
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData("text").trim(); // Get copied text

        if (!/^\d+$/.test(pastedData)) return; // Ensure only numbers

        const pastedArray = pastedData.split("").slice(0, otp.length); // Convert to array & limit length
        const newOtp = [...otp];

        pastedArray.forEach((num, index) => {
            newOtp[index] = num;
        });

        setOtp(newOtp);
    };


    const handleSubmit = () => {

        setIsLoading(true);

        const otpValue = otp.join('');

        if (otpValue.length !== 4) {
            alert("Entre le code OTP reçu par email !");
            setIsLoading(false);
            return;
        }

        // Call API or handle OTP verification
        onSubmitOtp(otpValue);
        setIsLoading(false);
    };

    return (
        <Form validateTrigger={["onFinish"]} onFinish={handleSubmit} style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", margin: "0px auto" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", margin: "40px auto" }}>
                {otp.map((value, index) => (
                    <input
                        key={index}
                        id={`otp-input-${index}`}
                        type="text"
                        value={value}
                        onChange={(e) => handleChange(e, index)}
                        maxLength={1}
                        onPaste={(e) => handlePaste(e)}
                        style={{ height: isMobile ? "40px" : "80px", width: isMobile ? "40px" : "80px", textAlign: 'center', marginRight: '5px', marginLeft: "5px", border: "1px solid #ccc", borderRadius: isMobile ? "5px" : "14px" }}
                    />
                ))}
            </div>

            <Form.Item>
                <AppButton loading={isLoading} htmlTypeSubmit={true}><a style={{ fontSize: "15px" }}>CONTINUER</a></AppButton>
            </Form.Item>

        </Form>
    );
};

export default OTPForm;
