import { Avatar, Button, Skeleton, Space, Tooltip } from "antd";
import * as CHALLENGES_SERVICES from "api/services/challenges/challenges.services";
import * as ROOMS_SERVICES from "api/services/rooms/rooms.services";
import IconRoom from "assets/images/icons/classes.svg";
import { isToday, StatusCode, UserRoles } from "common/enums";
import HelpPopover from "components/help";
import Matiers from "components/matiers";
import * as appRoutes from "config/routes.config";
import { FC, Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setCheckClass } from "store/app/appSlice";
import { useGetHistoriqueQuery } from "store/services/teacher/classes.services";
import EmptyIcon from "../../../../../assets/images/app/empty_icon.svg";
import GreenIdea from "../../../../../assets/images/app/greenIdea.svg";
import NextClassIcon from "../../../../../assets/images/app/next_class_icon.svg";
import s from "./style.module.css";

interface CardRoomProps {
  challenge: boolean;
  classroom?: object | any;
}

interface CoursesRoomProps {
  course?: object | any;
}

const CardRoom: FC<CardRoomProps> = ({ challenge, classroom }) => {

  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const redirectHandler = () => {
    if (auth?.user?._id === classroom?.teacher?._id) {
      return navigate(`${appRoutes.TEACHER_ROOMS}/${classroom?._id}`);
    }
    else {
      return navigate(`${appRoutes.STUDENT_MY_CLASS}/${classroom?._id}`);
    }
  };

  return (
    <div className={challenge ? s.challengeClasseCard : s.classeCard} onClick={redirectHandler} style={{ cursor: "pointer" }}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%", textAlign: "left" }}>
        <Tooltip
          title={classroom?.title ||
            `Code de la classe : ${classroom?.accessCode?.split("-")[0].toUpperCase() ||
            "Nom indisponible"
            }`}
        >
          {classroom?.title.length > 16 ? classroom?.title.substring(0, 16) + "..." : classroom?.title ||
            `Code de la classe : ${classroom?.accessCode?.split("-")[0].toUpperCase() ||
            "Nom indisponible"
            }`}
        </Tooltip>
        {challenge ? <Matiers
          cours={classroom?.course?.title.slice(0, 4) || "null"}
          level={classroom?.level?.title || "null"}
          className={s.challengeMatiers}
        /> : <Matiers
          cours={classroom?.course?.title.slice(0, 4) || "null"}
          level={classroom?.level?.title || "null"}
        />}
      </div>
    </div>
  );
};

const CoursesRoom: FC<CoursesRoomProps> = ({ course }) => {

  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const isStudent = auth?.user?.roles?.includes(UserRoles.STUDENT);

  const handleComingClass = () => {
    navigate(isStudent ? appRoutes?.STUDENT_CALENDAR : appRoutes?.TEACHER_CALENDAR);
  }

  return (
    <div className={s.classeCard} onClick={handleComingClass} style={{ cursor: "pointer" }}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
        <div className={s.avatarNameBox}>
          <Avatar.Group
            maxCount={3}
            maxStyle={{
              color: "#f56a00",
              backgroundColor: "#fde3cf",
            }}
            style={{ marginRight: "5px" }}
          >
            <Avatar
              size={38}
              style={{ backgroundColor: "#FFF2E0" }}
              src={course?.profId?._id === auth?.user?._id ? course?.studId?.profile : course?.profId?.profile}
            />
            {!isStudent && course?.typeCreneaux !== "individuel" && <Avatar
              size={38}
              style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029", position: "relative", left: "-25px" }}
              src={course?.studId?.profile}
            />}
            {!isStudent && course?.typeCreneaux !== "individuel" && <Avatar
              size={38}
              style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029", position: "relative", left: "-50px" }}
              src={course?.studId?.profile}
            />}
          </Avatar.Group>
          <div style={{ position: "relative", left: !isStudent && course?.typeCreneaux !== "individuel" ? "-40px" : "0px", fontSize: "13px", height: "30px", width: "70%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>{isStudent ? course?.profId?.firstname.length + course?.profId?.lastname.length > 13 ? course?.profId?.firstname.length > 13 ? course?.profId?.firstname.substring(0, 13) + "..." : course?.profId?.firstname : course?.profId?.firstname + " " + course?.profId?.lastname : course?.typeCreneaux !== "individuel" ? "Cours collectif" : course?.studId?.firstname.length + course?.studId?.lastname.length > 13 ? course?.studId?.firstname.length > 13 ? course?.studId?.firstname.substring(0, 13) + "..." : course?.studId?.firstname : course?.studId?.firstname + " " + course?.studId?.lastname}</div>
        </div>
        <Matiers
          cours={course?.topic?.title.slice(0, 4) || "null"}
          level={course?.level?.title || "null"}
        />
      </div>
    </div>
  );
};

const TeacherEvents = () => {

  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [classesLoading, setClassesLoading] = useState(false);
  const [classeWithChallenge, setClassesWithChallenge] = useState(false);
  const [coursesError, setCoursesError] = useState("");
  const [rooms, setRooms] = useState<any>([]);
  const [courses, setCourses] = useState<any>([]);
  const { data, isLoading, isError, refetch } = useGetHistoriqueQuery(
    auth?.user?._id
  );
  const isStudent = auth?.user?.roles?.includes(UserRoles.STUDENT);
  const isTeacher = auth?.user?.roles?.includes(UserRoles.TEACHER);

  const showLink = async (
    urlProf: any | undefined,
    urlStud: any | undefined,
    idProf: any | undefined
  ) => {
    //check if Prof
    if (idProf === auth?.user?._id) {
      window.open(urlProf, '_blank');
    } else {
      window.open(urlStud, '_blank');
    }
  };

  const handleComingClass = () => {
    navigate(isStudent ? appRoutes?.STUDENT_CALENDAR : appRoutes?.TEACHER_CALENDAR);
  }

  const fetchCourses = useCallback(async (id: string | undefined) => {
    try {
      setClassesLoading(true);
      let response = await ROOMS_SERVICES.findByUser(auth.user._id);
      if (response?.statusCode === StatusCode.OK) {
        //loop into classes and check if have challenge 
        for (let i = 0; i < response.data.length; i++) {
          try {
            let challengeFound = await CHALLENGES_SERVICES.findByIdClasse(response.data[i]._id);
            if (challengeFound?.statusCode === StatusCode.OK) {
              response.data[i] = { ...response.data[i], hasChallenge: true };
              setClassesWithChallenge(true);
            }
          } catch (innerError) {
            // Handle any exceptions thrown within the loop, if necessary
            response.data[i] = { ...response.data[i], hasChallenge: false };
          }
        }
        setRooms(response.data);
      } else {
        setCoursesError(response?.message || "Erreur serveur");
      }
    } catch (error: any) {
      setCoursesError(error?.message || "error");
    } finally {
      setClassesLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchCourses(auth.user?._id);
  }, [auth.user?._id, fetchCourses]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (data?.data) {
      const sortedCourses = [...data.data].sort(
        (a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

      const groupedByDate = sortedCourses.reduce((acc: any, course: any) => {
        const date = new Date(course.startDate).toISOString().split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(course);
        return acc;
      }, {});

      setCourses(groupedByDate);
    }
  }, [data]);

  return (
    <div className={s.teacherEvents}>
      <div className={s.top}>
        <Space align="start" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
            <div className={s.titleIconBox}><img src={NextClassIcon} height={20} /></div>
            <div className={s.title}>Mes prochains cours</div>
          </div>
          <div style={{ marginLeft: "10px" }}><HelpPopover id={"accueil_prochains_cours"} /></div>
        </Space>
        <div className={s.scheduled__course}>
          {isLoading ? (
            <>
              <Skeleton />
            </>
          ) : (
            <Fragment>
              {data?.data?.length > 0 ? (
                courses && Object.keys(courses).slice(0, 1)?.map((date) => {
                  return (
                    <>
                      <div key={date} style={{ marginTop: "10px" }}>
                        <div style={{ fontSize: "11px", fontFamily: "Poppins", color: "#707070", display: "flex", flexDirection: "row", alignItems: "center", textTransform: "capitalize" }}>
                          {new Intl.DateTimeFormat("fr-FR", {
                            weekday: "long",
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          }).format(new Date(date))}
                          {isToday(date) && <div className={s.todayBox}>Aujourd’hui</div>}
                        </div>
                        {courses[date].map((course: any) => (
                          <div key={course.id}>
                            <CoursesRoom course={course} />
                          </div>
                        ))}
                      </div>
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><Button type="link" style={{ marginTop: "5px auto" }} onClick={handleComingClass}><a style={{ textDecoration: "underline" }}>Voir plus</a></Button></div>
                    </>
                  );
                })
              ) : (
                <div className={s.emptyClass}>
                  Aucun cours planifié
                  <img src={EmptyIcon} height={50}></img>
                  {isStudent && <Button className={s.newButton} onClick={() => navigate(appRoutes.STUDENT_SEARCH_BEST_TEACHER)}>Réserver un cours</Button>}
                </div>
              )}
            </Fragment>
          )}
        </div>
      </div>

      <div className={s.myClasses}>
        <Space align="start" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
            <div className={s.titleIconBox}><img src={IconRoom} height={50} /></div>
            <div className={s.title}>Mes classes</div>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <HelpPopover id={"accueil_mes_classes"} />
          </div>
        </Space>

        {classesLoading ? (
          <>
            <Skeleton />
          </>
        ) : (<div style={{ textAlign: "center" }}>

          {!classesLoading &&
            rooms?.slice(0, 3)?.map((classroom: any) => (
              <Fragment>
                <CardRoom challenge={false} classroom={classroom} />
              </Fragment>
            ))}

          {!classesLoading && isTeacher && rooms.length === 0 && (
            <div className={s.emptyClass}>
              Aucune classe
              <img src={EmptyIcon} height={50}></img>
            </div>
          )}

          {!classesLoading && isTeacher && <Button className={s.newButton} style={{ marginTop: "10px" }} onClick={() => { dispatch(setCheckClass(true)); navigate(appRoutes.TEACHER_ROOMS) }}>Créer une classe</Button>}
        </div>)}
      </div>

      {isTeacher && <div className={s.myChallengeClasses}>
        <Space align="start" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
            <div className={s.titleIconBox}><img src={IconRoom} height={50} /></div>
            <div className={s.title}>Classes avec challenge en cours</div>
          </div>
          <div style={{ marginLeft: "10px" }}>
            <HelpPopover id={"accueil_mes_classes"} />
          </div>
        </Space>
        <img src={GreenIdea} height={18} style={{ position: "relative", left: "30px", top: "-45px" }}></img>
        {classesLoading ? (
          <>
            <Skeleton />
          </>
        ) : (<div style={{ textAlign: "center" }}>

          {!classesLoading &&
            rooms?.slice(0, 3)?.map((classroom: any) => (
              <Fragment>
                {classroom.hasChallenge && <CardRoom challenge={true} classroom={classroom} />}
              </Fragment>
            ))}

          {/* {!classesLoading && classeWithChallenge && rooms.length > 3 && <div style={{ position: "relative", top: "130px" }}><Button type="link" style={{ marginTop: "5px" }} onClick={() => navigate(appRoutes.TEACHER_ROOMS)}><a style={{ textDecoration: "underline" }}>Voir plus</a></Button></div>} */}

          {!classesLoading && !classeWithChallenge && (
            <div className={s.emptyClass}>
              Aucun challenge
              <img src={EmptyIcon} height={50}></img>
              {/* {isTeacher && <Button className={s.newButton} onClick={() => navigate(appRoutes.TEACHER_ROOMS)}>Créer un challenge</Button>} */}
            </div>
          )}
        </div>)}
      </div>}
    </div>
  );
};

export default TeacherEvents;
