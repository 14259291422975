import {
    Alert,
    Checkbox,
    Col,
    Form,
    Input,
    Row,
    Select,
    Steps,
    message
} from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import Container from "components/container";
import * as appRoutes from "config/routes.config";
import React, { Dispatch, FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as TEACHER_SERVICES from "../../../api/services/teacher/teacher.services";

//!COMPONENTS
import AppButton from "components/libs/button";
import H1 from "components/libs/title";

//!IMAGES
import EllipseOne from "../../../assets/images/app/ellipse_teacher_profile1.png";
import EllipseTwo from "../../../assets/images/app/ellipse_teacher_profile2.png";
import EllipseThree from "../../../assets/images/app/ellipse_teacher_profile3.png";
import EllipseFour from "../../../assets/images/app/ellipse_teacher_profile4.png";

//!STYLES
import { Languages, StatusCode, StepMessage, StepTitle, UserRoles, currentStep, getCountryCode } from "common/enums";
import Breadcrumbs from "components/breadcrumbs";
import PhoneInput from "react-phone-input-2";
import { updateUser } from "store/auth/authSlice";
import AnimatedPage from "utils/animatedPage";
import s from "./assets/register.module.css";
import FormStep from "./component";
import StepEight from "./stepEight";
import StepFive from "./stepFive";
import StepFour from "./stepFour";
import StepSeven from "./stepSeven";
import StepSix from "./stepSix";
import StepThree from "./stepThree";
import StepTwo from "./stepTwo";

const { Step } = Steps;

const ProfileComplete: FC = () => {
    //!HOOKS
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const auth = useSelector((state: RootStateOrAny) => state.auth);
    const [formInfos] = Form.useForm();
    const { user } = useSelector((state) => state.auth);

    //!STATES
    const [current, setCurrent] = useState<number>(1);
    const { isRecording } = useSelector((state) => state.app);

    //!STATES JOFREY
    const [registrationData, setRegistrationData] = useState<any>({});
    const [stepTwoData, setStepTwoData] = useState<any>({});
    const [stepFiveData, setStepFiveData] = useState<any>({});
    const [dataList] = useState<any>([]);
    const [countriesList, setCountries] = useState<any>([]);
    const [languagesList, setLanguages] = useState<any>([]);
    const [educationSystemList, setEducationSystem] = useState<any>([]);
    const [teachingExperiencesList, setTeachingExperiences] = useState<any>([]);
    const [actualSituationsList, setActualSituation] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorAge, setErrorAge] = useState<boolean>(false);

    //!CONSTANTS
    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    const updateUserInfos = async (values: any) => {

        const finalData = {
            firstname: values?.firstname,
            lastname: values?.lastname,
            pays: values.countries,
            langues: values.languages,
            educSystem: values.educSystem,
            experience: values.enseignement,
            situation: values.situation,
            phone: values?.phone,
            receiveNotifs: values?.acceptedMessagesAndNotifications,
            ageGap: values?.confirmAge,
            step: user && user?.step === "completed" ? "completed" : "a_propos",
        };

        if (values?.confirmAge === true) {
            setIsLoading(true);
            setRegistrationData(user);
            try {
                const response = await AUTH_SERVICES.updateTeacher(user?._id, {
                    ...finalData,
                });
                if (response?.statusCode === StatusCode.OK) {
                    message.success({
                        content: "Modification effectuée ",
                    });
                    dispatch(
                        updateUser({
                            ...user,
                            firstname: values?.firstname,
                            lastname: values?.lastname,
                            pays: values.countries,
                            langues: values.languages,
                            educSystem: values.educSystem,
                            experience: values.enseignement,
                            situation: values.situation,
                            phone: values?.phone,
                            receiveNotifs: values?.acceptedMessagesAndNotifications,
                            ageGap: values?.confirmAge,
                            step: user && user?.step === "completed" ? "completed" : "a_propos",
                        })
                    );
                }
                else {
                    message.warning({
                        content: "une erreur est survenue",
                    });
                }
            } catch (error: any) {
                let content = "";
                for (
                    let index = 0;
                    index < error?.response?.data?.message?.length;
                    index++
                ) {
                    content = error?.response?.data?.message[index] + ", " + content;
                }
                message.error({
                    content: content,
                });
            } finally {
                setIsLoading(false);
                setCurrent(2);
            }
        }
        else {
            setErrorAge(true);
        }
    };

    const checkUserAge = async () => {
        if (errorAge) {
            setErrorAge(false);
        }
        else {
            setErrorAge(true);
        }
    }

    const getStaticLists = useCallback(
        async (type: string, dataList: any, state: Dispatch<any>) => {
            const response = await TEACHER_SERVICES.getDataList(type);

            if (response?.statusCode === StatusCode.OK) {
                state(response?.data);
            }
        },
        [dataList]
    );

    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const checkScreenSize = async () => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 767);
        };

        // Initial call to set initial state
        handleResize();

        // Attach resize event listener
        window.addEventListener('resize', handleResize);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }

    const [selectedCountryCode, setSelectedCountryCode] = useState<any>("fr");

    const handleCountryChange = (countryName: any) => {
        const countryCode = getCountryCode(countryName);
        if (countryCode) {
            const code = countryCode?.toLowerCase();
            setSelectedCountryCode(code);
        }
        else {
            setSelectedCountryCode("fr");
        }
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 1050);
    };
    window.addEventListener('resize', handleResize);

    //!EFFECTS
    useEffect(() => {
        checkScreenSize();
        if (user && UserRoles.TEACHER) {
            getStaticLists('pays', countriesList, setCountries);
            getStaticLists('langues', languagesList, setLanguages);
            getStaticLists('educSystem', educationSystemList, setEducationSystem);
            getStaticLists('experiences', teachingExperiencesList, setTeachingExperiences);
            getStaticLists('situations', actualSituationsList, setActualSituation);
            if (user) {
                setCurrent(currentStep(user?.step));
                if (user?.step !== "") {
                    if (user?.step === "otp") {
                        navigate(appRoutes.REGISTER_TEACHER_BY_EMAIL);
                    }
                    else {
                        formInfos.setFieldsValue({
                            firstname: user?.firstname || "",
                            lastname: user?.lastname || "",
                            phone: user?.phone || "",
                            countries: user?.pays || "",
                            languages: user?.langues || "",
                            educSystem: user?.educSystem || "",
                            enseignement: user?.experience || "",
                            situation: user?.situation || "",
                        });
                    }
                }
            }
            else {
                navigate(appRoutes.HOME);
            }
        }
        else {
            navigate(appRoutes.STUDENT_DASHBOARD);
        }
    }, [user, formInfos]);

    return (
        <AnimatedPage>
            {isRecording ? <div style={{ height: "50px", width: "100%" }}></div> : <Breadcrumbs />}
            <div className={s.register__teacher}>
                <Container>

                    <div className={s.content__action}>
                        <div className={s.teacher}>
                            {/* <div className={s.avatar}>
                                <Image
                                    preview={false}
                                    width={60}
                                    src={AvatarTeacher}
                                    alt="teacher avatar"
                                />
                            </div> */}
                            <div className={s.header__text}>
                                <h1 className={s.title}>Complète ton profil</h1>
                            </div>
                        </div>
                    </div>

                    <div className={s.step}>
                        <Steps current={current - 1} labelPlacement={"vertical"} responsive={true}>
                            <Step
                                style={{ cursor: "pointer" }}
                                title={
                                    <div style={{ lineHeight: "22px" }} className={s.title__step}>
                                        <span>A propos</span>
                                    </div>
                                }
                                onClick={user && user?.step === "completed" ? () => setCurrent(1) : () => setCurrent(current)}
                            />
                            <Step
                                style={{ cursor: "pointer" }}
                                title={
                                    <div style={{ lineHeight: "22px" }} className={s.title__step}>
                                        <span>Photo</span>
                                    </div>
                                }
                                onClick={user && user?.step === "completed" ? () => setCurrent(2) : () => setCurrent(current)}
                            />
                            <Step
                                style={{ cursor: "pointer" }}
                                title={
                                    <div style={{ lineHeight: "22px" }} className={s.title__step}>
                                        <span>Formation</span>
                                    </div>
                                }
                                onClick={user && user?.step === "completed" ? () => setCurrent(3) : () => setCurrent(current)}
                            />
                            <Step
                                style={{ cursor: "pointer" }}
                                title={
                                    <div style={{ lineHeight: "22px" }} className={s.title__step}>
                                        <span>Certifications</span>
                                    </div>
                                }
                                onClick={user && user?.step === "completed" ? () => setCurrent(4) : () => setCurrent(current)}
                            />
                            <Step
                                style={{ cursor: "pointer" }}
                                title={
                                    <div style={{ lineHeight: "22px" }} className={s.title__step}>
                                        <span>Description</span>
                                    </div>
                                }
                                onClick={user && user?.step === "completed" ? () => setCurrent(5) : () => setCurrent(current)}
                            />
                            <Step
                                style={{ cursor: "pointer" }}
                                title={
                                    <div style={{ lineHeight: "22px" }} className={s.title__step}>
                                        <span>Vidéo</span>
                                    </div>
                                }
                                onClick={user && user?.step === "completed" ? () => setCurrent(6) : () => setCurrent(current)}
                            />
                            <Step
                                style={{ cursor: "pointer" }}
                                title={
                                    <div style={{ lineHeight: "22px" }} className={s.title__step}>
                                        <span>Tarif</span>
                                    </div>
                                }
                                onClick={user && user?.step === "completed" ? () => setCurrent(7) : () => setCurrent(current)}
                            />
                            <Step
                                style={{ cursor: "pointer" }}
                                title={
                                    <div style={{ lineHeight: "22px" }} className={s.title__step}>
                                        <span>Disponibilité</span>
                                    </div>
                                }
                                onClick={user && user?.step === "completed" ? () => setCurrent(8) : () => setCurrent(current)}
                            />
                        </Steps>
                    </div>
                    <div className={s.step_container}>
                        <FormStep index={1} title="A propos" current={current} onClick={user && user?.step === "completed" ? () => setCurrent(1) : () => setCurrent(current)} />
                        <FormStep index={2} title="Photo" current={current} onClick={user && user?.step === "completed" ? () => setCurrent(2) : () => setCurrent(current)} />
                        <FormStep index={3} title="Formation" current={current} onClick={user && user?.step === "completed" ? () => setCurrent(3) : () => setCurrent(current)} />
                        <FormStep index={4} title="Certifications" current={current} onClick={user && user?.step === "completed" ? () => setCurrent(4) : () => setCurrent(current)} />
                        <FormStep index={5} title="Description" current={current} onClick={user && user?.step === "completed" ? () => setCurrent(5) : () => setCurrent(current)} />
                        <FormStep index={6} title="Vidéo" current={current} onClick={user && user?.step === "completed" ? () => setCurrent(6) : () => setCurrent(current)} />
                        <FormStep index={7} title="Tarif" current={current} onClick={user && user?.step === "completed" ? () => setCurrent(7) : () => setCurrent(current)} />
                        <FormStep index={8} title="Disponibilité" current={current} onClick={user && user?.step === "completed" ? () => setCurrent(8) : () => setCurrent(current)} />
                    </div>
                    {!isMobile && <img src={EllipseOne} style={{ position: "absolute", top: "22%", left: "0%", zIndex: "-1" }}></img>}
                    {!isMobile && <img src={EllipseTwo} height={156} style={{ position: "absolute", top: "65%", left: "90%", zIndex: "-1" }}></img>}
                    {!isMobile && <img src={EllipseThree} style={{ position: "absolute", top: "17%", left: "90%", zIndex: "-1" }}></img>}
                    {!isMobile && <img src={EllipseFour} style={{ position: "absolute", top: "60%", left: "2%", zIndex: "-1" }}></img>}
                    <div className={s.formBox}>
                        <div className={s.stepTitleBox}><H1 className={`${current === 5 || current === 6 || current === 8 ? s.hideElement : s.boxTitle}`}>{StepTitle(current)}</H1></div>
                        <div className={`${current === 2 || current === 5 || current === 6 ? s.hideElement : s.boxMessage}`}>{StepMessage(current)}</div>
                        <div className={`${current === 1 ? s.form__container : ""}`}>
                            <Form
                                validateTrigger={["onFinish"]}
                                validateMessages={validateMessages}
                                initialValues={{ acceptedMessagesAndNotifications: true, confirmAge: true }}
                                name="registerTeacher"
                                autoComplete="off"
                                form={formInfos}
                                onFinish={updateUserInfos}
                            >
                                {current === 1 && (
                                    <React.Fragment>
                                        <Row gutter={10}>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="firstname"
                                                    rules={[{ required: true }]}
                                                    style={{ marginBottom: "20px" }}
                                                >
                                                    <Input
                                                        className={s.field}
                                                        placeholder={t("auth.firstname")}
                                                        maxLength={30}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item
                                                    name="lastname"
                                                    rules={[{ required: true }]}
                                                    style={{ marginBottom: "20px" }}
                                                >
                                                    <Input
                                                        className={s.field}
                                                        placeholder={t("auth.lastname")}
                                                        maxLength={30}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            style={{ marginBottom: "20px" }}
                                            name="countries"
                                            rules={[{ required: true }]}
                                        >
                                            <Select
                                                showSearch
                                                style={{ padding: "5px 0px 5px 0px", fontSize: "16px" }}
                                                className={s.selectInput}
                                                placeholder={<div className={s.placeholderStyle}>Pays d'origine</div>}
                                                optionFilterProp="children"
                                                bordered={false}
                                                allowClear
                                                dropdownStyle={{ padding: "3px" }}
                                                showArrow={true}
                                                onChange={handleCountryChange}
                                            >
                                                <Select.Option value="" disabled style={{ color: "#C0C0C0" }}><div className={s.placeholderStyle}>Pays d'origine</div></Select.Option>
                                                {countriesList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{item}</Select.Option>))}
                                            </Select>
                                        </Form.Item>
                                        <div className={s.inputRow}>
                                            <div className={s.inputRowChild}>
                                                <Form.Item
                                                    style={{ marginBottom: "20px" }}
                                                    name="languages"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Select
                                                        style={{ padding: "5px 0px 5px 0px", fontSize: "16px" }}
                                                        className={s.selectInput}
                                                        placeholder={<div className={s.placeholderStyle}>Langues parlées</div>}
                                                        mode="multiple"
                                                        bordered={false}
                                                        allowClear
                                                        dropdownStyle={{ padding: "3px" }}
                                                        showArrow={true}
                                                    >
                                                        {languagesList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{Languages(item)}</Select.Option>))}
                                                    </Select>
                                                </Form.Item>
                                            </div>
                                            <div className={s.inputRowChild}>
                                                <Form.Item
                                                    style={{ marginBottom: "20px" }}
                                                    name="educSystem"
                                                    rules={[{ required: true }]}
                                                >
                                                    <Select
                                                        style={{ padding: "5px 0px 5px 0px", fontSize: "16px" }}
                                                        className={s.selectInput}
                                                        placeholder={<div className={s.placeholderStyle}>Système d'éducation</div>}
                                                        mode="multiple"
                                                        bordered={false}
                                                        allowClear
                                                        dropdownStyle={{ padding: "3px" }}
                                                        showArrow={true}
                                                    >
                                                        {educationSystemList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{item}</Select.Option>))}
                                                    </Select>
                                                </Form.Item>
                                            </div>

                                        </div>

                                        <Form.Item
                                            style={{ marginBottom: "20px" }}
                                            name="enseignement"
                                            rules={[{ required: true }]}
                                        >
                                            <Select
                                                style={{ padding: "5px 0px 5px 0px", fontSize: "16px" }}
                                                className={s.selectInput}
                                                placeholder={<div className={s.placeholderStyle}>Ton expérience d'éducation</div>}
                                                bordered={false}
                                                allowClear
                                                dropdownStyle={{ padding: "3px" }}
                                                showArrow={true}
                                            >
                                                <Select.Option value="" disabled style={{ color: "#C0C0C0" }}><div className={s.placeholderStyle}>Ton expérience d'éducation</div></Select.Option>
                                                {teachingExperiencesList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{item}</Select.Option>))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            style={{ marginBottom: "20px" }}
                                            name="situation"
                                            rules={[{ required: true }]}
                                        >
                                            <Select
                                                style={{ padding: "5px 0px 5px 0px", fontSize: "16px" }}
                                                className={s.selectInput}
                                                placeholder={<div className={s.placeholderStyle}>Ta situation actuelle</div>}
                                                bordered={false}
                                                dropdownStyle={{ padding: "3px" }}
                                                showArrow={true}
                                            >
                                                <Select.Option value="" disabled><div className={s.placeholderStyle}>Ta situation actuelle</div></Select.Option>
                                                {actualSituationsList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{item}</Select.Option>))}
                                            </Select>
                                        </Form.Item>
                                        <Form.Item
                                            hasFeedback
                                            label={null}
                                            name="phone"
                                            rules={[{ required: false }]}
                                            style={{ marginBottom: "20px" }}
                                        >
                                            <PhoneInput
                                                inputProps={{
                                                    name: "phone",
                                                    required: true,
                                                    autoFocus: true,

                                                    className: `ant-input ant-input-lg ${s.field}`,
                                                    style: {
                                                        paddingLeft: "50px",
                                                    },
                                                }}
                                                placeholder={"Numéro de téléphone (optionnel)"}
                                                enableSearch
                                                specialLabel=""
                                                country={selectedCountryCode}
                                            />
                                        </Form.Item>
                                        <div className={s.termes__legals}>
                                            <Form.Item
                                                name="acceptedMessagesAndNotifications"
                                                valuePropName="checked"
                                                style={{ marginBottom: "20px" }}
                                            >
                                                <Checkbox
                                                    style={{ color: "#636363" }}
                                                    className={s.policy}
                                                >
                                                    {" "}
                                                    Recevoir des notifications par WhatsApp pour les nouveaux messages envoyés par les élèves.{" "}
                                                </Checkbox>
                                            </Form.Item>
                                        </div>

                                        <div className={s.termes__legals}>
                                            <Form.Item
                                                name="confirmAge"
                                                valuePropName="checked"
                                                style={{ marginBottom: "20px" }}
                                            >
                                                <Checkbox
                                                    style={{ color: "#636363" }}
                                                    className={s.policy}
                                                    onChange={checkUserAge}
                                                >
                                                    {" "}
                                                    Je confirme avoir plus de 18 ans.{" "}
                                                </Checkbox>
                                            </Form.Item>
                                        </div>

                                        {errorAge && <a style={{ color: "red" }}>Oups, tu dois avoir 18 ans ou plus pour devenir prof.</a>}

                                        <Row justify="center" style={{ marginTop: "30px" }}>
                                            <Form.Item>
                                                <AppButton loading={isLoading} htmlTypeSubmit={true} className={s.newMoveButton}>Suivant</AppButton>
                                            </Form.Item>
                                        </Row>
                                    </React.Fragment>
                                )}
                            </Form>

                            {current === 2 && (
                                <React.Fragment>
                                    <StepTwo
                                        stepOneData={registrationData}
                                        setCurrent={setCurrent}
                                    />
                                </React.Fragment>
                            )}
                            {current === 3 && (
                                <React.Fragment>
                                    <StepThree
                                        stepTwoData={stepTwoData}
                                        setCurrent={setCurrent}
                                    />
                                </React.Fragment>
                            )}
                            {current === 4 && (
                                <React.Fragment>
                                    <StepFour
                                        stepThreeData={registrationData}
                                        setCurrent={setCurrent}
                                    />
                                </React.Fragment>
                            )}
                            {current === 5 && (
                                <React.Fragment>
                                    <StepFive
                                        stepFourData={registrationData}
                                        setCurrent={setCurrent}
                                    />
                                </React.Fragment>
                            )}
                            {current === 6 && (
                                <React.Fragment>
                                    <StepSix
                                        stepFiveData={registrationData}
                                        setCurrent={setCurrent}
                                    />
                                </React.Fragment>
                            )}
                            {current === 7 && (
                                <React.Fragment>
                                    <StepSeven
                                        stepSixData={stepFiveData}
                                        setCurrent={setCurrent}
                                    />
                                </React.Fragment>
                            )}
                            {current === 8 && (
                                <React.Fragment>
                                    <StepEight
                                        stepSevenData={registrationData}
                                        setCurrent={setCurrent}
                                    />
                                </React.Fragment>
                            )}
                            {auth.registerErrors.length > 0 &&
                                auth.registerErrors.map((error: any, index: any) => (
                                    <Alert key={index} message={error} type="error" showIcon />
                                ))}

                            {current === 0 && <div
                                className={s.text}
                                style={{ marginTop: "1em", textAlign: "center" }}
                            >
                                <p>
                                    Tu as déjà un compte ?{" "}
                                    <Link to={appRoutes.LOGINS}>Connecte-toi</Link>
                                </p>
                            </div>}
                        </div>
                    </div>
                </Container>
            </div>
        </AnimatedPage>
    );
};

export default ProfileComplete;
