import {
  Avatar,
  Col,
  message,
  Popconfirm,
  Result,
  Row,
  Skeleton,
  Space
} from "antd";
import React, { Fragment, useEffect, useMemo, useState } from "react";

//!COMPONENTS

//!ASSETS
import BadgeActived from "assets/images/students/badgeInstaProf.svg";

//!STYLES
import { findDisabledModeInstaProf } from "api/services/advertissements/advertissements.services";
import { StatusCode } from "common/enums";
import AppDrawer from "components/appDrawer";
import AppResult from "components/appResults";
import HelpPopover from "components/help";
import AppButton from "components/libs/button";
import H1 from "components/libs/title";
import ZoomMeeting from "components/zoom";
import { badRequest } from "messages";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "store/auth/authSlice";
import { ACTIVE_DRAWER_ALERT_INSTAPROF, INIT_MODAL } from "store/notification";
import {
  useGetNotifByUserQuery,
  useStopRequestInstaProfMutation,
} from "store/services/teacher/notification.services";
import CardInstaProf from "./components/cardInstaProf";
import s from "./style.module.css";

type Props = {
  dataInstaProf: any;
  currentStudentAccepted: any;
  isLoading: boolean;
  objNotifInstaProf: any;
  setCurrentStudentAccepted: React.Dispatch<any>;
  onViewNotif: (id: string) => void;
  setIsCheckNotif: React.Dispatch<boolean>;
  onAcceptNotifInstaProf: (id: string) => void;
  showModalInfoInstaProf: (id: string) => void;
  refetch: () => void;
};

const DrawerInstaProfAllReq: React.FC<Props> = ({
  dataInstaProf,
  isLoading,
  objNotifInstaProf,
  currentStudentAccepted,
  onAcceptNotifInstaProf,
  onViewNotif,
  setIsCheckNotif,
  showModalInfoInstaProf,
  setCurrentStudentAccepted,
  refetch,
}) => {
  //!HOOKS
  const [isZoomMeetingVisible, setIsZoomMeetingVisible] = useState(false);
  const [currentMeetingId, setCurrentMeetingId] = useState('');

  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { refetch: refetchAllNotifications } = useGetNotifByUserQuery(user._id);
  const [
    stopRequestInstaProf,
    { isSuccess, isLoading: isStopingRequestInstaProf, isError },
  ] = useStopRequestInstaProfMutation();
  const { openDrawerAlertInstaProf } = useSelector(
    (state) => state.notifications
  );
  const { socket } = useSelector((state) => state.appChat);
  const [isStopingInstaProf, setIsStopingInstaProf] = useState<boolean>(false);

  const handleClose = () => {
    dispatch(INIT_MODAL());
  };

  const onDisabledInstaProf = async (values: any) => {
    try {
      const response = await findDisabledModeInstaProf(user?._id);
      if (response?.statusCode === StatusCode.CREATED) {
        message.success("Status InstaProf desactivé avec succès");
        dispatch(
          updateUser({
            ...user,
            instaProf: false,
          })
        );

        handleClose();
      }
    } catch (error) {
      message.error("error");
    }
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  useEffect(() => {
    if (isError) {
      message.error(badRequest.error);
    }
    if (isSuccess) {
      message.success("La session a été arrêtée avec succès");
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    socket?.on("receivedStopRequest", (resp: any) => {
      if (resp) {
        setIsStopingInstaProf(true);
        refetch();
        dispatch(ACTIVE_DRAWER_ALERT_INSTAPROF());
      }
    });
  }, [socket, isStopingInstaProf]);

  useEffect(() => {
    refetchAllNotifications();
  }, [openDrawerAlertInstaProf]);

  const handleMeetingClick = () => {
    if (!objNotifInstaProf?.data?.profLink) {
      message.error("Lien de réunion non trouvé pour ce cours");
      return;
    }

    // Extract meeting ID from Zoom link - handle multiple URL formats
    let meetingId;
    try {
      const url = new URL(objNotifInstaProf.data.profLink);
      // Handle different Zoom URL formats
      if (url.pathname.includes('/j/')) {
        meetingId = url.pathname.split('/j/')[1]?.split('?')[0];
      } else if (url.pathname.includes('/s/')) {
        meetingId = url.pathname.split('/s/')[1]?.split('?')[0];
      } else {
        // Try to find any number sequence that could be a meeting ID
        meetingId = url.pathname.match(/\d{10,11}/)?.[0];
      }

      // Remove any non-numeric characters
      meetingId = meetingId?.replace(/\D/g, '');

      if (!meetingId || meetingId.length < 10) { // Zoom meeting IDs are typically 10-11 digits
        throw new Error('Invalid meeting ID format');
      }

      setCurrentMeetingId(meetingId);
      setIsZoomMeetingVisible(true);
    } catch (error) {
      message.error("ID de réunion invalide");
      return;
    }
  };

  return useMemo(() => {
    return (
      <>
        <AppDrawer
          title={<H1>Instaprof</H1>}
          visible={openDrawerAlertInstaProf}
          onClose={handleClose}
        >
          <div className={s.header}>
            <Row justify="space-between" align="middle">
              <Col>
                <Space>
                  <div>
                    <img src={BadgeActived} alt="" width={"80%"} />
                  </div>

                  <div className={s.title}>
                    <h2>
                      Mode instaprof activé au tarif de {user?.instaProfCost}€ la session <br />
                      <span>
                        {" "}
                        <strong style={{ fontWeight: "700", textTransform: "uppercase" }}>
                          Durée d'une session instaprof : 60 mins{" "}
                        </strong>
                      </span>
                    </h2>
                  </div>
                </Space>
              </Col>

              <Col>
                <Space>
                  <AppButton onClick={showModalInfoInstaProf}>
                    Mettre à jour le tarif
                  </AppButton>
                  <Popconfirm
                    placement="bottom"
                    title={"Veux-tu désactiver le status INSTAPROF ?"}
                    onConfirm={onDisabledInstaProf}
                    okText="Oui"
                    cancelText="Non"
                  >
                    <AppButton>Arrêter instaProf</AppButton>
                  </Popconfirm>
                  <div style={{ position: "relative", top: "3px", marginLeft: "10px" }}><HelpPopover id={"insta_prof_arreter"} /></div>
                </Space>
              </Col>
            </Row>
          </div>
          <div className={s.content}>
            {isStopingInstaProf ? (
              <Result
                status="warning"
                title="L'élève a arrêté la session"
                extra={
                  <AppButton
                    onClick={() => {
                      setIsStopingInstaProf(false);
                      handleClose();
                    }}
                  >
                    {" "}
                    Quitter la Session
                  </AppButton>
                }
              />
            ) : (
              <Fragment>
                {objNotifInstaProf?.data?.etat === "booked" ? (
                  <Result
                    status="success"
                    title="Tu as une réservation en cours"
                    subTitle={
                      <Row align="middle" justify="center">
                        <Col>
                          Avec {objNotifInstaProf?.data?.student?.lastname}
                          {objNotifInstaProf?.data?.student?.firstname}
                        </Col>
                        <Col>
                          <Avatar
                            src={objNotifInstaProf?.data?.student?.profile}
                          />
                        </Col>
                      </Row>
                    }
                    extra={[
                      <AppButton onClick={handleMeetingClick}>
                        Rejoindre la visioconférence
                      </AppButton>,
                      <Popconfirm
                        placement="bottom"
                        title={"Veux-tu arrêter la session INSTAPROF ?"}
                        onConfirm={() => {
                          stopRequestInstaProf({
                            id: objNotifInstaProf?._id,
                            type: "student",
                          });
                        }}
                        okText="Oui"
                        cancelText="Non"
                      >
                        <AppButton loading={isStopingRequestInstaProf} style={{ marginTop: isMobile ? "10px" : "0px" }}>
                          Arrêter la session
                        </AppButton>
                        ,
                      </Popconfirm>,
                    ]}
                  />
                ) : (
                  <>
                    {isLoading ? (
                      <>
                        <Skeleton active />
                        <Skeleton active />
                      </>
                    ) : (
                      dataInstaProf?.map((item: any) => (
                        <CardInstaProf
                          item={item}
                          onViewNotif={onViewNotif}
                          onAcceptNotifInstaProf={onAcceptNotifInstaProf}
                          setIsCheckNotif={setIsCheckNotif}
                        />
                      ))
                    )}
                    {!isLoading && dataInstaProf?.length <= 0 && (
                      <AppResult
                        status="info"
                        title="Aucune demande en cours."
                        subTitle="Tu n’as aucune demande InstaProf en cours"
                        isMobile={false}
                      />
                    )}
                  </>
                )}
              </Fragment>
            )}
          </div>
        </AppDrawer>

        {isZoomMeetingVisible && (
          <ZoomMeeting
            meetingNumber={currentMeetingId}
            userName={user?.firstname + " " + user?.lastname}
            sdkKey={process.env.REACT_APP_ZOOM_SDK_KEY ?? ""}
            sdkSecret={process.env.REACT_APP_ZOOM_SDK_SECRET ?? ""}
            onClose={() => {
              setIsZoomMeetingVisible(false);
            }}
          />
        )}
      </>
    );
  }, [
    openDrawerAlertInstaProf,
    dataInstaProf,
    user,
    isLoading,
    currentStudentAccepted,
    isStopingRequestInstaProf,
    objNotifInstaProf,
    isStopingInstaProf,
  ]);
};

export default DrawerInstaProfAllReq;
