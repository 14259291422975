import { getToken } from "api";
import axios from "axios";
import { API_RESSOURCES } from "../teacher/teacher.routes";
import * as apiRoutes from "./studentJoinClass.routes";

export const StudentJoinClass = async (
  code: string,
  idString: string | any
) => {
  try {
    const response = await axios.post(apiRoutes.API_BASE_URL(code, idString));
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findAll = async () => {
  try {
    const token = await getToken();
    const response = await axios.get(apiRoutes.API_BASE_URL_CLASS, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const findAllClasses = async (studentId: string) => {
  try {
    const token = await getToken();
    const response = await axios.get(`${API_RESSOURCES}/classes?teacher=${studentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};
