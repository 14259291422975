import { Dropdown, Image, Menu, message } from "antd";
import { postchallengeParticipate } from "api/services/challenges/challenges.services";
import { StatusCode } from "common/enums";
import AppButton from "components/libs/button";
import * as appRoutes from "config/routes.config";
import { badRequest } from "messages";
import React, { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MsToTime } from "../challengerClasse/utils/Formatters";
import { useCountdown } from "../challengerClasse/utils/timerExpirationHook";
import { ReactComponent as ChallengeIcon } from "./assets/challenge.svg";
import style from "./assets/style.module.css";
import target from "./assets/target.svg";

interface TimerChallengeProps {
  subscribedStudentsLength: number;
  expiresAt: string;
  initialise: () => void;
  subscribedStudents?: any[];
  challengeId?: any;
}

export const TimerChallenge: React.FC<TimerChallengeProps> = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { user } = useSelector((state) => state.auth);

  const [days, hours, minutes, seconds] = useCountdown(props.expiresAt);

  const StudentChallenge = useCallback(async () => {
    try {
      if (user?._id && props?.challengeId) {
        const response = await postchallengeParticipate(
          user?._id,
          props?.challengeId
        );
        if (response?.statusCode === StatusCode.CREATED) {
          navigate(
            `${appRoutes.STUDENT_MY_CLASS}/${id}/challenge/${response?.data?._id}`
          );
        }
      }
    } catch (error) {
      message.error(badRequest.error);
    }
  }, [user?._id, props?.challengeId, navigate, id]);

  const participants = useMemo(
    () =>
      props.subscribedStudents?.map((e: any) => ({
        _id: e._id,
        profile: e.profile || e.avatar,
        firstname: e.firstname,
        lastname: e.lastname,
      })),
    [props.subscribedStudents]
  );

  const idsParticipants = useMemo(
    () => participants?.map((ec: any) => ec._id),
    [participants]
  );

  const menu = useMemo(() => {
    if (props.subscribedStudentsLength !== 0) {
      return (
        <Menu className={style.bodyDropDow__subscribedStudents}>
          {/* <MenuItemsUsers
            readOnly={true}
            searchFunction={null}
            users={participants}
          ></MenuItemsUsers> */}
        </Menu>
      );
    }
    return <span></span>;
  }, [props.subscribedStudentsLength, participants]);

  if (days + hours + minutes + seconds <= 0) {
    props.initialise();
    return null;
  }

  return (
    <>
      {user?.roles.includes("teacher") ? (
        <>
          {props.subscribedStudentsLength > 0 ? (
            <Dropdown overlay={menu} placement="bottomCenter">
              <div className={`${style.grayBackground} ${style.myArrangement}`}>
                <div className={style.subscribedStudents}>
                  {props.subscribedStudentsLength <= 1
                    ? `${props.subscribedStudentsLength} élève inscrit`
                    : `${props.subscribedStudentsLength} élèves inscrits`}
                </div>
                <Image preview={false} width={30} src={target} alt="" />
              </div>
            </Dropdown>
          ) : (
            <div className={`${style.grayBackground} ${style.myArrangement}`}>
              <div className={style.subscribedStudents}>Aucune inscription</div>
              <Image preview={false} width={20} src={target} alt="" />
            </div>
          )}
        </>
      ) : (
        <>
          {idsParticipants && !idsParticipants.includes(user?._id) ? (
            <AppButton
              onClick={StudentChallenge}
              style={{
                position: "relative",
                maxWidth: "350px",
                minWidth: "350px",
                zIndex: "2",
              }}
              icon={
                <ChallengeIcon
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "10%",
                    width: "18px",
                  }}
                />
              }
            >
              Participer à ce challenge
            </AppButton>
          ) : (
            <AppButton
              style={{
                position: "relative",
                maxWidth: "350px",
                minWidth: "350px",
                zIndex: "2",
                cursor: "not-allowed",
              }}
              icon={
                <ChallengeIcon
                  style={{
                    position: "absolute",
                    top: "0px",
                    left: "10%",
                    width: "17px",
                  }}
                />
              }
            >
              Tu as déjà participé
            </AppButton>
          )}
        </>
      )}

      {user?.roles.includes("teacher") ? (
        <div className={`${style.greenBackground} ${style.myArrangement}`}>
          <span>Le challenge termine dans :</span>
          <MsToTime hours={hours} seconds={seconds} minutes={minutes} />
        </div>
      ) : (
        <div
          className={`${style.greenBackground} ${style.myArrangement}`}
          style={{
            width: "350px",
            height: "100px",
            paddingTop: "10px",
            background: "#A8A8A8",
            transform: "translateY(-70%)",
          }}
        >
          <div>Le challenge termine dans :</div>
          <MsToTime hours={hours} seconds={seconds} minutes={minutes} />
        </div>
      )}
    </>
  );
};
