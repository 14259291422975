import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Image,
  Input,
  message,
  Row,
  Select,
} from "antd";
import { registerUserDto } from "api/services/auth/dto/login-user.dto";
import Container from "components/container";
import * as appRoutes from "config/routes.config";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { registerStudentWithEmailAndPassword } from "store/auth/authSlice";

import * as TEACHER_SERVICES from "api/services/teacher/teacher.services";

//!COMPONENTS
import H1 from "components/libs/title";

//!IMAGES
import Check from "../../../assets/images/app/checkAlert.svg";
import Close from "../../../assets/images/app/closeDeleteIcon.svg";
import AvatarStudent from "../../../assets/images/students/student.svg";

//!STYLES
import { useCheckEmailMutation } from "store/services/app/user.services";
import s from "./assets/registerByMAil.module.css";

const RegisterStudentByMail: FC = () => {
  //!HOOKS
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const { socket } = useSelector((state) => state.appChat);

  const [checkEmail, { isSuccess, isError, data }] =
    useCheckEmailMutation();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [sponsorCode, setSponsorCode] = useState<any>("");
  const [validSponsorCode, setValidSponsorCode] = useState<boolean>(false);
  const [sponsorCodeChecked, setSponsorCodeChecked] = useState<boolean>(false);
  const [errorEmail, setErrorEmail] = useState<boolean>(false);

  //!CONSTANTS
  const validateMessages = {
    required: t("auth.error.required"),
    types: {
      email: t("auth.error.emailInvalid"),
    },
  };

  //!FUNCTIONS
  const registerUser = async (values: registerUserDto) => {
    checkEmail({ email: values.email }).then(async (value: any) => {
      const valueData = value?.data;
      if (valueData?.data?.found) {
        setErrorEmail(true);
      }
      else {
        await dispatch(registerStudentWithEmailAndPassword(values));
        await socket?.emit("subscribe", auth.user?._id);
      }
    });
  };

  const checkSponsorCode = async (code: string) => {
    if (!code) return;

    try {
      setIsLoading(true);
      const response = await TEACHER_SERVICES.checkSponsorCode(code);
      if (response?.status === "OK") {
        setSponsorCodeChecked(true);
        setValidSponsorCode(true);
      }
      else {
        setSponsorCodeChecked(true);
        setValidSponsorCode(false);
        message.error("Code parrainage incorrect !");
      }
    } catch (error) {
      setSponsorCodeChecked(true);
      setValidSponsorCode(false);
      message.error("Code parrainage incorrect !");
    } finally {
      setIsLoading(false);
    }
  };

  //!EFFECTS
  useEffect(() => {
    if (auth.isLoggedIn) navigate(appRoutes.STUDENT_DASHBOARD);
  }, [auth.isLoggedIn, navigate]);

  const { Option } = Select;

  return (
    <div>
      <div className={s.register__teacher}>
        <Container>
          <H1 className={s.title}>Créez votre compte</H1>

          <div className={s.content__action}>
            <div className={s.student__action}>
              <div className={s.avatar}>
                <Image
                  preview={false}
                  width={150}
                  src={AvatarStudent}
                  alt="teacher avatar"
                />
              </div>
              <div className={s.text}>
                <p>
                  Je suis un élève<strong>de 3e</strong>{" "}
                  <Link to={"/"}>Modifier</Link>
                </p>
              </div>
            </div>
          </div>

          <div className={s.form__container}>
            <Form
              validateTrigger={["onFinish"]}
              validateMessages={validateMessages}
              initialValues={{ acceptedTermsAndConditions: true }}
              name="registerTeacher"
              autoComplete="off"
              onFinish={registerUser}
            >
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item name="firstname" rules={[{ required: true }]}>
                    <Input
                      className={s.field}
                      placeholder={t("auth.firstname")}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item name="lastname" rules={[{ required: true }]}>
                    <Input
                      className={s.field}
                      placeholder={t("auth.lastname")}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                name="email"
                rules={[{ required: true }, { type: "email" }]}
              >
                <Input
                  className={s.field}
                  type="email"
                  placeholder={t("auth.email")}
                />
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  { required: true },
                  {
                    pattern: /^.{6,}$/,
                    message:
                      "Le mot de passe doit contenir au moins 6 caractères !",
                  },
                ]}
              >
                <Input.Password
                  className={s.field}
                  placeholder={t("auth.password")}
                />
              </Form.Item>
              <Form.Item
                name="confirmPassword"
                rules={[
                  { required: true },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error("Les mots de passe ne correspondent :(")
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  className={s.field}
                  placeholder={t("auth.confirmPassword")}
                />
              </Form.Item>

              <Form.Item
                name="referredBy"
                rules={[
                  { required: false },
                ]}
                style={{ marginBottom: "20px" }}
              >
                <Input
                  type="text"
                  className={s.field}
                  placeholder={"Code de parrainage (optionnel)"}
                  value={sponsorCode}
                  onChange={(e) => setSponsorCode(e.target.value)}
                  onBlur={() => checkSponsorCode(sponsorCode)}
                  maxLength={6}
                  suffix={sponsorCode.length === 6 && sponsorCodeChecked && <img src={validSponsorCode ? Check : Close} height={20}></img>}
                />
              </Form.Item>

              <div className={s.termes__legals}>
                <Form.Item
                  name="acceptedTermsAndConditions"
                  valuePropName="checked"
                >
                  <Checkbox>
                    {" "}
                    J'accepte de recevoir des conseils pédagogiques et des
                    offres <br /> promotionnelles de Mon ami Albert par e-mail.{" "}
                  </Checkbox>
                </Form.Item>
              </div>

              <Button
                loading={auth.isRegistering || isLoading}
                block
                type="primary"
                className={s.button}
                htmlType="submit"
                style={{ display: "block" }}
              >
                Terminer
              </Button>

              <div className={s.info}>
                <span>
                  En cliquant sur terminer, vous acceptez les conditions
                  générales d'utilisation et la politique de confidentialité de
                  Mon ami Albert.
                </span>
              </div>
              <div className={s.info}>
                <p>
                  Vous avez déjà un compte ?{" "}
                  <Link to={"/student/dashboard"}> Connecte-toi</Link>{" "}
                </p>
              </div>
            </Form>
            {auth.registerErrors.length > 0 &&
              auth.registerErrors.map((error, index) => (
                <Alert key={index} message={error} type="error" showIcon />
              ))}

            {errorEmail && <Alert message={"Cette adresse email est déjà utilisée"} type="error" showIcon />}
          </div>

          {/* <RegisterTeacherForm /> */}
        </Container>
      </div>
    </div>
  );
};

export default RegisterStudentByMail;
