import { Avatar, Button, Drawer, Form, Input, message, Popconfirm, Rate, Skeleton } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { calculateTimeDifference, StatusCode } from "common/enums";
import AppResult from "components/appResults";
import Matiers from "components/matiers";
import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setStudentNoted } from "store/app/appSlice";
import { useGetHistoriqueReservationsStudentQuery } from "store/services/teacher/classes.services";
import ArrowBack from "../../../../assets/images/app/arrow_back.svg";
import Check from "../../../../assets/images/app/check.svg";
import ClockIcon from "../../../../assets/images/app/clock_icon.svg";
import EllipseTeacherProfileOne from "../../../../assets/images/app/ellipse_teacher_profile1.png";
import EllipseTeacherProfileTwo from "../../../../assets/images/app/ellipse_teacher_profile2.png";
import EllipseTeacherProfileFive from "../../../../assets/images/app/ellipse_teacher_profile5.png";
import Star from "../../../../assets/images/app/start.svg";
import s from "../assets/calendar.module.css";

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

interface CoursesRoomProps {
  course?: object | any;
}

const CoursesRoom: FC<CoursesRoomProps> = ({ course }) => {

  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1050);
  };
  window.addEventListener('resize', handleResize);

  const [isDataLoading, setIsLoading] = useState<boolean>(false);
  const [formInfos] = Form.useForm();

  const [explanationRating, setExplanationRating] = useState<any>(0);
  const [interactionRating, setInteractionRating] = useState<any>(0);
  const [applicationRating, setApplicationRating] = useState<any>(0);

  const [rateDrawerVisibility, setRateDrawerVisibility] = useState<boolean>(false);
  const closeRateDrawer = async () => {
    setRateDrawerVisibility(false);
    setCourseDate("");
    setCourseTopic("");
    setExplanationRating(0);
    setApplicationRating(0);
    setInteractionRating(0);
    formInfos.resetFields();
  }

  const [courseDate, setCourseDate] = useState<any>('');
  const [courseTopic, setCourseTopic] = useState<any>('');
  const [historyID, setHistoryID] = useState<any>('');

  const openRateDrawer = async (date: any, topic: any, id: any) => {
    setCourseDate(date);
    setCourseTopic(topic);
    setHistoryID(id);
  }

  const sendRemarks = async (values: any) => {
    setIsLoading(true);
    try {
      const response = await AUTH_SERVICES.rateTeacher(historyID, values?.explanationRating, values?.interactionRating, values?.applicationRating, values?.message);
      if (response?.statusCode === StatusCode.CREATED) {
        message.success({
          content: "Note envoyée ",
        });
        setRateDrawerVisibility(false);
        formInfos.resetFields();
        setExplanationRating(0);
        setApplicationRating(0);
        setInteractionRating(0);
        dispatch(setStudentNoted(true));
      }
      else {
        message.warning({
          content: "Ooops, Une erreur est survenue",
        });
        setIsLoading(false);
      }
    } catch (error: any) {
      let content = "";
      for (
        let index = 0;
        index < error?.response?.data?.message?.length;
        index++
      ) {
        content = error?.response?.data?.message[index] + ", " + content;
      }
      message.error({
        content: content,
      });
    }
    finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (courseDate !== "" && courseTopic !== "" && historyID !== "") {
      setRateDrawerVisibility(true);
    }
  }, [courseDate, courseTopic, historyID]);

  return (
    <>
      <div className={s.historyClasseCard} style={{ cursor: "pointer" }}>
        <div className={s.cardClassBox}>
          <div className={s.avatarNameBox}>
            <Avatar.Group
              maxCount={3}
              maxStyle={{
                color: "#f56a00",
                backgroundColor: "#fde3cf",
              }}
              style={{ marginRight: isMobile ? "0px" : "5px" }}
            >
              <Avatar
                size={45}
                style={{ backgroundColor: "#FFF2E0", boxShadow: "0px 3px 6px #00000029" }}
                src={course?.profId?.profile}
              />
            </Avatar.Group>
            <div style={{ position: "relative", left: isMobile ? "0px" : "15px", fontSize: isMobile ? "0.8em" : "13px", textTransform: "capitalize" }}>{course?.profId?.firstname + " " + course?.profId?.lastname}</div>
          </div>
          {isMobile && <Matiers
            cours={course?.topic?.title.slice(0, 4) || "null"}
            level={course?.level?.title || "null"}
          />}
          <div className={s.historyDetailsBox}>
            <div className={s.timeBox}>
              {!isMobile && <img src={ClockIcon} height={15} style={{ marginRight: isMobile ? "0px" : "10px" }}></img>}
              <div className={s.times}>
                <span className={s.time}>{isMobile && <img src={ClockIcon} height={10} style={{ marginRight: isMobile ? "5px" : "10px" }}></img>} De {course?.range[0]} à {course?.range[course?.range?.length - 1]}</span>
                <span className={s.timeDescription}>Séance de “{calculateTimeDifference(course?.range[0], course?.range[course?.range.length - 1])}”</span>
              </div>
            </div>
            {!isMobile && <Matiers
              cours={course?.topic?.title.slice(0, 4) || "null"}
              level={course?.level?.title || "null"}
            />}
          </div>
          {course?.codeCoupon && course?.codeCoupon?.couponType === "FREE_CLASS" ? <div className={s.historyPriceBox}>Cours gratuit</div> : <div className={s.historyPriceBox}>{(course?.byCredits === "oui" || course?.byCredits === "true") ? course?.creditedValue + " Crédit" : course?.price + " €"}</div>}
          {course?.studentNoted ? <div className={s.rateBoxDetails}>
            <div className={s.dashedBox}>
              <span style={{ color: "#7FB1B2" }}>Explication</span>
              <span className={s.rateBoxSpan}> {course?.studentRating?.explanationClarity}<img src={Star} height={10} className={s.rateBoxStar}></img></span>
            </div>
            <div className={s.dashedBox}>
              <span style={{ color: "#7FB1B2" }}>Interaction</span>
              <span className={s.rateBoxSpan}> {course?.studentRating?.interactionsEngagement}<img src={Star} height={10} className={s.rateBoxStar}></img></span>
            </div>
            <div className={s.dashedBox}>
              <span style={{ color: "#7FB1B2" }}>Application</span>
              <span className={s.rateBoxSpan}> {course?.studentRating?.relevanceOfApplications}<img src={Star} height={10} className={s.rateBoxStar}></img></span>
            </div>
          </div> : <div className={s.rateBoxDetails}>
            {course?.status !== "SUCCESS" && <Button className={s.newButton} onClick={() => openRateDrawer(course?.startDate, course?.topic?.description, course?._id)}>
              Noter le prof sur ce cours
            </Button>}
          </div>}
        </div>
      </div>

      <Drawer
        className="drawer"
        onClose={closeRateDrawer}
        visible={rateDrawerVisibility}
        placement="right"
        width={isMobile ? "100%" : "50%"}
        title={<div style={{ fontSize: isMobile ? "0.8em" : "20px", fontFamily: "Poppins", fontWeight: "600", color: "#393E3E", backgroundColor: "transparent" }}>Noter ce cours</div>}
        headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: isMobile ? "20px" : "40px", backgroundColor: "transparent" }}
        closeIcon={
          <img
            src={ArrowBack}
            height={isMobile ? 10 : 18}
            style={{}}
          ></img>
        }
        drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: isMobile ? "0px" : "50px", borderBottomLeftRadius: isMobile ? "0px" : "50px", boxShadow: "-3px 3px 34px #39393929" }}
        bodyStyle={{ zIndex: "1" }}
      >
        <div style={{ margin: "40px auto", width: "100%", height: "auto" }}>
          {!isMobile && <img src={EllipseTeacherProfileOne} height={64} style={{ position: "absolute", top: "14%", left: "5%", zIndex: "-1" }}></img>}
          {!isMobile && <img src={EllipseTeacherProfileTwo} height={20} style={{ position: "absolute", top: "30%", right: "50px", zIndex: "-1" }}></img>}
          {!isMobile && <img src={EllipseTeacherProfileFive} height={70} style={{ position: "absolute", top: "14%", right: "5%", zIndex: "-1" }}></img>}
          {!isMobile && <div className={s.studentDetailsDrawer} style={{ zIndex: "1" }}>
            <img src={course?.profId?.profile} height={isMobile ? 50 : 80} style={{ marginRight: "5%", background: "#FFF2E0", border: "1.5px solid #FFF", boxShadow: "0px 3px 6px #00000029", borderRadius: "50%" }}></img>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", width: "100%" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: isMobile ? "1em" : "1.3em", fontWeight: "bold", color: "#8AB0B1", textTransform: "uppercase", marginTop: isMobile ? "0px" : "10px" }}>{course?.profId?.firstname} {course?.profId?.lastname}</div>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#D5D5D5", paddingTop: "2.5px", paddingBottom: "2.5px", borderRadius: "8px", width: isMobile ? "80px" : "100px", fontSize: isMobile ? "0.75em" : "12px" }}>
                {course?.level?.levelGroup}
              </div>
              <div className={s.drawerTimeBox}>
                <div style={{ color: "green", fontWeight: "600", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "50%" }}><img src={Check} height={15} style={{ marginRight: "5px" }}></img> Cours terminé </div>
                <div className={s.timeBox} style={{ marginLeft: isMobile ? "0px" : "20px" }}>
                  <div className={s.times}>
                    <span className={s.time}>{isMobile && <img src={ClockIcon} height={10} style={{ marginRight: "5px" }}></img>} À {courseDate.substring(14, 18)}</span>
                    <span className={s.timeDescription}>{courseDate.substring(0, 10)}</span>
                  </div>
                </div>
                <Matiers
                  cours={courseTopic}
                  level={course?.level?.title || "null"}
                />
              </div>
            </div>
          </div>}

          {isMobile && <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", background: "#FFF", boxShadow: "4px 9px 18px #98989829", border: "1px solid #DFDDDD", borderRadius: "18px", width: "90%", margin: "0px auto", padding: "20px" }}>
            <div className={s.studentDetailsDrawer} style={{ zIndex: "1" }}>
              <img src={course?.profId?.profile} height={isMobile ? 50 : 80} style={{ marginRight: "5%", background: "#FFF2E0", border: "1.5px solid #FFF", boxShadow: "0px 3px 6px #00000029", borderRadius: "50%" }}></img>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", fontSize: isMobile ? "1em" : "1.3em", fontWeight: "bold", color: "#8AB0B1", textTransform: "capitalize", marginTop: isMobile ? "0px" : "10px" }}>{course?.profId?.firstname} {course?.profId?.lastname}</div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", background: "#D5D5D5", paddingTop: "2.5px", paddingBottom: "2.5px", borderRadius: "8px", width: isMobile ? "80px" : "100px", fontSize: isMobile ? "0.75em" : "12px" }}>
                  {course?.level?.levelGroup}
                </div>
              </div>
            </div>
            <div className={s.drawerTimeBox}>
              <span style={{ color: "green", fontWeight: "600" }}><img src={Check} height={15}></img> Cours </span>
              <div className={s.timeBox} style={{ marginLeft: isMobile ? "0px" : "40px" }}>
                <div className={s.times}>
                  <span className={s.time}>{isMobile && <img src={ClockIcon} height={10} style={{ marginRight: "5px" }}></img>} À {courseDate.substring(14, 18)}</span>
                  <span className={s.timeDescription}>{courseDate.substring(0, 10)}</span>
                </div>
              </div>
              <Matiers
                cours={courseTopic}
                level={course?.topic?.description || "null"}
              />
            </div>
          </div>}

          <div className={s.formBox}>
            <Form
              validateTrigger={["onFinish"]}
              name="registerParent"
              autoComplete="off"
              form={formInfos}
              onFinish={sendRemarks}
            >
              <div className={s.formTitle}>Évalue chaque aspect de ton cours en attribuant une note au prof.</div>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", fontSize: isMobile ? "0.8em" : "1.1em", marginLeft: "20px", marginTop: "10px" }}>
                <span style={{ color: "#7FB1B2" }}>Explication</span>
                <Form.Item
                  name="explanationRating"
                  rules={[{ required: true, message: 'Choisi une note' }]}
                  style={{ marginBottom: "0px" }}
                >
                  <Rate value={explanationRating} onChange={(value) => {
                    setExplanationRating(value);
                    formInfos.setFieldsValue({ courseRating: value });
                  }} />
                </Form.Item>
              </div>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", fontSize: isMobile ? "0.8em" : "1.1em", marginLeft: "20px", marginTop: "10px" }}>
                <span style={{ color: "#7FB1B2" }}>Interaction</span>
                <Form.Item
                  name="interactionRating"
                  rules={[{ required: true, message: 'Choisi une note' }]}
                  style={{ marginBottom: "0px" }}
                >
                  <Rate value={interactionRating} onChange={(value) => {
                    setInteractionRating(value);
                    formInfos.setFieldsValue({ interactionRating: value });
                  }} />
                </Form.Item>
              </div>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", fontSize: isMobile ? "0.8em" : "1.1em", marginLeft: "20px", marginTop: "10px" }}>
                <span style={{ color: "#7FB1B2" }}>Application</span>
                <Form.Item
                  name="applicationRating"
                  rules={[{ required: true, message: 'Choisi une note' }]}
                  style={{ marginBottom: "0px" }}
                >
                  <Rate value={applicationRating} onChange={(value) => {
                    setApplicationRating(value);
                    formInfos.setFieldsValue({ applicationRating: value });
                  }} />
                </Form.Item>
              </div>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start", fontSize: isMobile ? "0.8em" : "1.1m", marginLeft: "20px", marginTop: "10px" }}>
                <span style={{ color: "#7FB1B2" }}>Mon avis sur ce cours</span>
              </div>
              <div style={{ width: "80%", marginLeft: "20px", marginTop: "10px" }}>
                <Form.Item
                  name="message"
                  rules={[{ required: true }]}
                  style={{ marginBottom: "20px" }}
                >
                  <Input.TextArea
                    className={s.inputField}
                    maxLength={255}
                    rows={3}
                    placeholder="Écris ton avis ici..."
                  />
                </Form.Item>
              </div>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                <Form.Item>
                  <Popconfirm
                    placement="top"
                    title={
                      "Es-tu sur de vouloir confirmer cet avis ?"
                    }
                    onConfirm={() => formInfos.submit()}
                    okText="Oui"
                    cancelText="Non"
                  >

                    <Button className={s.newButton} loading={isDataLoading}>Envoyer mon avis</Button>
                  </Popconfirm>
                </Form.Item>
              </div>
            </Form>
          </div>
        </div>
      </Drawer>
    </>
  );
};

const DrawerHistorique: FC<Props> = ({ open, setOpen }) => {
  const { user } = useSelector((state) => state.auth);
  const { data: historiques, isLoading, isSuccess, refetch } = useGetHistoriqueReservationsStudentQuery(user?._id);
  const [courses, setCourses] = useState<any>([]);

  const { studentNoted } = useSelector((state) => state.app);

  useEffect(() => {
    if (historiques?.data) {
      const sortedCourses = [...historiques.data].sort(
        (a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
      );

      const groupedByDate = sortedCourses.reduce((acc: any, course: any) => {
        const date = new Date(course.startDate).toISOString().split("T")[0];
        if (!acc[date]) {
          acc[date] = [];
        }
        acc[date].push(course);
        return acc;
      }, {});

      setCourses(groupedByDate);
    }
  }, [historiques]);

  useEffect(() => {
    if (studentNoted) {
      refetch();
    }
  }, [studentNoted, refetch]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
  const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
    setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
    setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
    setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
    setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
  };
  window.addEventListener('resize', handleResize);

  return (
    <>
      <Drawer
        className="drawer"
        visible={open}
        onClose={() => setOpen((open: boolean) => !open)}
        title={<div style={{ fontSize: isMobile ? "0.8em" : "20px", fontFamily: "Poppins", fontWeight: "600", color: "#393E3E", backgroundColor: "transparent" }}>Mon historique</div>}
        width={isMobile ? "100%" : isXsScreen ? "100%" : isSmallScreen ? "85%" : isMediumScreen ? "80%" : isNormalScreen ? "70%" : "65%"}
        headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: isMobile ? "20px" : "40px", backgroundColor: "transparent" }}
        closeIcon={
          <img
            src={ArrowBack}
            height={isMobile ? 10 : 18}
            style={{}}
          ></img>
        }
        drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: isMobile ? "0px" : "30px", borderBottomLeftRadius: isMobile ? "0px" : "30px", boxShadow: "-3px 3px 34px #39393929" }}
      >
        {isLoading ? (
          <>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </>
        ) : (<div className={s.historyCalendar}>
          {historiques?.data?.length > 0 ? (
            courses && Object.keys(courses)?.map((date) => {
              return (
                <>
                  <div key={date} style={{ marginTop: "10px" }}>
                    <div className={s.dateBox}>
                      {new Intl.DateTimeFormat("fr-FR", {
                        weekday: "long",
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      }).format(new Date(date))}
                      <div className={s.horizontalLine}></div>
                    </div>
                    {!isMobile && courses[date].map((course: any) => (
                      <div key={course.id}>
                        <CoursesRoom course={course} />
                      </div>
                    ))}

                    {isMobile && <div className={s.cardDisplay}>
                      {courses[date].map((course: any) => (
                        <div key={course.id}>
                          <CoursesRoom course={course} />
                        </div>
                      ))}
                    </div>}
                  </div>
                </>
              );
            })
          ) : (
            <div className={s.emptyClass}>
              <AppResult status={"info"} title="Aucune réservation" />
            </div>
          )}
        </div>
        )}
      </Drawer>
    </>
  );
};

export default DrawerHistorique;
