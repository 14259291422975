import { ConfigProvider, notification } from "antd";
import locale from "antd/lib/locale/fr_FR";
import { killAllTeacherInsta } from "api/services/advertissements/advertissements.services";
import { FLAGS, StatusCode, UserRoles } from "common/enums";
import { AppDrawer } from "components/Drawer";
import Footer from "components/footer";
import { Header } from "components/header";
import { InformationsPaiement } from "components/informationsPaiement";
import AppButton from "components/libs/button";
import AppChat from "components/messagerieV1";
import { DrawerPaiementComponent } from "components/paiement";
import { ProfileDetailsDrawer } from "components/profileDetailsDrawer";
import DrawerReport from "components/reportDrawer";
import UserConnect from "components/userConnect";
import "moment/locale/fr";
import { FC, Suspense, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useIdleTimer } from "react-idle-timer";
import { useDispatch, useSelector } from "react-redux";
import AppRoutes from "routes";
import DrawerAds from "screens/teacher/TeacherAds/components";
import DrawerUpdate from "screens/teacher/TeacherAds/components/update";
import WizardTeacher from "screens/teacher/components/wizard";
import io from "socket.io-client";
import {
  initSocket,
  setChatReceiverId,
  setSelectUserChat,
  toggleAppChat
} from "store/AppChat/appChat";
import { useFindRoomMutation, useGetAllRoomsQuery } from "store/services/chat";
import "./App.less";
import "./global/globals.css";

//!ASSETS
import { CloseOutlined } from "@ant-design/icons";
import { getUserByUid } from "api/services/auth/auth.services";
import AlbertHappy from "assets/images/albert/status/Happy.svg";
import AlbertNeutre from "assets/images/albert/status/Neutre.svg";
import AlbertSad from "assets/images/albert/status/Sad.svg";
import AlbertVeryHappy from "assets/images/albert/status/Very happy.svg";
import CouponsDrawer from "screens/teacher/components/coupons";
import { updateUser } from "store/auth/authSlice";

const App: FC = () => {
  const { user, isLoggedIn } = useSelector((s) => s.auth);
  const dispatch = useDispatch();
  const { refetch } = useGetAllRoomsQuery(user?._id);
  /* const { openAppChat } = useSelector((state) => state.appChat); */
  const [findRoom, { isSuccess }] = useFindRoomMutation();

  const API_SOCKET = process.env.REACT_APP_API_SOCKET;
  const socket = useRef<any>(
    io(`${API_SOCKET}`, {
      reconnectionAttempts: 1,
      reconnectionDelay: 500,
      autoConnect: false,
      multiplex: false,
    })
  );

  const originalWarn = console.warn;
  console.warn = function (message, ...args) {
    if (!message.includes('This CKEditor 4.17.1 version is not secure')) {
      originalWarn.apply(console, [message, ...args]);
    }
  };

  const handleOnIdle = async (event?: any) => {
    if (isLoggedIn) {
      await socket?.current.emit("unsubscribe", user?._id);
      await socket.current?.emit("subscribe", user?._id);
    }
  };

  const handleOnActive = async (event: any) => {
    if (isLoggedIn) {
      await socket?.current.emit("unsubscribe", user?._id);
      await socket.current?.emit("subscribe", user?._id);
    }
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * 1,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    // onAction: handleOnAction,
    debounce: 500,
  });

  //!FUNCTIONS
  const handleDisplayUserChat = (idUser: string) => {
    const finalData = {
      actor1: user?._id,
      actor2: idUser,
      flag: FLAGS.SINGLE_FLAG,
      type: "",
      instaprof: "",
    };

    findRoom(finalData);
    dispatch(setChatReceiverId(idUser));
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (window.performance) {
        if (performance.navigation.type == 1) {
          localStorage.setItem("instaProfLength", "0");
          localStorage.setItem("reload", "true");

          const handleKill = async () => {
            if (user?._id) {
              await socket?.current.emit("unsubscribe", user?._id);
              await socket.current?.emit("subscribe", user?._id);
            }

            if (localStorage.getItem("studentTempCodeInstaProf")) {
              await socket?.current.emit(
                "killRequestInstaProf",
                localStorage.getItem("studentTempCodeInstaProf")
              );

              await killAllTeacherInsta(
                user?._id,
                localStorage.getItem("studentTempCodeInstaProf") || ""
              );
              localStorage.removeItem("studentTempCodeInstaProf");
            }
          };
          handleKill();
        }
      }
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      socket?.current.connect();
      dispatch(initSocket(socket.current));
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    if (isLoggedIn) {
      socket?.current?.on(
        "receivedNewNotification",
        async (newMessage: any) => {
          refetch();
          console.log("newMessage : ", newMessage);
          if (newMessage?.notifType) {
            if (newMessage?.notifType === "popup") {
              notification.open({
                message: null,
                description: (
                  <div className="custom-notification-content">
                    <div className="custom-notification-avatar">
                      {newMessage?.albertHead === "AlbertSad" ? (
                        <img src={AlbertSad} alt="Albert" width={40} />
                      ) : newMessage?.albertHead === "AlbertNeutre" ? (
                        <img src={AlbertNeutre} alt="Albert" width={40} />
                      ) : newMessage?.albertHead === "AlbertHappy" ? (
                        <img src={AlbertHappy} alt="Albert" width={40} />
                      ) : newMessage?.albertHead === "AlbertVeryHappy" ? (
                        <img src={AlbertVeryHappy} alt="Albert" width={40} />
                      ) : (
                        <img src={AlbertNeutre} alt="Albert" width={40} />
                      )}
                    </div>
                    <div className="custom-notification-text" dangerouslySetInnerHTML={{ __html: newMessage?.content }} />
                  </div>
                ),
                className: "custom-notification",
                closeIcon: <CloseOutlined style={{ color: "#fff" }} />,
                placement: "bottomRight",
              });
            }
            else {
              notification.open({
                message: <span style={{ color: "#FFF" }}>{newMessage?.title}</span>,
                description: (
                  <div>
                    <div dangerouslySetInnerHTML={{ __html: newMessage?.content }} />
                    {newMessage?.type === "messagerie" && (
                      <AppButton
                        onClick={() => {
                          handleDisplayUserChat(newMessage?.data?.senderId);
                        }}
                      >
                        Ouvrir la discussion
                      </AppButton>
                    )}
                  </div>
                ),
                icon:
                  newMessage?.albertHead === "AlbertSad" ? (
                    <div className="notificationImageBox"><img src={AlbertSad} alt="" height={30} /></div>
                  ) : newMessage?.albertHead === "AlbertNeutre" ? (
                    <div className="notificationImageBox"><img src={AlbertNeutre} alt="" height={30} /></div>
                  ) : newMessage?.albertHead === "AlbertHappy" ? (
                    <div className="notificationImageBox"><img src={AlbertHappy} alt="" height={30} /></div>
                  ) : newMessage?.albertHead === "AlbertVeryHappy" ? (
                    <div className="notificationImageBox"><img src={AlbertVeryHappy} alt="" height={30} /></div>
                  ) : (
                    <div className="notificationImageBox"><img src={AlbertNeutre} alt="Albert" height={30} /></div>
                  ),
                style: { background: "#4cb981", color: "#FFF" }
              });
            }
          }
          else {
            notification.open({
              message: <span style={{ color: "#FFF" }}>{newMessage?.title}</span>,
              description: (
                <div>
                  <div dangerouslySetInnerHTML={{ __html: newMessage?.content }} />
                  {newMessage?.type === "messagerie" && (
                    <AppButton
                      onClick={() => {
                        handleDisplayUserChat(newMessage?.data?.senderId);
                      }}
                    >
                      Ouvrir la discussion
                    </AppButton>
                  )}
                </div>
              ),
              icon: <div className="notificationImageBox"><img src={AlbertNeutre} alt="Albert" height={30} /></div>,
              style: { background: "#4cb981", color: "#FFF" }
            });
          }
          if (newMessage?.type === "profile") {
            const getInfosResponse = await getUserByUid(user?.uid);
            if (getInfosResponse?.statusCode === StatusCode.OK) {
              dispatch(updateUser(getInfosResponse?.data));
            }
          }
        }
      );
    }
  }, []);

  useEffect(() => {
    if (isSuccess) {
      dispatch(setSelectUserChat(true));
      dispatch(toggleAppChat(true));
    }
  }, [isSuccess]);

  useEffect(() => {
    const handleSocket = async () => {
      if (user?._id) {
        await socket?.current.emit("unsubscribe", user?._id);
        await socket.current?.emit("subscribe", user?._id);
      }
    };

    handleSocket();
  }, [user?._id]);

  return (
    <>
      <Helmet>
        {/* {user?.roles?.includes(UserRoles?.TEACHER) && (
          <meta name="viewport" content="width=1440, initial-scale=.1" />
        )} */}
      </Helmet>
      <Suspense fallback={<p>Loading...</p>}>
        <ConfigProvider locale={locale}>
          <div className="app">
            <div>
              <DrawerReport />
              <Header />
              <InformationsPaiement />
              <WizardTeacher />
              <UserConnect />
              <AppRoutes />
              <AppDrawer />
              <AppChat />
              <ProfileDetailsDrawer />
              <CouponsDrawer />
              <DrawerPaiementComponent />
              {/* <MAA /> */}
              {/* <AideComponent envoyerNumeroHandler={null} /> */}
              <DrawerAds />
              <DrawerUpdate />
              {/* <AideComponent /> */}
            </div>

            <div
              className={`${user?.roles.includes(UserRoles.STUDENT) && "footerDisplay"
                }`}
            >
              <Footer />
            </div>

            {user?.roles.includes(UserRoles.STUDENT) && (
              <div style={{ minHeight: "100px" }} className="fakeDiv" />
            )}
          </div>
        </ConfigProvider>
      </Suspense>
    </>
  );
};
export default App;
