import { UserRoles } from "common/enums";
import Breadcrumbs from "components/breadcrumbs";
import * as appRoutes from "config/routes.config";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MainContent from "./components/mainContent";
import TeacherInfos from "./components/profileInfo";
import TeacherEvents from "./components/teacherEvents";
import s from "./style.module.css";

interface Props { }

const TeacherActivities = (props: Props) => {
  const auth = useSelector((state) => state.auth);
  const [postsCount, setPostsCount] = useState(auth?.user?.postsNum ? auth?.user?.postsNum : 0);
  const [docsCount, setDocsCount] = useState(auth?.user?.docsNum ? auth?.user?.docsNum : 0);

  const navigate = useNavigate();

  const handleAddPost = () => {
    setPostsCount(postsCount + 1);
  };

  const handleAddDocs = (numToAdd: any) => {
    setDocsCount(docsCount + numToAdd);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  useEffect(() => {
    const isLoggedIn = auth.isLoggedIn;
    const isTeacher = auth.user?.roles?.includes(UserRoles.TEACHER);
    const isOTP = auth.user?.step === "otp";
    if (isLoggedIn && isTeacher && isOTP) {
      navigate(appRoutes.REGISTER_TEACHER_BY_EMAIL);
    }
  }, [auth.isLoggedIn, navigate, auth.user?.roles]);

  return (
    <React.Fragment>
      {isMobile && auth?.user?.accountManaged !== "" && <div style={{ height: "70px", width: "100%" }}></div>}
      <Breadcrumbs isAds={true} />
      <div className={s.containerBox}>
        <div className={s.mainBox}>
          <div className={s.flex}>
            <div className={s.left}>
              <TeacherInfos postsCount={postsCount} docsCount={docsCount} />
            </div>
            <div className={s.main}>
              <MainContent onAddPost={handleAddPost} handleAddDocs={handleAddDocs} />
            </div>
            <div className={s.right}>
              <TeacherEvents />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TeacherActivities;
