import { Select } from "antd";
import { FC, useEffect, useState } from "react";
//!RTK
import { useSelector } from "react-redux";
import { useGetAllLevelsQuery } from "store/services/app/levels.services";


const { Option, OptGroup } = Select;

type Props = {
  onChange?: (a?: any, b?: any) => void;
  onClick?: () => void;
  onClear?: () => void;
  style?: React.CSSProperties;
  placeholder: string;
  className?: string;
  initValue?: string;
  levelsSelected?: string[];
};

const AppSelectGroup: FC<Props> = ({
  onChange,
  onClick,
  onClear,
  style,
  placeholder,
  className,
  initValue,
  levelsSelected = [],
}) => {
  //!HOOKS
  const { data: levelsData, isLoading, isError } = useGetAllLevelsQuery("");
  const { user } = useSelector((state) => state.auth);

  const allLevels = levelsData?.data;
  const [filteredLevels, setFilteredLevels] = useState(allLevels);

  useEffect(() => {
    if (!allLevels) return;

    const removeDoublons = (levels: { title: string; _id: string }[] = []) => {
      return levels?.filter((l) => !levelsSelected?.includes(l._id));
    };

    const filterLevels = (levels: { title: string; _id: string }[] = []) => {
      const userFilterLevelsTemp = user?.nivMat?.map((x: any) => x?.niveau?._id);
      return levels?.filter((l) => userFilterLevelsTemp?.includes(l._id));
    };

    let updatedLevels: Record<string, { title: string; _id: string }[]> = {};

    Object.entries(allLevels).forEach(([levelGroup, levels]) => {
      const typedLevels = levels as { title: string; _id: string }[];

      let filteredLevels = removeDoublons(typedLevels);

      if (!user.roles.includes("admin")) {
        filteredLevels = filterLevels(filteredLevels);
      }

      // **Only keep non-empty level groups**
      if (filteredLevels.length > 0) {
        updatedLevels[levelGroup] = filteredLevels;
      }
    });

    setFilteredLevels(updatedLevels);
  }, [levelsSelected.length > 0 ? levelsSelected : undefined, allLevels]);

  return (
    <Select
      placeholder={placeholder}
      allowClear
      style={style}
      className={className}
      onChange={onChange}
      onClick={onClick}
      onClear={onClear}
      bordered={false}
      loading={isLoading}
    //defaultValue={user?.levelId || initValue}
    >
      {Object.keys(filteredLevels || {}).map((levelGroup) => (
        <OptGroup key={levelGroup} label={levelGroup}>
          {filteredLevels[levelGroup]?.map((level: any) => (
            <Option key={level._id} value={level._id}>
              {level.title}
            </Option>
          ))}
        </OptGroup>
      ))}
    </Select>
  );
};

export default AppSelectGroup;
