import {
    DislikeFilled,
    DislikeOutlined,
    LikeFilled,
    LikeOutlined,
} from "@ant-design/icons";
import { Avatar, Col, Comment, Drawer, Row, Skeleton, Space, Tooltip } from "antd";
import { ReactComponent as DownloadIcon } from "assets/images/icons/download.svg";
import AvatarTeacher from "assets/images/teacher/teacher.svg";
import AppLatex from "components/appLatex";
import socket from "config/api.socket";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
    useCommentDisLikeMutation,
    useCommentLikeMutation,
    useGetCommentsMutation,
    useGetPostsQuery,
    useUndoCommentDisLikeMutation,
    useUndoCommentLikeMutation,
} from "store/services/teacher/activities.services";
import AddComment from "./addComment";
import s from "./style.module.css";

interface Props {
    actionShared: "get" | "post";
    openSharedArticle: any;
    sharedPostId: any;
    setOpenSharedArticle: React.Dispatch<React.SetStateAction<boolean>>;
    info: any;
}

const SharedArticleComments: React.FC<Props> = ({
    openSharedArticle,
    sharedPostId,
    setOpenSharedArticle,
    actionShared: commentAction,
    info,
}) => {
    const [getComments, { data, isLoading }] = useGetCommentsMutation();
    const [likes, setLikes] = useState(0);
    const [dislikes, setDislikes] = useState(0);
    const [action, setAction] = useState<string | null>(null);
    const { user } = useSelector((state) => state.auth);
    const { refetch } = useGetPostsQuery(user?._id);

    const [commentLike, { isSuccess }] = useCommentLikeMutation();
    const [commentDisLike] = useCommentDisLikeMutation();

    const [undoCommentLike] = useUndoCommentLikeMutation();
    const [undoCommentDisLike] = useUndoCommentDisLikeMutation();

    const like = () => {
        setLikes(1);
        setDislikes(0);
        setAction("liked");
    };

    const dislike = () => {
        setLikes(0);
        setDislikes(1);
        setAction("disliked");
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    function convertToKiloOctets(bytes: number): number {
        const KILO: number = 1024;
        const kiloOctets: number = bytes / KILO;
        return Math.round(kiloOctets);
    }

    useEffect(() => {
        getComments(sharedPostId);

        if (socket) {
            socket.on("connect", () => {
                console.log("Socket connected!");
            });

            socket.on("newPostComment", (resData: any) => {
                refetch();
            });
        }

        return () => {
            socket?.off("newPost");
        };
    }, [openSharedArticle ? openSharedArticle : null]);

    const actions = [
        <Tooltip key="comment-basic-like" title="Like">
            <span onClick={like}>
                {action === "liked" ? <LikeFilled /> : <LikeOutlined />}
                <span className="comment-action">{likes}</span>
            </span>
        </Tooltip>,
        <Tooltip key="comment-basic-dislike" title="Dislike">
            <span onClick={dislike}>
                {action === "disliked" ? <DislikeFilled /> : <DislikeOutlined />}
                <span className="comment-action">{dislikes}</span>
            </span>
        </Tooltip>,
    ];

    return (
        <Drawer
            title={false}
            placement="right"
            onClose={() => setOpenSharedArticle(false)}
            visible={openSharedArticle}
            width={isMobile ? "100%" : "70%"}
        >
            <div className={s.articleDrawer}>
                <div className={info?.links[0]?.type.includes("image") ? s.cardArticleImage : s.cardArticle} style={{ maxWidth: isMobile ? "100%" : "68%", height: isMobile ? "50%" : "auto", overflow: "scroll", scrollbarWidth: "thin" }}>
                    <div className={s.container}>
                        <div className={s.header}>
                            <div className={s.user__infos}>
                                <Space>
                                    <div className="">
                                        <Avatar
                                            src={info?.profId?.profile || AvatarTeacher}
                                            size={44}
                                            style={{
                                                background: "#fff",
                                                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <h3>
                                            {info?.profId?.firstname?.split(" ")[0]}{" "}
                                            {info?.profId?.lastname?.split(" ")[0]}
                                        </h3>
                                        <span>{moment(info?.createdAt).fromNow()}</span>
                                    </div>
                                </Space>
                            </div>
                        </div>

                        <div className={s.body}>

                            <AppLatex content={info?.content} />

                            {info?.links?.find((img: any) => img.type?.includes("image"))
                                ?.url && (
                                    <div style={{}}>
                                        <img
                                            src={
                                                info?.links?.find((img: any) => img.type?.includes("image"))
                                                    ?.url
                                            }
                                            alt=""
                                        />
                                    </div>
                                )}

                            {info?.links?.find((img: any) => img.type?.includes("video"))
                                ?.url && (
                                    <div>
                                        <video key={info._id} className={s.body} controls style={{ maxHeight: "400px" }}>
                                            <source src={info?.links?.find((img: any) => img.type?.includes("video"))
                                                ?.url}
                                            />
                                        </video>
                                    </div>
                                )}

                            {info?.links
                                ?.reduce((acc: any, curr: any) => {
                                    if (curr?.type === "application/pdf") {
                                        acc?.push(curr);
                                    }

                                    return acc;
                                }, [])
                                .map((acc: any) => (
                                    <Row gutter={24} style={{ marginBottom: "1em" }}>
                                        <Col>
                                            <Space>
                                                <div>
                                                    <a href={acc?.url} target="_blank">
                                                        <DownloadIcon width={28} />
                                                    </a>
                                                </div>
                                                <div>
                                                    <a href={acc?.url} target="_blank">
                                                        <h4>Télécharger le PDF</h4>
                                                    </a>
                                                    <span>
                                                        Format PDF | {convertToKiloOctets(acc?.size)}ko
                                                    </span>
                                                </div>
                                            </Space>
                                        </Col>
                                    </Row>
                                ))}
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <>
                        <Skeleton />
                        <Skeleton />
                        <Skeleton />
                    </>
                ) : data && data.data.results.length > 0 ? (
                    <div style={{ width: isMobile ? "100%" : "30%", height: isMobile ? "50%" : "auto" }}>
                        <AddComment postId={sharedPostId} />
                        {data.data.results.map((comment: any, index: number) => (
                            <Comment
                                key={comment.id}
                                actions={
                                    [
                                        <Tooltip key="comment-basic-like" title="Like">
                                            <span onClick={async () => {
                                                const body = {
                                                    commentId: comment?._id,
                                                    userId: user?._id,
                                                };
                                                if (comment?.liked) {
                                                    await undoCommentLike(body);
                                                } else {
                                                    await commentLike(body);
                                                }
                                                getComments(sharedPostId);
                                            }}>
                                                {comment.liked ? <LikeFilled /> : <LikeOutlined />}
                                                <span className="comment-action">{comment?.likesCount}</span>
                                            </span>
                                        </Tooltip>,
                                        <Tooltip key="comment-basic-dislike" title="Dislike">
                                            <span onClick={async () => {
                                                const body = {
                                                    commentId: comment?._id,
                                                    userId: user?._id,
                                                };
                                                if (comment?.disliked) {
                                                    await undoCommentDisLike(body);
                                                } else {
                                                    await commentDisLike(body);
                                                }
                                                getComments(sharedPostId);
                                            }}>
                                                {comment.disliked ? <DislikeFilled /> : <DislikeOutlined />}
                                                <span className="comment-action">{comment?.dislikesCount}</span>
                                            </span>
                                        </Tooltip>,
                                    ]
                                }
                                author={<a>{comment.userId.firstname}</a>}
                                avatar={
                                    <Avatar
                                        src={comment.userId.profile}
                                        alt={comment.userId.firstname}
                                    />
                                }
                                content={<p>{comment.comment}</p>}
                                datetime={
                                    <Tooltip title={moment(comment.updatedAt).fromNow()}>
                                        <span>{moment(comment.updatedAt).fromNow()}</span>
                                    </Tooltip>
                                }
                            />
                        ))}
                    </div>
                ) : (
                    <div style={{ width: isMobile ? "100%" : "30%", height: isMobile ? "50%" : "auto" }}>
                        <AddComment postId={sharedPostId} />
                    </div>
                )}
            </div>
        </Drawer>
    );
};

export default SharedArticleComments;
