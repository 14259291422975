import { FC, Fragment, useEffect, useState } from "react";

//!LIBRARY
import { CopyOutlined } from "@ant-design/icons";
import { Button, message, Skeleton, Spin, Tooltip } from "antd";

//!REDUX
import { useDispatch, useSelector } from "react-redux";
import {
  setChatReceiverId,
  setSelectUserChat,
  toggleAppChat
} from "store/AppChat/appChat";
import {
  useFollowMutation,
  useUnFollowMutation,
} from "store/services/teacher/profileTeacher";

//!ASSETS
import EnglishIcon from "assets/images/students/englishIcon.svg";
import FrenchIcon from "assets/images/students/frenchIcon.svg";
import StartIcon from "assets/images/students/teacherStart.svg";
import { default as Teacher, default as TeacherIcon } from "assets/images/teacher/teacher.svg";
import EllipseTeacherProfileOne from "../../../../../assets/images/app/ellipse_teacher_profile1.png";
import EllipseTeacherProfileTwo from "../../../../../assets/images/app/ellipse_teacher_profile2.png";
import EllipseTeacherProfileThree from "../../../../../assets/images/app/ellipse_teacher_profile3.png";
import FollowIcon from "../../../../../assets/images/app/follow_icon.svg";
import FollowedIcon from "../../../../../assets/images/app/followed_icon.svg";
import InstaIcon from "../../../../../assets/images/app/insta_icon.svg";
import MessageIcon from "../../../../../assets/images/app/message_icon.svg";
import PlanningButtonIcon from "../../../../../assets/images/app/planning_icon.svg";
import ReportIcon from "../../../../../assets/images/app/report_icon.png";

//!STYLES
import s from "./assets/style.module.css";

//
import { FLAGS, UserRoles } from "common/enums";
import CertificationBadge from "components/certificationBadge";
import { Link } from "react-router-dom";
import TeacherCalendarForStudent from "screens/student/components/calendarTeacher";
import IconTopic from "screens/student/searchTeacher/components/iconTopic";
import DrawerSearchInstaProf from "screens/student/searchTeacher/drawerSearchInstaProf";
import { displayReportDrawer, setReportedUserData, setReportingUserData } from "store/adventissements";
import { closeProfileDrawer, setUserConnect } from "store/auth/authSlice";
import { useFindRoomMutation } from "store/services/chat";
import { handleClearTopic } from "utils/formats";

type Props = {
  teacher: any;
  displayButtonProfile?: boolean;
};

const HeaderProfileTeacher: FC<Props> = ({ teacher, displayButtonProfile }) => {
  //!HOOKS
  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const closeDrawer = () => dispatch(closeProfileDrawer());
  const [findRoom, { isSuccess: isSuccessRooms }] = useFindRoomMutation();
  const [follow, { isLoading, isSuccess, data, isError }] = useFollowMutation();
  const [open, setOpen] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [
    unFollow,
    {
      isLoading: isLoadingUnFollow,
      isSuccess: isSuccessUnFollow,
      data: dataUnFollow,
      isError: isErrorUnFollow,
    },
  ] = useUnFollowMutation();

  const [isBtnInstaProf, setIsBtnInstaProf] = useState<boolean>(false);
  const [openDrawerInstaProf, setOpenDrawerInstaProf] = useState<boolean>(false);
  const [isFollwed, setIsFollowed] = useState<boolean>(false);

  //!FUNCTIONS
  const handleDisplayTeacher = (idTeacher: string) => {
    const finalData = {
      actor1: user?._id,
      actor2: idTeacher,
      flag: FLAGS.SINGLE_FLAG,
      type: "",
      instaprof: "",
    };

    findRoom(finalData);
    dispatch(setChatReceiverId(idTeacher));
  };

  const handleFollow = () => {
    const bodyFollow = {
      profId: teacher?.teacher?._id,
      studId: user?._id,
    };

    follow(bodyFollow);
  };

  const handleUnFollow = () => {
    const bodyUnFollow = {
      profId: teacher?.teacher?._id,
      studId: user?._id,
    };

    unFollow(bodyUnFollow);
  };

  const pathTeacherObj = {
    teacher: teacher?.teacher,
  };

  const isStudent = user?.roles?.includes(UserRoles.STUDENT);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1050);
  };
  window.addEventListener('resize', handleResize);

  const showDrawerSearchInstaProf = () => {
    if (isLoggedIn) {
      setOpenDrawerInstaProf(!openDrawerInstaProf);
    } else {
      dispatch(setUserConnect(true));
    }
  };

  const showReportDrawer = () => {
    const reportingUser = { "id": user?._id, "name": user?.firstname + " " + user?.lastname, "role": user?.roles[0] };
    const reportedUser = { "id": teacher?.teacher?._id, "name": teacher?.teacher?.firstname + " " + teacher?.teacher?.lastname, "role": teacher?.teacher?.roles[0] };
    dispatch(setReportingUserData(reportingUser));
    dispatch(setReportedUserData(reportedUser));
    dispatch(displayReportDrawer(true));
  };

  const handleSpnsorCodeCopy = () => {
    if (teacher?.teacher?.referralCode) {
      navigator.clipboard
        .writeText(teacher?.teacher?.referralCode)
        .then(() => {
          message.success("Code parrainage copié !");
        })
        .catch((error) => {
          console.error("Failed to copy: ", error);
        });
    } else {
      console.warn("No referral code to copy!");
    }
  };

  useEffect(() => {
    if (isSuccessRooms) {
      dispatch(setSelectUserChat(true));
      dispatch(toggleAppChat(true));
    }
  }, [isSuccessRooms]);

  useEffect(() => {
    console.log(teacher);
    setIsFollowed(teacher?.alreadyFollowd);
    setTimeout(() => {
      setDataLoaded(true);
    }, 1000);
  }, [teacher]);

  return (
    <Fragment>
      {!isMobile && <img src={EllipseTeacherProfileOne} height={53} style={{ position: "absolute", top: "0px", zIndex: "-1" }}></img>}
      {!isMobile && <img src={EllipseTeacherProfileTwo} height={28} style={{ position: "absolute", top: "0px", left: "91%", zIndex: "-1" }}></img>}
      {!isMobile && <img src={EllipseTeacherProfileThree} height={78} style={{ position: "absolute", top: "0px", left: "92%", zIndex: "-2" }}></img>}
      <div className={s.headerBox}>
        {!dataLoaded ? <Skeleton></Skeleton> : <div className={s.detailsBox}>
          <div className={s.avatarBox}>
            <img src={teacher?.profile || Teacher} alt="Teacher" className={s.avatarPicture} />
          </div>
          <div className={s.nameBox}>
            <div className={s.nameDetails}>
              <div className={s.nameFollowBox}>
                <span className={s.name}>{teacher?.teacher?.firstname + " " + teacher?.teacher?.lastname} {teacher?.teacher?.certified && <CertificationBadge />}</span>
                {teacher?.teacher?._id !== user?._id && <div className={s.separator}></div>}

                <Tooltip
                  title={isFollwed ? "Ne plus suivre ce prof" : "Suivre ce prof"}
                >
                  {isLoading || isLoadingUnFollow ? <div className={s.spinLoading}><Spin size="small" /></div> : teacher?.teacher?._id !== user?._id && <div className={s.followButton} onClick={teacher?.alreadyFollowd ? handleUnFollow : handleFollow}>
                    <span style={{ marginRight: "5px" }}>
                      {teacher?.followsNum || 0}
                    </span>
                    <span>{teacher?.followsNum > 1 ? "Followers" : "Follower"}</span>{" "}
                    <img src={teacher?.alreadyFollowd ? FollowedIcon : FollowIcon} height={15} style={{ marginLeft: "5px" }}></img>
                  </div>}
                </Tooltip>

              </div>
              <span className={s.rateLanguages}>
                {teacher?.rate && teacher?.rate !== 0 && Number(teacher?.rate).toFixed(2)}
                {teacher?.rate && teacher?.rate !== 0 && <img src={StartIcon} style={{ position: "relative", top: "-2px", marginRight: "10px", marginLeft: "5px" }} alt="Star" />}
                Je parle {teacher?.teacher?.langues?.includes("fr") && <img src={FrenchIcon} alt="French" />} {teacher?.teacher?.langues?.includes("en") && <img src={EnglishIcon} alt="English" />}
              </span>
              {teacher?.teacher?.career !== "" && <span className={s.title} dangerouslySetInnerHTML={{ __html: teacher?.teacher?.career?.split("<p>")[1].split("</p>")[0] }}></span>}
              <span className={s.levels}>
                {teacher?.teacher?.groupLevels?.length > 0 ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
                  {teacher?.teacher?.groupLevels?.map((level: any, index: number) => {
                    return (
                      <>
                        <div style={{ marginRight: "5px" }} key={index}>{level?.levelGroup}</div>
                        <div style={{ marginRight: "5px" }}> - </div>
                      </>
                    );
                  })}
                </div> : <span>Non renseigné</span>}
              </span>
            </div>
            <div className={s.buttonsRow}>
              {user && isStudent && (
                <Button
                  onClick={() => handleDisplayTeacher(teacher?.teacher?._id)}
                  className={s.newMessageButton}
                >
                  <img src={MessageIcon} height={isMobile ? 14 : 18} style={{ marginRight: "5px" }} />
                  Envoyer un message
                </Button>
              )}

              {displayButtonProfile && <div onClick={closeDrawer}>
                <Link to={`/prof/${user?._id}/${user?.firstname ? user?.firstname : "nom"}.${user?.lastname ? user?.lastname : "complet"}`}>
                  <Button className={s.newProfileButton}>
                    Mon profil
                  </Button>
                </Link>
              </div>}

              {!isStudent && user?._id === teacher?.teacher?._id && <div onClick={closeDrawer} style={{ marginLeft: !isMobile && displayButtonProfile ? "5px" : "0px", marginTop: isMobile ? "10px" : "0px" }}>
                <Link to={'/teacher/profile/complete'}>
                  <Button className={s.editProfileButton}>
                    <img src={TeacherIcon} height={isMobile ? 14 : 18} style={{ marginRight: "5px" }} />
                    Modifier mon profil
                  </Button>
                </Link>
              </div>}

              {isStudent && <Button className={s.newPlanningButton} onClick={() => setOpen(true)}>
                <img
                  src={PlanningButtonIcon}
                  alt=""
                  style={{
                    position: "relative",
                    top: "-1px",
                    marginRight: "5px",
                  }}
                />
                Mon Planning
              </Button>}

              {isStudent && teacher?.teacher?.instaProf && <Button
                onClick={() => {
                  setIsBtnInstaProf(true);
                  showDrawerSearchInstaProf();
                }}
                className={s.newInstaButton}
              >
                <img
                  src={InstaIcon}
                  alt=""
                  style={{ height: isMobile ? "15px" : "20px", marginRight: "5px" }}
                />
                InstaProf
              </Button>}

              {isStudent && <Button
                onClick={showReportDrawer}
                className={s.reportButton}
              >
                <img
                  src={ReportIcon}
                  alt=""
                  style={{ height: isMobile ? "15px" : "20px", marginRight: "5px" }}
                />
                Signaler ce profil
              </Button>}
            </div>
          </div>
        </div>}

        <div className={s.coursesPicesBox}>
          <div className={s.coursesBox}>
            {handleClearTopic(teacher?.matieres)?.map((item: any) => (
              <div className={s.courses}>
                <IconTopic data={item} />
                {/*  <span>{item?.title}</span> */}
                <span>{item?.title.includes("-") ? item?.title.split("-")[0] : item?.title.substring(0, 4)}</span>
              </div>
            ))}
          </div>
          <div className={s.priceBox}>
            {!isStudent && user?._id === teacher?.teacher?._id && teacher?.teacher?.referralCode && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginBottom: isMobile ? "10px" : "20px" }}>
              Mon code parrain : {" "}
              <Button className={s.sponsorButton} onClick={handleSpnsorCodeCopy}>
                {teacher?.teacher?.referralCode}
                <CopyOutlined style={{ marginLeft: "5px" }} />
              </Button>
            </div>}
            <span className={s.priceTitle}>Mes cours à partir de</span>
            <span className={s.price}>{user?._id === teacher?.teacher?._id ? user?.groupLevels[0]?.price : teacher?.minPrice}€/séance</span>
          </div>
        </div>
      </div>

      <div className={s.lineSeparator}></div>

      <TeacherCalendarForStudent
        setOpen={setOpen}
        open={open}
        currentTeacher={teacher}
      />

      <DrawerSearchInstaProf
        openDrawerInstaProf={openDrawerInstaProf}
        showDrawerSearchInstaProf={showDrawerSearchInstaProf}
        isBtnInstaProf={isBtnInstaProf}
        setIsBtnInstaProf={setIsBtnInstaProf}
        dataOfTeacher={teacher}
      />
    </Fragment>
  );
};

export default HeaderProfileTeacher;
