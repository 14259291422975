import { Table } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setChatReceiverId, setSelectUserChat, toggleAppChat } from "store/AppChat/appChat";
import "./myStyle.css";

const ArrayRegistredStudents: React.FC<any> = (props) => {
  const data = props.data;
  const roomRecovery = useSelector((state) => state.room);
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);

  const handleMessageClick = (id: any) => {
    handleDisplayTeacherMSG(id);
  };

  const handleDisplayTeacherMSG = (idStudent: string) => {
    dispatch(setChatReceiverId(idStudent));
    dispatch(setSelectUserChat(true));
    dispatch(toggleAppChat(true));
  };

  const col = auth?.user?.roles.includes("teacher")
    ? [
      {
        title: "Élèves",
        dataIndex: "fullname",
        render: (text: string) => (
          <div style={{ textTransform: "capitalize" }}>{text}</div>
        ),
        key: "fullname",
      },
      {
        title: "Points",
        dataIndex: "score",
        key: "score",
        render: (text: string) => text + " Points",
      },
      {
        title: "Classement",
        dataIndex: "classement",
        key: "classement",
      },
      {
        title: "Action",
        dataIndex: "id",
        key: "id",
        render: (text: string) => (
          <a
            style={{ color: "#80B1B2" }}
            href="/"
            onClick={(e) => {
              e.preventDefault();
              handleMessageClick(text);
            }}
          >
            Envoyer un message
          </a>
        ),
      },
    ]
    : [
      {
        title: "Élèves",
        dataIndex: "fullname",
        render: (text: string) => (
          <div style={{ textTransform: "capitalize" }}>{text}</div>
        ),
        key: "fullname",
      },
      {
        title: "Points",
        dataIndex: "score",
        key: "score",
        render: (text: string) => text + " Points",
      },
      {
        title: "Classement",
        dataIndex: "classement",
        key: "classement",
      },
    ];
  return (
    <Table
      className="myArray"
      columns={col}
      dataSource={data}
      pagination={{ pageSize: 5 }}
    />
  );
};

export default ArrayRegistredStudents;
