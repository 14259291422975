import Card from "components/card";
import * as appRoutes from "config/routes.config";
import { FC, useState } from "react";

//!IMAGES
import Avatar from "./assets/images/avatar.svg";

//!STYLES
import { Image, message, Select, Spin } from "antd";
import { Option } from "antd/lib/mentions";
import * as ROOMS_SERVICES from "api/services/rooms/rooms.services";
import { StatusCode } from "common/enums";
import CertificationBadge from "components/certificationBadge";
import Matiers from "components/matiers";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setChatReceiverId, setSelectUserChat, toggleAppChat } from "store/AppChat/appChat";
import { useFindRoomMutation } from "store/services/chat";
import s from "./assets/cardTeacher.module.css";

interface CardCourses {
  teacher?: any;
}

const CardTeacher: FC<CardCourses> = ({ teacher }) => {

  const navigate = useNavigate();
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [findRoom, { isSuccess }] = useFindRoomMutation();
  const [loading, setLoading] = useState<boolean>(false);

  const handleDisplayTeacherMSG = (idTeacher: string) => {
    dispatch(setChatReceiverId(idTeacher));
    dispatch(setSelectUserChat(true));
    dispatch(toggleAppChat(true));
  };

  const handleDelete = async () => {
    setLoading(true);
    try {
      const response = await ROOMS_SERVICES.quitClass(teacher?.accessCode, user?._id);
      if (response?.statusCode === StatusCode.OK) {
        message.success({
          content: "Classe quittée ",
        });
        setTimeout(() => {
          setLoading(false);
          navigate(appRoutes.STUDENT_DASHBOARD);
        }, 2000);
      }
      else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleOptionClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    navigate(`${appRoutes.STUDENT_MY_CLASS}/${teacher?._id}`);
  };

  const handleMessageClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    handleDisplayTeacherMSG(teacher?._id);
  };

  const handleQuitClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    handleDelete();
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
  const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
    setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
    setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
    setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
    setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
  };
  window.addEventListener('resize', handleResize);

  return (
    <Card>
      <div className={s.content}>
        <div className={s.__top}>
          <div className={s.__avatar}>
            <Image src={Avatar} preview={false} width={isMobile ? 55 : 80} />
          </div>

          <h3 className={s.__name__teacher}>
            {teacher?.teacher?.firstname || "Aucun Nom"}
            {teacher?.teacher?.certified && <CertificationBadge />}
          </h3>
        </div>

        <Matiers
          level={teacher?.level?.title}
          cours={teacher?.course?.title}
        />

        <div>
          <span>{teacher?.title || teacher?.name || ""}</span>
        </div>

        <div className={s.__bottom}>

          {loading ? <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}><Spin /></div> : <Select
            defaultValue="Action"
            style={{ width: isMobile ? 100 : 170, marginLeft: isMobile ? "5px" : "10px", marginTop: "5px" }}
            className={s.select}
            clearIcon={true}
            value="Action"
          >
            <Option value="null">
              <div
                onMouseDown={handleOptionClick}
                style={{ cursor: "pointer", fontSize: isMobile ? "0.75em" : "12px" }}
              >
                <Link to={`${appRoutes.STUDENT_MY_CLASS}/${teacher?._id}`}>
                  Dashboard classe
                </Link>
              </div>
            </Option>

            <Option value="chat">
              <div
                onClick={handleMessageClick}
                style={{ cursor: "pointer", fontSize: isMobile ? "0.75em" : "12px" }}
              >
                <Link to={"#"}>
                  Message au prof
                </Link>
              </div>
            </Option>

            <Option value="quit">
              <div
                onClick={handleQuitClick}
                style={{ cursor: "pointer", fontSize: isMobile ? "0.75em" : "12px" }}
              >
                <Link to={"#"}>
                  Quitter la classe
                </Link>
              </div>
            </Option>
          </Select>}
        </div>
      </div>
    </Card>
  );
};

export default CardTeacher;
