import { getToken } from "api";
import axios from "axios";
import { ApiResponse } from "common/interfaces";
import { API_AUTH, API_BASE_URL, API_RESSOURCES, GET_LIST_DATA } from "./teacher.routes";

export const findAll = async (): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(API_BASE_URL);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDataList = async (
  type: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(GET_LIST_DATA(type), {
      headers: {},
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const uploadToCloudinary = async (
  image: any,
  type: any
): Promise<ApiResponse | undefined> => {

  const formData = new FormData();
  formData.append("file", image);

  try {
    const response = await axios.post(`${API_RESSOURCES}/users/profil/update/upload?type=${type}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }

};

export const checkCourseStatus = async (
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get('', {
      headers: {},
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchCourseDocs = async (
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get('', {
      headers: {},
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchSommary = async (
  id: string
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get('', {
      headers: {},
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOrganismeList = async (): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL_ADMIN}/utilisateurs/organisme/instance/fetch/all`, {
      headers: {},
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getOrganismeStudents = async (organisationID: string): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL_ADMIN}/utilisateurs/organisme/instance/fetch/students/all`, {
      params: { organisationID },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateOrganismeCredit = async (
  id: string,
  credit: any,
): Promise<ApiResponse | undefined> => {
  try {
    const credits = parseFloat(credit);
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`${process.env.REACT_APP_API_URL_ADMIN}/utilisateurs/organisme/update/credits`, { id, credits }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const setStudentCredit = async (
  organismeId: string,
  teacherId: string,
  studentId: string,
  credit: any,
  creditCost: any,
  matiereId: string,
  niveauId: string,
): Promise<ApiResponse | undefined> => {
  try {
    const credits = parseFloat(credit);
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`${process.env.REACT_APP_API_URL_ADMIN}/utilisateurs/organisme/student/credits/affect`, { organismeId, teacherId, studentId, credits, creditCost, matiereId, niveauId }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getStudentsAttibutions = async (teacherId: string, studentId: string): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL_ADMIN}/utilisateurs/organisme/instance/fetch/students/credits/all`, {
      params: { teacherId, studentId },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteTeacherCalendar = async (
  id: string | undefined
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    const response = await axios.delete(`${API_RESSOURCES}/advertisements/user/calender/delete/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const addLevelsCourses = async (
  uid: string,
  groupLevelsInstances: any,
  matieres: any,
  prices: any,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`${API_RESSOURCES}/advertisements/update/nivmat`, { uid, groupLevelsInstances, matieres, prices }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const addCoupon = async (
  couponType: string,
  applicableTo: string,
  profId: string,
  startingDate: string,
  expirationDate: string,
  usageLimit: any,
  discountRate: any,
  requiredSessions: any,
  autoApply: boolean,
  studentEmail?: string,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`${API_RESSOURCES}/coupons`, { couponType, applicableTo, profId, startingDate, expirationDate, usageLimit, discountRate, requiredSessions, autoApply, studentEmail }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const fetchCoupons = async (teacherId: string): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${API_RESSOURCES}/coupons?profId=${teacherId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchStudentCoupons = async (teacherId: string, studentId: string): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${API_RESSOURCES}/coupons?profId=${teacherId}&studentId=${studentId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const desactivateCoupon = async (id: any) => {
  const token = await getToken();
  try {
    const response = await axios.patch(`${API_RESSOURCES}/coupons/disable/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const applyCoupon = async (
  studId: string,
  coupon: string,
  amount: any,
): Promise<ApiResponse | undefined> => {
  try {
    const token = await getToken();
    if (!token) throw new Error("access token not valid");
    const response = await axios.post(`${API_RESSOURCES}/coupons/apply`, { studId, coupon, amount }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error: any) {
    throw error;
  }
};

export const getCoupon = async (
  id: string,
): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${API_RESSOURCES}/coupons/code/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const checkTeacherCoupons = async (teacherId: string): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${API_RESSOURCES}/coupons/prof/${teacherId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const checkSponsorCode = async (referralCode: string): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.post(`${API_AUTH}/users/referral/validate`, { referralCode });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const sendContactMessage = async (firstname: string, lastname: string, email: string, phone: string, message: string): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.post(`https://workflow.monamialbert.com/webhook/contact`, { firstname, lastname, email, phone, message });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const checkCanNote = async (studentId: string, teacherId: string): Promise<ApiResponse | undefined> => {
  try {
    const response = await axios.get(`${API_RESSOURCES}/avis/can-review/${studentId}/${teacherId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};
