import { Avatar, Button, Col, message, Popconfirm, Popover, Row, Space, Spin } from "antd";
import { API_RESSOURCES } from "api/services/teacher/teacher.routes";
import ConversationIcon from "assets/images/icons/conversation.svg";
import DisLikeIcon from "assets/images/icons/dislike.svg";
import LikeIcon from "assets/images/icons/like.svg";
import ShareIcon from "assets/images/icons/share.svg";
import AvatarTeacher from "assets/images/teacher/teacher.svg";
import axios from "axios";
import { StatusCode, UserRoles } from "common/enums";
import AppLatex from "components/appLatex";
import CertificationBadge from "components/certificationBadge";
import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { openProfileDetailsDrawer, setProfileDetailsID, setProfileDetailsType } from "store/auth/authSlice";
import {
  useGetPostsQuery,
  usePostDisLikeMutation,
  usePostLikeMutation,
} from "store/services/teacher/activities.services";
import Delete from "../../../../../../../assets/images/app/delete_article.svg";
import Edit from "../../../../../../../assets/images/app/edit_article.svg";
import FileDownload from "../../../../../../../assets/images/app/file_download.svg";
import MoreOptions from "../../../../../../../assets/images/app/more_options.svg";
import SharingModule from "../sharing/sharing";
import ArticleComments from "./articleComment";
import Comments from "./comments";
import SharedArticleComments from "./sharedArticleComment";
import s from "./style.module.css";

interface Props {
  info: any;
  setIsPostDeleted: React.Dispatch<any>;
  setIsEditingPost: React.Dispatch<any>;
  setArtcileEditing: React.Dispatch<any>;
}

const CardActivities: FC<Props> = ({ info, setIsPostDeleted, setIsEditingPost, setArtcileEditing }) => {

  const dispatch = useDispatch();
  const { socket } = useSelector((state) => state.appChat);
  const [postLike, { isSuccess }] = usePostLikeMutation();
  const [postDisLike, { data }] = usePostDisLikeMutation();
  const { user } = useSelector((state) => state.auth);
  const { refetch } = useGetPostsQuery(user?._id);
  const [like, setLike] = useState<number>(0);
  const [articleId, setArticleId] = useState<string>();
  const [sharedPostId, setSharedPostId] = useState<string>();

  const [open, setOpen] = useState<boolean>(false);
  const [openArticle, setOpenArticle] = useState<boolean>(false);
  const [openSharedArticle, setOpenSharedArticle] = useState<boolean>(false);
  const location = useLocation();

  const popoverRef = useRef<HTMLDivElement>(null);

  const [action, setAction] = useState<"get" | "post">("get");
  const [actionArticle, setActionArticle] = useState<"get" | "post">("get");
  const [actionSharedArticle, setActionSharedArticle] = useState<"get" | "post">("get");

  const isStudent = user?.roles?.includes(UserRoles.STUDENT);

  const showDrawer = () => {
    setOpen(true);
  };

  const showArticleDrawer = () => {
    setOpenArticle(true);
  };

  function convertToKiloOctets(bytes: number): number {
    const KILO: number = 1024;
    const kiloOctets: number = bytes / KILO;
    return Math.round(kiloOctets);
  }

  const handleShare = (article: any) => {
    setArticleId(article?._id);
  };

  const handleProfileDetails = () => {
    if (user?._id === info?.profId?._id) return;

    dispatch(setProfileDetailsID(info?.profId?.uid));
    dispatch(setProfileDetailsType("teacher"));
    dispatch(openProfileDetailsDrawer());
  }

  const [isDeletingPost, setIsDeletingPost] = useState<boolean>(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [visibleDelete, setVisibleDelete] = useState(false);
  const [visibleOptions, setVisibleOptions] = useState(false);

  const showPopconfirmEdit = () => {
    setVisibleEdit(true);
    setVisibleOptions(false);
  };

  const showPopconfirmDelete = () => {
    setVisibleDelete(true);
    setVisibleOptions(false);
  };

  const handleCancelEdit = () => {
    setVisibleEdit(false);
  };

  const handleCancelDelete = () => {
    setVisibleDelete(false);
  };

  const deletePost = async (values: any) => {
    try {
      setVisibleDelete(false);
      setIsDeletingPost(true);
      const response = await axios.delete(`${API_RESSOURCES}/posts/${info?._id}`);
      if (response.status === StatusCode.OK) {
        message.success("Post supprimé avec succès");
        setIsPostDeleted(true);
      }
    } catch (error) { }
    finally {
      setIsDeletingPost(false);
    }
  }

  const editPost = () => {
    setArtcileEditing(info);
    setIsEditingPost(true);
    setVisibleEdit(false);
  }

  const content = (
    <div>
      <div onClick={showPopconfirmEdit} style={{ fontSize: "15px", color: "#393E3E", cursor: "pointer", display: "none" }}><img src={Edit} height={15}></img> Modifier</div>
      <div style={{ height: "1px", width: "90%", margin: "0px auto", background: "#BFBCBC", marginTop: "5px", marginBottom: "5px", display: "none" }}></div>
      <div onClick={showPopconfirmDelete} style={{ fontSize: "15px", color: "#F56666", cursor: "pointer" }}><img src={Delete} height={15}></img> Supprimer</div>
    </div>
  );

  const downloadPdf = async (url: string) => {
    const pdfUrl = "https://example.com/path_to_your_file.pdf";
    const link = document.createElement("a");
    link.href = pdfUrl;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleClickOutside = (event: MouseEvent) => {
    setTimeout(() => {
      if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
        if (visibleDelete) {
          console.log("Action selected!");
        } else {
          setVisibleOptions(false);
        }
      }
    }, 200);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visibleDelete]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');

    if (id) {
      setSharedPostId(id);
      setActionSharedArticle("get");
      setOpenSharedArticle(true);
    }
  }, [location.search]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  return (
    <>
      <div className={s.card}>
        <div className={s.container}>
          <div className={s.header}>
            <div className={s.user__infos} onClick={() => handleProfileDetails()}>
              <Space>
                <div className="">
                  <Avatar
                    src={info?.profId?.profile || AvatarTeacher}
                    size={isMobile ? 30 : 44}
                    style={{
                      background: "#fff",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;",
                      position: "relative",
                      top: isMobile ? "-7px" : "0px"
                    }}
                  />
                </div>
                <div>
                  <h3>
                    {info?.profId?.firstname?.split(" ")[0]}{" "}
                    {!isMobile && info?.profId?.lastname?.split(" ")[0]}
                    {info?.profId?.certified && <CertificationBadge />}
                  </h3>
                  <span>{moment(info?.createdAt).fromNow().replace(/^\w/, (c) => c.toUpperCase())}</span>
                </div>
              </Space>
            </div>

            <Popconfirm
              placement="top"
              title={
                "Veux-tu supprimer ce post ?"
              }
              onConfirm={deletePost}
              visible={visibleDelete}
              onVisibleChange={(visible) => {
                if (!isDeletingPost) {
                  setVisibleDelete(visible);
                }
              }}
              okText={isDeletingPost ? <Spin size="small" /> : "Oui"}
              cancelText="Non"
              onCancel={handleCancelDelete}
              okButtonProps={{ disabled: isDeletingPost }}
            >
              <Button style={{ visibility: "hidden" }}></Button>
            </Popconfirm>

            <Popconfirm
              placement="top"
              title={
                "Veux-tu modifier ce post ?"
              }
              onConfirm={editPost}
              visible={visibleEdit}
              okText={"Oui"}
              cancelText="Non"
              onCancel={handleCancelEdit}
            >
              <Button style={{ visibility: "hidden" }}></Button>
            </Popconfirm>

            {isDeletingPost ? (<Spin size="small" />) : (user?._id === info?.profId?._id && <Popover
              visible={visibleOptions}
              placement="left"
              content={content}
              trigger="click"
              style={{ height: "70px !important", borderRadius: "8px", boxShadow: "4px 8px 16px #8B8B8B38", border: "0.5px solid #BFBCBC" }}
              className={s.customPopover}
              overlayClassName={s.customPopover}
            >
              <div ref={popoverRef} style={{ height: "20px", width: "25px" }} onClick={(e: any) => { e.stopPropagation(); setVisibleOptions(!visibleOptions); }}><img src={MoreOptions} height={4} ></img></div>
            </Popover>)}
          </div>

          <div className={s.body} style={{}} onClick={() => {
            setActionArticle("get");
            showArticleDrawer();
          }}>

            <AppLatex content={info?.content?.length > 500 ? info?.content?.substring(0, 500) + "..." : info?.content} />

            {info?.links?.find((img: any) => img.type?.includes("image"))
              ?.url && (
                <div>
                  <img
                    src={
                      info?.links?.find((img: any) => img.type?.includes("image"))
                        ?.url
                    }
                    style={{ maxHeight: isMobile ? "200px" : "250px", objectFit: "contain" }}
                    alt=""
                  />
                </div>
              )}

            {info?.links?.find((img: any) => img.type?.includes("video"))
              ?.url && (
                <div className={s.body}>
                  <video key={info._id} controls style={{ maxHeight: "250px", objectFit: "contain", borderRadius: "24px" }}>
                    <source src={info?.links?.find((img: any) => img.type?.includes("video"))
                      ?.url}
                    />
                  </video>
                </div>
              )}

            {info?.links
              ?.reduce((acc: any, curr: any) => {
                if (curr?.type === "application/pdf") {
                  acc?.push(curr);
                }

                return acc;
              }, [])
              .map((acc: any) => (
                <div style={{ marginBottom: "1.5em", display: "flex", flexDirection: "row" }}>
                  <Space>
                    <div style={{ height: "25px", width: "25px" }} onClick={() => downloadPdf(acc?.url)}>
                      <a href={acc?.url} target="_blank">
                        <img src={FileDownload} height={20} style={{ borderRadius: "0px" }} />
                      </a>
                    </div>
                    <div style={{ height: "25px", width: "auto", display: "flex", flexDirection: "column", position: "relative", top: "-4px" }}>
                      <a href={acc?.url} target="_blank" style={{ color: "#393E3E", fontSize: "12px", fontWeight: "600" }}>
                        {acc?.fileName ? acc?.fileName : "Télécharger le PDF"}
                      </a>
                      <span style={{ color: "#8AB0B1", fontSize: "12px", fontWeight: "400", position: "relative", top: "-5px" }}>
                        Format PDF | {convertToKiloOctets(acc?.size)}ko
                      </span>
                    </div>
                  </Space>
                </div>
              ))}
          </div>

          <div className={s.footer} style={{ width: "100%" }}>
            <Row gutter={12} className={s.buttonRow}>
              <Col>
                <button
                  className={s.button}
                  onClick={async () => {
                    const body = {
                      postId: info?._id,
                      userId: user?._id,
                    };
                    if (info?.liked) {
                      await postDisLike(body);
                    } else {
                      await postLike(body);
                      // socket.emit("newPostLike", body);
                    }
                    refetch();
                  }}
                >
                  <img
                    src={info?.liked ? LikeIcon : DisLikeIcon}
                    alt=""
                    width={15}
                    style={{ position: "relative", top: "-2px" }}
                  />{" "}
                  {info?.likesCount} | J’aime
                </button>
              </Col>
              <Col>
                <button className={s.button} onClick={() => {
                  setAction("get");
                  showDrawer();
                }}>
                  {" "}
                  <img
                    src={ConversationIcon}
                    alt=""
                    width={18}
                    style={{ position: "relative", top: "-1px" }}
                    onClick={() => {
                      setAction("get");
                      showDrawer();
                    }}
                  />{" "}
                  <span
                    onClick={() => {
                      setAction("get");
                      showDrawer();
                    }}
                  >
                    {info?.commentsCount}
                  </span>{" "}
                  |{" "}
                  <span
                    onClick={() => {
                      setAction("post");
                      showDrawer();
                    }}
                  >
                    Commenter
                  </span>
                </button>
              </Col>
              <Col>
                <button className={s.button} onClick={() => {
                  handleShare(info);
                }}>
                  {" "}
                  <img
                    src={ShareIcon}
                    alt=""
                    width={15}
                    style={{ position: "relative", top: "-2px", marginRight: "5px" }}
                  />{" "}
                  Partager
                </button>
              </Col>
            </Row>
            {articleId == info?._id ? <SharingModule info={info} /> : <div></div>}
          </div>
        </div>
      </div>
      <Comments
        open={open}
        setOpen={setOpen}
        action={action}
        postId={info?._id}
      />
      <ArticleComments
        action={actionArticle}
        openArticle={openArticle}
        postId={info?._id}
        setOpenArticle={setOpenArticle}
        info={info}
      />
      {sharedPostId === info?._id && <SharedArticleComments
        actionShared={actionSharedArticle}
        openSharedArticle={openSharedArticle}
        sharedPostId={sharedPostId}
        setOpenSharedArticle={setOpenSharedArticle}
        info={info}
      />}
    </>
  );
};

export default CardActivities;
