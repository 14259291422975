import { Button, Col, Form, Input, Row, Select } from "antd";
import { StatusCode } from "common/enums";
import AppSelectGroupInscription from "components/libs/selectGroupInscription";
import { Dispatch, FC, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import fr from "react-phone-input-2/lang/fr.json";
import { useSelector } from "react-redux";
import s from "./assets/drawerProfile.module.css";

import { fetchSchools } from "api/services/courses/courses.services";
import { getSystemList } from "api/services/studentQuizz/studentQuizz.services";
import * as TEACHER_SERVICES from "../../api/services/teacher/teacher.services";

interface Props {
  isPhoneVerified: boolean;
  isEmailVerified: boolean;
  isUpdating: boolean;
  formInfos: any;
  updateUserInfos: (values: any) => void;
}

const StudentUpdateProfile: FC<Props> = ({
  isPhoneVerified,
  isUpdating,
  formInfos,
  isEmailVerified,
  updateUserInfos,
}) => {
  //!HOOKS
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.auth);

  const [country, setCountry] = useState<string>();
  const [system, setSystem] = useState<string>();
  const [countriesList, setCountries] = useState<any>([]);
  const [schoolList, setSchools] = useState<string[]>([]);
  const [dataList, setDataList] = useState<any>([]);
  const [educationSystemList, setEducationSystem] = useState<string[]>([]);

  const [loadingSystems, setLoadingSystems] = useState<boolean>(false);
  const [loadingSchools, setLoadingSchools] = useState<boolean>(false);

  const getStaticLists = useCallback(
    async (type: string, dataList: any, state: Dispatch<any>) => {
      const response = await TEACHER_SERVICES.getDataList(type);
      if (response?.statusCode === StatusCode.OK) {
        state(response?.data);
      }
    },
    [dataList]
  );

  const handleCountryChange = (value: string) => {
    setCountry(value);
  };

  const handleSystemChange = (value: string) => {
    setSystem(value);
  };

  const getSchoolsLists = useCallback(
    async () => {
      try {
        setLoadingSchools(true);
        if (country !== undefined && system !== undefined) {
          const response = await fetchSchools(country, system);
          if (response?.statusCode === StatusCode.OK) {
            const namesArray = response.data.map((item: { name: any; }) => item);
            setSchools(namesArray);
          }
        }
      }
      catch (error) {
        ///message.error("Une erreur non connue s'est produite.");
      }
      finally {
        setLoadingSchools(false);
      }
    },
    [country, system]
  );

  const getSystemLists = useCallback(
    async () => {
      try {
        setLoadingSystems(true);
        if (country !== undefined) {
          const response = await getSystemList(country);
          if (response?.statusCode === StatusCode.OK) {
            const systemArray = response.data.map((item: { name: any; }) => item);
            setEducationSystem(systemArray);
          }
        }
      }
      catch (error) {
        ///message.error("Une erreur non connue s'est produite.");
      }
      finally {
        setLoadingSystems(false);
      }
    },
    [country]
  );

  //!EFFECTS
  useEffect(() => {
    getStaticLists('pays', countriesList, setCountries);
  }, []);

  useEffect(() => {
    getSchoolsLists();
  }, [country, system]);

  useEffect(() => {
    getSystemLists();
  }, [country]);

  return (
    <Form form={formInfos} onFinish={updateUserInfos}>
      <div className={s.field}>
        <Form.Item
          hasFeedback
          validateStatus={!!user?.levelId ? "success" : "warning"}
          help={!!user?.levelId ? "" : "Niveau invalide"}
          name="levelId"
          rules={[
            {
              required: true,
              message: "Champ obligatoire",
            },
          ]}
          style={{ margin: 0, padding: 0 }}
          initialValue={user?.levelId}
        >
          <AppSelectGroupInscription
            initValue={user?.levelId}
            placeholder="Choisir un niveau"
          // onChange={(value: string) => OnSelectLevel(value)}
          />
        </Form.Item>
      </div>

      <Form.Item
        hasFeedback
        validateStatus={isEmailVerified ? "success" : "warning"}
        // help={isEmailVerified ? "Email validé" : "Email non validé"}
        label={null}
        name="email"
        rules={[{ required: true, message: "Champ obligatoire" }]}
        style={{ margin: 0, padding: 0 }}
      >
        <Input
          size="large"
          className={s.field}
          placeholder={t("auth.email")}
          readOnly={true}
        //   onChange={(e) => setEmailState(e.target.value)}
        />
      </Form.Item>

      <Form.Item
        hasFeedback
        validateStatus={isPhoneVerified ? "success" : "warning"}
        help={isPhoneVerified ? null : t("auth.phoneNotVerified")}
        label={null}
        name="phone"
        rules={[{ required: true, message: "Champ obligatoire" }]}
        style={{
          margin: 0,
          padding: 0,
          marginBottom: "15px",
        }}
      >
        <PhoneInput
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: true,
            className: `ant-input ant-input-lg ${s.field}`,
            style: {
              paddingLeft: "50px",
            },
          }}
          localization={fr}
          enableSearch
          specialLabel=""
          country={"fr"}
        //   onChange={(phone) => setPhoneState({ phone }.phone)}
        />
      </Form.Item>

      <Form.Item
        hasFeedback
        validateStatus={!!user?.firstname ? "success" : "warning"}
        help={!!user?.firstname ? "" : "Prénom non validé"}
        label={null}
        name="firstname"
        rules={[{ required: true, message: "Champ obligatoire" }]}
        style={{ margin: 0, padding: 0 }}
      >
        <Input
          className={s.field}
          size="large"
          placeholder={t("auth.firstname")}
          readOnly={true}
        //   onChange={(e: any) =>
        //      setFirstnameState(e.target.value)
        //   }
        />
      </Form.Item>

      <Form.Item
        hasFeedback
        validateStatus={!!user?.lastname ? "success" : "warning"}
        help={!!user?.lastname ? "" : "Nom non validé"}
        name="lastname"
        rules={[{ required: true, message: "Champ obligatoire" }]}
        style={{ margin: 0, padding: 0 }}
      >
        <Input
          size="large"
          className={s.field}
          bordered={false}
          placeholder={t("auth.lastname")}
          readOnly={true}
        //   onChange={(e: any) =>
        //      setLastnameState(e.target.value)
        //   }
        />
      </Form.Item>

      <Form.Item
        hasFeedback
        validateStatus={!!user?.pays ? "success" : "warning"}
        help={!!user?.pays ? "" : "Pays invalide"}
        style={{ marginBottom: "15px" }}
        name="pays"
        rules={[{ required: true }]}
      >
        <Select
          showSearch
          style={{ padding: "0px", fontSize: "16px", color: "#333" }}
          className={s.field}
          placeholder={<div style={{ fontSize: "16px", color: "#C0C0C0" }}>Choisis ton pays</div>}
          bordered={false}
          allowClear
          dropdownStyle={{ padding: "3px" }}
          showArrow={true}
          value={country}
          onSelect={handleCountryChange}
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            option?.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {countriesList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{item}</Select.Option>))}
        </Select>
      </Form.Item>

      <Form.Item
        hasFeedback
        validateStatus={!!user?.educSystem ? "success" : "warning"}
        help={!!user?.educSystem ? "" : "Système d'éducation invalide"}
        style={{ marginBottom: "15px" }}
        name="educSystem"
        rules={[{ required: true }]}
      >
        <Select
          disabled={country ? false : true}
          style={{ padding: "0px", fontSize: "16px", color: "#333" }}
          className={s.field}
          placeholder={<div style={{ fontSize: "16px", color: "#C0C0C0" }}>Choisis ton système</div>}
          bordered={false}
          allowClear
          dropdownStyle={{ padding: "3px" }}
          showArrow={true}
          value={system}
          loading={loadingSystems}
          optionFilterProp="children"
          onSelect={handleSystemChange}
        >
          {!loadingSystems && educationSystemList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{item}</Select.Option>))}
        </Select>
      </Form.Item>

      <Form.Item
        hasFeedback
        validateStatus={!!user?.etablissement ? "success" : "warning"}
        help={!!user?.etablissement ? "" : "Établissement invalide"}
        style={{ marginBottom: "15px" }}
        name="etablissement"
        rules={[{ required: true }]}
      >
        <Select
          disabled={country && system ? false : true}
          showSearch
          style={{ padding: "0px", fontSize: "16px", color: "#333" }}
          className={s.field}
          placeholder={<div style={{ fontSize: "16px", color: "#C0C0C0" }}>Choisis ton établissement</div>}
          bordered={false}
          allowClear
          dropdownStyle={{ padding: "3px" }}
          showArrow={true}
          loading={loadingSchools}
          optionFilterProp="children"
          filterOption={(input: any, option: any) =>
            option?.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {!loadingSchools && schoolList.map((item: any, i: any) => (<Select.Option key={i} value={item}>{item}</Select.Option>))}
        </Select>
      </Form.Item>

      <Form.Item style={{ margin: 0, padding: 0 }}>
        <Row gutter={20} justify="center">
          <Col span={12}>
            <Button
              loading={isUpdating}
              htmlType="submit"
              block
              type="primary"
              size="large"
              className={s.button}
            >
              Mettre à jour
            </Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default StudentUpdateProfile;
