import { Col, Form, message, Row } from "antd";
import * as TEACHER_SERVICES from "api/services/teacher/teacher.services";
import AppButton from "components/libs/button";
import H1 from "components/libs/title";
import Stylo from "components/stylo";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import AnimatedPage from "utils/animatedPage";
import AppScreen from "../ScreenWithSidebar";

//!STYLES
import s from "./assets/contact.module.css";

const Contact: FC = () => {
  //!HOOKS
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);
  const [loading, setLoading] = useState<boolean>(false);

  const handleFormSubmit = async (values: any) => {
    console.log("Form Values:", values);
    try {
      setLoading(true);
      const response = await TEACHER_SERVICES.sendContactMessage(values?.name, values?.lastname, values?.email, values?.number, values?.message);
      if (response?.message === "Workflow was started") {
        message.success("Message envoyé avec succès");
      }
    } catch (error) { }
    finally {
      setLoading(false);
      form.resetFields();
    }
  };

  return (
    <AnimatedPage>
      {/* <Breadcrumbs /> */}
      <Stylo />
      <AppScreen className={s.__contact}>
        <H1>Contact</H1>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleFormSubmit}
        >
          <div className={isMobile ? s.main__contact_mobile : s.main__contact}>
            <div className={s.__main_left}>
              <Row justify="space-between">
                <Col span={12}>
                  {" "}
                  <label htmlFor="">Nom</label>
                  <div className={s.field}>
                    <Form.Item name="name" initialValue={user?.firstname}>
                      <input type="text" />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <label htmlFor="">Prénom</label>
                  <div className={s.field}>
                    <Form.Item name="lastname" initialValue={user?.lastname}>
                      <input
                        type="text"
                        placeholder="Entrer votre prénom"
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
              <Row justify="space-between">
                <Col span={12}>
                  <label htmlFor="">Email</label>
                  <div className={s.field}>
                    <Form.Item name="email" initialValue={user?.email}>
                      <input type="email" disabled />
                    </Form.Item>
                  </div>
                </Col>

                <Col span={12}>
                  <label htmlFor="">Téléphone</label>
                  <div className={s.field}>
                    <Form.Item name="number">
                      <input
                        type="text"
                        name="tel"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        maxLength={12}
                        placeholder="Entre ton numéro de téléphone"
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <div className={isMobile ? s.paper_mobile : s.paper}>
                <div className={s.paper_content}>
                  <Form.Item name="message">
                    <textarea autoFocus></textarea>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>

          <Row justify="center">
            <AppButton className={s.__submit} htmlTypeSubmit={true} loading={loading}>Envoyer</AppButton>
          </Row>
        </Form>
      </AppScreen>
    </AnimatedPage>
  );
};

export default Contact;
