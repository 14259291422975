import { Form, Input, message, Select, Skeleton, Steps } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { updateTeacher } from "api/services/auth/auth.services";
import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUser } from "store/auth/authSlice";
import * as TEACHER_SERVICES from "../../api/services/teacher/teacher.services";
import * as TOPICS_SERVICES from "../../api/services/topics/topics.services";

//!COMPONENTS

//!IMAGES

//!STYLES
import { CKEditor } from "ckeditor4-react";
import {
  educSystem,
  HandlerInputError,
  Language,
  removeDuplicates,
  StatusCode,
} from "common/enums";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import s from "./assets/stepTwo.module.css";

const { Step } = Steps;
const { Option } = Select;

type Props = {
  // setCurrentStep: React.Dispatch<React.SetStateAction<number>>;
  // firstStepData: any;
  // setCurrent: React.Dispatch<React.SetStateAction<number>>;
  formRef?: any;
};

const UpdateProfileStepTwo: FC<Props> = ({ formRef }) => {
  //!HOOKS
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();
  ///const levels = useSelector((state) => state.levels);

  //!STATES
  const [isLoading, setisLoading] = useState<boolean>(false);
  const [dataLoaded, setDataLoaded] = useState<boolean>(false);
  const [userNivMat, setUserNivMat] = useState<any>([]);
  const [tempTopic, setTempTopic] = useState<any>([]);
  const refAddCreneau = useRef<any>();
  const refAddMatiere = useRef<any>();

  const [levels, setLevels] = useState<any>([]);
  const [loadingLevels, setLoadingLevels] = useState<boolean>(false);
  const [userLevelsLoaded, setUserLevelsLoaded] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<{ [key: string]: any[] }>({});

  const [courses, setCourses] = useState<any>([]);
  const [loadingCourses, setLoadingCourses] = useState<boolean>(false);
  const [selectedCourse, setSelectedCourse] = useState<any>([]);

  const [prices, setPrices] = useState<any>([]);

  const [userDataLoaded, setUserDataLoaded] = useState<boolean>(false);

  //!FUNCTIONS
  const UpdateUser = async (values: any) => {

    setisLoading(true);

    /* const cleanNivMat = values.nivMat.map((niv: any) => {
      return {
        niveau: typeof niv.niveau === "object" ? niv.niveau?._id : niv.niveau,
        matieres: niv?.matieres.map((mat: any) => {
          return {
            cost: Number(mat?.cost),
            costCollectif: Number(mat?.costCollectif),
            language: [...values.langues],
            matiere: mat?.matiere?._id || mat?.matiere?.title,
          };
        }),
      };
    }); */

    /* const finalData = {
      uid: user?._id,
      arrayNivMat: cleanNivMat,
    }; */

    const patchUpdate = {
      educSystem: [...values.educSystem],
      langues: [...values.langues],
      career: values.career,
    };

    try {
      const respons = await TEACHER_SERVICES.addLevelsCourses(user?._id, selectedData, selectedCourse, prices);
      if (respons?.statusCode === StatusCode.CREATED) {
        const response = await updateTeacher(user?._id, patchUpdate);
        if (response?.statusCode === StatusCode.OK) {
          const finalUser = await AUTH_SERVICES.getUserByUid(user.uid);
          if (finalUser?.data) {
            dispatch(updateUser(finalUser.data));
          }
          message.success("Modifié avec succès");
        }
      }
      else {
        message.warning({
          content: "une erreur est survenue",
        });
      }
    } catch (error) {
    } finally {
      setisLoading(false);
    }
  };

  const handleGroupClick = (group: string) => {
    setSelectedData((prev: any) => {
      // Toggle selection: If group is already selected, remove it; otherwise, add it
      if (prev[group]) {
        const { [group]: _, ...rest } = prev; // Remove the selected group
        return rest;
      }
      return { ...prev, [group]: levels[group] };
    });
  };

  const handleCourseClick = (course: string) => {
    setSelectedCourse((prev: string[]) => {
      if (prev.includes(course)) {
        // Remove the course if it already exists
        return prev.filter((item) => item !== course);
      } else {
        // Add the course if it does not exist
        return [...prev, course];
      }
    });
  };

  const handlePrices = (level: string, value: string | number) => {
    setPrices((prevPrices: any[]) => {
      const updatedPrices = [...prevPrices];
      const existingIndex = updatedPrices.findIndex((p) => p.level === level);
      if (existingIndex !== -1) {
        // Update the existing price
        updatedPrices[existingIndex].price = value;
      } else {
        // Add a new entry
        updatedPrices.push({ levelGroup: level, price: value });
      }
      return updatedPrices;
    });
  };

  const fetchLevels = async () => {
    try {
      setLoadingLevels(true);
      const response = await TOPICS_SERVICES.fetchLevels();
      if (response) {
        const structuredData = Object.keys(response.data).reduce((acc, key) => {
          acc[key] = response.data[key] ?? [];
          return acc;
        }, {} as Record<string, any[]>);

        setLevels(structuredData);
      }
    } catch (error) { }
    finally {
      setLoadingLevels(false);
    }
  }

  const fetchCourses = async () => {
    try {
      setLoadingCourses(true);
      const response = await TOPICS_SERVICES.fetchCourses();
      if (response) {
        setCourses(response?.data);
      }
    } catch (error) { }
    finally {
      setLoadingCourses(false);
    }
  }

  useEffect(() => {
    fetchLevels();
  }, [user]);

  useEffect(() => {
    if (user?.groupLevels.length !== 0) {
      const uniqueData = removeDuplicates(user?.groupLevels);
      uniqueData.forEach((groupData: any) => {
        handleGroupClick(groupData?.levelGroup);
      });
    }
  }, [user, levels]);

  useEffect(() => {
    if (user?.matieres.length !== 0) {
      user?.matieres.forEach((course: any) => {
        handleCourseClick(course);
      });
      setDataLoaded(true);
    }
  }, [levels, courses]);

  useEffect(() => {
    if (dataLoaded && user?.groupLevels.length !== 0 && user?.matieres.length !== 0) {
      user?.groupLevels.forEach((groupData: any) => {
        handlePrices(groupData?.levelGroup, groupData?.price);
      });
    }
  }, [dataLoaded]);

  useEffect(() => {
    if (dataLoaded && user?.groupLevels.length !== 0 && user?.matieres.length !== 0 && prices) {
      prices.forEach((data: any) => {
        form.setFieldsValue({
          [`price${data.levelGroup}`]: data.price || 0,
        });
      });
    }
  }, [user, dataLoaded, prices, form, selectedCourse]);

  useEffect(() => {
    if (Object.keys(selectedData).length === 0) {
      setCourses([]);
    }
    else {
      fetchCourses();
    }
  }, [selectedData]);

  return (
    <>
      <div className={s.levelsBox}>
        <span>Je donne des cours en : </span>
        {loadingLevels ? <Skeleton></Skeleton> : <div className={s.levelsButtons}>
          {Object.keys(levels).map((group) => (
            <button
              key={group}
              onClick={() => handleGroupClick(group)}
              className={`${selectedData[group] ? s.levelButtonActive : s.levelButton}`}
            >
              {group}
            </button>
          ))}
        </div>}
      </div>

      {courses.length !== 0 && <div className={s.levelsBox}>
        <span>J’enseigne les matières : </span>
        {loadingCourses ? <Skeleton></Skeleton> : <div className={s.levelsButtons}>
          {courses.map((course: string) => (
            <button
              key={course}
              onClick={() => handleCourseClick(course)}
              className={`${selectedCourse.includes(course) ? s.levelButtonActive : s.levelButton}`}
            >
              {course}
            </button>
          ))}
        </div>}
      </div>}

      {Object.keys(selectedData).length !== 0 && selectedCourse.length !== 0 && <div className={s.pricesBox}>
        <span>Mes tarifs horaires sont : </span>
        <div className={s.priceBox}>
          {Object.keys(selectedData).map((level) => {
            return (<div className={s.price}>
              <div className={s.priceLevel}>{level}</div>
              <Form form={form}>
                <Form.Item
                  name={`price${level}`}
                  rules={[{ required: true }]}
                  style={{ marginLeft: "20px" }}
                >
                  <Input
                    className={s.field}
                    placeholder="30"
                    suffix={"€/Heure"}
                    type="number"
                    min={0}
                    onBlur={(e) => handlePrices(level, e.target.value)}
                  />
                </Form.Item>
              </Form>
            </div>);
          })}
        </div>
      </div>}

      <Form onFinish={UpdateUser} initialValues={{ nivMat: user?.nivMat }} ref={formRef}>
        <div className={s.full__content}>
          <div className={s.__content} style={{ gap: "2em" }}>
            <div className={s.levelBox}>
              <div className={s.inputBox}>
                <div>
                  <H2>Système d'éducation :</H2>
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <div className={s.field}>
                    <Form.Item
                      style={{ margin: 0, padding: 0 }}
                      name={"educSystem"}
                      initialValue={user?.educSystem}
                    >
                      <Select
                        className={s.select}
                        placeholder="Système d'éducation"
                        mode="multiple"
                        style={{ width: "100%" }}
                        bordered={false}
                        allowClear
                      >
                        <Option value={educSystem.MOROCCO}>
                          Système Marocain
                        </Option>
                        <Option value={educSystem.FRENCH}>
                          Système Français
                        </Option>
                        <Option value={educSystem.BELGE}>Système Belge</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
            <div className={s.languageBox}>
              <div className={s.inputBox}>
                <div>
                  <H2>Je parle :</H2>
                </div>
                <div style={{ marginLeft: "10px" }}>
                  <div className={s.field}>
                    <Form.Item
                      style={{ margin: 0, padding: 0 }}
                      name={"langues"}
                      initialValue={user?.langues}
                    >
                      <Select
                        className={s.select}
                        placeholder="Langues"
                        mode="multiple"
                        style={{ width: "100%" }}
                        bordered={false}
                        allowClear
                      >
                        <Option value={Language.ENGLISH}>Anglais</Option>
                        <Option value={Language.FRENCH}>Français</Option>
                      </Select>
                    </Form.Item>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={s.full__content}>
          <H2 className={s.title}>À propos de moi et mon cours : </H2>

          <div className={s.text__area}>
            {/* <Form.Item style={{ margin: 0, padding: 0 }} name="career">
            <Input.TextArea bordered={false} rows={12} />
          </Form.Item> */}
            <Form.Item
              initialValue={user?.career}
              name={"career"}
              valuePropName="data"
              getValueFromEvent={(event) => {
                const data = event.editor.getData();
                return data;
              }}
              rules={[
                {
                  required: true,
                  message: HandlerInputError.content,
                },
              ]}
            >
              <CKEditor
                initData={user?.career}
                config={{
                  language: 'fr',
                }}
                style={{
                  border: "none",
                }}
              />
            </Form.Item>
          </div>
        </div>

        <div style={{ display: "flex", marginBottom: "4.5em", alignItems: "center", justifyContent: "center" }}>
          <Form.Item style={{ margin: 0, padding: 0 }}>
            <AppButton loading={isLoading} htmlTypeSubmit={true}>
              Mettre à jour
            </AppButton>
          </Form.Item>
        </div>
      </Form>
    </>
  );
};

export default UpdateProfileStepTwo;
