import axios from "axios";
import { AppNotification } from "components/appNotification";
import * as apiRoutes from "./studentReservation.routes";

export const findReservationStudent = async (idAds: string, values: any) => {
  try {
    const response = await axios.patch(apiRoutes.API_BASE_URL(idAds), values);
    return response.data;
  } catch (error: any) {
    if (error?.includes("status code 400")) {
      AppNotification(
        "Ce prof a déjà une réservation en cours.",
        "AlbertSad"
      );
    }
    throw error;
  }
};

export const findProgrammeStudent = async (studentId: string | any) => {
  try {
    const response = await axios.get(apiRoutes.API_BASE_URL_ADS(studentId));
    return response.data;
  } catch (error) {
    throw error;
  }
};
