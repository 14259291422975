import H1 from "components/libs/title";
import React, { FC, useMemo, useState } from "react";

//!STYLE
import s from "./assets/cardTeacher.module.css";

//!ASSETS
import ArrowBack from "assets/images/app/arrow_back.svg";
import TEACHER from "assets/images/teacher/teacher.svg";

//!COMPONENTS
import { Avatar, Drawer, message, Popconfirm, Row } from "antd";
import AppResult from "components/appResults";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import ZoomMeeting from "components/zoom";
import { useSelector } from "react-redux";

interface Props {
  openD: boolean;
  currentProfAccepted: any;
  infoTeacherAcceptedReservation: any;
  setOpenD: React.Dispatch<React.SetStateAction<boolean>>;
  handleDisplayTeacher: (v: any) => void;
}

const SuccesSessionInsta: FC<Props> = ({
  openD,
  setOpenD,
  handleDisplayTeacher,
  currentProfAccepted,
  infoTeacherAcceptedReservation,
}) => {
  const { user } = useSelector((state) => state.auth);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const [isZoomMeetingVisible, setIsZoomMeetingVisible] = useState(false);
  const [currentMeetingId, setCurrentMeetingId] = useState('');

  const handleMeetingClick = () => {
    if (!infoTeacherAcceptedReservation?.data?.studentLink) {
      message.error("Lien de réunion non trouvé pour ce cours");
      return;
    }

    setOpenD(false);
    setConfirmVisible(false);

    // Extract meeting ID from Zoom link - handle multiple URL formats
    let meetingId;
    try {
      const url = new URL(infoTeacherAcceptedReservation?.data?.studentLink);
      // Handle different Zoom URL formats
      if (url.pathname.includes('/j/')) {
        meetingId = url.pathname.split('/j/')[1]?.split('?')[0];
      } else if (url.pathname.includes('/s/')) {
        meetingId = url.pathname.split('/s/')[1]?.split('?')[0];
      } else {
        // Try to find any number sequence that could be a meeting ID
        meetingId = url.pathname.match(/\d{10,11}/)?.[0];
      }

      // Remove any non-numeric characters
      meetingId = meetingId?.replace(/\D/g, '');

      if (!meetingId || meetingId.length < 10) { // Zoom meeting IDs are typically 10-11 digits
        throw new Error('Invalid meeting ID format');
      }

      setCurrentMeetingId(meetingId);
      setIsZoomMeetingVisible(true);
    } catch (error) {
      message.error("ID de réunion invalide");
      return;
    }
  };

  const [confirmVisible, setConfirmVisible] = useState(false);

  const handleClose = () => {
    setConfirmVisible(true);
  };

  const handleQuit = () => {
    setOpenD(false);
    setConfirmVisible(false);
  };

  const handleStay = () => {
    setConfirmVisible(false);
  };

  return useMemo(() => {
    return (
      <Drawer
        className="drawer"
        maskClosable={false}
        onClose={handleClose}
        visible={openD}
        width={isMobile ? "100%" : "70%"}
        style={{ overflowX: "hidden" }}
        title={isMobile ? <div>Instaprof confirmé</div> : <H1>Instaprof confirmé</H1>}
        headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: isMobile ? "20px" : "40px", backgroundColor: "transparent" }}
        closeIcon={
          <img
            src={ArrowBack}
            height={isMobile ? 10 : 18}
            style={{}}
          ></img>
        }
        drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: isMobile ? "0px" : "30px", borderBottomLeftRadius: isMobile ? "0px" : "30px", boxShadow: "-3px 3px 34px #39393929" }}
      >
        {/* Popconfirm */}
        <Popconfirm
          title="Attention, la fermeture de cette fenêtre interrompra la session Instaprof en cours. Veux-tu fermer ?"
          visible={confirmVisible}
          onConfirm={handleQuit}
          onCancel={handleStay}
          okText="Oui"
          cancelText="Non"
        />

        <div>
          <AppResult status="success" title="" isMobile={isMobile} />
          <Row justify="center" align="middle">
            <div className={s.card__teacher__reservation}>
              <div className={s.teacher__reservation__avatar}>
                <Avatar
                  src={currentProfAccepted?.prof?.profile || TEACHER}
                  alt=""
                  size={84}
                />
              </div>
              <div className={s.description}>
                <H2>
                  {currentProfAccepted?.prof?.firstname}{" "}
                  {currentProfAccepted?.prof?.lastname}
                </H2>
              </div>

              <AppButton onClick={handleMeetingClick}>
                Rejoindre la visioconférence
              </AppButton>

              <div
                className={s.open__messagerie}
                onClick={() =>
                  handleDisplayTeacher(currentProfAccepted?.prof?._id)
                }
              >
                <span>ENVOYER UN MESSAGE</span>
              </div>
            </div>
          </Row>
        </div>

        {isZoomMeetingVisible && (
          <ZoomMeeting
            meetingNumber={currentMeetingId}
            userName={user?.firstname + " " + user?.lastname}
            sdkKey={process.env.REACT_APP_ZOOM_SDK_KEY ?? ""}
            sdkSecret={process.env.REACT_APP_ZOOM_SDK_SECRET ?? ""}
            onClose={() => {
              setIsZoomMeetingVisible(false);
            }}
          />
        )}
      </Drawer>
    );
  }, [
    openD,
    handleClose,
    handleDisplayTeacher,
    currentProfAccepted,
    infoTeacherAcceptedReservation,
  ]);
};

export default SuccesSessionInsta;
