import {
    Button,
    Form,
    message
} from "antd";
import { FC, useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//!STYLES
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import { StatusCode } from "common/enums";
import { useDispatch, useSelector } from "react-redux";
import { updateUser } from "store/auth/authSlice";
import st from "./assets/register.module.css";

import TeacherPicture from "../../../assets/images/teacher/teacher.svg";

type Props = {
    stepFourData: any;
    setCurrent: React.Dispatch<React.SetStateAction<number>>;
};

const StepFive: FC<Props> = ({ stepFourData, setCurrent }) => {
    //!HOOKS
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const [formInfos] = Form.useForm();

    //!STATES
    const [titleText, setTitleText] = useState('');
    const [presentationText, setPresentationText] = useState('');
    const [experienceText, setExperienceText] = useState('');
    const [incitationText, setInciationText] = useState('');
    const [linkedInText, setLinkedInText] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [imagePreview, setImagePreview] = useState('');
    const [imagePreviewAdded, setImagePreviewAdded] = useState<boolean>(false);
    //!STATES JOFREY
    const [stepFiveData, setStepFiveData] = useState<any>({});

    const [inputValue, setInputValue] = useState('');
    const [isValidLinkedIn, setIsValidLinkedIn] = useState(true);

    //!CONSTANTS
    const validateMessages = {
        required: t("auth.error.required"),
        types: {
            email: t("auth.error.emailInvalid"),
        },
    };

    const handleLinkedChange = (event: any) => {
        const newInputValue = event.target.value;
        setInputValue(newInputValue);

        // Regular expression to match LinkedIn profile URLs
        const linkedInRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9_-]+\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+\/?$/i;

        setIsValidLinkedIn(linkedInRegex.test(newInputValue));
        setLinkedInText(newInputValue);
    };

    const handleChange = (e: any) => {
        const { value } = e.target;
        var type = e.target.name;
        if (type === "title") {
            setTitleText(value);
        }
        else if (type === "presentation") {
            setPresentationText(value);
        }
        else if (type === "experience") {
            setExperienceText(value);
        }
        else if (type === "incitation") {
            setInciationText(value);
        }
    };

    const characterCountTitle = titleText.length;
    const characterCountPresentation = presentationText.length;
    const characterCountExperience = experienceText.length;
    const characterCountInciation = incitationText.length;
    const maxCharacterCountTitle = 50;
    const maxCharacterCountPresentation = 100;
    const maxCharacterCountExperience = 100;
    const maxCharacterCountIncitation = 100;

    //!FUNCTIONS
    const moveToStepSix = async (values: any) => {
        setIsLoading(true);
        setStepFiveData(user);

        /* if (titleText.length < 50) {
            message.warning({
                content: "Ton titre doit avoir une longueur de 50 caractères minimum !",
            });
        } */

        const finalData = {
            career: "<p>" + titleText + "</p></n>" +
                "<p>" + presentationText + "</p></n>" +
                "<p>" + experienceText + "</p></n>" +
                "<p>" + incitationText + "</p></n>",
            linkedIn: linkedInText,
            /* linkedIn: values.linkedIn, */
            step: user && user?.step === "completed" ? "completed" : "description",
        };

        try {
            const response = await AUTH_SERVICES.updateTeacher(user?._id, {
                ...finalData,
            });
            if (response?.statusCode === StatusCode.OK) {
                message.success({
                    content: "Modification effectuée ",
                });
                dispatch(
                    updateUser({
                        ...user,
                        career: "<p>" + titleText + "</p></n>" +
                            "<p>" + presentationText + "</p></n>" +
                            "<p>" + experienceText + "</p></n>" +
                            "<p>" + incitationText + "</p></n>",
                        linkedIn: linkedInText,
                        /* linkedIn: values.linkedIn, */
                        step: user && user?.step === "completed" ? "completed" : "description"
                    })
                );
            }
            else {
                message.warning({
                    content: "une erreur est survenue",
                });
            }
        } catch (error: any) {
            let content = "";
            for (
                let index = 0;
                index < error?.response?.data?.message?.length;
                index++
            ) {
                content = error?.response?.data?.message[index] + ", " + content;
            }
            message.error({
                content: content,
            });
        } finally {
            setIsLoading(false);
            setCurrent(6);
        }
    }

    const TextWithoutHtmlTags = (htmlText: any) => {
        const removeHtmlTags = (html: string) => {
            const tempDiv = document.createElement('div');
            tempDiv.innerHTML = html;
            return tempDiv.textContent || tempDiv.innerText || '';
        };

        const textWithoutTags = removeHtmlTags(htmlText);

        return textWithoutTags.replace(/^<p>/, '').replace(/<\/p>$/, '').trim();
    }

    // Generalized function to handle validation for all textareas
    const handleValidation = (event: React.InvalidEvent<HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        if (value === '') {
            event.target.setCustomValidity(`Merci de remplir ce champ`);
        } else {
            event.target.setCustomValidity(''); // Reset the custom error when valid
        }
    };

    //!EFFECTS
    useEffect(() => {
        if (user) {
            setImagePreview(user?.profile);
            setImagePreviewAdded(true);
            var career = user?.career;
            if (career) {
                setTitleText(TextWithoutHtmlTags(career.split('</p>')[0]));
                setPresentationText(TextWithoutHtmlTags(career.split('</p>')[1]));
                setExperienceText(TextWithoutHtmlTags(career.split('</p>')[2]));
                setInciationText(TextWithoutHtmlTags(career.split('</p>')[3]));
                setLinkedInText(user?.linkedIn);
                /* formInfos.setFieldsValue({
                    linkedIn: user?.linkedIn || "",
                }); */
            }
        }
    }, [user, formInfos]);

    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    return (
        <div style={{ height: "auto", margin: "0px auto", }}>
            <div className={st.avatarBox}>
                {imagePreviewAdded ? <img src={imagePreview} alt="Uploaded Preview" height={140} style={{ borderRadius: "50%" }}></img> : <img src={TeacherPicture} height={140}></img>}
            </div>
            <p className={st.descriptionTitle}>Crée ou mets à jour ton titre et une nouvelle description de ton profil.<br />Ces éléments apparaîtront sur ton profil dans la page ‘Trouver un professeur’.</p>
            <Form
                validateTrigger={["onFinish"]}
                name="registerTeacher"
                autoComplete="off"
                form={formInfos}
                validateMessages={validateMessages}
                onFinish={moveToStepSix}
            >
                <div className={st.certificateDiplomaFormBox}>
                    {/* <div className={st.teacherDescriptionBox}>
                        <p className={st.teacherDescriptionTitle}>Description pour les élèves francophones</p>
                    </div> */}
                    <div className={st.stepFiveContent}>
                        {/* <div className={st.pictureBoxRow}>
                            <div className={st.picturePreviewBox}>
                                {imagePreviewAdded ? <img src={imagePreview} alt="Uploaded Preview" height="100%" width="100%" style={{ borderRadius: "5px" }} /> : <p>Aucune image</p>}
                            </div>
                            <div className={st.titleBox}>
                                <textarea
                                    name="title"
                                    value={titleText}
                                    onChange={handleChange}
                                    onInvalid={handleValidation}
                                    onInput={(e) => (e.target as HTMLTextAreaElement).setCustomValidity('')}
                                    placeholder="Rédigez votre titre en français"
                                    rows={2}
                                    cols={2}
                                    className={st.customTextarea}
                                    required={true}
                                    style={{ margin: "0px" }}
                                />
                                <div className={st.textareaMaxText}>
                                    {maxCharacterCountTitle} caractères minimum. Vous avez utilisé {characterCountTitle} caractères.
                                </div>
                                <p className={st.textareaMessageFull}>Bon exemple: “Tuteur certifié<br /> avec 5 ans d’expérience”</p>
                                <p className={st.textareaMessageSmall}>Bon exemple: “Tuteur certifié avec 5 ans d’expérience”</p>
                            </div>
                        </div> */}
                        <textarea
                            name="title"
                            value={titleText}
                            onChange={handleChange}
                            onInvalid={handleValidation}
                            onInput={(e) => (e.target as HTMLTextAreaElement).setCustomValidity('')}
                            placeholder="Rédige ton titre en français"
                            rows={2}
                            cols={2}
                            className={st.customTextarea}
                            required={true}
                            style={{ margin: "0px" }}
                        />
                        <div className={st.textareaMaxText}>
                            {maxCharacterCountTitle} caractères minimum. Tu as utilisé {characterCountTitle} caractères.
                        </div>
                        <textarea
                            name="presentation"
                            value={presentationText}
                            onChange={handleChange}
                            onInvalid={handleValidation}
                            onInput={(e) => (e.target as HTMLTextAreaElement).setCustomValidity('')}
                            placeholder="Présente-toi et parle brièvement de tes centres d’intérêt."
                            rows={4}
                            cols={50}
                            className={st.customDescriptionTextarea}
                            required={true}
                        />
                        <div className={st.textareaMaxText}>
                            {maxCharacterCountPresentation} caractères minimum. Tu as utilisé {characterCountPresentation} caractères.
                        </div>
                        <textarea
                            name="experience"
                            value={experienceText}
                            onChange={handleChange}
                            onInvalid={handleValidation}
                            onInput={(e) => (e.target as HTMLTextAreaElement).setCustomValidity('')}
                            placeholder="Décris ton expérience en tant que professeur, tes diplômes et tes méthodes d’enseignement."
                            rows={4}
                            cols={50}
                            className={st.customTextarea}
                            required={true}
                        />
                        <div className={st.textareaMaxText}>
                            {maxCharacterCountExperience} caractères minimum. Tu as utilisé {characterCountExperience} caractères.
                        </div>
                        <textarea
                            name="incitation"
                            value={incitationText}
                            onChange={handleChange}
                            onInvalid={handleValidation}
                            onInput={(e) => (e.target as HTMLTextAreaElement).setCustomValidity('')}
                            placeholder="Incite les élèves à réserver un cours avec toi."
                            rows={3}
                            cols={50}
                            className={st.customTextarea}
                            required={true}
                        />
                        <div className={st.textareaMaxText}>
                            {maxCharacterCountIncitation} caractères minimum. Tu as utilisé {characterCountInciation} caractères.
                        </div>

                        {/* <div>
                            <Form.Item
                                name="linkedIn"
                                rules={[{ required: false }]}
                                style={{ marginBottom: "20px", marginTop: "10px", fontSize: "12px" }}
                            >
                                <Input
                                    className={st.field}
                                    placeholder="Profil linkedin ( optionnel )"
                                    onChange={handleLinkedChange}
                                />
                            </Form.Item>
                        </div> */}
                        <textarea
                            name="linkedIn"
                            value={linkedInText}
                            onChange={handleLinkedChange}
                            placeholder="Profil linkedin ( optionnel )"
                            rows={1}
                            cols={1}
                            className={st.customTextarea}
                            required={false}
                        />
                        {isValidLinkedIn ? null : <p className={st.linkedError}> Merci d'insérer un lien Linkedin.</p>}

                    </div>
                </div>
                <div className={st.rowButtons}>
                    <Button onClick={() => setCurrent((n) => n - 1)} className={st.newMoveButton}>Retour</Button>
                    <Form.Item style={{ margin: 0, padding: 0 }}>
                        <Button loading={isLoading} htmlType="submit" className={st.newMoveButton}>Suivant</Button>
                    </Form.Item>
                </div>
            </Form>
        </div>
    );
};

export default StepFive;
