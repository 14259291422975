import { Drawer, Spin, Tabs } from "antd";
import { getNationalRanking, getStudentRanking, getStudentSchoolRanking } from "api/services/studentQuizz/studentQuizz.services";
/* import belgiumFlag from "assets/images/app/belgium-flag.png"; */
/* import frenchFlag from "assets/images/app/french-flag.png"; */
/* import moroccoFlag from "assets/images/app/morocco-flag.png"; */
import schoolPic from "assets/images/app/school.svg";
import { useEffect, useState } from "react";
import Student from "../../assets/images/students/student.svg";
import st from "./style.module.css";

const { TabPane } = Tabs;

type Props = {
    visible: boolean,
    onClose: () => void,
    user: any,
    type: any,
}

const RankingDrawer: React.FC<Props> = ({ visible, onClose, user, type }) => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    const [rankingData, setRankingData] = useState<any>([]);
    const [schoolRankingData, setSchoolRankingData] = useState<any>([]);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [userExist, setUserExist] = useState<boolean>(false);
    const [displayUser, setDisplayUser] = useState<boolean>(true);

    const [currentTab, handleTabChange] = useState<string>("1");

    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const closeDrawer = async () => {
        setRankingData([]);
        setSchoolRankingData([]);
        setTotalPages(1);
        setUserExist(false);
        setDisplayUser(true);
        setPage(1);
        setLoading(false);
        setHasMore(true);
        handleTabChange("1");
        onClose();
    }

    const fetchStudentData = async () => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 5000);

        try {
            if (page <= totalPages) {
                setLoading(true);

                const res = await getStudentRanking(user?._id, page, controller.signal);
                clearTimeout(timeoutId);

                if (!res) return;

                const data = res?.data?.results;
                const pages = res?.data?.totalPages;
                const userExist = res?.data?.userExist;
                ///const newData = data.map((element: any) => element.user);
                setTotalPages(pages);
                setUserExist(userExist);

                setRankingData((prevData: any) => [...prevData, ...data]);

                if (page === pages) {
                    setHasMore(false);
                }
                else {
                    setHasMore(true);
                }
            }

        } catch (error: any) {
            if (error.name === "AbortError") {
                console.log("Request timed out after 10 seconds");
            } else {
                console.error("Error fetching national data:", error);
            }
        } finally {
            clearTimeout(timeoutId);
            setLoading(false);
        }
    };

    const fetchSchoolData = async () => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 5000);

        try {
            if (page <= totalPages) {
                setLoading(true);

                const res = await getStudentSchoolRanking(user?._id, page, controller.signal);
                clearTimeout(timeoutId);

                if (!res) return;

                const newData = res?.data?.results;
                const pages = res?.data?.totalPages;
                const userExist = res?.data?.userExist;
                setTotalPages(pages);
                setUserExist(userExist);

                setSchoolRankingData((prevData: any) => [...prevData, ...newData]);

                if (page === pages) {
                    setHasMore(false);
                }
                else {
                    setHasMore(true);
                }
            }

        } catch (error: any) {
            if (error.name === "AbortError") {
                console.log("Request timed out after 10 seconds");
            } else {
                console.error("Error fetching national data:", error);
            }
        } finally {
            clearTimeout(timeoutId);
            setLoading(false);
        }
    };

    const fetchNationalData = async () => {
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 5000);

        try {
            if (page <= totalPages) {
                setLoading(true);

                const res = await getNationalRanking(user?._id, page, controller.signal);
                clearTimeout(timeoutId);

                if (!res) return;

                const data = res?.data?.results;
                const pages = res?.data?.totalPages;
                const userExist = res?.data?.userExist;
                ///const newData = data.map((element: any) => element.user);
                setTotalPages(pages);
                setUserExist(userExist);

                setRankingData((prevData: any) => [...prevData, ...data]);

                if (page === pages) {
                    setHasMore(false);
                }
                else {
                    setHasMore(true);
                }
            }

        } catch (error: any) {
            if (error.name === "AbortError") {
                console.log("Request timed out after 10 seconds");
            } else {
                console.error("Error fetching national data:", error);
            }
        } finally {
            clearTimeout(timeoutId);
            setLoading(false);
        }
    };

    const switchSchoolData = async (tabIndex: string) => {
        setRankingData([]);
        setSchoolRankingData([]);
        setTotalPages(1);
        setUserExist(true);
        setDisplayUser(false);
        setPage(1);
        setLoading(true);
        setHasMore(true);
    }

    useEffect(() => {
        const containerElement = document.querySelector('.ant-drawer-body');

        const handleScroll = () => {
            if (containerElement && visible) {
                const { scrollTop, scrollHeight, clientHeight } = containerElement;
                if (scrollTop + clientHeight >= scrollHeight && !loading && hasMore) {
                    setPage((prevPage) => prevPage + 1);
                }
            }
        };

        if (containerElement && visible) {
            containerElement.addEventListener('scroll', handleScroll);

            return () => {
                containerElement.removeEventListener('scroll', handleScroll);
            };
        }
    }, [visible, loading, hasMore]);

    useEffect(() => {
        if (type === "school") {
            if (currentTab === "1") {
                setUserExist(false);
                setDisplayUser(true);
                fetchStudentData();
            }
            else {
                fetchSchoolData();
            }
        }
        else if (type === "national") {
            fetchNationalData();
        }
    }, [page, type, currentTab]);

    // Reset page to 1 when the drawer is opening
    useEffect(() => {
        if (visible) {
            setPage(1);
        }
    }, [visible]);

    // Reset page to 1 when the component is unmounted
    useEffect(() => {
        return () => {
            setPage(1);
        };
    }, []); // Empty dependency array means it only runs on mount and unmount

    useEffect(() => {
        if (userExist) {
            setDisplayUser(false);
        }
    }, [userExist]);

    return (
        <div>
            <Drawer
                title={<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-end" }}>
                    <div style={{ textTransform: "uppercase", fontSize: "1.2em", margin: "0px auto" }}>{type === "school" ? "Classement Etablissement" : `Classement National ${user?.pays !== "" ? `(${user?.pays})` : ""}`}</div>
                    {type === "school" && <div><img src={schoolPic} height={25}></img></div> /* : <div><img src={user?.educSystem === "Système Belge" ? belgiumFlag : user?.educSystem === "Système Français" ? frenchFlag : moroccoFlag} height={25}></img></div> */}
                </div>}
                placement="left"
                closable={true}
                onClose={closeDrawer}
                visible={visible}
                width={isMobile ? "100%" : "30%"}
                headerStyle={{ display: "flex", justifyContent: "space-between" }}
                bodyStyle={{ background: "#FFF", padding: "0px" }}
            /* getContainer={false} */
            >
                {type === "school" ? <Tabs activeKey={currentTab} onChange={(key) => { handleTabChange(key); switchSchoolData(key); }} centered>
                    <TabPane tab="Mon classement en établissement" key="1">
                        {/* Display the content for the first tab */}
                        <ul className="ant-drawer-body" style={{ padding: "0px" }}>
                            {/* Display user ranking only */}
                            {!loading && rankingData.length === 0 && <li style={{ position: "absolute", top: "100px", left: "0px", right: "10px" }}>
                                <div className={st.currentUserBox}>
                                    <div className={st.userNavInfoLeft}>
                                        <div className={st.currentUserRight}>{type === "school" ? user.rankEtab : user.rankNational}<span className={st.currentUserClassementInfo}>{type === "school" && user.rankEtab === 1 ? "er" : type === "national" && user.rankNational === 1 ? "er" : "e"}</span></div>
                                    </div>
                                    <div style={{ height: "auto", width: "65%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{ background: "white", borderRadius: "50%" }}>
                                            <img src={user?.profile} height={35} style={{ borderRadius: "50%" }}></img>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px", color: "#FFF", alignItems: "start", justifyContent: "center" }}>
                                            <span className={st.currentUserName}>{user?.firstname}</span>
                                            <span className={st.currentUserLastname}>{user?.lastname}</span>
                                        </div>
                                    </div>
                                    <div style={{ height: "100%", width: "2px", background: "white" }}></div>
                                    <div className={st.userNavInfoRight}>
                                        <div className={st.currentUserRight}>{user?.points}<span className={st.currentUserClassementInfo}>PTS</span></div>
                                    </div>
                                </div>
                            </li>}
                            {/* Display the ranking data */}
                            {!loading && rankingData.length > 0 && rankingData.map((item: any, index: any) => {
                                console.log(item);
                                return (<li key={index}>
                                    <div className={item?._id === user?._id ? st.currentUserBox : st.userBox}>
                                        <div className={st.userNavInfoLeft}>
                                            <div className={item?._id === user?._id ? st.currentUserRight : st.right}>{type === "school" ? item?.rankEtab : item?.rankNational}<span className={item?._id === user?._id ? st.currentUserClassementInfo : st.classementInfo}>{type === "school" && item?.rankEtab === 1 ? "er" : type === "national" && item?.rankNational === 1 ? "er" : "e"}</span></div>
                                        </div>
                                        <div style={{ height: "auto", width: "65%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <div style={{ background: "white", borderRadius: "50%", marginRight: "5px" }}>
                                                <img src={item?.profile || Student} height={35} style={{ borderRadius: "50%" }}></img>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px", color: "#FFF", alignItems: "start", justifyContent: "center" }}>
                                                <span className={item?._id === user?._id ? st.currentUserName : st.userName}>{item?.firstname.charAt(0).toUpperCase() + item?.firstname.slice(1).toLowerCase()}</span>
                                                <span className={item?._id === user?._id ? st.currentUserLastname : st.userLastname}>{item?.lastname.charAt(0).toUpperCase() + item?.lastname.slice(1).toLowerCase()}</span>
                                            </div>
                                        </div>
                                        <div style={{ height: "100%", width: "2px", background: "white" }}></div>
                                        <div className={st.userNavInfoRight}>
                                            <div className={item?._id === user?._id ? st.currentUserRight : st.right}>{item?.points}<span className={item?._id === user?._id ? st.currentUserClassementInfo : st.classementInfo}>PTS</span></div>
                                        </div>
                                    </div>
                                </li>)
                            })}
                            {/* Display user ranking only under */}
                            {displayUser && !loading && rankingData.length !== 0 && <li style={{ position: "absolute", bottom: "30px", left: "0px", right: "10px" }}>
                                <div className={st.currentUserBox}>
                                    <div className={st.userNavInfoLeft}>
                                        <div className={st.currentUserRight}>{type === "school" ? user.rankEtab : user.rankNational}<span className={st.currentUserClassementInfo}>{type === "school" && user.rankEtab === 1 ? "er" : type === "national" && user.rankNational === 1 ? "er" : "e"}</span></div>
                                    </div>
                                    <div style={{ height: "auto", width: "65%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{ background: "white", borderRadius: "50%" }}>
                                            <img src={user?.profile} height={35} style={{ borderRadius: "50%" }}></img>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px", color: "#FFF", alignItems: "start", justifyContent: "center" }}>
                                            <span className={st.currentUserName}>{user?.firstname}</span>
                                            <span className={st.currentUserLastname}>{user?.lastname}</span>
                                        </div>
                                    </div>
                                    <div style={{ height: "100%", width: "2px", background: "white" }}></div>
                                    <div className={st.userNavInfoRight}>
                                        <div className={st.currentUserRight}>{user?.points}<span className={st.currentUserClassementInfo}>PTS</span></div>
                                    </div>
                                </div>
                            </li>}
                            {loading && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}><Spin size="large" /></div>}
                        </ul>
                    </TabPane>
                    <TabPane tab="Classement de mon établissement" key="2">
                        {/* Display the content for the second tab */}
                        <ul className="ant-drawer-body" style={{ padding: "0px" }}>
                            {!loading && schoolRankingData.map((item: any, index: any) => (
                                <li key={index}>
                                    <div className={item?.name === user.etablissement ? st.currentUserBox : st.userBox}>
                                        <div style={{ height: "auto", width: "65%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                            <div className={item?.name === user.etablissement ? st.currentUserRight : st.right}>
                                                {item?.rank}
                                                <span className={item?.name === user.etablissement ? st.currentUserClassementInfo : st.classementInfo} style={{ marginRight: "5px" }}>{item?.rank === 1 ? "er" : "e"}</span>
                                                <div className={st.schoolUserName}>
                                                    {item?.name}
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ height: "100%", width: "2px", background: "white" }}></div>
                                        <div className={st.userNavInfoRight}>
                                            <div className={item?.name === user.etablissement ? st.currentUserRight : st.right}>{item?.points}<span className={item?.name === user.etablissement ? st.currentUserClassementInfo : st.classementInfo}>PTS</span></div>
                                        </div>
                                    </div>
                                </li>
                            ))}
                            {loading && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}><Spin size="large" /></div>}
                        </ul>
                    </TabPane>
                </Tabs> :
                    <ul className="ant-drawer-body" style={{ padding: "0px" }}>
                        {/* Display user ranking only */}
                        {!loading && rankingData.length === 0 && <li style={{ position: "absolute", top: "70px", left: "0px", right: "10px" }}>
                            <div className={st.currentUserBox}>
                                <div className={st.userNavInfoLeft}>
                                    <div className={st.currentUserRight}>{type === "school" ? user.rankEtab : user.rankNational}<span className={st.currentUserClassementInfo}>{type === "school" && user.rankEtab === 1 ? "er" : type === "national" && user.rankNational === 1 ? "er" : "e"}</span></div>
                                </div>
                                <div style={{ height: "auto", width: "65%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div style={{ background: "white", borderRadius: "50%" }}>
                                        <img src={user?.profile} height={35} style={{ borderRadius: "50%" }}></img>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px", color: "#FFF", alignItems: "start", justifyContent: "center" }}>
                                        <span className={st.currentUserName}>{user?.firstname}</span>
                                        <span className={st.currentUserLastname}>{user?.lastname}</span>
                                    </div>
                                </div>
                                <div style={{ height: "100%", width: "2px", background: "white" }}></div>
                                <div className={st.userNavInfoRight}>
                                    <div className={st.currentUserRight}>{user?.points}<span className={st.currentUserClassementInfo}>PTS</span></div>
                                </div>
                            </div>
                        </li>}
                        {/* Display the ranking data */}
                        {!loading && rankingData.map((item: any, index: any) => (
                            <li key={index}>
                                <div className={item?.firstname === user.firstname && item?.lastname === user.lastname ? st.currentUserBox : st.userBox}>
                                    <div className={st.userNavInfoLeft}>
                                        <div className={item?.firstname === user.firstname && item?.lastname === user.lastname ? st.currentUserRight : st.right}>{type === "school" ? item?.rankEtab : item?.rankNational}<span className={item?.firstname === user.firstname && item?.lastname === user.lastname ? st.currentUserClassementInfo : st.classementInfo}>{type === "school" && item?.rankEtab === 1 ? "er" : type === "national" && item?.rankNational === 1 ? "er" : "e"}</span></div>
                                    </div>
                                    <div style={{ height: "auto", width: "65%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                        <div style={{ background: "white", borderRadius: "50%" }}>
                                            <img src={item?.profile} height={35} style={{ borderRadius: "50%" }}></img>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px", color: "#FFF", alignItems: "start", justifyContent: "center" }}>
                                            <span className={item?.firstname === user.firstname && item?.lastname === user.lastname ? st.currentUserName : st.userName}>{item?.firstname}</span>
                                            <span className={item?.firstname === user.firstname && item?.lastname === user.lastname ? st.currentUserLastname : st.userLastname}>{item?.lastname}</span>
                                        </div>
                                    </div>
                                    <div style={{ height: "100%", width: "2px", background: "white" }}></div>
                                    <div className={st.userNavInfoRight}>
                                        <div className={item?.firstname === user.firstname && item?.lastname === user.lastname ? st.currentUserRight : st.right}>{item?.points}<span className={item?.firstname === user.firstname && item?.lastname === user.lastname ? st.currentUserClassementInfo : st.classementInfo}>PTS</span></div>
                                    </div>
                                </div>
                            </li>
                        ))}
                        {/* Display user ranking only under */}
                        {displayUser && !loading && rankingData.length !== 0 && rankingData.length > 16 && <li style={{ position: "absolute", bottom: "30px", left: "0px", right: "10px" }}>
                            <div className={st.currentUserBox}>
                                <div className={st.userNavInfoLeft}>
                                    <div className={st.currentUserRight}>{type === "school" ? user.rankEtab : user.rankNational}<span className={st.currentUserClassementInfo}>{type === "school" && user.rankEtab === 1 ? "er" : type === "national" && user.rankNational === 1 ? "er" : "e"}</span></div>
                                </div>
                                <div style={{ height: "auto", width: "65%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                    <div style={{ background: "white", borderRadius: "50%" }}>
                                        <img src={user?.profile} height={35} style={{ borderRadius: "50%" }}></img>
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px", color: "#FFF", alignItems: "start", justifyContent: "center" }}>
                                        <span className={st.currentUserName}>{user?.firstname}</span>
                                        <span className={st.currentUserLastname}>{user?.lastname}</span>
                                    </div>
                                </div>
                                <div style={{ height: "100%", width: "2px", background: "white" }}></div>
                                <div className={st.userNavInfoRight}>
                                    <div className={st.currentUserRight}>{user?.points}<span className={st.currentUserClassementInfo}>PTS</span></div>
                                </div>
                            </div>
                        </li>}
                        {loading && <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}><Spin size="large" /></div>}
                    </ul>}
            </Drawer>
        </div>
    );

};

export default RankingDrawer;