import { RedoOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Row,
  Select,
  Skeleton,
  Steps,
  Tooltip
} from "antd";
import * as appRoutes from "config/routes.config";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";

import { StatusCode, UserRoles } from "common/enums";

//!COMPONNETS
import Breadcrumbs from "components/breadcrumbs";

//!STYLES
import AppScreen from "../ScreenWithSidebar";
import styles from "./assets/quiz.module.css";

//!IMAGES
import { fetchCoursForTopic } from "api/services/courses/courses.services";
import AppResult from "components/appResults";
import AppSelectGroup from "components/libs/selectGroup";
import {
  Icourses,
  useGetAllQuizzMutation,
} from "store/services/teacher/courses";
import CardQuizz from "../components/cardQuizz";
import HeaderTabContent from "../components/headerTabContent";

import AppButton from "components/libs/button";
import Pagination from "components/pagination";
import QuizIcon from "../../../assets/images/app/Quiz.svg";
import SearchIcon from "../../../assets/images/app/search.svg";

const { Option } = Select;
const { Step } = Steps;
const { Search } = Input;

const Quizz: FC = () => {
  const { flag } = useSelector((s) => s.quizz);
  const navigate = useNavigate();
  //!HOOKS
  const [getAllQuizz, { data, isLoading, isError, isSuccess }] =
    useGetAllQuizzMutation();
  const [form] = Form.useForm();

  //!STATE
  const [courses, setCourses] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [tempTopic, setTempTopic] = useState<any>();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [course, setCourse] = useState<any>(null);
  const submit = useRef<any>(null);
  const [isloadingTopic, setIsloadingTopic] = useState<boolean>(false);
  const [currentPage, setcurrentPage] = useState<number>(1);
  const { user } = useSelector((state) => state.auth);
  const isTeacher = user?.roles?.includes(UserRoles.TEACHER);

  const [title, setTitle] = useState<string>("Quiz");

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  const OnSelectLevel = useCallback(
    async (levelId: string) => {
      const response = await TOPICS_SERVICES.findAll(levelId);
      if (response?.statusCode === StatusCode.OK) {
        setTempTopic(response?.data);
      }
    },
    [tempTopic]
  );

  const OnSelectCours = useCallback(
    async (courseId: any) => {
      // setIsLoading(true);
      setIsloadingTopic(true);
      try {
        if (courseId) {
          const response = await fetchCoursForTopic(courseId);
          if (response?.statusCode === StatusCode.OK) {
            setCourse(response?.data);
            setIsDisabled(false);
            submit.current.click();
          }
        } else {
          submit.current.click();
        }
      } catch (error) {
      } finally {
        // setIsLoading(false);
        setIsloadingTopic(false);
      }
    },
    [course, isDisabled]
  );

  const onReset = () => {
    form.resetFields();
    getAllQuizz({
      title: "",
      levelId: "",
      topicId: "",
      status: "",
      chapterId: "",
      self: false,
    } as Icourses);
  };

  const filterQuiz = useCallback(async (values: any) => {
    await getAllQuizz(values);
  }, []);

  useEffect(() => {
    getAllQuizz({
      title: "",
      levelId: "",
      topicId: "",
      status: "",
      chapterId: "",
      self: false,
      page: currentPage,
    } as Icourses);
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <React.Fragment>
      <Breadcrumbs isMycontent={true} />
      <AppScreen>
        <div className={styles.headerBox}>
          <div className={styles.titleBox}>
            <div className={styles.titlePictureBox}>
              <img src={QuizIcon} height={isMobile ? 20 : 35}></img>
            </div>
            {title}
          </div>
          <div className={styles.buttonBox}>
            {isTeacher && user?.isActive && user?.step === "completed" && !isMobile && <Link to={appRoutes.TEACHER_CREATE__QUIZ}>
              <Button className={styles.newButton} onClick={() => navigate(appRoutes.TEACHER_CREATE__QUIZ)}>
                Créer un quiz
              </Button>
            </Link>}
          </div>
        </div>

        <HeaderTabContent />

        <Form
          layout="inline"
          name="search"
          autoComplete="off"
          onFinish={filterQuiz}
          form={form}
        >
          <div className={styles.searchBox}>
            <div className={styles.selectBox}>
              <span style={{ color: "#393E3E", opacity: "0.3", fontSize: "14px" }}>Choisir le niveau</span>
              <Form.Item name="levelId">
                <AppSelectGroup
                  placeholder="Niveau"
                  style={{ width: "205px", position: "relative", left: "-12px" }}
                  onChange={(value: string) => (
                    OnSelectLevel(value), submit.current.click()
                  )}
                  onClear={() => setTempTopic(undefined)}
                />
              </Form.Item>
            </div>

            <div className={styles.separation}></div>

            <div className={styles.selectBox}>
              <span style={{ color: "#393E3E", opacity: "0.3", fontSize: "14px" }}>Choisir ta matière</span>
              <Form.Item name="topicId">
                <Select
                  placeholder={<span style={{ color: "#000", opacity: "0.8" }}>Matière</span>}
                  allowClear
                  loading={isloadingTopic}
                  bordered={false}
                  style={{ width: "205px", position: "relative", left: "-12px" }}
                  onChange={(courseId: string) => OnSelectCours(courseId)}
                >
                  {tempTopic?.map((topic: any) => (
                    <Option key={topic._id} value={topic._id}>
                      {topic.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className={styles.separation}></div>

            <div className={styles.selectBox}>
              <span style={{ color: "#393E3E", opacity: "0.3", fontSize: "14px" }}>Choisir le chapitre</span>
              <Form.Item name="chapterId">
                <Select
                  placeholder={<span style={{ color: "#000", opacity: "0.8" }}>Chapitre</span>}
                  allowClear
                  loading={isLoading}
                  disabled={isDisabled}
                  bordered={false}
                  style={{ width: "205px", position: "relative", left: "-12px" }}
                  onChange={() => submit.current.click()}
                >
                  {course?.map((course: any) => (
                    <Option key={course._id} value={course._id}>
                      {course.title}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </div>

            <div className={styles.inputBox}>
              <div className={styles.filedSearch}>
                <Form.Item name="title">
                  <Input
                    className={styles.searchField}
                    placeholder="Chercher ici"
                    bordered={false}
                    style={{ textAlign: "left", paddingTop: isMobile ? "2px" : "10px", paddingLeft: "30px", paddingRight: "30px" }}
                  />
                </Form.Item>
              </div>
            </div>

            <div style={{ width: isMobile ? "10%" : "auto" }}>
              <Form.Item>
                <Button
                  ref={submit}
                  shape="circle"
                  htmlType="submit"
                  style={{ background: "#8AB0B1", height: isMobile ? "35px" : "45px", width: isMobile ? "35px" : "45px", marginLeft: isMobile ? "5px" : "0px" }}
                >
                  <img src={SearchIcon} height={15} style={{ filter: 'brightness(0) invert(1)' }}></img>
                </Button>
              </Form.Item>
            </div>

            {!isMobile && <Tooltip placement="left" title={"Réinitialiser tous les filtres"}>
              <Button
                onClick={onReset}
                shape="circle"
                htmlType="button"
                style={{ height: "45px", width: "45px" }}
              >
                <RedoOutlined />
              </Button>
            </Tooltip>}
          </div>
          <div className={`${title === "Quiz" ? styles.myCoursesBox : styles.myCoursesBoxActive}`}>
            <Form.Item name="self" valuePropName="checked">
              <Checkbox
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  if (isChecked) {
                    setTitle("Mes quiz");
                  } else {
                    setTitle("Quiz");
                  }
                  submit.current.click();
                }}
                style={{ color: title === "Quiz" ? "#FFF" : "#707070", fontSize: isMobile ? "0.7em" : "14px", width: isMobile ? "5px" : "auto" }}
              >
                {!isMobile && "Mes quiz"}
              </Checkbox>
            </Form.Item>
            {isMobile && "Mes quiz"}
          </div>
        </Form>

        <div style={{ marginBottom: "80px", marginTop: "40px" }}>
          {isLoading && (
            <div>
              <Skeleton active />
              <Skeleton active />
            </div>
          )}

          {!isLoading && data?.data?.result.length > 0 && (
            <div className={styles.quizBox}>
              {data?.data?.result.map((quizz: any) => (
                <React.Fragment key={quizz?._id}>
                  <CardQuizz quizz={quizz} />
                </React.Fragment>
              ))}
            </div>
          )}
          {!isLoading && data?.data?.result.length < 1 && (
            <>
              {!isMobile && <Row justify="center">
                <Link to={appRoutes.TEACHER_CREATE__QUIZ}>
                  <AppButton>
                    Créer un quiz
                  </AppButton>
                </Link>
              </Row>}
              <AppResult
                status="info"
                title="Soyez le premier à poster un quiz"
                subTitle=""
              />
            </>
          )}
        </div>
      </AppScreen>
      {!isLoading && data?.data?.result.length > 0 && <Pagination
        totalPages={data?.data?.count}
        setcurrentPage={setcurrentPage}
        currentPage={currentPage}
      />}
    </React.Fragment>
  );
};

export default Quizz;
