import { SendOutlined } from "@ant-design/icons";
import {
  Avatar,
  Button,
  Col,
  Form,
  message,
  Modal,
  Rate,
  Row
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import React, { FC, Fragment, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useSendAvisMutation } from "store/services/teacher/profileTeacher";

import CardAvis from "../cardAvis";

//!ASSETS
import StartIcon from "assets/images/students/teacherStart.svg";
import Teacher from "assets/images/teacher/teacher.svg";

//!STYLES
import AppResult from "components/appResults";
import { badRequest } from "messages";
import s from "./assets/style.module.css";

import * as TEACHER_SERVICES from "api/services/teacher/teacher.services";

type Props = {
  avis: any;
};

const AvisProfileTeacher: FC<Props> = ({ avis }) => {
  //!HOOKS
  const params = useParams<"id">();
  const [sendAvis, { data, isLoading, isError, isSuccess }] =
    useSendAvisMutation();
  const { user } = useSelector((state) => state.auth);
  const [form] = Form.useForm();

  //!STATES
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [displayButton, setDisplayButton] = useState<boolean>(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onSubmitAvis = (values: any) => {
    const finalData = {
      ...values,
      studId: user?._id,
      profId: params.id!,
    };
    try {
      sendAvis(finalData);
    } catch (error) {
    }
  };

  const CheckButton = async () => {
    try {
      setLoading(true);
      const response = await TEACHER_SERVICES.checkCanNote(user?._id, params?.id!);
      if (response?.data) {
        setDisplayButton(response.data);
      } else {
        setDisplayButton(false);
      }
    } catch (error) {
      setDisplayButton(false);
    }
    finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (isError) {
      message.error(badRequest.error);
    }

    if (isSuccess) {
      form.resetFields();
      handleCancel();
      message.success("Ton avis a été ajouté avec succès");
    }
  }, [isError, isSuccess]);

  useEffect(() => {
    CheckButton();
  }, [user, params]);

  return (
    <React.Fragment>
      <div className={s.card}>
        <Row
          justify="space-between"
          style={{ marginBottom: "1em" }}
          align="middle"
        >
          <div className={s.titleBox}>
            <h3>Avis des étudiants</h3>
            <div className={s.tagAvis}>
              <span>
                <strong>{avis?.rate ? Number(avis?.rate).toFixed(2) : 0} </strong>
              </span>
              <img
                src={StartIcon}
                alt=""
                width={17}
                style={{
                  position: "relative",
                  top: "-2px",
                  marginRight: "5px",
                  marginLeft: "5px"
                }}
              />
              <span>{`( ${avis?.rateNum || 0} avis)`}</span>
            </div>
          </div>

          <Col>
            <Row gutter={8}>
              <Col>
                {!user || !user.roles.includes("teacher") && (
                  <Fragment>
                    {!avis?.alreadyGivedAvis && displayButton && (
                      <Button
                        shape="round"
                        type="primary"
                        style={{ border: "unset" }}
                        onClick={showModal}
                        className={s.btn}
                      >
                        Donner un avis
                      </Button>
                    )}
                  </Fragment>
                )}
              </Col>
            </Row>
          </Col>
        </Row>

        {avis?.avis?.length === 0 && <div className={s.emptyContent}>
          <AppResult status={"info"} title="Aucun avis disponible : )" />
        </div>}

        {avis?.avis?.length > 0 && <div className={s.content}>
          <>
            {avis?.avis?.map((av: any) => (
              <CardAvis av={av} />
            ))}
          </>
        </div>}
      </div>

      <Modal
        title={false}
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        centered
        bodyStyle={{ padding: "2em" }}
        width={"50vw"}
      >
        <Row justify="center" style={{ marginBottom: "1em" }}>
          <Col>
            <Avatar size={64} src={avis?.profile || Teacher} />
          </Col>
        </Row>

        <Form layout="vertical" onFinish={onSubmitAvis} form={form}>
          <Row justify="center">
            <Col>
              <Form.Item
                name={"rate"}
                rules={[{ required: true, message: "Ce champs est requis" }]}
              >
                <Rate allowHalf defaultValue={0} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name={"content"}
            label="Mon avis"
            rules={[
              { required: true, message: "Ce champs est requis" },
              {
                type: "string",
                min: 6,
                message: "minimum de 6 caractères : )",
              },
            ]}
          >
            <div style={{ border: "1px solid #ccc", borderRadius: "5px" }}>
              <TextArea rows={4} bordered={false} />
            </div>
          </Form.Item>

          <Row justify="center">
            <Col>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ textTransform: "uppercase", border: "unset" }}
                  loading={isLoading}
                  shape="round"
                  icon={<SendOutlined />}
                >
                  Envoyer mon avis
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default AvisProfileTeacher;
