import { FC, Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";

//!COMPONENTS
import * as CHALLENGES_SERVICES from "api/services/challenges/challenges.services";
import * as ROOMS_SERVICES from "api/services/rooms/rooms.services";
import AppButton from "components/libs/button";
import H2 from "components/libs/subtitle";
import SOCIAL_SHARING from "../../../components/libs/readyToUse/socialNetworks";
import AppScreen from "../ScreenWithSidebar";
//!Images
import { ReactComponent as SarchIcon } from "../../../assets/images/app/search.svg";
import ChallengeIcon from "./assets/images/challenge.svg";
import Student from "./assets/images/students.svg";

//!STYLES
import { Button, Form, Image, Input, message, Pagination, Popover, Row, Select, Skeleton, Tooltip } from "antd";
import { StatusCode } from "common/enums";
import CercleChart from "components/barChart/cercleChart";
import ArrayRegistredStudents from "./assets/columns";
import s from "./assets/room.module.css";

import CardMenu from "components/libs/readyToUse/challengerClasse/card/CardMenu";
import CardMenuLoader from "components/libs/readyToUse/challengerClasse/card/CardMenuLoader";
import { TimerChallenge } from "components/libs/readyToUse/timerChallenge";
import { useDispatch, useSelector } from "react-redux";

// !COPYtoCLIP-BOARD
import { RedoOutlined } from "@ant-design/icons";
import AppDrawer from "components/appDrawer";
import AppResult from "components/appResults";
import Breadcrumbs from "components/breadcrumbs";
import ChallengeChart from "components/challengeCharts";
import HelpPopover from "components/help";
import AppSelectGroup from "components/libs/selectGroup";
import H1 from "components/libs/title";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toggleAppChat } from "store/AppChat/appChat";
import { useFindRoomMutation } from "store/services/chat";
import {
  Icourses,
  useGetAllQuizzMutation,
} from "store/services/teacher/courses";
import * as TOPICS_SERVICES from "../../../api/services/topics/topics.services";
import CardContribution from "../components/cardContribution";
import DrawerPublications from "../components/drawerPublications";
import styles from "../Quizz/assets/quiz.module.css";
import { studentParser, studentsFilter } from "./assets/utils/converters";


const { Search } = Input;
const { Option } = Select;
const MESSAGE_PREFIX_PARTAGE_RESEAUX_SOCIAUX =
  "Bonjour ! Rejoins ma classe sur Monamilabert.com en utilisant ce code :";
const Room: FC = (props) => {
  // !!HOOKS
  const { id } = useParams();
  const [findRoom, { data: dataFindRoom, isSuccess }] = useFindRoomMutation();

  const auth = useSelector((state) => state.auth);
  const [visibleSocialMedia, setVisibleSocialMedia] = useState(false);
  const handleVisibleChange = () => {
    setVisibleSocialMedia(!visibleSocialMedia);
  };
  const hide = () => setVisibleSocialMedia(false);
  const dispatch = useDispatch();
  // !!CLASSE_INFOS
  const [classe, setClasse] = useState<any>();
  // !! CHALLENGE_INFOS
  const [challenge, setChallenge] = useState<any>();
  // !!REGISTRED_INFO
  const [registredStudents, setRegistredStudents] = useState<any>();
  const [registredStudentsBigOne, setRegistredStudentsBigOne] = useState<any>();
  const [drawerConfirm, setDrawerConfirm] = useState<boolean>(false);
  const [tempsChallenge, setTempsChallenge] = useState<number>(0);
  const [openDrawerPublications, setOpenDrawerPublications] = useState(false);

  // !!STATES
  //STATISTIQUES
  const [pieData, setPieData] = useState([]);
  const [barData, setBarData] = useState([]);
  const [challengePercentage, setChallengePercentage] = useState<any>();

  // !!FILTER_QUIZZES
  const [searchInput, setSearchInput] = useState("");
  const [levelInput, setLevelInput] = useState("");
  const [durationInput, setDurationInput] = useState("");
  // !!FILTER_REGISTRED_STUDENTS
  const [searchInputRegistredStudents, setSearchInputRegistredStudents] =
    useState("");
  // !!FUNCTIONS
  const handleSearchChangeRegistredStudents = (value: any) =>
    setSearchInputRegistredStudents(value.target.value);
  // FILTER_QUIZZES

  const [hidePanel, setHidePanel] = useState(true);
  const [isDrawerChallenge, setIsDrawerChallenge] = useState<boolean>(false);
  const [challenges, setChallenges] = useState([]);
  const [publicationData, setPublicationData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  // !!FUNCTIONS
  /* const fetchPublicationByClass = useCallback(
    async (idClasse: string | undefined, idProf: string) => {
      try {
        setLoading(true);
        const response = await ROOMS_SERVICES.fetchPublicationByClass(idClasse, idProf);
        if (response?.statusCode === StatusCode.OK) {
          setPublicationData(response?.data);
          setLoading(false);
        }
      } catch (error: any) { }
    },
    []
  ); */

  const fetchPublicationByClass = async (idClasse: string | undefined, idProf: string) => {
    try {
      setLoading(true);
      const response = await ROOMS_SERVICES.fetchPublicationByClass(idClasse, idProf);
      if (response?.statusCode === StatusCode.OK) {
        setPublicationData(response?.data);
        setLoading(false);
      }
    } catch (error: any) { }
  }

  const fetchClasse = useCallback(async (id: string | undefined) => {
    try {
      const response = await ROOMS_SERVICES.findOne(id);
      if (response?.statusCode === StatusCode.OK) setClasse(response.data);
    } catch (error: any) {
    }
  }, []);

  const fetchChallengesByIdClass = useCallback(
    async (id: string | undefined) => {
      try {
        const response = await CHALLENGES_SERVICES.findByIdClasse(id);
        if (response?.statusCode === StatusCode.OK) {
          setHidePanel(!hidePanel);
          setChallenge(response?.data);
        }
      } catch (error: any) { }
    },
    []
  );

  const fetchPieStatsByIdClass = useCallback(async (id: string | undefined) => {
    try {
      const response = await CHALLENGES_SERVICES.getPieStatsByIdClasse(id);
      if (response?.statusCode === StatusCode.OK) {
        const data = response?.data;
        setPieData(response?.data);
        // setBarData(response?.data);
      }
    } catch (error: any) { }
  }, []);

  const fetchBarStatsByIdClass = useCallback(async (id: string | undefined) => {
    try {
      const response = await CHALLENGES_SERVICES.getBarStatsByIdClasse(id);
      if (response?.statusCode === StatusCode.OK) {
        const data = response?.data;
        setBarData(
          data?.flatMap((e: any) => [
            { name: e.name, value: e.participation },
            { name: "Stat 2", value: 100 - e.participation }
          ])
        );
        setChallengePercentage(
          data?.flatMap((e: any) => {
            return e.participation
          })
        );
      }
    } catch (error: any) { }
  }, []);

  const fetchClassementByChallenge = useCallback(
    async (id: string | undefined) => {
      try {
        const response = await CHALLENGES_SERVICES.fetchClassementByChallengee(
          id
        );
        if (response?.statusCode === 200) {
          setRegistredStudents(studentParser(response.data));
          setRegistredStudentsBigOne(studentParser(response.data));
        }
      } catch (error: any) { }
    },
    []
  );

  const initialiseHandler = () => {
    setHidePanel(!hidePanel);
    setChallenge({});
  };

  // INTERNAL STATES
  const OnSubmitChallengeHandler = (infos: any) => {
    setHidePanel(!hidePanel);
    setChallenge(infos);
  };

  useEffect(() => {
    if (window.location.pathname.includes("challenge"))
      setIsDrawerChallenge(true);
    fetchClasse(id);

    fetchChallengesByIdClass(id);
  }, []);

  useEffect(() => {
    fetchPieStatsByIdClass(challenge?._id);
    fetchBarStatsByIdClass(challenge?._id);
    fetchClassementByChallenge(challenge?._id);
  }, [challenge]);

  useEffect(() => {
    fetchPublicationByClass(id, auth?.user?._id);
  }, [id, auth]);


  let d = {};
  d = { ...d, poi: "" };

  //!EFFECTS
  useEffect(() => {
    const getChallenges = async (id: string) => {
      const rsponse = await CHALLENGES_SERVICES.fetchChallengesByClassId(id);
      setChallenges(rsponse?.data);
    };
    getChallenges(id || "");
  }, []);


  //functions added by morad
  const [tempTopic, setTempTopic] = useState<any>();
  const [currentPage, setcurrentPage] = useState<number>(1);
  const submit = useRef<any>(null);

  const [getAllQuizz, { data, isLoading, isError }] =
    useGetAllQuizzMutation();

  const [form] = Form.useForm();

  const filterQuiz = useCallback(async (values: any) => {
    if (values?.levelId === undefined) {
      values.topicId = undefined
      form.setFieldsValue({
        ['topicId']: undefined,
      });
    }
    await getAllQuizz(values);
  }, []);

  const onReset = () => {
    form.resetFields();
    getAllQuizz({
      title: "",
      levelId: "",
      topicId: "",
      status: "",
      self: false,
      page: 1,
      duration: "",
      quizzLevel: ""
    } as Icourses);
  };

  const OnSelectLevel = useCallback(
    async (levelId: any) => {
      if (levelId) {
        const response = await TOPICS_SERVICES.findAll(levelId);
        if (response?.statusCode === StatusCode.OK) {
          setTempTopic(response?.data);
          form.setFieldsValue({
            ['topicId']: undefined,
          });
          //call static get quizz
          getAllQuizz({
            title: form.getFieldValue('title') === undefined ? "" : form.getFieldValue('title'),
            levelId: levelId,
            topicId: "",
            status: "",
            self: false,
            page: currentPage,
            duration: form.getFieldValue('duration') === undefined ? "" : form.getFieldValue('duration'),
            quizzLevel: form.getFieldValue('quizzLevel') === undefined ? "" : form.getFieldValue('quizzLevel')
          } as Icourses);
        }
      }
    },
    [tempTopic]
  );

  useEffect(() => {
    getAllQuizz({
      title: "",
      levelId: "",
      topicId: "",
      status: "",
      self: false,
      page: currentPage,
      duration: "",
      quizzLevel: ""
    } as Icourses);
    window.scrollTo(0, 0);
  }, [currentPage]);

  //end functions added by morad

  /* const challengeData = [
    { name: "Stat 1", value: 80 },
    { name: "Stat 2", value: 20 },
  ]; */

  return (
    <>
      <Breadcrumbs isRooms={true} />
      <AppScreen>
        <div style={{ marginBottom: "200px" }}>
          <div className={s.header}>
            <div className={s.left}>
              <H2 className={s.subtitle}>{classe?.title}</H2>
            </div>
            <div className={s.center} />
            <div className={s.right} style={{ display: "flex", flexDirection: "row" }}>
              {hidePanel ? (
                <AppButton
                  className={`${s.__btn__challenge}`}
                  onClick={() => setIsDrawerChallenge(!isDrawerChallenge)}
                >
                  <img src={ChallengeIcon} height={15} style={{ marginRight: "10px" }}></img> Challenger cette classe
                </AppButton>
              ) : (
                <TimerChallenge
                  subscribedStudentsLength={challenge?.participants?.length}
                  expiresAt={challenge?.expiresAt}
                  initialise={initialiseHandler}
                  subscribedStudents={challenge?.participants}
                />
              )}
              <div style={{ position: "relative", top: !hidePanel ? "35px" : "7px", marginLeft: "10px", border: "1px solid #FFF", borderRadius: "50%", height: 21, width: 21, display: "flex", alignItems: "center", justifyContent: "center" }}><HelpPopover id={"ma_classe_prefere_challenger"} /></div>
            </div>
          </div>

          <div className={s.main}>
            <div className={s.main__row}>
              <div className={s.main__left}>
                <div className={s.mainLeftContentEmpty}>
                  {!isMobile && <div style={{ position: "relative", top: "50px", left: "92%" }}><HelpPopover id={"ma_classe_prefere_statistique_de_la_classe"} /></div>}
                  {pieData.length === 0 ? <div
                    style={{
                      background: "#fafafa",
                      borderRadius: "15px",
                      padding: "0 5%",
                      width: "100%"
                    }}
                  >
                    <AppResult
                      status="info"
                      title={'Aucun challenge en cours !'}
                    />
                  </div> : barData.length === 0 ? <div
                    style={{
                      background: "#fafafa",
                      borderRadius: "15px",
                      padding: "0 5%",
                      width: "100%"
                    }}
                  >
                    <AppResult
                      status="info"
                      title={'Aucune donnée reçu pour le moment !'}
                    />
                  </div> : <div className={s.graphe__left}>
                    {/* update statistics dashboard */}
                    <div className={s.graphe__left__header}>
                      <div className={`${s.select}`}>
                        <Select
                          placeholder="Challenge"
                          style={{ width: "100%" }}
                          bordered={false}
                          onChange={(e: any) => {
                            fetchPieStatsByIdClass(e);
                            fetchBarStatsByIdClass(e);
                            fetchClassementByChallenge(e);
                          }}
                          allowClear
                        >
                          {challenges?.map((challenge: any) => (
                            <Option key={challenge?._id} value={challenge?._id}>
                              {challenge?.title}
                            </Option>
                          ))}
                        </Select>
                      </div>
                    </div>
                    {/* update statistics dashboard */}

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "400px",
                          height: "300px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{ fontFamily: "CartoonFont", fontSize: "25px" }}
                        >
                          Taux de participation
                        </div>
                        {/* <CercleChart pieData={barData} /> */}
                        <ChallengeChart pieData={barData} />
                        <div
                          style={{
                            fontWeight: "Bold",
                            fontSize: "0.8rem",
                            textTransform: "uppercase",
                            display: "flex",
                            flexDirection: "row",
                            gap: "30px",
                          }}
                        >
                          <div>Participation : {Number(challengePercentage) % 1 === 0 ? Number(challengePercentage).toFixed(0) : Number(challengePercentage).toFixed(2)} %</div>
                        </div>
                      </div>
                      <div
                        style={{
                          width: "400px",
                          height: "300px",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          fontFamily: "var(--body-font)",
                        }}
                      >
                        <div
                          style={{ fontFamily: "CartoonFont", fontSize: "25px" }}
                        >
                          Précision moyenne
                        </div>
                        <CercleChart pieData={pieData} />
                        <div
                          style={{
                            fontWeight: "Bold",
                            fontSize: "0.8rem",
                            textTransform: "uppercase",
                            display: "flex",
                            flexDirection: "row",
                            gap: "30px",
                          }}
                        >
                          <div
                            style={{
                              color: "#80b1b2",
                            }}
                          >
                            Bonne réponse
                          </div>{" "}
                          <div style={{
                            color: "#B0E0E1",
                          }}>
                            Mauvaise réponse
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>}
                </div>

                <div className={s.array__conent}>
                  {!isMobile && <div style={{ position: "relative", top: "10px", left: "95%" }}><HelpPopover id={"ma_classe_prefere_tableau_des_eleves"} /></div>}
                  <div className={s.array__conent__header}>
                    <div className="input">
                      <div className={s.filed__search}>
                        <Search
                          placeholder="Tape ici ta recherche"
                          size="large"
                          onChange={(e) => handleSearchChangeRegistredStudents(e)}
                          enterButton={
                            <SarchIcon
                              style={{
                                position: "relative",
                                width: "30px",
                                top: "3px",
                              }}
                              onClick={() => {
                                setRegistredStudents(
                                  studentsFilter(
                                    registredStudentsBigOne,
                                    searchInputRegistredStudents
                                  )
                                );
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className={s.__array}>
                    <ArrayRegistredStudents data={registredStudents} />
                  </div>
                </div>

                <div className={s.publications}>
                  <div className={s.header}>
                    <div className={s.left}>
                      <H2 className={s.subtitle}>Mes publications</H2>
                    </div>

                    <div className={s.center} />

                    <div className={s.right}>
                    </div>
                  </div>

                  <div className={s.main}>
                    {loading ? (
                      <>
                        <Fragment>
                          <Skeleton />
                          <Skeleton />
                          <Skeleton />
                        </Fragment>
                      </>
                    ) : (
                      <>
                        {!loading && publicationData?.results.length > 0 ? (
                          <div className={s.grid}>
                            {publicationData?.results?.map((contribution: any, i: number) => (
                              <CardContribution key={i} contribution={contribution} />
                            ))}
                          </div>
                        ) : (
                          <AppResult status="info" title="Aucune publication" />
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className={s.main__right}>
                <div className={s.__first__card}>
                  {!isMobile && <div style={{ position: "relative", top: "10px", left: "40%" }}><HelpPopover id={"ma_classe_prefere_message_collectif"} /></div>}
                  {isMobile && <div style={{ position: "relative", top: "10px", right: "-40%" }}><HelpPopover id={"ma_classe_prefere_message_collectif"} /></div>}
                  <Image preview={false} src={Student} width={60} />

                  <h4>
                    {classe?.registeredStudents?.length > 1
                      ? `${classe?.registeredStudents?.length} élèves`
                      : `${classe?.registeredStudents?.length} élève`}{" "}
                  </h4>
                  {classe?.registeredStudents?.length >= 1 ? (
                    <AppButton
                      className={s.__btn}
                      onClick={() => {
                        const findRoomBody = {
                          actor1: auth.user?._id,
                          actor2: id || "",
                          flag: "GROUP_FLAG",
                          instaprof: "",
                          type: "",
                        };

                        findRoom(findRoomBody);
                        dispatch(toggleAppChat(true));
                      }}
                    >
                      Message collectif
                    </AppButton>
                  ) : (
                    <AppButton className={`${s.__btn} ${s.__btn__challengeX}`}>
                      Message collectif
                    </AppButton>
                  )}

                  <AppButton
                    style={{ margin: "1em 0" }}
                    className={s.__btn}
                    onClick={() => setOpenDrawerPublications(true)}
                  >
                    Ajouter une publication
                  </AppButton>
                </div>

                <div className={s.__second__card}>
                  {isMobile && <div style={{ position: "relative", top: "10px", right: "-40%" }}><HelpPopover id={"ma_class_prefere_code_daccess"} /></div>}
                  <div className={s.__access__code}>
                    {!isMobile && <div style={{ position: "relative", top: "10px", left: "87%" }}><HelpPopover id={"ma_class_prefere_code_daccess"} /></div>}
                    <span>Code d’accès</span>

                    <div className={s.code}>
                      <span>
                        {classe?.accessCode.substring(0, 3)}&nbsp;-&nbsp;
                        {classe?.accessCode.substring(3, 8)}
                      </span>
                    </div>

                    <div className={s.__content__btn}>
                      <CopyToClipboard
                        text={classe?.accessCode}
                        onCopy={() =>
                          message.success({
                            content: "copié dans le presse-papiers",
                          })
                        }
                      >
                        <AppButton className={s.__btn}>Copier</AppButton>
                      </CopyToClipboard>

                      <Popover
                        content={
                          <>
                            <div>
                              {" "}
                              <SOCIAL_SHARING
                                prefix={MESSAGE_PREFIX_PARTAGE_RESEAUX_SOCIAUX}
                                codeAcces={classe?.accessCode + ", à bientôt"}
                              ></SOCIAL_SHARING>
                            </div>
                            <div style={{ width: "100%", textAlign: "center" }}>
                              <a
                                onClick={(e) => {
                                  e.preventDefault();
                                  hide();
                                }}
                                href="/"
                              >
                                Fermer
                              </a>
                            </div>
                          </>
                        }
                        trigger="click"
                        visible={visibleSocialMedia}
                        onVisibleChange={handleVisibleChange}
                      >
                        {" "}
                        <AppButton className={s.__btn}>partager</AppButton>
                      </Popover>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
      </AppScreen>

      {hidePanel && (
        <AppDrawer
          title={<H2>CHALLENGER CETTE CLASSE</H2>}
          headerStyle={{ border: "none" }}
          onClose={() => setIsDrawerChallenge(!isDrawerChallenge)}
          visible={isDrawerChallenge}
        >
          <div className={s.__first_drawer}>
            <div className={s.drawer__content}>

              <Form
                layout="inline"
                name="search"
                autoComplete="off"
                onFinish={filterQuiz}
                form={form}
              >
                <div className={styles.filter}>
                  <Tooltip placement="left" title={"Réinitialiser tous les filtres"}>
                    <Button
                      className={styles.__reset__btn}
                      onClick={onReset}
                      shape="circle"
                      htmlType="button"
                    >
                      <RedoOutlined />
                    </Button>
                  </Tooltip>

                  <Row gutter={12}>
                    <div>
                      <div className={styles.filed__search} style={{ marginBottom: isMobile ? "10px" : "0px" }}>
                        <Form.Item name="title">
                          <Search
                            onChange={() => submit.current.click()}
                            placeholder="Tape ici ta recherche"
                            size="large"
                            bordered={false}
                            enterButton={
                              <button type="submit">
                                <SarchIcon
                                  style={{
                                    position: "relative",
                                    top: "3px",
                                    left: "10px",
                                  }}
                                />
                              </button>
                            }
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div style={{ marginLeft: isMobile ? "0px" : "10px", marginBottom: isMobile ? "10px" : "0px" }}>
                      <div className={`${styles.select}`}>
                        <Form.Item name="levelId">
                          <AppSelectGroup
                            placeholder="Niveau"
                            style={{ width: isMobile ? "100px" : "150px" }}
                            onChange={(value: string) => (
                              OnSelectLevel(value)
                            )}
                            onClear={() => (setTempTopic(undefined), submit.current.click())}
                          />
                        </Form.Item>
                      </div>
                    </div>

                    <div style={{ marginLeft: isMobile ? "10px" : "10px", marginBottom: isMobile ? "10px" : "0px" }}>
                      <div className={`${styles.select}`}>
                        <Form.Item name="topicId">
                          <Select
                            placeholder="Matière"
                            allowClear
                            onChange={(value: string) => (
                              submit.current.click()
                            )}
                            bordered={false}
                            style={{ width: isMobile ? "100px" : "150px" }}
                          >
                            {tempTopic?.map((topic: any) => (
                              <Option key={topic._id} value={topic._id}>
                                {topic.title}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                    <div style={{ marginLeft: isMobile ? "0px" : "10px", marginBottom: isMobile ? "10px" : "0px" }}>
                      <div className={`${styles.select}`}>
                        <Form.Item name="quizzLevel">
                          <Select
                            onChange={(e: string) => {
                              submit.current.click()
                            }}
                            placeholder="Difficulté"
                            allowClear
                            bordered={false}
                          >
                            <Option key={"1"} value={"Facile"}>
                              Facile
                            </Option>
                            <Option key={"2"} value={"Moyen"}>
                              Moyen
                            </Option>
                            <Option key={"3"} value={"Difficile"}>
                              Difficile
                            </Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>

                    <div style={{ marginLeft: isMobile ? "10px" : "10px", marginBottom: isMobile ? "10px" : "0px" }}>
                      <div className={`${styles.select}`}>
                        <Form.Item name="duration">
                          <Select
                            placeholder="Durée"
                            allowClear
                            bordered={false}
                            onChange={(e: string) => {
                              submit.current.click()
                            }}
                          >
                            <Option key={"topic._id"} value={"5"}>
                              5 mins
                            </Option>
                            <Option key={"topic._id"} value={"10"}>
                              10 mins
                            </Option>
                            <Option key={"topic._id"} value={"15"}>
                              15 mins
                            </Option>
                            <Option key={"topic._id"} value={"20"}>
                              20 mins
                            </Option>
                            <Option key={"topic._id"} value={"30"}>
                              30 mins
                            </Option>
                            <Option key={"topic._id"} value={"45"}>
                              45 mins
                            </Option>
                          </Select>
                        </Form.Item>
                      </div>
                    </div>
                  </Row>
                </div>

                <Button
                  ref={submit}
                  htmlType="submit"
                  className={styles.__submit__hidden}
                ></Button>
              </Form>
            </div>
            {!isLoading ? (
              <>
                <CardMenu
                  setDrawerConfirm={setDrawerConfirm}
                  setTempsChallenge={setTempsChallenge}
                  data={data?.data}
                  someClasseDataNeeded={classe}
                  handler={OnSubmitChallengeHandler}
                />
                <Row justify="center" style={{ marginTop: "2em" }}>
                  <Pagination
                    showSizeChanger={false}
                    onChange={(curr: number) => setcurrentPage(curr)}
                    defaultCurrent={currentPage}
                    total={data?.data?.count * 10}
                  />
                </Row>
              </>
            ) : (
              <CardMenuLoader />
            )}
          </div>
        </AppDrawer>
      )}

      <AppDrawer
        onClose={() => setDrawerConfirm(false)}
        visible={drawerConfirm}
      >
        <H1>
          Challenge valide pendant {tempsChallenge}{" "}
          {tempsChallenge > 1 ? "heures" : "heure"}{" "}
        </H1>

        <AppResult status="success" title=" Challenge envoyé aux élèves" />
      </AppDrawer>

      <DrawerPublications
        open={openDrawerPublications}
        currRoom={classe}
        setOpen={setOpenDrawerPublications}
        currLevel={classe?.level}
      />
    </>
  );
};

export default Room;
