import { Avatar, Badge, Dropdown, Image, Menu, Result, Tooltip } from "antd";
import { FC, useEffect, useState } from "react";

//!IMAGES
import Notif from "../header/assets/images/notif.svg";

import { useDispatch, useSelector } from "react-redux";

//!COMPONENTS
import { setChatReceiverId, toggleAppChat } from "store/AppChat/appChat";

//!STYLE
import { FLAGS } from "common/enums";
import ReactAudioPlayer from "react-audio-player";
import { useNavigate } from "react-router-dom";
import { useFindRoomMutation } from "store/services/chat";
import {
  useGetCountNotifByUserQuery,
  useResetCountNotifByUserMutation,
} from "store/services/teacher/notification.services";
import s from "./style.module.css";

type Props = {
  dataNotif: any;
  refetch: () => void;
  viewNotifByUser: (id: string) => void;
  isLoading: boolean;
};

const NotificationStudent: FC<Props> = ({
  dataNotif,
  refetch,
  viewNotifByUser,
  isLoading,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, currentStudentInstaProf } = useSelector((state) => state.auth);
  const { socket } = useSelector((state) => state.appChat);
  const { data: dataCountNotif, refetch: coutRefecth } =
    useGetCountNotifByUserQuery(user._id);
  // const [
  //   deleteNotifByUser,
  //   { data: deleteData, isLoading: isLoadingDelete, isSuccess },
  // ] = useDeleteNotifByUserMutation();
  const [findRoom, { isSuccess: isSuccessRooms }] = useFindRoomMutation();

  const [resetCountNotifByUser] = useResetCountNotifByUserMutation();

  //!STATE
  const [activeSoundNotif, setActiveSoundNotif] = useState<boolean>(false);

  //
  const sound = "/assets/avatars/notif.mp3";
  const audio = new Audio(sound);
  audio.loop = true;

  useEffect(() => {
    socket?.on("receivedNewNotification", (newNotifResponse: any) => {
      refetch();
      coutRefecth();
      setActiveSoundNotif(true);
    });

    setActiveSoundNotif(false);
  }, [socket, dataNotif, isLoading]);

  const onViewNotif = async (id: string) => {
    if (id) {
      viewNotifByUser(id);
    }
  };

  const handleDisplayTeacher = (idTeacher: string) => {
    const finalData = {
      actor1: user?._id,
      actor2: idTeacher,
      flag: FLAGS.SINGLE_FLAG,
      type: "",
      instaprof: "",
    };

    findRoom(finalData);
    dispatch(setChatReceiverId(idTeacher));
  };

  const menu = (
    <Menu className={s.bodyDropDow}>
      {dataNotif?.data.length > 0 ? (
        <>
          {dataNotif?.data?.map((notif: any) => (
            <Menu.Item
              key={notif?._id}
              className={`${s.dropdow__item} ${notif?.seen && s.seen}`}
              onClick={() => {
                onViewNotif(notif?._id);
                if (notif?.type === "messagerie") {
                  dispatch(toggleAppChat(true));
                }
                else if (notif?.type === "mes_annonces") {
                  navigate("/eleve/calendrier");
                }
                else { }
              }}
            >
              <div className={`${s.dropdown__row}`}>
                <div className="left">
                  <Avatar size={30} src={notif?.userId?.profile} />
                </div>

                <div className="right">
                  <div className={s.title}>
                    <h3>{notif?.title}</h3>
                  </div>

                  <div className={s.msg}>
                    <Tooltip placement="top" title={<div dangerouslySetInnerHTML={{ __html: notif?.content }} />}>
                      <div dangerouslySetInnerHTML={{ __html: notif?.content }} />
                    </Tooltip>
                  </div>
                </div>
              </div>
            </Menu.Item>
          ))}
        </>
      ) : (
        <Result subTitle="Pas de nouvelles notifications pour l'instant." />
      )}
    </Menu>
  );

  return (
    <div
      onClick={() => {
        if (dataCountNotif?.data > 0) {
          resetCountNotifByUser({ userId: user?._id });
        }
      }}
    >
      <Badge
        size="small"
        // count={isCheckNotif ? 0 : dataCountNotif?.data}
        // count={newNotifs?.length}
        count={dataCountNotif?.data}
      >
        <Dropdown
          overlay={menu}
          placement="bottomRight"
          arrow
          overlayStyle={{ right: "200px" }}
        >
          <Image preview={false} src={Notif} width={23} />
        </Dropdown>
      </Badge>

      {activeSoundNotif && (
        <ReactAudioPlayer
          src={activeSoundNotif && sound}
          autoPlay={true}
          controls
          loop={true}
          style={{ display: "none" }}
        // className={s.__sound}
        />
      )}
    </div>
  );
};

export default NotificationStudent;
