import { Select } from "antd";
import { FC } from "react";

//!RTK
import { useSelector } from "react-redux";
import { useGetAllLevelsQuery } from "store/services/app/levels.services";

const { Option, OptGroup } = Select;

type Props = {
  onChange?: (a?: any, b?: any) => void;
  onClick?: () => void;
  onClear?: () => void;
  style?: React.CSSProperties;
  placeholder: string;
  className?: string;
  initValue?: string;
  levelsSelected?: string[];
};

const AppSelectGroupInscription: FC<Props> = ({
  onChange,
  onClick,
  onClear,
  style,
  placeholder,
  className,
  initValue,
  levelsSelected = [],
}) => {
  //!HOOKS
  const { data: levelsData, isLoading, isError } = useGetAllLevelsQuery("");
  const { user } = useSelector((state) => state.auth);

  const allLevels = levelsData?.data;

  return (
    <Select
      placeholder={placeholder}
      style={style}
      className={className}
      onChange={onChange}
      onClick={onClick}
      bordered={false}
      loading={isLoading}
      defaultValue={user?.levelId || initValue}
    >
      {Object.keys(allLevels || {}).map((levelGroup) => (
        <OptGroup key={levelGroup} label={levelGroup}>
          {allLevels[levelGroup]?.map((level: any) => (
            <Option key={level._id} value={level._id}>
              {level.title}
            </Option>
          ))}
        </OptGroup>
      ))}
    </Select>
  );
};

export default AppSelectGroupInscription;
