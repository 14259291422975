import { Col, Drawer, message, Popconfirm, Row } from "antd";
import React, { useEffect, useMemo, useState } from "react";

//!COMPONENTS
import H2 from "components/libs/subtitle";

//!ASSETS
import AlbertHappy from "assets/images/albert/status/Very happy.svg";
import ArrowBack from "assets/images/app/arrow_back.svg";
import Student from "assets/images/students/student.svg";

//!STYLES
import { FLAGS } from "common/enums";
import AppButton from "components/libs/button";
import H1 from "components/libs/title";
import ZoomMeeting from "components/zoom";
import { useDispatch, useSelector } from "react-redux";
import {
  setChatReceiverId,
  setSelectUserChat,
  toggleAppChat
} from "store/AppChat/appChat";
import { INIT_MODAL } from "store/notification";
import { useFindRoomMutation } from "store/services/chat";
import s from "./style.module.css";

type Props = {
  currentStudentAccepted: any;
};

const DrawerInstaProfStudentAccepted: React.FC<Props> = ({
  currentStudentAccepted,
}) => {
  //!HOOKS
  const dispatch = useDispatch();
  const { currentStudentInstaProf, user } = useSelector((state) => state.auth);
  const { openModalTeacherAccepted } = useSelector(
    (state) => state.notifications
  );
  const [findRoom, { isSuccess: isSuccessRooms }] = useFindRoomMutation();

  //!STATE
  const [state, setstate] = useState<boolean>(true);

  /* const handleClose = () => {
    dispatch(INIT_MODAL());
  }; */

  const [confirmVisible, setConfirmVisible] = useState(false);

  const handleClose = () => {
    setConfirmVisible(true);
  };

  const handleQuit = () => {
    dispatch(INIT_MODAL());
    setConfirmVisible(false);
  };

  const handleStay = () => {
    setConfirmVisible(false);
  };

  const handleDisplayTeacher = (idTeacher: string) => {
    const finalData = {
      actor1: user?._id,
      actor2: idTeacher,
      flag: FLAGS.SINGLE_FLAG,
      type: "",
      instaprof: "",
    };

    findRoom(finalData);
    dispatch(setChatReceiverId(idTeacher));
    /* handleClose(); */
  };

  const [isZoomMeetingVisible, setIsZoomMeetingVisible] = useState(false);
  const [currentMeetingId, setCurrentMeetingId] = useState('');

  const handleMeetingClick = () => {
    if (!currentStudentAccepted?.profLink) {
      message.error("Lien de réunion non trouvé pour ce cours");
      return;
    }

    dispatch(INIT_MODAL());
    setConfirmVisible(false);

    // Extract meeting ID from Zoom link - handle multiple URL formats
    let meetingId;
    try {
      const url = new URL(currentStudentAccepted?.profLink);
      // Handle different Zoom URL formats
      if (url.pathname.includes('/j/')) {
        meetingId = url.pathname.split('/j/')[1]?.split('?')[0];
      } else if (url.pathname.includes('/s/')) {
        meetingId = url.pathname.split('/s/')[1]?.split('?')[0];
      } else {
        // Try to find any number sequence that could be a meeting ID
        meetingId = url.pathname.match(/\d{10,11}/)?.[0];
      }

      // Remove any non-numeric characters
      meetingId = meetingId?.replace(/\D/g, '');

      if (!meetingId || meetingId.length < 10) { // Zoom meeting IDs are typically 10-11 digits
        throw new Error('Invalid meeting ID format');
      }

      setCurrentMeetingId(meetingId);
      setIsZoomMeetingVisible(true);
    } catch (error) {
      message.error("ID de réunion invalide");
      return;
    }
  };

  useEffect(() => {
    if (isSuccessRooms) {
      dispatch(setSelectUserChat(true));
      dispatch(toggleAppChat(true));
    }
  }, [isSuccessRooms]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 767);
  };
  window.addEventListener('resize', handleResize);

  return useMemo(() => {
    return (
      <Drawer
        className="drawer"
        maskClosable={false}
        onClose={handleClose}
        visible={openModalTeacherAccepted}
        width={isMobile ? "100%" : "70%"}
        title={false}
        style={{ overflowX: "hidden" }}
        headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: isMobile ? "20px" : "40px", backgroundColor: "transparent" }}
        closeIcon={
          <img
            src={ArrowBack}
            height={isMobile ? 10 : 18}
            style={{}}
          ></img>
        }
        drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: isMobile ? "0px" : "30px", borderBottomLeftRadius: isMobile ? "0px" : "30px", boxShadow: "-3px 3px 34px #39393929" }}
      >
        {/* Popconfirm */}
        <Popconfirm
          title="Attention, la fermeture de cette fenêtre interrompra la session Instaprof en cours. Veux-tu fermer ?"
          visible={confirmVisible}
          onConfirm={handleQuit}
          onCancel={handleStay}
          okText="Oui"
          cancelText="Non"
        />
        <div className={s.content}>
          {/* <AppConfetti /> */}
          <Row
            justify="center"
            align="middle"
            gutter={12}
            style={{ marginBottom: "1.5em" }}
          >
            <Col>
              <div className={s.avatar}>
                <img
                  src={
                    currentStudentInstaProf?.data?.student?.profile || Student
                  }
                  alt=""
                />
              </div>
            </Col>
            <Col>
              <H1>
                {currentStudentInstaProf?.data?.student?.firstname} vous a
                CHOISI !
              </H1>
            </Col>
          </Row>

          <Row
            justify="center"
            align="middle"
            gutter={12}
            style={{ marginBottom: "1em" }}
          >
            <Col>
              <div className={s.albert}>
                <img src={AlbertHappy} alt="" width={"55%"} />
              </div>
            </Col>
          </Row>

          <Row
            justify="center"
            align="middle"
            gutter={12}
            style={{ marginBottom: "0.5em" }}
          >
            <Col>
              <H2>Session Instaprof confirmée</H2>
            </Col>
          </Row>
          <Row
            justify="center"
            align="middle"
            gutter={12}
            style={{ marginBottom: "1.3em" }}
          >
            <Col>
              <AppButton onClick={handleMeetingClick}>Rejoindre la visioconférence</AppButton>
            </Col>
          </Row>

          <Row justify="center" align="middle" gutter={12}>
            <Col>
              <span
                onClick={() => handleDisplayTeacher(currentStudentInstaProf?.data?.student?._id)}
                className={s.msg}
              >
                ENVOYER UN MESSAGE
              </span>
            </Col>
          </Row>
        </div>

        {isZoomMeetingVisible && (
          <ZoomMeeting
            meetingNumber={currentMeetingId}
            userName={user?.firstname + " " + user?.lastname}
            sdkKey={process.env.REACT_APP_ZOOM_SDK_KEY ?? ""}
            sdkSecret={process.env.REACT_APP_ZOOM_SDK_SECRET ?? ""}
            onClose={() => {
              setIsZoomMeetingVisible(false);
            }}
          />
        )}
      </Drawer>
    );
  }, [openModalTeacherAccepted, handleClose, currentStudentAccepted]);
};

export default DrawerInstaProfStudentAccepted;
