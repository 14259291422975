import { Button, Popconfirm, Spin, Tooltip } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import React, { FC, Fragment, useEffect, useState } from "react";

import "katex/dist/katex.min.css";
import Latex from "react-latex-next";

//IMAGES
import EnglishIcon from "assets/images/students/englishIcon.svg";
import FrenchIcon from "assets/images/students/frenchIcon.svg";
import StartIcon from "assets/images/students/teacherStart.svg";
import TEACHER from "../../../../assets/images/teacher/teacher.svg";


//!STYLES
import { FLAGS, StatusCode } from "common/enums";
import { useDispatch, useSelector } from "react-redux";
import { setIsDrawerInstaProf } from "store/chat/roomSlice";
import s from "./assets/cardTeacher.module.css";

import * as appRoutes from "config/routes.config";

import { Link } from "react-router-dom";
import { setIdTeacher, setUserConnect } from "store/auth/authSlice";
import { openPaiementDrawer } from "store/chat/mainSlice";
import { handleClearTopic } from "utils/formats";

import CertificationBadge from "components/certificationBadge";
import {
  setChatReceiverId,
  setSelectUserChat,
  toggleAppChat
} from "store/AppChat/appChat";
import { useFindRoomMutation } from "store/services/chat";
import IconTopic from "./iconTopic";

interface CardAdsTeacher {
  ad: any;
  PlanningIsVisble?: boolean;
  isInstaProf?: boolean;
  instaProfData?: any;
  infoTeacherAcceptedReservation?: any;
  currentProfAccepted?: any;
  setCurrentProfAccepted?: any;
  isBtnInstaProf: boolean;
  resetDrawerInstaProf?: () => void;
  handleStudentAcceptTeacher?: any;
  showDrawerSearchInstaProf: () => void;
  handleDisplayTeacher: (id: string) => void;
  onShowDrawerCalendarProf: () => void;
  handleOpenDrwerProfileTeacher: () => void;
  setIsBtnInstaProf: React.Dispatch<React.SetStateAction<boolean>>;
  teacherAdded?: boolean;
}

const CardAdsTeacher: FC<CardAdsTeacher> = ({
  ad,
  PlanningIsVisble = true,
  isInstaProf,
  handleStudentAcceptTeacher,
  setCurrentProfAccepted,
  onShowDrawerCalendarProf,
  setIsBtnInstaProf,
  handleDisplayTeacher,
  handleOpenDrwerProfileTeacher,
  showDrawerSearchInstaProf,
  teacherAdded,
}) => {
  //!HOOKS
  const { user, isLoggedIn } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [findRoom, { isSuccess }] = useFindRoomMutation();

  const closeDrawerPaiement = () => {
    dispatch(openPaiementDrawer());
  };

  const day = new Date();
  const handleDisplayTeacherMSG = (idTeacher: string) => {
    if (isInstaProf) {
      dispatch(setIsDrawerInstaProf(true));
    }
    const finalData = {
      actor1: user?._id,
      actor2: idTeacher,
      flag: FLAGS.SINGLE_FLAG,
      type: `${isInstaProf ? "instaprof" : ""}`,
      instaprof: `${isInstaProf ? "find" : ""}`,
    };

    findRoom(finalData);
    dispatch(setChatReceiverId(idTeacher));
  };

  ///Teachers
  const [isAdded, setIsAdded] = useState(teacherAdded ? true : false);
  const [loading, setLoading] = useState<boolean>(false);

  const setTeacher = async (idTeacher: string) => {
    setLoading(true);
    if (!isAdded) {
      try {
        const res = await AUTH_SERVICES.suggestTeacher(user?._id, idTeacher);
        if (res?.statusCode === StatusCode.CREATED) {
          setIsAdded(true);
        }
      }
      catch (error) { }
      finally {
        setLoading(false);
      }
    }
    else {
      try {
        const res = await AUTH_SERVICES.cancelSuggestTeacher(user?._id, idTeacher);
        if (res?.statusCode === StatusCode.CREATED) {
          setIsAdded(false);
        }
      }
      catch (error) { }
      finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(setSelectUserChat(true));
      dispatch(toggleAppChat(true));
    }
  }, [isSuccess]);

  return (
    <React.Fragment>
      <div className={`${s.card} ${!isInstaProf && `square`} `}>
        <div className={s.content}>
          <div className={s.card__header}>
            <div className={s.round__user}>
              <img
                src={ad?.teacher?.profile || TEACHER}
              // width={65}
              />
            </div>
            <div style={{ textAlign: "left" }}>
              <Tooltip
                title={
                  <span style={{ textTransform: "capitalize" }}>
                    {ad?.teacher?.firstname + " " + ad?.teacher?.lastname ||
                      "-"}
                  </span>
                }
              >
                <h3>
                  {ad?.teacher?.firstname?.split(" ")[0] || "-"}{" "}
                  {ad?.teacher?.lastname?.split(" ")[0] || "-"}
                  {ad?.teacher?.certified && <CertificationBadge />}
                </h3>
              </Tooltip>
              <div
                style={{
                  fontFamily: "var(--title-font)",
                  textTransform: "uppercase",
                  lineHeight: "12px",
                  marginTop: "3px",
                }}
              >
                <span>
                  {ad?.prof?.points > 0 &&
                    ad?.prof?.points <= 1000 &&
                    "C'est mon ami"}
                  {ad?.prof?.points > 1000 &&
                    ad?.prof?.points <= 5000 &&
                    "C'est mon grand ami"}
                  {ad?.prof?.points > 5000 &&
                    ad?.prof?.points <= 10000 &&
                    "C'est mon vieil ami"}
                  {ad?.prof?.points > 10000 && "Tu es mon meilleur ami"}
                </span>
              </div>
              <div>
                <span
                  style={{
                    color: "var(--title-color)",
                    fontSize: "12px",
                  }}
                >
                  <strong
                    style={{
                      fontWeight: "500",
                    }}
                  >
                    {ad?.teacher?.rate && ad?.teacher?.rate !== 0 && Number(ad?.teacher?.rate).toFixed(2)}
                    {ad?.teacher?.rate && ad?.teacher?.rate !== 0 && <img src={StartIcon} style={{ position: "relative", top: "-2px", marginLeft: "5px", marginRight: "5px" }} />}
                  </strong>
                  {ad?.teacher?.rateNum && ad?.teacher?.rateNum !== 0 && `(${ad?.teacher?.rateNum || 0} avis)`}
                </span>
              </div>
              <div>
                <Link
                  to={`${isInstaProf
                    ? "#"
                    : isLoggedIn
                      ? `${appRoutes.PROFILETEACHER}/${ad?.teacher?._id}/${ad?.teacher?.firstname ? ad?.teacher?.firstname : "nom"}.${ad?.teacher?.lastname ? ad?.teacher?.lastname : "complet"}`
                      : "#"
                    }`}
                >
                  <Button
                    type="primary"
                    size="small"
                    block
                    className={s.btn__profil__prof}
                    onClick={() => {
                      if (isLoggedIn) {
                        dispatch(setIdTeacher(ad?._id));
                        isInstaProf && handleOpenDrwerProfileTeacher();
                      } else {
                        dispatch(setUserConnect(true));
                      }
                    }}
                    style={{
                      width: "120px",
                      border: "none",
                      textTransform: "uppercase",
                      fontSize: "11px",
                      borderRadius: "10px",
                      marginTop: "5px"
                    }}
                  >
                    Voir mon PRofil
                  </Button>
                </Link>
              </div>
              <div>
                {!isInstaProf && (
                  <>
                    <span style={{ color: "var(--title-color)" }}>
                      Je parle
                    </span>{" "}
                    {ad?.language?.includes("en") && (
                      <>
                        <img src={EnglishIcon} alt="" />{" "}
                      </>
                    )}
                    {ad?.language?.includes("fr") && (
                      <img src={FrenchIcon} alt="" />
                    )}
                  </>
                )}

                {isInstaProf && (
                  <Fragment>
                    <span style={{ color: "var(--title-color)" }}>
                      Je parle
                    </span>{" "}
                    {ad?.prof?.langues?.includes("en") && (
                      <>
                        <img src={EnglishIcon} alt="" />{" "}
                      </>
                    )}
                    {ad?.prof?.langues?.includes("fr") && (
                      <img src={FrenchIcon} alt="" />
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </div>

          <div className={s.tarif}>
            <span>Mes cours à partir de</span>{" "}
            {isInstaProf ? ad?.studentCost : ad?.minPrice}€/séance
          </div>

          <div className={s.__matiers}>
            {handleClearTopic(ad?.topic)?.map((item: any) => (
              <div className={s.__tag__topic}>
                <IconTopic data={item} />
                {item?.title?.substring(0, 4)}
              </div>
            ))}
          </div>

          <div className={s.levelGroup}>
            {ad?.teacher?.groupLevels?.length > 0 ? <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
              {ad?.teacher?.groupLevels?.map((level: any, index: number) => {
                return (
                  <>
                    <div style={{ marginRight: "5px" }} key={index}>{level?.levelGroup}</div>
                    <div style={{ marginRight: "5px" }}> - </div>
                  </>
                );
              })}
            </div> : <span>Non renseigné</span>}
          </div>

          <div className={s.__description}>
            <Latex>
              {ad?.teacher?.career
                ? ad?.teacher?.career?.substring(0, 300)?.concat("...")
                : "Aucune description disponible"}
            </Latex>
          </div>

          <div className={s.__row}>
            <Popconfirm
              title={isAdded ? "Souhaitez-vous retirer ce professeur de votre liste de favoris ?" : "Souhaitez-vous ajouter ce professeur à votre liste de favoris ?"}
              onConfirm={async () => { setTeacher(ad?.teacher?._id) }}
              onCancel={() => { }}
              okText="Oui"
              cancelText="Non"
            >
              {!loading && <Button onClick={() => { }} style={{ background: isAdded ? "red" : "#80B1B2" }}>
                {isAdded ? "Retirer de ma liste" : "Ajouter à ma liste"}
              </Button>}
              <Spin spinning={loading} size="large" />
            </Popconfirm>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CardAdsTeacher;
