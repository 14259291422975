import {
  ArrowLeftOutlined,
  LoadingOutlined,
  SearchOutlined,
  UsergroupAddOutlined
} from "@ant-design/icons";
import { Avatar, Badge, Col, Input, message, Popconfirm, Popover, Result, Spin } from "antd";
import * as AUTH_SERVICES from "api/services/auth/auth.services";
import React, { FC, useEffect, useRef, useState } from "react";
import Linkify from "react-linkify";
import { useDispatch, useSelector } from "react-redux";

//!ASSETS
import TeacherIcon from "assets/images/teacher/teacher.svg";
import { ReactComponent as Albert } from "../../../../assets/images/albert/albert_empty_conversation.svg";
import { ReactComponent as AlbertSearch } from "../../../../assets/images/albert/albert_insta_prof.svg";

import PlanningButtonIcon from "../../../../assets/images/app/calendar_icon.svg";
import MoreOptions from "../../../../assets/images/app/chat_options.svg";
import InstaIcon from "../../../../assets/images/app/insta_icon.svg";
import NoSeenIcon from "../../../../assets/images/app/no_seen_icon.svg";
import ReportIcon from "../../../../assets/images/app/report_icon.png";
import SeenIcon from "../../../../assets/images/app/seen_icon.svg";
import UserBlockIcon from "../../../../assets/images/app/user_lock_icon.svg";

import moment from "moment";
import { setCurrentChat } from "store/AppChat/appChat";

//!STYLE
import { API_RESSOURCES } from "api/services/teacher/teacher.routes";
import axios from "axios";
import { StatusCode } from "common/enums";
import TeacherCalendarForStudent from "screens/student/components/calendarTeacher";
import DrawerSearchInstaProf from "screens/student/searchTeacher/drawerSearchInstaProf";
import { displayReportDrawer, setReportedUserData, setReportingUserData } from "store/adventissements";
import s from "./assets/style/chat.module.css";

type Props = {
  conversation: any;
  isTyping: boolean;
  isBlocked: boolean;
  setIsBlocked: React.Dispatch<any>;
};

const ChatSpace: FC<Props> = ({ conversation, isTyping, isBlocked, setIsBlocked }) => {
  //!HOOKS
  const dispatch = useDispatch();
  const { idSender, idReceiver, currentChat, startChat, socket, talkId } = useSelector(
    (state) => state.appChat
  );
  const { user } = useSelector((state) => state.auth);
  const { isChatInstaProf } = useSelector((state) => state.room);
  const refChat = useRef<any>();

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1050);
  const handleResize = () => {
    setIsMobile(window.innerWidth <= 1050);
  };
  window.addEventListener('resize', handleResize);

  const [openDrawerInstaProf, setOpenDrawerInstaProf] = useState<boolean>(false);
  const [showDrawerTeacherCalendar, setShowDrawerTeacherCalendar] = useState<boolean>(false);
  const [teacherData, setTeacherData] = useState<any>();
  const [teacherID, setTeacherID] = useState<any>();
  const [blockerId, setBlockerId] = useState<any>();

  const popoverReportRef = useRef<HTMLDivElement>(null);
  const [isUserOnline, setIsUserOnline] = useState<boolean>(false);

  const [isBtnInstaProf, setIsBtnInstaProf] = useState<boolean>(true);
  const showDrawerSearchInstaProf = () => {
    setOpenDrawerInstaProf(!openDrawerInstaProf);
  };

  const onShowDrawerTeacherCalendar = () => {
    setShowDrawerTeacherCalendar(true);
  };

  const getTeacherData = async (teacherId: string) => {
    try {
      const response = await AUTH_SERVICES.getTeacherData(user?._id, teacherId);
      setTeacherData(response?.data);
    } catch (error: any) { }
  };

  const [isBlocking, setIsBlocking] = useState<boolean>(false);

  const [isSearching, setIsSearching] = useState(false);
  const [visibleBlock, setVisibleBlock] = useState(false);
  const [visibleReport, setVisibleReport] = useState(false);
  const [visibleOptions, setVisibleOptions] = useState(false);
  const [filteredMessages, setFilteredMessages] = useState([]);

  const showPopconfirmReport = () => {
    setVisibleReport(true);
    setVisibleOptions(false);
  };

  const showPopconfirmBlock = () => {
    setVisibleBlock(true);
    setVisibleOptions(false);
  };

  const showReportDrawer = () => {
    const reportingUser = {
      "id": user?._id,
      "name": user?.firstname + " " + user?.lastname,
      "role": user?.roles[0]
    };

    const reportedUser = {
      "id": user?._id === currentChat?.senderId?._id ? currentChat?.receiverId?._id : currentChat?.senderId?._id,
      "name": user?._id === currentChat?.senderId?._id ? currentChat?.receiverId?.firstname + " " + currentChat?.receiverId?.lastname : currentChat?.senderId?.firstname + " " + currentChat?.senderId?.lastname,
      "role": user?._id === currentChat?.senderId?._id ? currentChat?.receiverId?.roles[0] : currentChat?.senderId?.roles[0]
    };
    dispatch(setReportingUserData(reportingUser));
    dispatch(setReportedUserData(reportedUser));
    setVisibleReport(false);
    dispatch(displayReportDrawer(true));
  };

  const content = (
    <div onClick={(e) => e.stopPropagation()}>
      {(user?._id === currentChat?.senderId?._id && currentChat?.receiverId?.roles[0] === "teacher" || user?._id === currentChat?.receiverId?._id && currentChat?.senderId?.roles[0] === "teacher") && <div onClick={onShowDrawerTeacherCalendar} style={{ fontSize: isMobile ? "0.8em" : "13px", color: "#393E3E", cursor: "pointer" }}><img src={PlanningButtonIcon} height={18} ></img> Calendrier</div>}
      {(user?._id === currentChat?.senderId?._id && currentChat?.receiverId?.roles[0] === "teacher" || user?._id === currentChat?.receiverId?._id && currentChat?.senderId?.roles[0] === "teacher") && <div style={{ height: "1px", width: "90%", margin: "0px auto", background: "#BFBCBC", marginTop: "5px", marginBottom: "5px" }}></div>}
      {(user?._id === currentChat?.senderId?._id && currentChat?.receiverId?.roles[0] === "teacher" || user?._id === currentChat?.receiverId?._id && currentChat?.senderId?.roles[0] === "teacher") && <div onClick={showDrawerSearchInstaProf} style={{ fontSize: isMobile ? "0.8em" : "13px", color: "#393E3E", cursor: "pointer" }}><img src={InstaIcon} height={20} ></img> InstaProf</div>}
      {(user?._id === currentChat?.senderId?._id && currentChat?.receiverId?.roles[0] === "teacher" || user?._id === currentChat?.receiverId?._id && currentChat?.senderId?.roles[0] === "teacher") && <div style={{ height: "1px", width: "90%", margin: "0px auto", background: "#BFBCBC", marginTop: "5px", marginBottom: "5px" }}></div>}
      <div onClick={showPopconfirmReport} style={{ fontSize: isMobile ? "0.8em" : "13px", color: "#393E3E", cursor: "pointer" }}><img src={ReportIcon} height={20} ></img> Signaler un abus</div>
      <div style={{ height: "1px", width: "90%", margin: "0px auto", background: "#BFBCBC", marginTop: "5px", marginBottom: "5px" }}></div>
      {!isBlocked && <div onClick={showPopconfirmBlock} style={{ fontSize: isMobile ? "0.8em" : "13px", color: "#F56666", cursor: "pointer" }}><img src={UserBlockIcon} height={12} style={{ marginRight: "5px" }}></img>{"Bloquer"}</div>}
      {isBlocked && blockerId === user?._id && <div onClick={showPopconfirmBlock} style={{ fontSize: isMobile ? "0.8em" : "13px", color: "#F56666", cursor: "pointer" }}><img src={UserBlockIcon} height={12} style={{ marginRight: "5px" }}></img>{"Débloquer"}</div>}
    </div>
  );

  const handleCancelBlock = () => {
    setVisibleBlock(false);
  };

  const handleCancelReport = () => {
    setVisibleReport(false);
  };

  const checkBlocking = async () => {
    try {
      const myId = user._id;
      const userId = user?._id === currentChat?.senderId?._id ? currentChat?.receiverId?._id : currentChat?.senderId?._id;
      const response = await axios.get(`${API_RESSOURCES}/abuse-reports/${myId}/blocked/${userId}`);
      if (response.status === StatusCode.OK) {
        const payloadData = response?.data?.data;
        if (payloadData?.isBlocked) {
          setIsBlocked(true);
          setBlockerId(payloadData?.blockerId);
        }
        else {
          setIsBlocked(false);
        }
      }
    } catch (error) { }
  }

  const blockUser = async (values: any) => {
    try {
      setVisibleBlock(false);
      setIsBlocking(true);
      const myId = user._id;
      const userId = user?._id === currentChat?.senderId?._id ? currentChat?.receiverId?._id : currentChat?.senderId?._id;
      const response = await axios.post(`${API_RESSOURCES}/abuse-reports/block`, { myId, userId });
      if (response.status === StatusCode.CREATED) {
        message.success("Utilisateur bloqué avec succès");
        setIsBlocked(true);
        sendBlocking();
      }
    } catch (error) { }
    finally {
      setIsBlocking(false);
    }
  }

  const unblockUser = async (values: any) => {
    try {
      setVisibleBlock(false);
      setIsBlocking(true);
      const myId = user._id;
      const userId = user?._id === currentChat?.senderId?._id ? currentChat?.receiverId?._id : currentChat?.senderId?._id;
      const response = await axios.put(`${API_RESSOURCES}/abuse-reports/${myId}/unblock/${userId}`);
      if (response.status === StatusCode.OK) {
        message.success("Utilisateur débloqué avec succès");
        setIsBlocked(false);
        sendUnblocking();
      }
    } catch (error) { }
    finally {
      setIsBlocking(false);
    }
  }

  const sendBlocking = async () => {
    socket?.connect();
    await socket.emit("userBlock", talkId);
  };

  const sendUnblocking = async () => {
    socket?.connect();
    await socket.emit("userUnblock", talkId);
  };

  const handleClickOutside = (event: MouseEvent) => {
    setTimeout(() => {
      if (popoverReportRef.current && !popoverReportRef.current.contains(event.target as Node)) {
        if (visibleReport || visibleBlock) {
          console.log("Action selected!");
        } else {
          setVisibleOptions(false);
        }
      }
    }, 200);
  };

  const handleMessageSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.toLowerCase();
    if (value.length >= 3) {
      setIsSearching(true);
      // Filter messages based on input
      const filtered = conversation.filter((chat: any) =>
        chat.message.toLowerCase().includes(value)
      );
      setFilteredMessages(filtered);
    }
    else {
      setIsSearching(false);
      setFilteredMessages([]);
    }
  }

  const handleCancelSearch = () => {
    setIsSearching(false);
    setFilteredMessages([]);
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [visibleReport, visibleBlock]);

  useEffect(() => {
    refChat?.current?.scrollIntoView({
      behavior: "smooth",
    });
    if (currentChat?.receiverId?.roles[0] === "teacher") {
      setTeacherID(currentChat?.receiverId?._id);
      getTeacherData(currentChat?.receiverId?._id);
    }
  }, [conversation, isTyping, currentChat]);

  useEffect(() => {
    checkBlocking();
  }, [conversation, currentChat]);

  useEffect(() => {
    socket?.on("userBlocked", async (msgResponse: any) => {
      checkBlocking();
    });
    socket?.on("userUnblocked", async (msgResponse: any) => {
      checkBlocking();
    });
  }, [socket]);

  return (
    <React.Fragment>
      <div className={`${s.chat__space} ${currentChat?.instaProf && s.isInsta}`}>
        {startChat && <div className={s.header}>
          <div
            className={s.back}
            onClick={() => dispatch(setCurrentChat(null))}
          >
            <ArrowLeftOutlined style={{ fontSize: "1em" }} />
          </div>
          <span>
            <React.Fragment>
              {currentChat?.receiverIdC ? (
                <span>
                  {currentChat?.senderId?.firstname}{" "}
                  {currentChat?.senderId?.lastname}
                </span>
              ) : (
                <React.Fragment>
                  {!isMobile ? <div className={s.receiverDetails}>
                    {/* Avatar And Name */}
                    <div className={s.receiverNameAvatarBox}>
                      <Avatar
                        size={45}
                        style={{
                          height: "45px",
                          width: "45px"
                        }}
                        src={
                          currentChat?.receiverIdC ? (
                            <UsergroupAddOutlined
                              style={{
                                color: "var(--first-color)",
                                fontSize: "26px",
                              }}
                            />
                          ) : user?._id === currentChat?.receiverId?._id ? (
                            currentChat?.senderId?.profile || TeacherIcon
                          ) : (
                            currentChat?.receiverId?.profile || TeacherIcon
                          )
                        }
                      />
                      <div className={s.nameStatusBox}>
                        {user?._id === currentChat?.receiverId?._id ? currentChat?.senderId?.firstname?.split(" ")[0] : currentChat?.receiverId?.firstname?.split(" ")[0]}
                        <div className={isUserOnline ? s.activeUser : s.inactiveUser} style={{ visibility: "hidden" }}>{isUserOnline ? <><Badge color="lime" style={{ position: "relative", bottom: "3.5px" }} /> Actif</> : <><Badge status="error" style={{ position: "relative", bottom: "3.5px" }} /> Inactif</>}</div>
                      </div>
                    </div>
                    {/* Search Box Buttons */}
                    <div className={s.searchBoxButtons}>
                      <div className={s.searchBox}>
                        <Input
                          onChange={handleMessageSearch}
                          placeholder="Chercher un message"
                          style={{ width: "100%" }}
                          bordered={false}
                          allowClear={true}
                          onReset={handleCancelSearch}
                        />
                        <SearchOutlined style={{ fontSize: "15px" }} />
                      </div>
                      <Popover
                        visible={visibleOptions}
                        placement="left"
                        content={content}
                        trigger="click"
                        style={{ height: "70px !important", borderRadius: "8px", boxShadow: "4px 8px 16px #8B8B8B38", border: "0.5px solid #BFBCBC" }}
                        className={s.customPopover}
                        overlayClassName={s.customPopover}
                      >
                        <div ref={popoverReportRef} className={s.optionButton} onClick={(e: any) => { e.stopPropagation(); setVisibleOptions(!visibleOptions); }}><img src={MoreOptions} height={17}></img></div>
                      </Popover>
                      <Popconfirm
                        placement="top"
                        title={
                          "Confirmer l'action ?"
                        }
                        onConfirm={isBlocked ? unblockUser : blockUser}
                        visible={visibleBlock}
                        okText={isBlocking ? <Spin size="small" /> : "Oui"}
                        cancelText="Non"
                        onCancel={handleCancelBlock}
                        okButtonProps={{ disabled: isBlocking }}
                      >
                      </Popconfirm>

                      <Popconfirm
                        placement="top"
                        title={
                          "Confirmer l'action ?"
                        }
                        onConfirm={showReportDrawer}
                        visible={visibleReport}
                        okText={"Oui"}
                        cancelText="Non"
                        onCancel={handleCancelReport}
                      >
                      </Popconfirm>
                    </div>
                  </div> : <div className={s.receiverDetails}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", width: "90%", marginLeft: "10%" }}>
                      {/* Avatar And Name */}
                      <div className={s.receiverNameAvatarBox}>
                        <Avatar
                          size={35}
                          style={{
                            height: "35px",
                            width: "35px"
                          }}
                          src={
                            currentChat?.receiverIdC ? (
                              <UsergroupAddOutlined
                                style={{
                                  color: "var(--first-color)",
                                  fontSize: "26px",
                                }}
                              />
                            ) : user?._id === currentChat?.receiverId?._id ? (
                              currentChat?.senderId?.profile || TeacherIcon
                            ) : (
                              currentChat?.receiverId?.profile || TeacherIcon
                            )
                          }
                        />
                        <div className={s.nameStatusBox}>
                          {user?._id === currentChat?.receiverId?._id ? currentChat?.senderId?.firstname?.split(" ")[0] : currentChat?.receiverId?.firstname?.split(" ")[0]}
                          <div className={isUserOnline ? s.activeUser : s.inactiveUser} style={{ visibility: "hidden" }}>{isUserOnline ? <><Badge color="lime" style={{ position: "relative", bottom: "6px" }} /> Actif</> : <><Badge status="error" style={{ position: "relative", bottom: "6px" }} /> Inactif</>}</div>
                        </div>
                      </div>
                      <div className={s.searchBoxButtons}>
                        <Popover
                          visible={visibleOptions}
                          placement="left"
                          content={content}
                          trigger="click"
                          style={{ height: "70px !important", borderRadius: "8px", boxShadow: "4px 8px 16px #8B8B8B38", border: "0.5px solid #BFBCBC" }}
                          className={s.customPopover}
                          overlayClassName={s.customPopover}
                        >
                          <div ref={popoverReportRef} className={s.optionButton} onClick={(e: any) => { e.stopPropagation(); setVisibleOptions(!visibleOptions); }}><img src={MoreOptions} height={17}></img></div>
                        </Popover>
                      </div>
                    </div>
                    <div className={s.searchBox}>
                      <Input
                        className={s.searchField}
                        onChange={handleMessageSearch}
                        placeholder="Chercher un message"
                        style={{ width: "100%", fontSize: "0.8em" }}
                        bordered={false}
                        allowClear={true}
                        onReset={handleCancelSearch}
                      />
                      <SearchOutlined style={{ fontSize: "15px" }} />
                    </div>
                  </div>}
                </React.Fragment>
              )}
            </React.Fragment>
          </span>
        </div>}
        {isSearching && <div className={s.searchAnswersBox}>
          {filteredMessages && filteredMessages.length > 0 ? (
            filteredMessages.map((chat: any, index: number) => (
              <div key={index} className={`${s.chat__msg}`}>
                <div className={s.chat__msg__profile}>
                  <img
                    className={s.chat__msg__img}
                    src={
                      user?._id === chat?.receiverId?._id
                        ? chat?.senderId?.profile || TeacherIcon
                        : chat?.receiverId?.profile || TeacherIcon
                    }
                    alt=""
                  />
                </div>
                <div className={s.chat__msg__content}>
                  <div className={s.chat__msg__text_search}>
                    <span style={{ color: "var(--first-color)" }}>
                      {chat?.message}
                    </span>
                  </div>
                  <div className={s.chat__msg__date}>
                    {moment(chat?.createdAt).fromNow().replace(/^\w/, (c) => c.toUpperCase())}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", textAlign: "center" }}>
              Aucun messages trouvé
              <Result icon={<AlbertSearch className={s.Albert} style={{ height: "120px" }} />} />
            </div>
          )}
        </div>}
        <div className={s.chat__content}>
          {startChat && conversation.length > 0 ? (
            <React.Fragment>
              {conversation?.map((c: any) => (
                <>
                  <div key={c} ref={refChat}>
                    <div className={`${s.chat__msg} ${user?._id === c?.senderId?._id ? s.owner : ""}`}>
                      <div className={s.chat__msg__profile}>
                        <img
                          className={s.chat__msg__img}
                          src={
                            c?.senderId?.profile
                              ? c?.senderId?.profile
                              : TeacherIcon
                          }
                          alt=""
                        />
                      </div>
                      <div className={s.chat__msg__content}>
                        <div className={s.chat__msg__text}>
                          <Linkify>{c?.message}</Linkify>
                        </div>
                        {!isMobile && <div className={s.chat__msg__date}>
                          {moment(c?.createdAt).fromNow().replace(/^\w/, (c) => c.toUpperCase())}
                          <img src={c?.seen ? SeenIcon : NoSeenIcon} height={isMobile ? 7 : 10} style={{ marginLeft: "5px", marginTop: "5px" }}></img>
                        </div>}
                      </div>
                      {isMobile && <div className={s.chat__msg__date}>
                        {moment(c?.createdAt).fromNow().replace(/^\w/, (c) => c.toUpperCase())}
                        <img src={c?.seen ? SeenIcon : NoSeenIcon} height={isMobile ? 7 : 10} style={{ marginLeft: "5px", marginTop: "5px" }}></img>
                      </div>}
                      {c?.receiverIdC && (
                        <div>
                          <span
                            style={{
                              fontSize: "12px",
                              fontWeight: "500",
                              color: "var(--first-color)",
                            }}
                          >
                            {c.senderId?.lastname} {c.senderId?.firstname}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ))}

              {isTyping && (
                <div className={`${s.chat__msg}`}>
                  <div className={s.chat__msg__profile}>
                    <img
                      className={s.chat__msg__img}
                      src={
                        user?._id === currentChat?.receiverId?._id
                          ? currentChat?.senderId?.profile || TeacherIcon
                          : currentChat?.receiverId?.profile || TeacherIcon
                      }
                      alt=""
                    />
                    <div className={s.chat__msg__date}>
                      <LoadingOutlined />
                    </div>
                  </div>
                  <div className={s.chat__msg__content}>
                    <div className={s.chat__msg__text}>
                      <span style={{ color: "var(--first-color)" }}>
                        En cours d'écrire
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </React.Fragment>
          ) : (
            <React.Fragment>
              {startChat ? (
                <Col>
                  <div className={s.emptyChatTitle}>
                    Hey {user?.roles[0].includes("student") ? "élève " : "prof "} {user?.lastname} !
                    <span className={s.emptyChatSubtitle}>Commences une discussion avec<br /><b>{currentChat?.receiverIdC ? currentChat?.receiverIdC?.title : user?._id === currentChat?.receiverId?._id ? currentChat?.senderId?.firstname?.split(" ")[0] : currentChat?.receiverId?.firstname?.split(" ")[0]}{" "}</b></span>
                  </div>
                  <Result
                    icon={<Albert className={s.Albert} />}
                  />
                </Col>
              ) : (
                <>
                  <div className={s.emptyChatTitle}>
                    Hey {user?.roles[0].includes("student") ? "élève " : "prof "} {user?.lastname} !
                    <span className={s.emptyChatSubtitle}>Choisis une discussion</span>
                  </div>
                  <Result icon={<Albert className={s.Albert} />} />
                </>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
      <TeacherCalendarForStudent
        open={showDrawerTeacherCalendar}
        setOpen={setShowDrawerTeacherCalendar}
        currentTeacher={teacherData}
      />

      <DrawerSearchInstaProf
        openDrawerInstaProf={openDrawerInstaProf}
        showDrawerSearchInstaProf={showDrawerSearchInstaProf}
        isBtnInstaProf={isBtnInstaProf}
        setIsBtnInstaProf={setIsBtnInstaProf}
        dataOfTeacher={teacherData}
      />
    </React.Fragment>
  );
};

export default ChatSpace;
