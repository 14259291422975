import { Avatar, Image } from "antd";
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openProfileDrawer } from 'store/auth/authSlice';
import st from "./style.module.css";

import dollarCoins from "assets/images/app/dollar-coins.png";
import infoPic from "assets/images/app/info-circle.svg";
import schoolPic from "assets/images/app/school.svg";
import starCoins from "assets/images/app/star-coins.png";
import RankingDrawer from "components/rankingDrawer";
import { openPaiementDrawer } from "store/chat/mainSlice";

/* import belgiumFlag from "assets/images/app/belgium-flag.png"; */
/* import frenchFlag from "assets/images/app/french-flag.png"; */
/* import moroccoFlag from "assets/images/app/morocco-flag.png"; */


const UserNavBar: React.FC = () => {

    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);
    const [type, setType] = useState<string>("");

    const openDrawer = () => dispatch(openProfileDrawer());

    const openPaiementStudentDrawer = () => {
        dispatch(openPaiementDrawer());
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
    };
    window.addEventListener('resize', handleResize);

    const [rakingDrawerVisible, setRakingDrawerVisible] = useState(false);
    const showRakingDrawer = (rankType: string) => {
        setType(rankType);
    };

    const onCloseRankingDrawer = () => {
        setType("");
    };

    useEffect(() => {
        if (type !== "") {
            setRakingDrawerVisible(true);
        }
        else {
            setRakingDrawerVisible(false);
        }
    }, [type, rakingDrawerVisible]);

    return (
        <>
            <div className={st.navbar}>
                {!isMobile && <div style={{ height: "auto", width: "120px", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div className={st.avatar} onClick={openDrawer}>
                        <Avatar
                            style={{ backgroundColor: "#FFF" }}
                            size={35}
                            src={
                                <Image
                                    fallback={`http://gravatar.com/avatar${user?.uid}?d=identicon`}
                                    preview={false}
                                    src={
                                        !!user?.profile
                                            ? user?.profile
                                            : `https://gravatar.com/avatar/${user?._id}?d=identicon`
                                    }
                                />
                            }
                        >
                            {""}
                        </Avatar>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", marginLeft: "5px", color: "#FFF", alignItems: "start", justifyContent: "center" }} onClick={openDrawer}>
                        <span>{user?.pseudo.length > 11 ? user?.pseudo.substring(0, 11) + "..." : user?.pseudo}</span>
                        <span style={{ fontSize: "14px", fontWeight: "600" }}>Mon Profil</span>
                    </div>
                </div>}
                <div className={st.userNavInfoBox}>
                    <div className={st.userNavBox}>
                        <div className={st.infoBox}>
                            <div className={st.info} onClick={() => openPaiementStudentDrawer()}>
                                <div className={st.left}><img src={dollarCoins} height={isMobile ? 10 : 15} className={st.leftText}></img> Solde</div>
                                <div className={st.right}>{user?.coins}<span className={st.coinsInfo}>EUR</span></div>
                            </div>
                        </div>
                        <div className={st.infoBox}>
                            <div className={st.info}>
                                <div className={st.left}><img src={starCoins} height={isMobile ? 10 : 15} className={st.leftText}></img> Points</div>
                                <div className={st.right}>{user?.points}<span className={st.coinsInfo}>PTS</span></div>
                            </div>
                        </div>
                    </div>
                    <div className={st.userNavBox}>
                        {user?.rankEtab !== undefined && <div className={st.infoBoxHoverSchool}>
                            <div className={st.info} onClick={() => showRakingDrawer("school")}>
                                <div className={st.left}><img src={schoolPic} height={isMobile ? 10 : 15} className={st.leftText}></img> Ets</div>
                                <div className={st.right}>{user?.rankEtab}<span className={st.classementInfo}>{user?.rankEtab === 1 ? "er" : "e"}</span><img src={infoPic} height={15} className={st.infoPic}></img></div>
                            </div>
                        </div>}
                        {user?.rankNational !== undefined && <div className={st.infoBoxHoverNat}>
                            <div className={st.info} onClick={() => showRakingDrawer("national")}>
                                {/* <div className={st.left}><img src={user?.educSystem === "Système Belge" ? belgiumFlag : user?.educSystem === "Système Français" ? frenchFlag : moroccoFlag} height={isMobile ? 10 : 15} className={st.leftText}></img></div> */}
                                <div className={st.left}><img src={schoolPic} height={isMobile ? 10 : 15} className={st.leftText}></img> Pays</div>
                                <div className={st.right}>{user?.rankNational}<span className={st.classementInfo}>{user?.rankNational === 1 ? "er" : "e"}</span><img src={infoPic} height={15} className={st.infoPic}></img></div>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
            <RankingDrawer visible={rakingDrawerVisible} onClose={onCloseRankingDrawer} user={user} type={type} />
        </>
    );
};

export default UserNavBar;