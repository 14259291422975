import { Button, Checkbox, DatePicker, Drawer, Form, Input, InputNumber, message, Popconfirm, Popover, Select, Spin } from "antd";
import { FC, useEffect, useRef, useState } from "react";


//!STYLE
import { useDispatch, useSelector } from "react-redux";
import s from "./style.module.css";

//!Images
import 'antd/dist/antd.css';
import * as TEACHER_SERVICES from "api/services/teacher/teacher.services";
import { StatusCode } from "common/enums";
import { setCouponDrawer } from "store/auth/authSlice";
import AlbertEmptyData from "../../../../assets/images/albert/albert_empty_data.svg";
import ArrowBack from "../../../../assets/images/app/arrow_back.svg";
import Delete from "../../../../assets/images/app/delete_article.svg";
import Desactivate from "../../../../assets/images/app/desactivate_icon.svg";
import Edit from "../../../../assets/images/app/edit_article.svg";
import MoreOptions from "../../../../assets/images/app/more_options.svg";

const { Option } = Select;

const CouponsDrawer: FC = () => {

    //!HOOKS
    const dispatch = useDispatch();
    const { user, openCouponDrawer, newCouponAdded } = useSelector((state) => state.auth);

    const [teacherCoupons, setTeacherCoupons] = useState<any>([]);
    const [addCoupon, setAddCoupon] = useState<boolean>(false);
    const [form] = Form.useForm();
    const [couponNature, setCouponNature] = useState<any>();
    const [target, setTarget] = useState<any>();
    const [showCoursesNumber, setShowCoursesNumber] = useState<boolean>(false);
    const [showDiscount, setShowDiscount] = useState<boolean>(false);
    const [showStudentEmailInput, setShowStudentEmailInput] = useState<boolean>(false);
    const [sessionNumber, setSessionNumber] = useState<any>();
    const [isUnlimited, setIsUnlimited] = useState(false);

    const handleCloseDrawer = () => {
        setAddCoupon(false);
        dispatch(setCouponDrawer(false));
        form.resetFields();
    }

    const handleFormSubmit = async (values: any) => {
        console.log("Form Values:", values);
        try {
            setLoadingCoupons(true);

            const startingDate = values.validUntil[0]?._d;
            const endingDate = values.validUntil[1]?._d;

            const response = values?.couponType === "ALL" ? await TEACHER_SERVICES.addCoupon(values?.couponType, values.targetUsers, user?._id, startingDate, endingDate, values.unlimitedUse ? 1000 : parseInt(values.numberOfSessions), values.discount ? values.discount : 0, values.unlimitedUse ? 1000 : parseInt(values.numberOfSessions), false) :
                values?.couponType === "MY_STUDENTS" ? await TEACHER_SERVICES.addCoupon(values?.couponType, values.targetUsers, user?._id, startingDate, endingDate, values.unlimitedUse ? 1000 : parseInt(values.numberOfSessions), values.discount ? values.discount : 0, values.unlimitedUse ? 1000 : parseInt(values.numberOfSessions), false) :
                    await TEACHER_SERVICES.addCoupon(values?.couponType, values.targetUsers, user?._id, startingDate, endingDate, values.unlimitedUse ? 1000 : parseInt(values.numberOfSessions), values.discount ? values.discount : 0, values.unlimitedUse ? 1000 : parseInt(values.numberOfSessions), false, values.studentEmail);
            if (response?.statusCode === StatusCode.CREATED) {
                message.success("Coupon ajouté avec succès");
                fetchCoupons();
            }
        } catch (error) { }
        finally {
            setLoadingCoupons(false);
            form.resetFields();
            setAddCoupon(false);
            setShowStudentEmailInput(false);
        }
    };

    const popoverRef = useRef<HTMLDivElement>(null);
    const [visibleIndex, setVisibleIndex] = useState<number | null>(null);
    const [loadingCoupon, setLoadingCoupons] = useState<boolean>(false);
    const [isEditingCoupon, setIsEditingCoupon] = useState<boolean>(false);
    const [isDesactivatingCoupon, setIsDesactivatingCoupon] = useState<boolean>(false);
    const [isDeletingCoupon, setIsDeletingCoupon] = useState<boolean>(false);
    const [visibleEdit, setVisibleEditIndex] = useState<number | null>(null);
    const [visibleDesactivate, setVisibleDesactivateIndex] = useState<number | null>(null);
    const [visibleDelete, setVisibleDeleteIndex] = useState<number | null>(null);
    /* const [visibleOptions, setVisibleOptions] = useState(false); */

    const togglePopover = (index: number) => {
        setVisibleIndex(visibleIndex === index ? null : index);
    };

    const showPopconfirmEdit = () => {
        setVisibleEditIndex(visibleIndex);
        setVisibleIndex(null);
    };

    const showPopconfirmDesactivate = () => {
        setVisibleDesactivateIndex(visibleIndex);
        setVisibleIndex(null);
    };

    const showPopconfirmDelete = () => {
        setVisibleDeleteIndex(visibleIndex);
        setVisibleIndex(null);
    };

    const handleCancelEdit = () => {
        setVisibleEditIndex(null);
    };

    const handleCancelDesactivate = () => {
        setVisibleDesactivateIndex(null);
    };

    const handleCancelDelete = () => {
        setVisibleDeleteIndex(null);
    };

    const editCoupon = () => {
        form.setFieldsValue({
            numberOfSessions: "3",
            discount: 30
        });
        setVisibleEditIndex(null);
        setAddCoupon(true);
    }

    const fetchCoupons = async () => {
        try {
            setLoadingCoupons(true);
            const response = await TEACHER_SERVICES.fetchCoupons(user?._id);
            if (response?.statusCode === StatusCode.OK) {
                setTeacherCoupons(response?.data?.items);
            }
        } catch (error) { }
        finally {
            setLoadingCoupons(false);
        }
    }

    const desactivateCoupon = async (id: string) => {
        try {
            setVisibleDesactivateIndex(null);
            setIsDesactivatingCoupon(true);
            const response = await TEACHER_SERVICES.desactivateCoupon(id);
            if (response.statusCode === StatusCode.NO_CONTENT) {
                message.success("Coupon désactivé avec succès");
            }
        } catch (error) { }
        finally {
            setIsDesactivatingCoupon(false);
            fetchCoupons();
        }
    }

    const deleteCoupon = async (values: any) => {
        try {
            setVisibleDeleteIndex(null);
            setIsDeletingCoupon(true);
        } catch (error) { }
        finally {
            setIsDeletingCoupon(false);
        }
    }

    const content = (
        <div>
            <div onClick={showPopconfirmEdit} style={{ fontSize: "15px", color: "#393E3E", cursor: "pointer", display: "none" }}><img src={Edit} height={15}></img> Modifier</div>
            <div style={{ height: "1px", width: "90%", margin: "0px auto", background: "#BFBCBC", marginTop: "5px", marginBottom: "5px", display: "none" }}></div>
            <div onClick={showPopconfirmDesactivate} style={{ fontSize: "15px", color: "#393E3E", cursor: "pointer", marginTop: "5px", marginBottom: "5px" }}><img src={Desactivate} height={15}></img> Désactiver</div>
            <div style={{ height: "1px", width: "90%", margin: "0px auto", background: "#BFBCBC", marginTop: "5px", marginBottom: "5px", display: "none" }}></div>
            <div onClick={showPopconfirmDelete} style={{ fontSize: "15px", color: "#F56666", cursor: "pointer", marginTop: "5px", marginBottom: "5px", display: "none" }}><img src={Delete} height={15}></img> Supprimer</div>
        </div>
    );

    const handleClickOutside = (event: MouseEvent) => {
        setTimeout(() => {
            if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
                if (visibleDelete || visibleDesactivate || visibleEdit) {
                    console.log("Action selected!");
                } else {
                    setVisibleIndex(null);
                }
            }
        }, 200);
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [visibleDelete, visibleDesactivate, visibleEdit]);

    useEffect(() => {
        console.log("newCouponAdded :", newCouponAdded);
        form.setFieldsValue({
            numberOfSessions: "3",
            discount: 30
        });
    }, [newCouponAdded]);

    useEffect(() => {
        if (couponNature && couponNature === "SESSION_DISCOUNT") {
            setShowCoursesNumber(true);
            setShowDiscount(true);
        }
        else if (couponNature && couponNature === "SPECIAL_DISCOUNT") {
            setShowCoursesNumber(true);
            setShowDiscount(true);
        }
        else {
            setShowCoursesNumber(true);
            setShowDiscount(false);
        }
    }, [couponNature]);

    useEffect(() => {
        if (target === "SPECIFIC_STUDENT") {
            setShowStudentEmailInput(true);
        }
        else {
            setShowStudentEmailInput(false);
        }
    }, [target]);

    useEffect(() => {
        fetchCoupons();
        setCouponNature("FREE_CLASS");
        form.setFieldsValue({
            numberOfSessions: "3",
            discount: 30
        });
    }, [user]);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
    const [isXsScreen, setIsXsScreen] = useState(window.innerWidth > 767 && window.innerWidth <= 1151);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth > 1151 && window.innerWidth <= 1321);
    const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1321 && window.innerWidth <= 1606);
    const [isNormalScreen, setIsNormalScreen] = useState(window.innerWidth > 1606 && window.innerWidth <= 1799);
    const handleResize = () => {
        setIsMobile(window.innerWidth <= 767);
        setIsXsScreen(window.innerWidth > 767 && window.innerWidth <= 1151);
        setIsSmallScreen(window.innerWidth > 1151 && window.innerWidth <= 1321);
        setIsMediumScreen(window.innerWidth > 1321 && window.innerWidth <= 1606);
        setIsNormalScreen(window.innerWidth > 1606 && window.innerWidth <= 1799);
    };
    window.addEventListener('resize', handleResize);

    return (
        <Drawer
            className="drawer"
            onClose={handleCloseDrawer}
            visible={openCouponDrawer}
            width={isMobile ? "100%" : isXsScreen ? "100%" : isSmallScreen ? "65%" : isMediumScreen ? "65%" : isNormalScreen ? "45%" : "45%"}
            title={<div style={{ fontSize: isMobile ? "0.8em" : "20px", fontFamily: "Poppins", fontWeight: "600", color: "#393E3E", backgroundColor: "transparent" }}>{<h3>Mes coupons</h3>}</div>}
            headerStyle={{ border: "none", paddingTop: "50px", paddingLeft: isMobile ? "20px" : "40px", backgroundColor: "transparent" }}
            closeIcon={
                <img
                    src={ArrowBack}
                    height={isMobile ? 10 : 18}
                    style={{}}
                ></img>
            }
            drawerStyle={{ backgroundColor: "#FFF", borderTopLeftRadius: isMobile ? "0px" : "30px", borderBottomLeftRadius: isMobile ? "0px" : "30px", boxShadow: "-3px 3px 34px #39393929" }}
        >
            {addCoupon ? <div className={s.drawerCouponBody}>
                {isMobile ? <p>Crée des coupons pour attirer de nouveaux élèves et augmenter ta visibilité sur la plateforme ! Offre des réductions et fais découvrir tes cours à plus de monde.</p> : <p>Crée des coupons pour attirer de nouveaux élèves et augmenter ta visibilité sur<br /> la plateforme ! Offre des réductions et fais découvrir tes cours à plus de monde.</p>}
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleFormSubmit}
                    style={{
                        width: "100%",
                        background: "#f9f9f9",
                        paddingTop: "20px",
                        borderRadius: "10px",
                    }}
                >
                    {/* Nature du coupon */}
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start" }}>
                        <div style={{ width: isMobile ? "50%" : "40%", fontSize: isMobile ? "0.8em" : "14px", fontFamily: "Poppins", fontWeight: "500", color: "#707070", marginTop: isMobile ? "8px" : "5px" }}>Nature du coupon</div>
                        <Form.Item
                            name="couponType"
                            rules={[{ required: true, message: "Merci de sélectionner une option" }]}
                            style={{ width: "50%", border: "1px solid #707070", borderRadius: "8px" }}
                            initialValue={"FREE_CLASS"}
                        >
                            <Select placeholder="Sélectionne la nature du coupon" value={"FREE_CLASS"} defaultValue={"FREE_CLASS"} bordered={false} onChange={(value) => setCouponNature(value)}>
                                <Option value="FREE_CLASS">Cours gratuit</Option>
                                {/* <Option value="SESSION_DISCOUNT">Nombre de séances</Option> */}
                                <Option value="SPECIAL_DISCOUNT">Remise spéciale</Option>
                            </Select>
                        </Form.Item>
                    </div>

                    {/* Valide jusqu'à */}
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", marginTop: isMobile ? "5px" : "10px" }}>
                        <div style={{ width: isMobile ? "50%" : "40%", fontSize: isMobile ? "0.8em" : "14px", fontFamily: "Poppins", fontWeight: "500", color: "#707070", marginTop: isMobile ? "8px" : "5px" }}>{/* Valide jusqu’au */}Planning d'activation</div>
                        <div style={{ width: isMobile ? "50%" : "60%", display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "flex-start", justifyContent: "flex-start" }}>
                            <Form.Item
                                name="validUntil"
                                rules={[{ required: false, message: "Merci de sélectionner une date" }]}
                                style={{ width: isMobile ? "100%" : "83%", border: "1px solid #707070", borderRadius: "8px" }}
                            >
                                <DatePicker.RangePicker
                                    style={{ width: "100%" }}
                                    placeholder={["Date de début", "Date de fin"]}
                                    bordered={false}
                                />
                            </Form.Item>
                            <Form.Item name="validUntilLife" valuePropName="checked" style={{ width: isMobile ? "100%" : "20%", marginLeft: isMobile ? "0px" : "10px", display: "none" }}>
                                <Checkbox style={{ fontSize: isMobile ? "0.75em" : "12px" }}>À vie</Checkbox>
                            </Form.Item>
                        </div>
                    </div>

                    {/* Nombre de séances */}
                    {showCoursesNumber && <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", marginTop: isMobile ? "5px" : "10px" }}>
                        <div style={{ width: isMobile ? "50%" : "40%", fontSize: isMobile ? "0.8em" : "14px", fontFamily: "Poppins", fontWeight: "500", color: "#707070", marginTop: isMobile ? "8px" : "5px" }}>Limite d'utilisation</div>
                        <div style={{ width: isMobile ? "50%" : "60%", display: "flex", flexDirection: isMobile ? "column" : "row", alignItems: "flex-start", justifyContent: "flex-start" }}>
                            <Form.Item
                                name="numberOfSessions"
                                rules={[
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (getFieldValue("unlimitedUse")) {
                                                return Promise.resolve();
                                            }
                                            return value ? Promise.resolve() : Promise.reject(new Error("Merci de choisir un nombre"));
                                        }
                                    })
                                ]}
                                style={{ width: isMobile ? "100%" : "30%", border: "1px solid #707070", borderRadius: "8px" }}
                            >
                                <InputNumber
                                    min={1}
                                    max={50}
                                    defaultValue={3}
                                    bordered={false}
                                    controls={true}
                                    onChange={(value) => setSessionNumber(value)}
                                    disabled={isUnlimited}
                                />
                            </Form.Item>

                            {/* Utilisation illimitée */}
                            <Form.Item name="unlimitedUse" valuePropName="checked" style={{ width: isMobile ? "100%" : "50%", marginLeft: isMobile ? "0px" : "10px" }}>
                                <Checkbox onChange={(e) => { setIsUnlimited(e.target.checked); form.validateFields(["numberOfSessions"]) }} style={{ fontSize: isMobile ? "0.75em" : "12px" }}>Utilisation Illimitée</Checkbox>
                            </Form.Item>
                        </div>
                    </div>}

                    {/* Remise */}
                    {showDiscount && <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", marginTop: isMobile ? "5px" : "10px" }}>
                        <div style={{ width: isMobile ? "50%" : "40%", fontSize: isMobile ? "0.8em" : "14px", fontFamily: "Poppins", fontWeight: "500", color: "#707070", marginTop: isMobile ? "8px" : "5px" }}>Remise</div>
                        <div style={{ width: isMobile ? "50%" : "60%", display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start" }}>
                            <Form.Item
                                name="discount"
                                rules={[{ required: true, message: "Merci de sélectionner une remise" }]}
                                style={{ width: isMobile ? "100%" : "30%", border: "1px solid #707070", borderRadius: "8px" }}
                            >
                                <Select bordered={false}>
                                    <Option value={10} label="10%">10%</Option>
                                    <Option value={20} label="20%">20%</Option>
                                    <Option value={30} label="30%">30%</Option>
                                    <Option value={40} label="40%">40%</Option>
                                    <Option value={50} label="50%">50%</Option>
                                </Select>
                            </Form.Item>
                        </div>
                    </div>}

                    {/* Qui peut l’utiliser */}
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", marginTop: isMobile ? "5px" : "10px" }}>
                        <div style={{ width: isMobile ? "50%" : "40%", fontSize: isMobile ? "0.8em" : "14px", fontFamily: "Poppins", fontWeight: "500", color: "#707070", marginTop: isMobile ? "8px" : "5px" }}>À destination de</div>
                        <Form.Item
                            name="targetUsers"
                            rules={[{ required: true, message: "Merci de sélectionner une option" }]}
                            style={{ width: "50%", border: "1px solid #707070", borderRadius: "8px" }}
                        >
                            <Select placeholder="Sélectionnez les utilisateurs" bordered={false} onChange={(value) => setTarget(value)}>
                                <Option value="ALL">Tout le monde</Option>
                                <Option value="MY_STUDENTS">Mes élèves</Option>
                                <Option value="SPECIFIC_STUDENT">Offre à un élève spécifique</Option>
                            </Select>
                        </Form.Item>
                    </div>

                    {/* Qui peut l’utiliser */}
                    {showStudentEmailInput && <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "flex-start", marginTop: isMobile ? "5px" : "10px" }}>
                        <div style={{ width: isMobile ? "50%" : "40%", fontSize: isMobile ? "0.8em" : "14px", fontFamily: "Poppins", fontWeight: "500", color: "#707070", marginTop: isMobile ? "8px" : "5px" }}>Élève bénéficiaire</div>
                        <Form.Item
                            name="studentEmail"
                            rules={[{ required: true, message: "Merci de saisir le mail de l'élève" }]}
                            style={{ width: "50%", border: "1px solid #707070", borderRadius: "8px" }}
                        >
                            <Input
                                placeholder="Entre l'email de l'élève"
                                bordered={false}
                                type="text"
                            />
                        </Form.Item>
                    </div>}

                    {/* Appliquer ce coupon automatiquement */}
                    <Form.Item name="applyAutomatically" valuePropName="checked" style={{ marginTop: isMobile ? "5px" : "10px", display: "none" }}>
                        <Checkbox style={{ fontSize: isMobile ? "0.75em" : "12px", color: "#868686" }}>Appliquer ce coupon automatiquement</Checkbox>
                    </Form.Item>

                    {/* Submit Button */}
                    <Form.Item>
                        <Button htmlType="submit" className={s.newCouponButton} loading={loadingCoupon}>
                            Créer le coupon
                        </Button>
                    </Form.Item>
                </Form>
            </div> : <div className={s.drawerBody}>
                {teacherCoupons.length > 0 ? <div>
                    {isMobile ? <p>Voici tous tes coupons actifs, prêts à être utilisés par tes élèves pour profiter pleinement de tes offres !</p> : <p>Voici tous tes coupons actifs, prêts à être utilisés par tes élèves<br />pour profiter pleinement de tes offres !</p>}
                    {teacherCoupons.map((coupon: any, index: number) => {
                        return (
                            <>
                                <div className={coupon.couponType === "FREE_CLASS" && coupon.couponStatus === "ACTIVE" ? s.ticket : coupon.couponType === "SESSION_DISCOUNT" && coupon.couponStatus === "ACTIVE" ? s.ticketSessions : coupon.couponType === "SPECIAL_DISCOUNT" && coupon.couponStatus === "ACTIVE" ? s.ticketDiscount : s.ticketDisabled}>
                                    <div style={{ height: "25px", width: isMobile ? "8px" : "25px", background: "#FFF", position: "absolute", left: isMobile ? "-10px" : "-25px", zIndex: "2" }}></div>
                                    <div className={s.type}>
                                        <div style={{ height: "10px", width: "10px", background: coupon.couponType === "FREE_CLASS" && coupon.couponStatus === "ACTIVE" ? "#B88F4B" : coupon.couponType === "SESSION_DISCOUNT" && coupon.couponStatus === "ACTIVE" ? "#4BA8B9" : coupon.couponType === "SPECIAL_DISCOUNT" && coupon.couponStatus === "ACTIVE" ? "#60CD95" : "#AFAFAF", margin: "auto", borderRadius: "50%", marginRight: "5px" }}></div>
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", justifyContent: "flex-start" }}>
                                            {coupon.couponType === "FREE_CLASS" ? "Cours gratuit" : coupon.couponType === "SESSION_DISCOUNT" ? "Nombre de séances" : "Remise spéciale"}
                                            <span style={{}}>{"Code : " + coupon.code}</span>
                                        </div>
                                    </div>
                                    <span className={s.details}>{coupon.requiredSessions < 1000 && coupon.requiredSessions} {coupon.requiredSessions < 2 ? "séance" : coupon.requiredSessions < 1000 ? "séances" : "illimité"}</span>
                                    <span className={s.discount}>{coupon.couponType === "FREE_CLASS" ? "Gratuit" : "-" + coupon.discountRate + "%"}</span>
                                    <div style={{ display: "flex", flexDirection: "row", height: "50px !important", width: "50px !important" }}>
                                        <Popconfirm
                                            placement="top"
                                            title={
                                                "Veux-tu modifier ce coupon ?"
                                            }
                                            onConfirm={editCoupon}
                                            visible={visibleEdit === index}
                                            /* onVisibleChange={(visible) => {
                                                if (!isEditingCoupon) {
                                                    setVisibleDeleteIndex(index);
                                                }
                                            }} */
                                            okText={isEditingCoupon ? <Spin size="small" /> : "Oui"}
                                            cancelText="Non"
                                            onCancel={handleCancelEdit}
                                            okButtonProps={{ disabled: isEditingCoupon }}
                                        >
                                            <Button style={{ display: visibleEdit === index ? "block" : "none", width: "5px !important" }}></Button>
                                        </Popconfirm>

                                        <Popconfirm
                                            placement="top"
                                            title={
                                                "Veux-tu désactiver ce coupon ?"
                                            }
                                            onConfirm={() => desactivateCoupon(coupon?._id)}
                                            visible={visibleDesactivate === index}
                                            onVisibleChange={(visible) => {
                                                if (visibleDesactivate === index && !isDesactivatingCoupon) {
                                                    setVisibleDesactivateIndex(null);
                                                }
                                            }}
                                            okText={isDesactivatingCoupon ? <Spin size="small" /> : "Oui"}
                                            cancelText="Non"
                                            onCancel={handleCancelDesactivate}
                                            okButtonProps={{ disabled: isDesactivatingCoupon }}
                                        >
                                            <Button style={{ display: visibleDesactivate === index ? "block" : "none", width: "5px !important" }}></Button>
                                        </Popconfirm>

                                        <Popconfirm
                                            placement="top"
                                            title={
                                                "Veux-tu supprimer ce coupon ?"
                                            }
                                            onConfirm={deleteCoupon}
                                            visible={visibleDelete === index}
                                            /* onVisibleChange={(visible) => {
                                                if (!isDeletingCoupon) {
                                                    setVisibleDeleteIndex(index);
                                                }
                                            }} */
                                            okText={isDeletingCoupon ? <Spin size="small" /> : "Oui"}
                                            cancelText="Non"
                                            onCancel={handleCancelDelete}
                                            okButtonProps={{ disabled: isDeletingCoupon }}
                                        >
                                            <Button style={{ display: visibleDelete === index ? "block" : "none", width: "5px !important" }}></Button>
                                        </Popconfirm>

                                        <Popover
                                            key={index}
                                            visible={visibleIndex === index}
                                            placement="left"
                                            content={content}
                                            trigger="click"
                                            style={{ height: "70px !important", borderRadius: "8px", boxShadow: "4px 8px 16px #8B8B8B38", border: "0.5px solid #BFBCBC" }}
                                            className={s.customPopover}
                                            overlayClassName={s.customPopover}
                                        >
                                            <div ref={popoverRef} style={{ height: "20px", width: "25px" }} onClick={(e: any) => { e.stopPropagation(); togglePopover(index); }}><img src={MoreOptions} height={4} ></img></div>
                                        </Popover>
                                    </div>
                                    <div style={{ height: "25px", width: isMobile ? "8px" : "25px", background: "#FFF", position: "absolute", right: isMobile ? "-10px" : "-25px", zIndex: "2" }}></div>
                                </div>
                            </>
                        );
                    })}
                    <Button className={s.newCouponButton} onClick={() => setAddCoupon(true)}>Ajouter un coupon</Button>
                </div> : <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", textAlign: "center", fontSize: isMobile ? "0.8em" : "16px" }}>
                    Aucun coupon actif
                    <Button className={s.newCouponButton} onClick={() => setAddCoupon(true)}>Crée ton premier coupon</Button>
                    <img src={AlbertEmptyData} height={isMobile ? 150 : 350}></img>
                </div>}
            </div>}
        </Drawer>
    );
};

export default CouponsDrawer;
